import { Fragment } from "react";
import { Dialog, Transition, Tab } from "@headlessui/react";
import {
  CheckCircleIcon,
  ClockIcon,
  CloudArrowUpIcon,
  InformationCircleIcon,
  KeyIcon,
  PencilIcon,
  TrashIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { PlusCircleIcon } from "@heroicons/react/24/outline";
import { XCircleIcon } from "@heroicons/react/24/outline";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import axios from "axios";
import Toast from "../../components/Cards/Toast";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";
import { getOrders } from "../../../features/Global/Orders";
export default function SlideOver({ Title, open, setOpen, id }) {
  const [checkPassword, setCheckPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [toast, setToast] = useState(false);
  const [disable, setDisable] = useState(false);
  const [error, setError] = useState(false);
  const [checkpasswordType, setCheckPasswordType] = useState();
  const [message, setMessage] = useState("");
  const [file, setFile] = useState();
  const [preview, setPreview] = useState();
  const handleSelect = (e) => {
    setFile(e.target.files[0]);
    const objectUrl = URL.createObjectURL(e.target.files[0]);
    setPreview(objectUrl);
  };
  const dispatch = useDispatch();
  const strongRegex = new RegExp(
    "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
  );
  const mediumRegex = new RegExp(
    "^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})"
  );
  const config = {
    headers: {
      Authorization: `Bearer ${Cookies.get("dashboardToken")}`,
    },
  };
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm();
  useEffect(() => {
    if (password === newPassword && password && newPassword !== "") {
      setCheckPassword(true);
      if (strongRegex.test(password)) {
        setCheckPasswordType(1);
      } else if (mediumRegex.test(password)) {
        setCheckPasswordType(2);
      } else {
        setCheckPasswordType(3);
      }
    } else {
      setCheckPassword(false);
    }
  }, [password, newPassword]);
  const onSubmit = (data) => {
    setDisable(true);
    // console.log(password, newPassword);
    if (checkpasswordType <= 2) {
      if (password === newPassword) {
        data.userId = id;
        data.newPassword = password;
        // console.log(data); 
        axios
          .post("api/DashboardUsers", data, config)
          .then((r) => {
            setDisable(false);
            console.log(r);
            if (r.status === 200 || r.data === 204) {
              setToast(true);
              dispatch(getOrders());
              setMessage("Password Updated Successfully");
              setOpen(false);
            }
          })
          .catch((e) => {
            setDisable(false);
            // console.log(e);
            if (
              e.response.status === 400 &&
              e.response.data.message ===
                "email or phone number is already taken"
            ) {
              console.log(e.response.data);
              setMessage(e.response.data);
              setError(true);
            }
          });
      }
    }
  };
  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10 " onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-full p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-2xl overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <div className="py-10 text-center text-white bg-gradient-to-br from-blue-600 to-blue-500">
                  <p className={` text-2xl font-semibold`}>
                    <PencilIcon className="inline mx-2 w-7 " /> Update User
                    Password
                  </p>
                </div>
                <form
                  className="px-8 py-8 space-y-2"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <div className="my-2 antialiased text-gray-600 ">
                    <div className="flex flex-col justify-center my-2">
                      <div className="w-full max-w-2xl mx-auto my-2 bg-white rounded-sm ">
                        <div className="w-full p-3">
                          <div className="overflow-x-auto">
                            <label className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-300">
                              New password
                              <span className="float-right mr-2">
                                {password.length > 0 &&
                                  newPassword.length > 0 && (
                                    <>
                                      <>
                                        {" "}
                                        {checkpasswordType === 1 ? (
                                          <KeyIcon className="inline w-6 text-blue-600" />
                                        ) : (
                                          ""
                                        )}{" "}
                                      </>
                                      <>
                                        {" "}
                                        {checkpasswordType === 2 ? (
                                          <KeyIcon className="inline w-6 text-secondary-400" />
                                        ) : (
                                          ""
                                        )}{" "}
                                      </>
                                      <>
                                        {" "}
                                        {checkpasswordType === 3 ? (
                                          <KeyIcon className="inline w-6 text-rose-500" />
                                        ) : (
                                          ""
                                        )}{" "}
                                      </>
                                    </>
                                  )}
                              </span>
                            </label>
                            <input
                              type="password"
                              placeholder="new password"
                              onChange={(e) => setPassword(e.target.value)}
                              className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white border ${
                                errors.EnglishTitle && "border-rose-500"
                              } bg-white py-2 px-6 text-base font-medium text-gray-700 outline-none focus:border-blue-500 focus:shadow-md dark:bg-gray-700 dark:text-gray-300`}
                            />
                          </div>
                          <div className="mt-2 overflow-x-auto">
                            <label className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-300">
                              Confirm password
                              <span className="float-right mr-2">
                                {password.length > 0 &&
                                  newPassword.length > 0 && (
                                    <>
                                      <>
                                        {" "}
                                        {checkpasswordType === 1 ? (
                                          <KeyIcon className="inline w-6 text-blue-600" />
                                        ) : (
                                          ""
                                        )}{" "}
                                      </>
                                      <>
                                        {" "}
                                        {checkpasswordType === 2 ? (
                                          <KeyIcon className="inline w-6 text-secondary-400" />
                                        ) : (
                                          ""
                                        )}{" "}
                                      </>
                                      <>
                                        {" "}
                                        {checkpasswordType === 3 ? (
                                          <KeyIcon className="inline w-6 text-rose-500" />
                                        ) : (
                                          ""
                                        )}{" "}
                                      </>
                                    </>
                                  )}
                              </span>
                            </label>
                            <input
                              type="password"
                              onChange={(e) => setNewPassword(e.target.value)}
                              placeholder="Confirm password"
                              className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white border ${
                                errors.EnglishTitle && "border-rose-500"
                              } bg-white py-2 px-6 text-base font-medium text-gray-700 outline-none focus:border-blue-500 focus:shadow-md dark:bg-gray-700 dark:text-gray-300`}
                            />
                          </div>
                          <div className="my-3 ">
                            {checkPassword === true &&
                            newPassword.length > 0 &&
                            password.length > 0 ? (
                              <div className="my-2 text-sm text-secondary-500">
                                <CheckCircleIcon className="inline w-6 h-6" />{" "}
                                Passwords Matched
                              </div>
                            ) : (
                              <>
                                {newPassword.length > 0 &&
                                  password.length > 0 && (
                                    <>
                                      <div className="text-sm text-rose-500">
                                        <XCircleIcon className="inline w-6 h-6" />{" "}
                                        Passwords Not Match
                                      </div>
                                    </>
                                  )}
                              </>
                            )}{" "}
                            {checkpasswordType > 2 && (
                              <div className={` text-rose-600 mt-1 text-sm `}>
                                <>
                                  {" "}
                                  <InformationCircleIcon className="inline-flex w-6 text-rose-600" />{" "}
                                  new Password is too weak can not change
                                  password!
                                </>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <button
                    type="submit"
                    disabled={disable}
                    className="px-6 py-2 mx-1 text-blue-600 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200 hover:text-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent dark:text-blue-100 dark:bg-blue-600"
                  >
                    <PencilIcon className={`inline w-6 mx-1`} /> Update
                  </button>
                </form>
                {toast && (
                  <Toast
                    Text={"Order Status Updated Successfully"}
                    Type={"secondary"}
                    setOpen={setToast}
                  />
                )}
                {error && (
                  <Toast Text={message} Type={"rose"} setOpen={setError} />
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
