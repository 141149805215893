import { Fragment } from "react";
import { Dialog, Transition, Tab } from "@headlessui/react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { setAuth } from "../../../features/web/WebAuthentication";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";
import {
  ArrowRightOnRectangleIcon,
  KeyIcon,
  PhoneIcon,
} from "@heroicons/react/24/outline";
import {
  CheckCircleIcon,
  CloudArrowUpIcon,
  TrashIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { PlusCircleIcon } from "@heroicons/react/24/outline";
import { XCircleIcon } from "@heroicons/react/24/outline";

import { useEffect, useState } from "react";
import axios from "axios";
import login from "../../assets/icons/login.svg";
import { useTranslation } from "react-i18next";

export default function SlideOver({ Title, open, setOpen, id }) {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const onSubmit = (data) => {
    ////    console.log(data);

    data.phoneNumber = data?.phoneNumber
      .replace(/^0/, "")
      .trim()
      .replace(/\s/g, "");
    axios.post("/api/UsersAuth/LogIn", data).then((r) => {
      // // console.log(r);
      if (r.status === 200) {
        Cookies.set("webAuth", r.data.token, { expires: 7 });
        Cookies.set("Customer", JSON.stringify(r.data), { expires: 7 });

        dispatch(setAuth(true));
        setOpen(false);
      }
    });
  };
  const l = localStorage.getItem("i18nextLng");
  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10 " onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-full p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md p-6 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                {/* <Dialog.Title
                  as="h3"
                  className="my-2 text-lg font-medium leading-6 text-gray-900"
                >
                  {t("login")}
                </Dialog.Title> */}

                {/* <form onSubmit={handleSubmit(onSubmit)}> */}
                {/* <div className="container mx-auto my-auto mb-5"> */}
                <div className="flex flex-col max-w-md px-4 py-8 mx-auto bg-white rounded-lg shadow w- dark:bg-gray-800 sm:px-6 md:px-8 lg:px-10">
                  <div
                    className={`self-center mb-2 text-xl font-medium text-gray-600 sm:text-2xl dark:text-white ${l}`}
                  >
                    {t("login")}{" "}
                    {/* <span className="bg-pink-300 ">
                      <ArrowRightOnRectangleIcon className="p-4 mx-auto mt-3 text-white bg-pink-400 rounded-full w-18" />{" "}
                    </span> */}
                    <br />
                  </div>
                  <span className="w-full">
                    <p className={`text-sm text-gray-600 text-center ${l}`}>
                      {t("signIntoContinuePurchase")}
                    </p>
                  </span>
                  <div className="mt-8">
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="flex flex-col mb-2">
                        <label
                          className={`block mb-2 text-sm text-gray-600 ${
                            l === "en-US" ? "" : "float-right text-right"
                          } ${l}`}
                        >
                          {t("phoneNumberPlaceholder")}
                        </label>
                        <div className="relative flex ">
                          {/* <span
                  className={`${
                    l === "en-Us" ? "rounded-l-md" : "rounded-r-md"
                  } inline-flex  items-center px-3 border-t bg-white border-l border-b  border-gray-300 text-gray-500 shadow-sm text-sm`}
                >
                  <PhoneIcon className="w-5 h-5 " />
                </span> */}
                          <input
                            type="tel"
                            className={`w-full px-3 py-3 placeholder-gray-300 border border-gray-300 rounded-xl focus:outline-none focus:ring focus:ring-pink-100 focus:border-pink-600 ${
                              errors.phoneNumber &&
                              "focus:ring-rose-100 focus:border-rose-300"
                            } ${l}`}
                            placeholder={t("phoneNumber")}
                            {...register("phoneNumber", {
                              required: true,
                              pattern: /[0-9]{1}/,
                            })}
                          />
                        </div>
                      </div>
                      <div className="flex flex-col mb-6">
                        <label
                          className={`block mb-2 text-sm text-gray-600 ${
                            l === "en-US" ? "" : "float-right text-right"
                          } ${l}`}
                        >
                          {t("password")}
                        </label>
                        <div className="relative flex ">
                          {/* <span
                  className={`${
                    l === "en-Us" ? "rounded-l-md" : "rounded-r-md"
                  } inline-flex  items-center px-3 border-t bg-white border-l border-b  border-gray-300 text-gray-500 shadow-sm text-sm`}
                >
              
                  <KeyIcon className="w-5 h-5 " />
                </span> */}
                          <input
                            type="password"
                            className={`w-full px-3 py-3 placeholder-gray-300 border border-gray-300 rounded-xl focus:outline-none focus:ring focus:ring-pink-100 focus:border-pink-600 ${
                              errors.password &&
                              "focus:ring-rose-100 focus:border-rose-300"
                            } ${l}`}
                            placeholder={t("password")}
                            {...register("password", { required: true })}
                          />
                        </div>
                      </div>
                      <div className="flex items-center mb-6 -mt-4">
                        {/* <div className="flex ml-auto">
                <Link
                  href="#"
                  className={`inline-flex text-xs font-thin text-gray-500 sm:text-sm dark:text-gray-100 hover:text-gray-700 dark:hover:text-white ${l}`}
                >
                  {t("forgotPassword")}
                </Link>
              </div> */}
                      </div>
                      <div className="flex w-full">
                        <button
                          type="submit"
                          className={`w-full px-4 py-2 text-base font-semibold text-center text-white transition duration-200 ease-in bg-pink-600 rounded-lg shadow-md hover:bg-pink-700 focus:ring-pink-500 focus:ring-offset-pink-200 focus:outline-none focus:ring-2 focus:ring-offset-2  ${l}`}
                        >
                          {t("login")}
                        </button>
                      </div>
                    </form>
                  </div>
                  <div className="flex items-center justify-center mt-6">
                    <Link
                      to="/register"
                      className="inline-flex items-center text-xs font-thin text-center text-gray-500 hover:text-gray-700 dark:text-gray-100 dark:hover:text-white"
                    >
                      <p className={`mt-2 font-medium text-gray-700 ${l}`}>
                        {t("dontHaveAccount")}{" "}
                        <Link
                          to="/register"
                          className={`text-pink-500 cursor-pointer hover:text-pink-700 ${l}`}
                        >
                          {t("createAccount")}
                        </Link>
                      </p>
                    </Link>
                  </div>
                </div>
                {/* </div> */}
                {/*     </form> */}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
