import { useSelect } from "@mui/base";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { setAuth } from "../../../features/web/WebAuthentication";
import { useDispatch } from "react-redux";
import vary from "../../assets/images/vary-removebg-preview.png";
import {
  KeyIcon,
  PhoneIcon,
  UserCircleIcon,
} from "@heroicons/react/24/outline";
import { ArrowRightOnRectangleIcon } from "@heroicons/react/24/outline";
import BreadCrumb from "../../componets/BreadCrumb";
import { getConfiguration } from "../../../features/Global/Configuration";
import SnackBar from "../../componets/Snackbar/SnackBarDynamic";
import ForgotPasswordModal from "./ForgetPasswordModal";
import Cookies from "js-cookie";
function MyApp() {
  const [snackBar, setSnackBar] = useState(false);
  const [forgetPasswordModal, setSforgetPasswordModal] = useState(false);
  const [message, setMessage] = useState();
  const locale = localStorage.getItem("i18nextLng");
  const { t } = useTranslation();
  const data = useSelector((state) => state.Configuration?.data);
  useEffect(() => {
    dispatch(getConfiguration());
  }, []);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const onSubmit = (data) => {
    // console.log(data);

    data.phoneNumber = data?.phoneNumber
      .replace(/^0/, "")
      .trim()
      .replace(/\s/g, "");

    axios
      .post("/api/UsersAuth/LogIn", data)
      .then((r) => {
        // console.log(r);
        if (r.status === 200) {
          Cookies.set("webAuth", r.data.token, { expires: 7 });
          Cookies.set("Customer", JSON.stringify(r.data), { expires: 7 });

          dispatch(setAuth(true));
          navigate("/shop");
        }
        if (r.status === 401 && r.data === "email or password is wrong") {
          setSnackBar(true);
          setMessage("emailOrPasswordIncorrect");
        }
        if (r.status === 400) {
          setSnackBar(true);
          setMessage("tooManyRequestsForLogin");
        }
      })
      .catch((e) => {
        if (e.status === 401 && e.data === "email or password is wrong") {
          setSnackBar(true);
          setMessage("emailOrPasswordIncorrect");
        } else {
          // console.log(e);
          setSnackBar(true);
          setMessage("userDoseNotExist");
        }
      });
    setSnackBar(false);
    // console.log("password not match");
  };
  return (
    <div className="container p-4 mx-auto mt-4 mb-8 text-gray-600 bg-white rounded-lg body-font ">
      <BreadCrumb
        Name={"login"}
        Iconn={ArrowRightOnRectangleIcon}
        className="m-3"
      />

      <div className="flex flex-wrap items-center justify-center">
        <div className="w-full px-4 py-10 text-white lg:w-3/4 lg:mx-2 lg:max-w-xl rounded-3xl bg-gradient-to-t from-pink-700 via-pink-700 to-pink-600 sm:px-10 md:m-6 md:mr-8">
          <p
            className={`mb-10 font-bold tracking-wider text-center text-lg ${locale}`}
          >
            <img
              src={data[0]?.imageUrl}
              className="inline object-cover w-16 h-16 mx-2 rounded-full"
            />{" "}
            {locale === "en-US" && data[0]?.englishName}
            {locale === "ar" && data[0]?.arabicName}
            {locale === "ku" && data[0]?.kurdishName}{" "}
          </p>
          <p
            className={`mb-4 text-3xl font-bold md:text-4xl md:leading-snug ${locale}`}
          >
            {t("loginPageMessage")}
          </p>
          <p className={`mb-28 leading-relaxed text-gray-200 ${locale}`}>
            {t("loginPageMessageDetail")}
          </p>
          <div className="px-4 py-8 bg-pink-600/80 rounded-2xl">
            <div className="flex items-center">
              {/* <img
                    src={data[0]?.imageUrl}
                    className="object-cover w-16 h-16 rounded-full"
                  /> */}
              <p className={`${locale === "en-US" ? "ml-4" : "mr-4"} w-56`}>
                {locale === "en-US" ? (
                  <>
                    <span className={` text-gray-200 font-medium ${locale}`}>
                      {" "}
                      {/* <strong className={`block font-semibold text-md ${locale}`}> */}
                      {/* {t("varyPharmacy")} */}
                      {/* </strong> */}
                      {/* {t("vary")}{" "} */}
                    </span>
                  </>
                ) : (
                  <>
                    <span
                      className={`text-xs text-gray-200 font-medium ${locale}`}
                    >
                      {/* {t("pharmacy")}{" "} */}
                    </span>
                    <strong className={`block font-medium ${locale}`}>
                      {/* {t("vary")} */}
                      {locale === "en-US" && data[0]?.englishName}
                      {locale === "ar" && data[0]?.arabicName}
                      {locale === "ku" && data[0]?.kurdishName}{" "}
                    </strong>
                  </>
                )}
              </p>
            </div>
            {/* <p className={`mt-3 text-gray-200 text-lg font-medium ${locale} `}>
              {t("loginMot")}
            </p> */}
          </div>
        </div>
        <div className="flex-col w-full mx-4 my-3 bg-white rounded-lg shadow-2xl lg:mx-auto lg:flex lg:w-2/5">
          <div className="mx-2 my-6 text-center">
            <h1 className={`text-3xl font-semibold text-gray-800 ${locale}`}>
              {t("login")}
            </h1>
            <p className={`mt-2 font-medium text-gray-700 ${locale}`}>
              {t("dontHaveAccount")}{" "}
              <Link
                to="/register"
                className={`text-pink-500 cursor-pointer hover:text-pink-700 ${locale}`}
              >
                {t("createAccount")}
              </Link>
            </p>
          </div>
          <div className="pb-8 mx-6">
            <form className="mb-4" onSubmit={handleSubmit(onSubmit)}>
              <div className="mb-6">
                <label
                  htmlFor="email"
                  className={`block mb-2 text-sm text-gray-600 ${locale}`}
                >
                  {t("PhoneNumberLabel")}
                </label>
                <input
                  type="tel"
                  name="email"
                  id="email"
                  placeholder={t("770(50)000 00 00")}
                  style={{ direction: "ltr" }}
                  {...register("phoneNumber", {
                    required: true,
                    pattern: /[0-9]{1}/,
                  })}
                  className={`w-full px-3 py-3 placeholder-gray-300 border border-gray-300 rounded-xl focus:outline-none focus:ring focus:ring-pink-100 focus:border-pink-600 ${
                    errors.phoneNumber &&
                    "focus:ring-rose-100 focus:border-rose-300"
                  } ${locale}`}
                />
              </div>
              <div className="mb-6">
                <div className="flex justify-between mb-2">
                  <label
                    htmlFor="password"
                    className={`text-sm text-gray-600 ${locale}`}
                  >
                    {t("password")}
                  </label>
                </div>
                <input
                  type="password"
                  name="password"
                  id="password"
                  placeholder={t("passwordPlaceholder")}
                  className={`w-full px-3 py-3 placeholder-gray-300 border  border-gray-300 rounded-xl focus:outline-none focus:ring focus:ring-pink-100 focus:border-pink-600 ${
                    errors.password &&
                    "focus:ring-rose-100 focus:border-rose-300"
                  } ${locale}`}
                  {...register("password", { required: true })}
                />
              </div>
              <div className="mb-6">
                <button
                  type="submit"
                  className={`w-full px-3 py-3 text-lg font-medium text-white duration-100 ease-in-out bg-pink-600 rounded-xl hover:bg-pink-400 focus:outline-none ${locale}`}
                >
                  {t("login")}
                </button>
              </div>
              <p className={`mt-2 font-medium text-gray-700 ${locale}`}>
                <Link
                  to="/reset-password"
                  className={`text-sm text-gray-400 focus:outline-none focus:text-pink-500 hover:text-pink-500 dark:hover:text-pink-300 ${
                    locale === "en-US" ? "float-right" : "float-left"
                  } ${locale}`}
                >
                  {t("forgetPassword")}
                </Link>
              </p>
            </form>
            {forgetPasswordModal && (
              <ForgotPasswordModal
                open={forgetPasswordModal}
                setOpen={setSforgetPasswordModal}
              />
            )}
            {snackBar && <SnackBar Title={message} />}
          </div>
        </div>
      </div>
    </div>
  );
}

export default MyApp;
