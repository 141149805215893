import React from 'react'
import st from './style.css'
const Loading = () => {
  return (
	<div className='flex justify-center align-middle h-96'>

<span className="loader"></span>  
	</div>
  )
}

export default Loading
