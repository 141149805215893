import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  Fragment,
} from "react";
import BreadCrumb from "../../components/Cards/BreadCrumb";
import Header from "../../components/Sidebar/Header";
import Sidebar from "../../components/Sidebar/Sidebar";
import { useLocation } from "react-router-dom";
import orders from "../../icons/chat.svg";
import InfoCard from "../../components/Cards/Carts";
import { Player } from "@lottiefiles/react-lottie-player";
import active from "../Anim/Chat.json";
import Inactive from "../Anim/inActive.json";
import Cookies from "js-cookie";
import { motion } from "framer-motion";
import { ReactMic } from "react-mic";
import { setUser } from "../../../features/Global/Dashboard/CurrentChatUser";
import DeleteDropDown from "./DeleteDropDown";
// redux mapping
// import { getMessages } from "../../features/Global/MessagesDashborad";
import {
  HttpTransportType,
  HubConnectionBuilder,
  LogLevel,
} from "@microsoft/signalr";
import { useDispatch, useSelector } from "react-redux";
import { getCustomers } from "../../../features/Global/Customers";
import { setHubConnection } from "../../../features/Global/Dashboard/HubConnection";
import {
  filterDate,
  filterStatus,
  getChatUsers,
  resetFilter,
  searchFilter,
} from "../../../features/Global/ChatUsers";
import {
  ArrowPathIcon,
  ChatBubbleLeftRightIcon,
  ChatBubbleOvalLeftEllipsisIcon,
  CheckIcon,
  ChevronDownIcon,
  MagnifyingGlassIcon,
  MicrophoneIcon,
  PhoneIcon,
  PhotoIcon,
  UserCircleIcon,
} from "@heroicons/react/24/outline";
import axios from "axios";
import { ChatBubbleLeftEllipsisIcon } from "@heroicons/react/20/solid";
import { getMessages } from "../../../features/Global/Messages";
import { Connect } from "../../../features/Global/Dashboard/DashbaordChat";
import Toast from "../../components/Cards/Toast";
import { Menu, Transition } from "@headlessui/react";
const Chat = () => {
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  const bottomRef = useRef(null);

  const data = useSelector((state) => state.Customers.data);
  const CurrnetUser = useSelector((state) => state.CurrentChatUser?.value);
  // console.log(data);
  const user = Cookies.get("Customer")
    ? JSON.parse(Cookies.get("Customer"))
    : "";
  const [customer, setCustomer] = useState("");
  const [sendMessageData, setSendMessageData] = useState();
  const [messagesData, setMessagesData] = useState([]);
  const [activeCustomer, setActiveCustomer] = useState();
  const [disable, setDisable] = useState(false);
  const [fetch, setFetch] = useState(true);
  const dispatch = useDispatch();
  const messagesEndRef = useRef(null);
  const [search, setsearch] = useState();
  const [selectedFile, setSelectedFile] = useState(null);
  const [isRecording, setIsRecording] = useState(null);
  const [filter, setFilter] = useState("Unseen");
  const [dropDown, setDropDown] = useState(false);

  const toggleDropdown = () => {
    setDropDown(!dropDown);
  };
  // const connection = useSelector((state) => state.DaashboardChat?.value);
  const [connection, setConnection] = useState("");
  const config = {
    headers: {
      Authorization: `Bearer ${Cookies.get("dashboardToken")}`,
    },
  };
  // adding check state for getting oce fetch
  useEffect(() => {
    dispatch(getChatUsers());
    if (!connection && Cookies.get("dashboardToken")) {
      const connection = new HubConnectionBuilder()
        // .withUrl(`https://localhost:7247/chatHub`, {
        .withUrl(`https://varyhealthcare.com/api/chatHub`, {
          accessTokenFactory: () => Cookies.get("dashboardToken"),
          UseDefaultCredentials: true,
          skipNegotiation: true,
          transport: HttpTransportType.WebSockets,
        })
        .configureLogging(LogLevel.Information)
        .withAutomaticReconnect()
        .build();
      connection.logging = true;
      connection.start();
      setConnection(connection);
      dispatch(setHubConnection(connection));
    }
  }, [CurrnetUser, connection]);
  const handleCustomer = (item) => {
    setCustomer(item);
    dispatch(setUser(item));
    // dispatch(getCustomers());
    // dispatch(getChatUsers());
    axios.get(`api/Messages/${item?.id}`, config).then((r) => {
      setMessagesData(r.data);
      setActiveCustomer(() => item?.id); // Use callback in setActiveCustomer
      bottomRef.current?.scrollIntoView();

      if (connection) {
        connection.on("receive", (message) => {
          if (item?.id > 0) {
            const config = {
              headers: {
                Authorization: `Bearer ${Cookies.get("dashboardToken")}`,
              },
            };
            axios.get(`api/Messages/${item?.id}`, config).then((r) => {
              // Filter messages by current user id
              const filteredMessages = r.data.filter(
                (msg) => msg.userId === item.id
              );
              setMessagesData(filteredMessages);
              // dispatch(getCustomers());
              dispatch(getChatUsers());
              // dispatch(getMessages());
              bottomRef.current?.scrollIntoView();
            });
          }
        });
      }
      bottomRef.current?.scrollIntoView();
    });
  };

  // useEffect(() => {
  //   // 👇️ scroll to bottom every time messages change
  //   bottomRef.current?.scrollIntoView();
  // }, [messagesData]);

  const [message, setMessage] = useState(false);
  const messages = useSelector((state) => state.MessagesDashborad.data);
  const chatUsers = useSelector((state) => state.ChatUsers.copyData);

  const sendMessage = (userId, messagedataa) => {
    if (isVoiceSend === false) {
      handleSendRecording(user?.id);
    } else {
      if (
        messagedataa !== null ||
        messagedataa !== "" ||
        messagedataa !== "undefined"
      ) {
        if (messagedataa.trim()?.length > 0) {
          //Join Room on Start

          if (messagedataa?.length > 0) {
            if (!connection?._connectionStarted) {
              dispatch(Connect());
            }
            connection.invoke(
              "SendMessage",
              userId?.toString(),
              messagedataa?.toString()
            );
            setSendMessageData("");
          }

          axios.get(`api/Messages/${CurrnetUser?.id}`, config).then((r) => {
            setMessagesData(r.data);
            bottomRef.current?.scrollIntoView();
          });
        }
      }
    }
  };

  useEffect(() => {
    if (connection) {
      connection.on("receive", (message) => {
        if (CurrnetUser) {
          console.log("customer", CurrnetUser.id);
          console.log("user", user);
          const config = {
            headers: {
              Authorization: `Bearer ${Cookies.get("dashboardToken")}`,
            },
          };
          axios.get(`api/Messages/${CurrnetUser?.id}`, config).then((r) => {
            // Filter messages by current user id
            const filteredMessages = r.data.filter(
              (msg) => msg.userId === CurrnetUser.id
            );
            setMessagesData(filteredMessages);
            // dispatch(getCustomers());
            dispatch(getChatUsers());
            // dispatch(getMessages());
            bottomRef.current?.scrollIntoView();
          });
        }
      });
    }
  }, [connection, CurrnetUser, dispatch]);

  const closeConnection = () => {
    connection.onclose((e) => {
      console.log("close connection", e);
    });
  };

  const handleFilter = (e) => {
    dispatch(searchFilter(e.target.value));
  };
  const handleEnterKey = (e) => {
    if (e.keyCode === 13) {
      // Do your action here when the Enter key is pressed
      sendMessage(CurrnetUser?.id, sendMessageData);
    }
  };

  const handleFileChange = (e, userId) => {
    const config = {
      headers: {
        Authorization: `Bearer ${Cookies.get("dashboardToken")}`,
      },
    };
    setSelectedFile(e.target.files[0]);
    var form = new FormData();
    form.append("File", e.target.files[0]);
    form.append("UserId", userId);
    console.log(selectedFile);
    console.log(user.id);
    axios
      .post("api/Messages", form, config)
      .then((r) => {
        console.log("send.......");
        if (r.status === 200) {
          connection.invoke("SendFile", userId?.toString());
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const [recordedChunks, setRecordedChunks] = useState([]);
  const [isVoiceSend, setIsVoiceSend] = useState(null);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [micAccess, setMicAccess] = useState(null);
  const handleStartRecording = (userId) => {
    if (!isRecording) {
      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then((stream) => {
          const recorder = new MediaRecorder(stream);
          setMediaRecorder(recorder);
          recorder.start();
          setIsRecording(true);
          setRecordedChunks([]);

          recorder.addEventListener("dataavailable", (event) => {
            setRecordedChunks((prevChunks) => [...prevChunks, event.data]);
          });

          recorder.addEventListener("stop", () => {
            setIsRecording(false);
            console.log("stop recording");
            setIsVoiceSend(false);

            //   const audioBlob = new Blob(recordedChunks, {
            //     type: "audio/webm",
            //     name: ".webm",
            //     fileName: ".webm",
            //   });
            //   const formData = new FormData();
            //   formData.append("File", audioBlob, "recorded_audio.webm"); // Set the desired file name
            //   formData.append("UserId", userId);
            //   for (const value of formData.values()) {
            //     console.log(value);
            //   }

            //   const config = {
            //     headers: {
            //       Authorization: `Bearer ${Cookies.get("dashboardToken")}`,
            //       "Content-Type": "multipart/form-data",
            //     },
            //   };

            //   axios
            //     .post("api/Messages", formData, config)
            //     .then((response) => {
            //       console.log("File sent");
            //       connection.invoke("SendFile", userId?.toString());
            //       setIsVoiceSend(true);
            //     })
            //     .catch((error) => {
            //       console.log("Error sending file: ", error);
            //       setIsVoiceSend(false);
            //     });
          });
        })
        .catch((error) => {
          setMicAccess(false);
          console.log("Error accessing microphone: ", error);
          setTimeout(() => {
            setMicAccess(true);
          }, 5000);
        });
    } else {
      if (mediaRecorder && mediaRecorder.state === "recording") {
        mediaRecorder.stop();
      }
    }
  };

  const handleSendRecording = (userId) => {
    // Handle sending the recorded chunks to the server
    const audioBlob = new Blob(recordedChunks, {
      type: "audio/webm",
      name: ".webm",
      fileName: ".webm",
    });
    const formData = new FormData();
    formData.append("File", audioBlob, "recorded_audio.webm"); // Set the desired file name
    formData.append("UserId", userId);
    for (const value of formData.values()) {
      console.log(value);
    }

    const config = {
      headers: {
        Authorization: `Bearer ${Cookies.get("dashboardToken")}`,
        "Content-Type": "multipart/form-data",
      },
    };

    axios
      .post("api/Messages", formData, config)
      .then((response) => {
        console.log("File sent");
        connection.invoke("SendFile", userId?.toString());
        setIsVoiceSend(true);
      })
      .catch((error) => {
        console.log("Error sending file: ", error);
        setIsVoiceSend(false);
      });
  };

  // Record animation
  const buttonVariants = {
    initial: { scale: 1 },
    recording: { scale: 1.2 },
  };

  const handleMeessageStatus = (status) => {
    if (status === "All") {
      setFilter("All");
      // dispatch(filterStatus("All"));
      dispatch(resetFilter());
    } else if (status === "Unseen") {
      setFilter("Unseen");
      dispatch(filterStatus(false));
    } else if (status === "Seen") {
      setFilter("Seen");
      dispatch(filterStatus(true));
    }
  };
  const now = new Date();

  const handleRemoveFilters = () => {
    // dispatch(removeFilter());
    search.current.value = "";
    setFrom("");
    setTo("");
  };
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const handleSelectedFilter = (f, t) => {
    setFrom(f);
    setTo(t);
    dispatch(filterDate([f, t]));
  };
  return (
    <div className="ltr">
      <main className="relative bg-gray-100 dark:bg-gray-800">
        <div className="flex items-start justify-between h-screen overflow-y-auto">
          <Sidebar />
          <div className="flex flex-col w-full h-screen overflow-y-auto md:space-y-4">
            <header className="z-10 flex items-center justify-between w-full h-16">
              <div className="block ml-6 lg:hidden">
                <button className="flex items-center p-2 text-gray-500 bg-white bg-gray-800 rounded-full shadow text-md">
                  <svg
                    width="20"
                    height="20"
                    className="text-gray-400"
                    fill="currentColor"
                    viewBox="0 0 1792 1792"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M1664 1344v128q0 26-19 45t-45 19h-1408q-26 0-45-19t-19-45v-128q0-26 19-45t45-19h1408q26 0 45 19t19 45zm0-512v128q0 26-19 45t-45 19h-1408q-26 0-45-19t-19-45v-128q0-26 19-45t45-19h1408q26 0 45 19t19 45zm0-512v128q0 26-19 45t-45 19h-1408q-26 0-45-19t-19-45v-128q0-26 19-45t45-19h1408q26 0 45 19t19 45z"></path>
                  </svg>
                </button>
              </div>
              {/* Header */}
              <Header />
            </header>
            <div className="h-full px-4 overflow-auto md:px-6">
              {/* <InfoCard /> */}

              <div className="flex h-full antialiased text-gray-800">
                <div className="flex flex-row w-full h-full overflow-x-hidden">
                  <div className="flex flex-col flex-shrink-0 px-2 py-8 overflow-y-scroll bg-white w-72 dark:bg-gray-700">
                    <div className="flex flex-row items-center justify-center w-full h-12">
                      <div className="flex items-center justify-center w-10 h-10 text-secondary-700 bg-secondary-50 rounded-2xl">
                        <ChatBubbleLeftRightIcon className="w-8 h-8" />
                      </div>
                      <div className="ml-2 text-2xl font-bold dark:text-gray-300">
                        Vary Chat
                      </div>
                    </div>
                    <div className="flex flex-col items-center w-full px-4 py-6 mt-4 border border-gray-200 rounded-lg bg-secondary-100">
                      <div className="w-20 h-20 overflow-hidden border rounded-full">
                        <UserCircleIcon
                          className={`p-1 w-full h-full rounded-full ring border border-spacing-2 ${
                            CurrnetUser?.isActive === true
                              ? "ring-secondary-500 dark:ring-secondary-600 border-secondary-500 dark:border-secondary-600"
                              : "ring-rose-300 dark:ring-rose-600 border-rose-500 dark:border-rose-600"
                          } `}
                          src={CurrnetUser?.imageUrl}
                          alt="Bordered avatar"
                        />
                      </div>
                      <div className="mt-2 text-sm font-semibold">
                        {CurrnetUser?.fullName}
                      </div>

                      <div className="flex flex-col items-center min-w-full mt-3">
                        <span
                          className={`text-xs leading-none px-3 py-2 rounded-lg font-bold mx-1 my-1 ${
                            CurrnetUser?.isPhoneNumberConfirmed?.toString() ===
                            "true"
                              ? "bg-indigo-200 text-indigo-500"
                              : "bg-rose-200 text-rose-500"
                          } `}
                        >
                          <PhoneIcon className="inline w-5 mx-1" />:{" "}
                          <span
                            className={`inline-block w-2 h-2 ml-2 mr-1 animate-pulse ${
                              CurrnetUser?.isPhoneNumberConfirmed === true
                                ? "bg-indigo-400"
                                : "bg-rose-500"
                            } rounded-full`}
                          ></span>
                          {CurrnetUser?.isPhoneNumberConfirmed === true
                            ? "Confirmed"
                            : "Not Confirmed"}
                        </span>
                      </div>
                    </div>
                    <div className="flex-row w-full">
                      <div className="flex mt-2 justify-evenly">
                        <span
                          onClick={() => handleMeessageStatus("All")}
                          className={`inline-flex items-center px-2 py-1 text-xs font-medium text-blue-700 rounded-md cursor-pointer ring-1 ring-inset ring-blue-600/10 ${
                            filter === "All" ? "bg-blue-300" : "bg-blue-50"
                          }`}
                        >
                          Reset
                        </span>
                        <span
                          onClick={() => handleMeessageStatus("Unseen")}
                          className={`inline-flex items-center px-2 py-1 text-xs font-medium text-red-700 rounded-md cursor-pointer ring-1 ring-inset ring-red-600/10 ${
                            filter === "Unseen" ? "bg-red-300" : "bg-red-50"
                          }`}
                        >
                          Unseen
                        </span>
                        <span
                          onClick={() => handleMeessageStatus("Seen")}
                          className={`inline-flex items-center px-2 py-1 text-xs font-medium text-green-700 rounded-md cursor-pointer  ring-1 ring-inset ring-green-600/20 ${
                            filter === "Seen" ? "bg-green-300" : "bg-green-50"
                          }`}
                        >
                          Seen
                        </span>
                        <Menu
                          as="div"
                          className="relative inline-block h-full my-auto text-left align-middle "
                        >
                          <div>
                            <Menu.Button className="inline-flex justify-center w-full px-3 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-secondary-500 focus:ring-offset-2 focus:ring-offset-gray-100 dark:focus-ring-offset-gray-600 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-600">
                              Filters
                              <ChevronDownIcon
                                className="w-5 h-5 ml-2 -mr-1"
                                aria-hidden="true"
                              />
                            </Menu.Button>
                          </div>

                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items className="absolute right-0 z-10 w-56 mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dark:bg-gray-700 dark:text-gray-300">
                              <div className="py-1">
                                <Menu.Item>
                                  {({ active }) => (
                                    <button
                                      onClick={() =>
                                        handleSelectedFilter(
                                          new Date(
                                            now.getFullYear(),
                                            now.getMonth(),
                                            now.getDate()
                                          ).toISOString(),
                                          new Date(
                                            now.getFullYear(),
                                            now.getMonth(),
                                            now.getDate() + 1
                                          ).toISOString()
                                        )
                                      }
                                      className={classNames(
                                        active
                                          ? "bg-gray-100 text-gray-900 dark:bg-gray-600 dark:text-gray-200"
                                          : "text-gray-700 dark:text-gray-300",
                                        "block px-4 py-2 text-sm w-full text-left"
                                      )}
                                    >
                                      Today{" "}
                                    </button>
                                  )}
                                </Menu.Item>
                                <Menu.Item>
                                  {({ active }) => (
                                    <button
                                      onClick={() =>
                                        handleSelectedFilter(
                                          new Date(
                                            now.getFullYear(),
                                            now.getMonth(),
                                            now.getDate() - 7
                                          ).toISOString(),
                                          new Date(
                                            now.getFullYear(),
                                            now.getMonth(),
                                            now.getDate()
                                          ).toISOString()
                                        )
                                      }
                                      className={classNames(
                                        active
                                          ? "bg-gray-100 text-gray-900 dark:bg-gray-600 dark:text-gray-200"
                                          : "text-gray-700 dark:text-gray-300",
                                        "block px-4 py-2 text-sm w-full text-left"
                                      )}
                                    >
                                      Last week{" "}
                                    </button>
                                  )}
                                </Menu.Item>
                                <Menu.Item>
                                  {({ active }) => (
                                    <button
                                      onClick={() =>
                                        handleSelectedFilter(
                                          new Date(
                                            now.getFullYear(),
                                            now.getMonth() - 1,
                                            now.getDate()
                                          ).toISOString(),
                                          new Date(
                                            now.getFullYear(),
                                            now.getMonth(),
                                            now.getDate()
                                          ).toISOString()
                                        )
                                      }
                                      className={classNames(
                                        active
                                          ? "bg-gray-100 text-gray-900 dark:bg-gray-600 dark:text-gray-200"
                                          : "text-gray-700 dark:text-gray-300",
                                        "block px-4 py-2 text-sm w-full text-left"
                                      )}
                                    >
                                      Last month{" "}
                                    </button>
                                  )}
                                </Menu.Item>
                                <Menu.Item>
                                  {({ active }) => (
                                    <button
                                      onClick={() =>
                                        handleSelectedFilter(
                                          new Date(
                                            now.getFullYear(),
                                            now.getMonth() - 3,
                                            now.getDate()
                                          ).toISOString(),
                                          new Date(
                                            now.getFullYear(),
                                            now.getMonth(),
                                            now.getDate()
                                          ).toISOString()
                                        )
                                      }
                                      className={classNames(
                                        active
                                          ? "bg-gray-100 text-gray-900 dark:bg-gray-600 dark:text-gray-200"
                                          : "text-gray-700 dark:text-gray-300",
                                        "block px-4 py-2 text-sm w-full text-left"
                                      )}
                                    >
                                      Last three month
                                    </button>
                                  )}
                                </Menu.Item>
                                <Menu.Item>
                                  {({ active }) => (
                                    <button
                                      onClick={() =>
                                        handleSelectedFilter(
                                          new Date(
                                            now.getFullYear() - 1,
                                            now.getMonth(),
                                            now.getDate()
                                          ).toISOString(),
                                          new Date(
                                            now.getFullYear(),
                                            now.getMonth(),
                                            now.getDate()
                                          ).toISOString()
                                        )
                                      }
                                      className={classNames(
                                        active
                                          ? "bg-gray-100 text-gray-900 dark:bg-gray-600 dark:text-gray-200"
                                          : "text-gray-700 dark:text-gray-300",
                                        "block px-4 py-2 text-sm w-full text-left"
                                      )}
                                    >
                                      Last three month
                                    </button>
                                  )}
                                </Menu.Item>
                              </div>
                            </Menu.Items>
                          </Transition>
                        </Menu>
                      </div>
                      <div className="relative my-3 ">
                        <label htmlFor="search" className={`sr-only`}>
                          search
                        </label>
                        <input
                          type="search"
                          id="search"
                          name="search"
                          value={search}
                          placeholder="search"
                          onChange={(e) => handleFilter(e)}
                          className={` block w-full py-3 
                          pl-5 
                           text-sm placeholder-gray-300 border-gray-300 rounded-md shadow-sm  form-input pl-11 bg-gray-50 focus:border-secondary-600 focus:ring-1 focus:ring-secondary-500`}
                        />
                        {/* ::::icon */}
                        <span
                          className={`absolute text-gray-400 transform -translate-y-1/2 top-1/2 left-5 " 
                      `}
                        >
                          <MagnifyingGlassIcon className="w-4 h-4" />
                        </span>
                      </div>

                      <div className="flex flex-col mt-3 overflow-y-auto h-100">
                        <div className="flex flex-row items-center justify-between text-xs">
                          <span className="font-bold dark:text-gray-300">
                            Last Conversations
                          </span>
                          {chatUsers?.filter(
                            (item) => item.seenMessages === false
                          ).length > 0 && (
                            <span className="inline-flex items-center px-2 py-1 text-xs font-medium rounded-md text-rose-700 bg-rose-50 ring-1 ring-inset ring-rose-600/10 animate-pulse">
                              New message{" "}
                              {
                                chatUsers?.filter(
                                  (item) => item.seenMessages === false
                                ).length
                              }
                            </span>
                          )}
                        </div>
                        <div className="flex flex-col h-auto mt-4 -mx-2 space-y-1 overflow-y-auto">
                          {chatUsers?.map((item, index) => (
                            <button
                              key={index}
                              onClick={() => handleCustomer(item)}
                              className={`flex flex-row items-center p-2 hover:bg-gray-100 dark:hover:bg-gray-600 rounded-lg duration-500  ${
                                activeCustomer === item?.id
                                  ? "dark:bg-gray-600 bg-gray-200"
                                  : ""
                              }`}
                            >
                              <div className="flex items-center justify-center w-8 h-8 border rounded-full bg-secondary-200 border-spacing-0 dark:bg-secondary-500 dark:text-gray-50 ku">
                                {item?.fullName?.charAt(0)}
                              </div>
                              <div
                                className={`ml-1 text-sm  ${
                                  item?.seenMessages === true
                                    ? "font-normal"
                                    : "font-semibold"
                                } dark:text-gray-300`}
                              >
                                {item?.fullName}
                              </div>
                            </button>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col flex-auto bg-white dark:bg-gray-800">
                    <div className="flex flex-col flex-auto flex-shrink-0 h-full bg-gray-100 dark:bg-slate-700 rounded-2xl">
                      <div className="flex flex-col h-full mb-4 overflow-x-auto">
                        {CurrnetUser === "" ? (
                          <>
                            <div className="container w-3/4 overflow-hidden h-5/6">
                              <Player
                                src={active}
                                autoplay
                                loop
                                className="inline w-10 player"
                              />{" "}
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="flex flex-col h-full">
                              <div className="grid grid-cols-12 gap-y-2">
                                {messagesData.map((item, index) =>
                                  item?.isFromThisUser === true ? (
                                    <>
                                      <div
                                        className="col-start-1 col-end-8 p-3 rounded-lg"
                                        // ref={wrapper}
                                        key={item.id}
                                      >
                                        <div className="flex flex-row items-center">
                                          <div className="flex items-center justify-center flex-shrink-0 w-10 h-10 bg-white border rounded-full dark:text-secondary-500 border-spacing-0 ku">
                                            {CurrnetUser?.fullName?.charAt(0)}
                                          </div>
                                          <div className="relative px-4 py-2 ml-2 text-sm bg-white shadow dark:bg-gray-800 dark:text-gray-300 rounded-xl">
                                            <div>
                                              {item.type === 1 ? (
                                                <>
                                                  <a
                                                    href={item?.message}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                  >
                                                    <img
                                                      src={item?.message}
                                                      alt="sent image"
                                                      className="max-w-64 max-h-64"
                                                    />
                                                  </a>
                                                </>
                                              ) : (
                                                <>
                                                  {item.type === 3 ? (
                                                    <>
                                                      <audio
                                                        controls
                                                        src={item.message}
                                                      />
                                                    </>
                                                  ) : (
                                                    <>
                                                      {item?.message?.includes(
                                                        "http"
                                                      ) ? (
                                                        <>
                                                          {/* {item.message?.includes()} */}
                                                          <a
                                                            href={item?.message}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            className="text-blue-500 hover:underline"
                                                          >
                                                            {item?.message}
                                                          </a>
                                                        </>
                                                      ) : (
                                                        <>{item?.message}</>
                                                      )}
                                                    </>
                                                  )}
                                                </>
                                              )}
                                            </div>
                                            <div className="flex justify-between w-full mt-2 ">
                                              <span className="text-xs text-gray-500 dark:text-gray-100 ">
                                                {new Date(
                                                  item?.dateTime
                                                )?.toLocaleString(undefined, {
                                                  dateStyle: "short",
                                                  timeStyle: "short",
                                                })}
                                              </span>
                                              <span className={`text-xs `}>
                                                {/* <CheckIcon
                                                  className={`w-4 ${
                                                    item?.isSeenByUser === true
                                                      ? "text-secondary-500"
                                                      : "text-gray-400"
                                                  }`}
                                                /> */}
                                                <span>
                                                  <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className={`icon icon-tabler icon-tabler-checks w-5 h-5 ${
                                                      item?.isSeenByUser ===
                                                      true
                                                        ? "text-secondary-500"
                                                        : "text-gray-400"
                                                    }`}
                                                    viewBox="0 0 24 24"
                                                    strokeWidth="1.5"
                                                    currentColor="green"
                                                    stroke={`${
                                                      item?.isSeenByCustmer ===
                                                      true
                                                        ? "#14b8a6"
                                                        : "#94a3b8"
                                                    }`}
                                                    fill="none"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                  >
                                                    <path
                                                      stroke="none"
                                                      d="M0 0h24v24H0z"
                                                      fill="none"
                                                    />
                                                    <path d="M7 12l5 5l10 -10" />
                                                    <path d="M2 12l5 5m5 -5l5 -5" />
                                                  </svg>
                                                  {/* <CheckIcon className="w-4 text-secondary-500" /> */}
                                                </span>
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      <div
                                        className="col-start-6 col-end-13 p-3 rounded-lg"
                                        // ref={wrapper}
                                      >
                                        <div className="flex flex-row-reverse items-center justify-start">
                                          <div className="flex items-center justify-center flex-shrink-0 w-10 h-10 align-middle rounded-full bg-secondary-500 dark:text-gray-50 ku">
                                            <h1 className="font-semibold ku text-gray-50">
                                              V
                                            </h1>
                                          </div>
                                          <div className="relative px-4 py-2 mr-2 text-sm shadow bg-secondary-100 dark:bg-secondary-700 dark:text-gray-100 rounded-xl">
                                            <span className="absolute right-5">
                                              {/* <ChevronDownIcon
                                                onClick={toggleDropdown}
                                                className="inline w-4 h-4 text-gray-400 duration-300 cursor-pointer hover:text-gray-500"
                                              /> */}
                                              <DeleteDropDown id={item.id} userId={user?.id?.toString() } /> 
                                            </span>
                                            {dropDown && (
                                              <div className="absolute right-0 z-10 w-48 mt-1 bg-white border border-gray-200 rounded-md shadow-lg top-full">
                                                {/* Dropdown content goes here */}
                                                <ul>
                                                  <li className="px-3 py-1 cursor-pointer hover:bg-gray-100">
                                                    Delete
                                                  </li>
                                                </ul>
                                              </div>
                                            )}
                                            <div>
                                              {item.type === 1 ? (
                                                <>
                                                  <a
                                                    href={item?.message}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                  >
                                                    <img
                                                      src={item?.message}
                                                      alt="sent image"
                                                      className="max-w-64 max-h-64"
                                                    />
                                                  </a>
                                                </>
                                              ) : (
                                                <>
                                                  {item.type === 3 ? (
                                                    <>
                                                      <audio
                                                        controls
                                                        src={item.message}
                                                      />
                                                    </>
                                                  ) : (
                                                    <>
                                                      {item?.message?.includes(
                                                        "http"
                                                      ) ? (
                                                        <>
                                                          <a
                                                            href={item?.message}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            className="text-blue-500 hover:underline"
                                                          >
                                                            {item?.message}
                                                          </a>
                                                        </>
                                                      ) : (
                                                        <>{item?.message}</>
                                                      )}
                                                    </>
                                                  )}
                                                </>
                                              )}
                                            </div>
                                            <div className="flex justify-between mx-2">
                                              <span className="text-xs text-gray-500 dark:text-gray-100 ">
                                                {new Date(
                                                  item?.dateTime
                                                )?.toLocaleString(undefined, {
                                                  dateStyle: "short",
                                                  timeStyle: "short",
                                                })}
                                              </span>
                                              <span>
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  className={`icon icon-tabler icon-tabler-checks w-5 h-5 ${
                                                    item?.isSeenByUser === true
                                                      ? "text-secondary-500"
                                                      : "text-gray-400"
                                                  }`}
                                                  viewBox="0 0 24 24"
                                                  strokeWidth="1.5"
                                                  currentColor="green"
                                                  stroke={`${
                                                    item?.isSeenByCustmer ===
                                                    true
                                                      ? "#14b8a6"
                                                      : "#94a3b8"
                                                  }`}
                                                  fill="none"
                                                  strokeLinecap="round"
                                                  strokeLinejoin="round"
                                                >
                                                  <path
                                                    stroke="none"
                                                    d="M0 0h24v24H0z"
                                                    fill="none"
                                                  />
                                                  <path d="M7 12l5 5l10 -10" />
                                                  <path d="M2 12l5 5m5 -5l5 -5" />
                                                </svg>
                                                {/* <CheckIcon className="w-4 text-secondary-500" /> */}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  )
                                )}
                                <div ref={bottomRef} />
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                      <div className="flex flex-row items-center w-full h-16 px-4 bg-white dark:bg-gray-800 rounded-xl">
                        <div className="relative w-full">
                          <input
                            type="text"
                            style={{ unicodeBidi: "plaintext" }}
                            value={sendMessageData}
                            onChange={(e) => setSendMessageData(e.target.value)}
                            onKeyDown={handleEnterKey}
                            className="flex w-full h-10 pl-4 border rounded-xl focus:outline-none dark:bg-gray-700 dark:text-gray-300 focus:border-secondary-600 dark:focus:border-secondary-500"
                          />
                          <label className="absolute w-6 h-6 mb-1 mr-2 cursor-pointer right-1 -top-4">
                            <input
                              type="file"
                              className="w-0 h-0 opacity-0"
                              onChange={(e) =>
                                handleFileChange(e, CurrnetUser?.id)
                              }
                            />
                            <PhotoIcon className="w-6 h-6 text-gray-400 hover:text-gray-600" />
                          </label>
                          <button
                            onClick={() =>
                              handleStartRecording(CurrnetUser?.id)
                            }
                            className="absolute w-6 h-6 mt-6 mr-10 cursor-pointer right-1 -top-4"
                          >
                            <motion.div
                              initial="initial"
                              animate={isRecording ? "recording" : "initial"}
                              variants={buttonVariants}
                            >
                              <MicrophoneIcon
                                className={`w-6 h-6 ${
                                  isRecording
                                    ? "text-rose-500 animate-pulse"
                                    : "text-gray-400"
                                } `}
                              />
                            </motion.div>
                          </button>
                          {isVoiceSend === false && (
                            <span className="absolute mr-10 cursor-pointer right-10 top-1">
                              <div className="flex items-center">
                                <audio
                                  src={URL.createObjectURL(
                                    new Blob(recordedChunks, {
                                      type: "audio/webm",
                                    })
                                  )}
                                  controls
                                  className="h-8 mr-2"
                                />
                                {/* <ArrowPathIcon
                                  onClick={() =>
                                    handleSendRecording(customer?.id)
                                  }
                                  className={`${
                                    isVoiceSend
                                      ? "text-secondary-500"
                                      : "text-rose-500 "
                                  } w-6 inline`}
                                /> */}
                              </div>
                              {isVoiceSend}
                            </span>
                          )}
                        </div>

                        <div className="ml-4">
                          <button
                            disabled={disable}
                            onClick={() =>
                              sendMessage(CurrnetUser?.id, sendMessageData)
                            }
                            className="flex items-center justify-center flex-shrink-0 px-4 py-1 text-white bg-secondary-500 hover:bg-secondary-600 rounded-xl"
                          >
                            <span>Send</span>
                            <span className="ml-2">
                              <svg
                                className="w-4 h-4 -mt-px transform rotate-45"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8"
                                ></path>
                              </svg>
                            </span>
                          </button>
                        </div>
                      </div>
                      {micAccess === false && (
                        <Toast
                          Text={"Can not access your mic!"}
                          Type={"rose"}
                          setOpen={setMicAccess}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Chat;
