import React, { useEffect, useState } from "react";
import male from "../../assets/images/pharmisist-malle.webp";
import female from "../../assets/images/pharmisist-female.jpeg";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
// import Pagination from "../pagination/Pagination";
import BreadCrumb from "../BreadCrumb";
import { useDispatch, useSelector } from "react-redux";
import { getProfiles } from "../../../features/Global/Profiles";
import { MagnifyingGlassIcon, UsersIcon } from "@heroicons/react/24/outline";
import usePagination from "../../componets/pagination/Pagination";
import { Pagination } from "@mui/material";
import Cookies from "js-cookie";
import { searchFilter } from "../../../features/Global/Profiles";

const Profile = () => {
  const locale = useSelector((state) => state.locale.locale);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.Profiles.data);
  const l = localStorage.getItem("i18nextLng");
  const [search, setSearch] = useState();

  const PER_PAGE = 15;
  let [page, setPage] = useState(1);
  const count = Math.ceil(data?.length / PER_PAGE);
  var _DATA = usePagination(data, PER_PAGE);
  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  useEffect(() => {
    dispatch(getProfiles());
    // console.log('data',data);
  }, []);
  const handleSearch = (e) => {
    dispatch(searchFilter(e.target.value));
  };

  return (
    <div>
      <div className="p-4 px-4 mx-auto bg-white rounded-md max-w-7xl sm:px-6 lg:px-8">
        <BreadCrumb
          Name={"varyTeam"}
          Iconn={UsersIcon}
          className="container px-5 m-2 my-3"
        />

        <div className="container flex flex-row flex-wrap py-10 mx-auto">
          {/* <div className="title">
            <p className={`mb-4 text-4xl font-bold text-pink-600 ${l}`}>
              {t("varyTeam")}
            </p>
            <p className={`text-2xl font-light text-gray-400 ${l}`}>
              {t("varyTeamDescription")}
            </p>
          </div>
          <div className="relative hidden w-full mx-3 mt-4 lg:inline-block lg:w-1/3">
            <label htmlFor="search" className={` sr-only ${l}`}>
              {"search"}
            </label>
            <input
              type="search"
              id="search"
              name="search"
              placeholder={t("search")}
              value={search}
              onChange={(e) => handleFilter(e)}
              className={`block w-full px-10 py-3 text-sm placeholder-gray-300 border-gray-300 rounded-md shadow-sm form-input pl-11 bg-gray-50 focus:border-pink-600 focus:ring-1 focus:ring-pink-500 ${l}`}
            />
            <span
              className={`absolute text-gray-400 transform -translate-y-1/2 top-1/2 ${
                l === "en-US" ? "left-3" : "right-3"
              }`}
            >
              <MagnifyingGlassIcon className="w-4 h-4" />
            </span>
          </div> */}
          <div
            className={`flex flex-col items-end justify-between mb-12 lg:flex-row header w-full`}
          >
            <div
              className={`title w-full ${
                l === "en-US"
                  ? "float-left text-left"
                  : " float-right text-right"
              }`}
            >
              <p className={`mb-4 text-4xl font-bold text-pink-600 ${l}`}>
                {t("varyTeam")}
              </p>
              <p className={`text-2xl font-light text-gray-400 ${l}`}>
                {t("varyTeamDescription")}
              </p>
            </div>

            <div className="relative hidden w-full mx-3 mt-4 lg:inline-block lg:w-1/3">
              {/* ::::label */}
              <label htmlFor="search" className={` sr-only ${l}`}>
                {"search"}
              </label>
              {/* ::::input */}
              <input
                type="search"
                id="search"
                name="search"
                placeholder={t("search")}
                value={search}
                onChange={(e) => handleSearch(e)}
                className={`block w-full px-10 py-3 text-sm placeholder-gray-300 border-gray-300 rounded-md shadow-sm form-input pl-11 bg-gray-50 focus:border-pink-600 focus:ring-1 focus:ring-pink-500 ${l}`}
              />
              {/* ::::icon */}
              <span
                className={`absolute text-gray-400 transform -translate-y-1/2 top-1/2 ${
                  l === "en-US" ? "left-3" : "right-3"
                }`}
              >
                <MagnifyingGlassIcon className="w-4 h-4" />
              </span>
            </div>
          </div>
        </div>
        <section className="bg-white dark:bg-gray-900">
          <div className="max-w-screen-xl px-4 py-8 mx-auto text-center lg:py-16 lg:px-6">
            <div className="grid gap-8 lg:gap-16 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
              {_DATA
                ?.currentData()
                ?.filter((item) => item?.type === 0)
                ?.map((item, index) => (
                  <div
                    key={index}
                    className="text-center text-gray-500 duration-500 ease-in-out dark:text-gray-400 hover:scale-105"
                  >
                    <img
                      className="mx-auto mb-4 rounded-full w-36 h-36"
                      src={item.fileName}
                      alt="Bonnie Avatar"
                    />
                    <h3
                      className={`mb-1 text-2xl font-bold tracking-tight text-gray-900 dark:text-white ${l}`}
                    >
                      <span className={`${l}`}>
                        {l === "en-US" && item?.englishName}
                        {l === "ar" && item?.arabicName}
                        {l === "ku" && item?.kurdishName}
                      </span>
                    </h3>
                    <p className={`${l}`}>
                      {" "}
                      {l === "en-US" && item?.englishClinicName}
                      {l === "ar" && item?.arabicClinicName}
                      {l === "ku" && item?.kurdishClinicName}
                    </p>
                    <ul className="flex justify-center mt-4 space-x-4 list-none">
                      <li>
                        <span className="text-[#39569c] hover:text-gray-900 dark:hover:text-white">
                          {item?.opensAt}
                        </span>
                      </li>
                      <li>-</li>
                      <li>
                        <Link className="text-[#00acee] hover:text-gray-900 dark:hover:text-white">
                          {item?.closesAt}
                        </Link>
                      </li>
                    </ul>
                  </div>
                ))}
            </div>
          </div>
          <div className="flex justify-end w-full px-3 my-5">
            <Pagination
              count={count}
              size="large"
              page={page}
              variant="outlined"
              shape="rounded"
              onChange={handleChange}
              color="success"
              className="text-pink-600 border-pink-400 "
              showFirstButton
              showLastButton
              dir={`${l === "en-US" ? "ltr" : "rtl"}`}
            />
          </div>
        </section>
        {/* Paginaton */}
        {/* <Pagination /> */}
        {/* Paginaton */}

        {/* <div className="flex justify-center mx-auto mt-5">
          <Link
            to={"/profiles"}
            type="button"
            title="more about"
            className="order-first w-full text-center transition bg-pink-100 lg:w-44 lg:py-3 sm:w-maxpx-6 rounded-xl hover:bg-pink-200 active:bg-pink-300 focus:bg-pink-200 "
          >
            <span className="block font-semibold text-pink-600">
              {t('viewMore')}
            </span>
          </Link>
        </div> */}
      </div>
    </div>
  );
};

export default Profile;
