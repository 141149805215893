import React from "react";
import Content from "../componets/Content";
// import style from "../assets/css/index.css";
import Header from "../componets/Header";
import Navbar from "../componets/Navbar";
import { motion } from "framer-motion"
const Index = () => {
  return (
    <div className="bg-white">
      {/* <!-- ./header --> */}
      {/* <Header /> */}
      {/* <!-- navbar --> */}
      
      {/* <!-- ./navbar --> */}
   
    <Navbar />
      {/* <!-- banner --> */}
     <Content />
      {/* <!-- ./copyright --> */}
    </div>
  );
};

export default Index;