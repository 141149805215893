import { useSelect } from "@mui/base";
import React, { useState, useEffect, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import Cookies from "js-cookie";
import axios from "axios";
import { setAuth } from "../../../features/web/WebAuthentication";
import { useDispatch } from "react-redux";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { authentication } from "../../../firebase";
import OtpInput from "react18-input-otp";
import { Player } from "@lottiefiles/react-lottie-player";
import loaderAnim from "../../assets/icons/otp.json";
import vary from "../../assets/images/vary-removebg-preview.png";
// import Stepper from "./ForgetPassword/Stepper";
// import StepperControl from "./ForgetPassword/StepperControl";
// import { UseContextProvider } from "./ForgetPassword/Steps/StrepperContext";

// import Account from "./ForgetPassword/steps/Account";
// import Details from "./ForgetPassword/steps/Details";
// import Payment from "./ForgetPassword/steps/Payment";
// import Final from "./ForgetPassword/steps/Final";

import {
  KeyIcon,
  PhoneIcon,
  UserCircleIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";
import { ArrowRightOnRectangleIcon } from "@heroicons/react/24/outline";
import BreadCrumb from "../BreadCrumb";
import { getConfiguration } from "../../../features/Global/Configuration";
import SnackBar from "../Snackbar/SnackBarDynamic";

function MyApp() {
  const [snackBar, setSnackBar] = useState(false);
  const [message, setMessage] = useState();
  const locale = localStorage.getItem("i18nextLng");
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [showModalChangePassword, setShowModalChangePassword] = useState(false);
  const [user, setUser] = useState();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [succesRegister, setSuccessRegister] = useState(false);
  const [UIDToken, setUIDToken] = useState("");
  const [otp, setOtp] = useState("");
  const handleChange = (enteredOtp) => {
    setOtp(enteredOtp);
  };
  const data = useSelector((state) => state.Configuration?.data);
  useEffect(() => {
    dispatch(getConfiguration());
  }, []);
  const inputStyles = {
    border: "1px solid #2f3e46",
    borderRadius: "8px",
    width: "54px",
    height: "54px",
    fontSize: "16px",
    color: "#2f3e46",
    fontWeight: "400",
    caretColor: "pink",
    dir: "ltr",
    direction: "ltr",
    unicodeBidi: "bidi-override",
  };

  if (window.innerWidth <= 600) {
    inputStyles.width = "40px";
    inputStyles.height = "40px";
  }

  const responsiveInputStyles = {
    "@media only screen and (maxWidth: 600px)": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontSize: "14px",
    },
    "@media only screen and (maxWidth: 600px) input": {
      width: "35px",
      height: "35px",
      fontSize: "18px",
    },
  };
  const combinedStyles = { ...inputStyles, ...responsiveInputStyles };

  const genarateRecaptcha = () => {
    window.recaptchaVerifier = new RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
        callback: (response) => {
          console.log("prepared phone auth process");

          // reCAPTCHA solved, allow signInWithPhoneNumber.
          // ...
        },
        "expired-callback": () => {
          // Response expired. Ask user to solve reCAPTCHA again.
          // ...
        },
      },
      authentication
    );
  };
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const onSubmit = (data) => {
    // console.log(data);
    data.phoneNumber = data?.phoneNumber
      .replace(/^0/, "")
      .trim()
      .replace(/\s/g, "");
    console.log(`+964${data.phoneNumber}`);
    Cookies.set("phoneNumber", data.phoneNumber);
    setPhoneNumber(data.phoneNumber);
    genarateRecaptcha();
    const appVerifier = window.recaptchaVerifier;
    signInWithPhoneNumber(
      authentication,
      `+964${data.phoneNumber}`,
      appVerifier
    )
      .then((confirmationResult) => {
        console.log("send");
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        window.confirmationResult = confirmationResult;
        setShowModal(true);

        // ...
      })
      .catch((error) => {
        // Error; SMS not sent
        // ...
        console.log(error);
        // console.log(error, `+964${data.phoneNumber}`);
      });
    // axios
    //   .post("/api/UsersAuth/LogIn", data)
    //   .then((r) => {
    //     // console.log(r);
    //     if (r.status === 200) {
    //       dispatch(setAuth(true));
    //       navigate("/shop");
    //     }

    //     if (r.status === 400) {
    //       setSnackBar(true);
    //       setMessage("tooManyRequestsForLogin");
    //     }
    //   })
    //   .catch((e) => {
    //     console.log(e);
    //     setSnackBar(true);
    //     setMessage("userDoseNotExist");
    //   });
    setSnackBar(false);
    // console.log("password not match");
  };
  const verifyOTP = () => {
    //  console.log(otp);
    if (otp.length === 6) {
      let confirmationResult = window.confirmationResult;

      confirmationResult.confirm(otp).then((result) => {
        // User signed in successfully.
        const user = result.user;
        user
          .getIdToken()
          .then((r) => {
            // setToken = r;
            // console.log(user);
            setUser(user);
            const set = {};
            // console.log("phone number ", phoneNumber);
            set.phoneNumber = phoneNumber;
            set.firebaseUserIdToken = r;
            setUIDToken(r);
            setShowModal(false);
            setShowModalChangePassword(true);
            Cookies.set("UID", r);
            navigate("/change-password");
            // console.log("tokennnnn::::::::::", set.firebaseUserIdToken);
            const config = {
              headers: {
                Authorization: `Bearer ${Cookies.get("webAuth")}`,
              },
            };
            // ...
          })
          .catch((error) => {
            // User couldn't sign in (bad verification code?)
            // ...
          });
      });
    }
  };
  return (
    <div className="container p-4 mt-4 mb-8 rounded-lg bg-gray-50">
      {/* // <div className="container mx-auto my-auto mb-5">
      //   <div className="flex flex-col max-w-md px-4 py-8 mx-auto bg-white rounded-lg shadow w- dark:bg-gray-800 sm:px-6 md:px-8 lg:px-10">
      //     <div className={`self-center mb-6 text-xl font-light text-gray-600 sm:text-2xl dark:text-white ${locale}`}>
      //       {t("login")}{" "}
      //     </div>
      //     <div className="mt-8">
      //       <form onSubmit={handleSubmit(onSubmit)}>
      //         <div className="flex flex-col mb-2">
      //           <div className="relative flex ">
      //             <span
      //               className={`${
      //                 locale === "en-Us" ? "rounded-l-md" : "rounded-r-md"
      //               } inline-flex  items-center px-3 border-t bg-white border-l border-b  border-gray-300 text-gray-500 shadow-sm text-sm`}
      //             >
      //               <PhoneIcon className="w-5 h-5 " />
      //             </span>
      //             <input
      //               type="number"
      //               className={` ${
      //                 locale === "en-US" ? "rounded-r-lg" : "rounded-l-lg"
      //               } flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-pink-600 focus:border-transparent ${
      //                 errors.phoneNumber && "border-rose-500"
      //               }`}
      //               placeholder={t("phoneNumber")}
      //               {...register("phoneNumber", { required: true })}
      //             />
      //           </div>
      //         </div>
      //         <div className="flex flex-col mb-6">
      //           <div className="relative flex ">
      //             <span
      //               className={`${
      //                 locale === "en-Us" ? "rounded-l-md" : "rounded-r-md"
      //               } inline-flex  items-center px-3 border-t bg-white border-l border-b  border-gray-300 text-gray-500 shadow-sm text-sm`}
      //             >
      //               <KeyIcon className="w-5 h-5 " />
      //             </span>
      //             <input
      //               type="password"
      //               className={`${
      //                 locale === "en-US" ? "rounded-r-lg" : "rounded-l-lg"
      //               } flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-pink-600 focus:border-transparent ${
      //                 errors.password && "border-rose-500"
      //               }`}
      //               placeholder={t("password")}
      //               {...register("password", { required: true })}
      //             />
      //           </div>
      //         </div>
      //         <div className="flex items-center mb-6 -mt-4">
      //           <div className="flex ml-auto">
      //             <Link
      //               href="#"
      //               className={`inline-flex text-xs font-thin text-gray-500 sm:text-sm dark:text-gray-100 hover:text-gray-700 dark:hover:text-white ${locale}`}
      //             >
      //               {t("forgotPassword")}
      //             </Link>
      //           </div>
      //         </div>
      //         <div className="flex w-full">
      //           <button
      //             type="submit"
      //             className={`w-full px-4 py-2 text-base font-semibold text-center text-white transition duration-200 ease-in bg-pink-600 rounded-lg shadow-md hover:bg-pink-700 focus:ring-pink-500 focus:ring-offset-pink-200 focus:outline-none focus:ring-2 focus:ring-offset-2  ${locale}`}
      //           >
      //             {t("login")}
      //           </button>
      //         </div>
      //       </form>
      //     </div>
      //     <div className="flex items-center justify-center mt-6">
      //       <Link
      //         to="/register"
      //         className="inline-flex items-center text-xs font-thin text-center text-gray-500 hover:text-gray-700 dark:text-gray-100 dark:hover:text-white"
      //       >
      //         <span className={`ml-2 ${locale}`}>{t("dontHaveAccount")}</span>
      //       </Link>
      //     </div>
      //   </div>
      // </div> */}

      {/* <BreadCrumb Name={"login"} Iconn={ArrowRightOnRectangleIcon} /> */}
      <BreadCrumb Name={"ressetPassword"} Iconn={KeyIcon} className="m-3" />
      <div className="flex flex-wrap items-center mx-auto ">
        {/* <div className="absolute hidden w-56 h-56 text-indigo-300 sm:block a-z-10 left-50 -top-30">
            <svg
              id="patternId"
              width="100%"
              height="100%"
              xmlns="http://www.w3.org/2000/svg"
            >
              <defs>
                <pattern
                  id="a"
                  patternUnits="userSpaceOnUse"
                  width="40"
                  height="40"
                  patternTransform="scale(0.6) rotate(0)"
                >
                  <rect x="0" y="0" width="100%" height="100%" fill="none" />
                  <path
                    d="M11 6a5 5 0 01-5 5 5 5 0 01-5-5 5 5 0 015-5 5 5 0 015 5"
                    strokeWidth="1"
                    stroke="none"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width="800%"
                height="800%"
                transform="translate(0,0)"
                fill="url(#a)"
              />
            </svg>
          </div> */}
        {/* <div
          className={`lg:w-3/5 ${locale === "en-US" ? "lg:pr-8" : "lg:pl-8"} `}
        >
          <h1
            className={`text-3xl font-bold text-center  py-3 text-transparent bg-clip-text bg-gradient-to-br from-pink-600 to-pink-500 title-font md:text-5xl ${
              locale === "en-US" ? "lg:text-left" : "lg:text-right"
            } ${locale}`}
          >
            {t("loginPageMessage")}
          </h1>
          <p
            className={`mt-4 text-lg font-normal leading-relaxed text-center  text-transparent bg-clip-text bg-gradient-to-br from-gray-800 to-gray-700 md:text-2xl lg:max-w-xl ${
              locale === "en-US" ? "lg:text-left" : "lg:text-right"
            }  ${locale}`}
          >
            <div className="absolute hidden w-56 h-56 text-indigo-300 sm:block -z-10 -left-20 -bottom-30">
              <svg
                id="patternId"
                width="100%"
                height="100%"
                xmlns="http://www.w3.org/2000/svg"
              >
                <defs>
                  <pattern
                    id="a"
                    patternUnits="userSpaceOnUse"
                    width="40"
                    height="40"
                    patternTransform="scale(0.6) rotate(0)"
                  >
                    <rect x="0" y="0" width="100%" height="100%" fill="none" />
                    <path
                      d="M11 6a5 5 0 01-5 5 5 5 0 01-5-5 5 5 0 015-5 5 5 0 015 5"
                      strokeWidth="1"
                      stroke="none"
                      fill="currentColor"
                    />
                  </pattern>
                </defs>
                <rect
                  width="800%"
                  height="800%"
                  transform="translate(0,0)"
                  fill="url(#a)"
                />
              </svg>
            </div>{" "}
            {t("loginPageMessageDetail")}
          </p>

        </div> */}
        <div className="w-full px-4 py-10 mx-3 text-white lg:w-3/4 lg:mx-2 lg:max-w-xl rounded-3xl bg-gradient-to-t from-pink-700 via-pink-700 to-pink-600 sm:px-10 md:m-6 md:mr-8">
          <p
            className={`mb-10 font-bold tracking-wider text-center text-lg ${locale}`}
          >
            <img
              src={data[0]?.imageUrl}
              className="inline object-cover w-16 h-16 mx-2 rounded-full"
            />{" "}
            {/* {t("varyPharmacy")} */}
            {locale === "en-US" && data[0]?.englishName}
            {locale === "ar" && data[0]?.arabicName}
            {locale === "ku" && data[0]?.kurdishName}{" "}
          </p>
          <p
            className={`mb-4 text-3xl font-bold md:text-4xl md:leading-snug ${locale}`}
          >
            {t("resetPageMessage")}
          </p>
          <p className={`mb-28 leading-relaxed text-gray-200 ${locale}`}>
            {t("resetPageMessageDetail")}

            {/* Lorem ipsum dolor sit amet consectetur adipisicing elit. Facere nisi
            voluptas a officia. Omnis.  */}
          </p>
          <div className="px-4 py-8 bg-pink-600/80 rounded-2xl">
            <div className="flex items-center">
              {/* <img
                    src={data[0]?.imageUrl}
                    className="object-cover w-16 h-16 rounded-full"
                  /> */}
              <p className={`${locale === "en-US" ? "ml-4" : "mr-4"} w-56`}>
                {locale === "en-US" ? (
                  <>
                    <span className={` text-gray-200 font-medium ${locale}`}>
                      {" "}
                      {/* <strong className={`block font-semibold text-md ${locale}`}> */}
                      {/* {t("varyPharmacy")} */}
                      {/* </strong> */}
                      {/* {t("vary")}{" "} */}
                    </span>
                  </>
                ) : (
                  <>
                    <span
                      className={`text-xs text-gray-200 font-medium ${locale}`}
                    >
                      {" "}
                      {/* {t("pharmacy")}{" "} */}
                    </span>
                    <strong className={`block font-medium ${locale}`}>
                      {/* {t("vary")} */}
                      {locale === "en-US" && data[0]?.englishName}
                      {locale === "ar" && data[0]?.arabicName}
                      {locale === "ku" && data[0]?.kurdishName}{" "}
                    </strong>
                  </>
                )}
              </p>
            </div>
            {/* <p className={`mt-3 text-gray-200 text-lg font-medium ${locale} `}>
              {t("loginMot")}
            </p> */}
          </div>
        </div>

        <div className="flex-col w-full mx-4 my-3 bg-white rounded-lg shadow-2xl lg:mx-auto lg:flex lg:w-2/5">
          <div className="mx-2 my-6 text-center">
            <h1 className={`text-3xl font-semibold text-gray-800 ${locale}`}>
              {t("ressetPassword")}
            </h1>
            <p className={`mt-2 font-medium text-gray-700 ${locale}`}>
              {t("dontHaveAccount")}{" "}
              <Link
                to="/register"
                className={`text-pink-500 cursor-pointer hover:text-pink-700 ${locale}`}
              >
                {t("createAccount")}
              </Link>
            </p>
          </div>
          <div className="pb-8 mx-6">
            <form className="mb-4" onSubmit={handleSubmit(onSubmit)}>
              <div className="mb-6">
                <label
                  htmlFor="email"
                  className={`block mb-2 text-sm text-gray-600 ${locale}`}
                >
                  {t("phoneNumberPlaceholder")}
                </label>
                <input
                  type="tel"
                  name="email"
                  id="email"
                  // placeholder={t("PhoneNumberPlaceholder")}
                  placeholder={t("770(50)000 00 00")}
                  style={{ direction: "ltr" }}
                  {...register("phoneNumber", {
                    required: true,
                    pattern: /[0-9]{1}/,
                  })}
                  className={`w-full px-3 py-3 placeholder-gray-300 border border-gray-300 rounded-xl focus:outline-none focus:ring focus:ring-pink-100 focus:border-pink-600 ${
                    errors.phoneNumber &&
                    "focus:ring-rose-100 focus:border-rose-300"
                  } ${locale}`}
                />
              </div>
              <div className="mb-6">
                <button
                  type="submit"
                  className={`w-full px-3 py-3 text-lg font-medium text-white duration-100 ease-in-out bg-pink-600 rounded-xl hover:bg-pink-400 focus:outline-none ${locale}`}
                >
                  {t("reset")}
                </button>
              </div>
            </form>
            {showModal && (
              <>
                <Transition appear show={showModal} as={Fragment}>
                  <Dialog
                    as="div"
                    className="relative z-10 "
                    // onClose={setShowModal}
                    onClose={() => {}}
                    disableBackdropClick={true}
                  >
                    <Transition.Child
                      as={Fragment}
                      enter="ease-out duration-300"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in duration-200"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                      <div className="flex items-center justify-center min-h-full p-4 text-center">
                        <Transition.Child
                          as={Fragment}
                          enter="ease-out duration-300"
                          enterFrom="opacity-0 scale-95"
                          enterTo="opacity-100 scale-100"
                          leave="ease-in duration-200"
                          leaveFrom="opacity-100 scale-100"
                          leaveTo="opacity-0 scale-95"
                        >
                          <Dialog.Panel className="w-full max-w-xl overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl md:p-6 rounded-2xl">
                            <div className="relative flex flex-col justify-center py-8 overflow-hidden ">
                              <div className="relative w-full max-w-xl pt-10 mx-auto bg-white shadow-xl md:px-6 pb-9 rounded-2xl">
                                <span
                                  className="fixed top-0 right-0 float-right"
                                  onClose={setShowModal}
                                >
                                  <XCircleIcon
                                    onClose={setShowModal}
                                    onClick={() => setShowModal(false)}
                                    className="inline w-8 p-1 duration-500 rounded-full hover:text-white text-rose-600 hover:bg-rose-600"
                                  />
                                </span>
                                <div className="flex flex-col w-full max-w-3xl mx-auto space-y-16">
                                  <div className="flex flex-col items-center justify-center space-y-2 text-center">
                                    <div className="text-3xl font-semibold">
                                      <p className={locale}>
                                        {t("accountVerification")}
                                      </p>
                                    </div>
                                    <div className="flex flex-row text-sm font-medium text-gray-400">
                                      <p className={`${locale}`}>
                                        {t(
                                          "weHaveSendMessageForYourPhoneNumber"
                                        )}{" "}
                                      </p>
                                    </div>
                                    <Player
                                      src={loaderAnim}
                                      autoplay
                                      loop
                                      className="w-56 player"
                                    />
                                  </div>
                                  <div>
                                    <form onSubmit={handleSubmit(verifyOTP)}>
                                      <div className="flex flex-col space-y-16">
                                        <div className="flex flex-row items-center justify-between w-full mx-auto max-w-7xl">
                                          <span className="w-full mx-auto">
                                            <OtpInput
                                              value={otp}
                                              onChange={handleChange}
                                              numInputs={6}
                                              // numInputs={6}
                                              separator={
                                                <span
                                                  style={{ width: "8px" }}
                                                ></span>
                                              }
                                              isInputNum={true}
                                              shouldAutoFocus={true}
                                              className="mx-1 md:mx-2"
                                              inputStyle={combinedStyles}
                                              // inputStyle={{
                                              //   border: "1px solid #2f3e46",
                                              //   borderRadius: "8px",
                                              //   width: "54px",
                                              //   height: "54px",
                                              //   fontSize: "16px",
                                              //   color: "#2f3e46",
                                              //   fontWeight: "400",
                                              //   caretColor: "pink",
                                              // }}
                                              focusStyle={{
                                                border: "1px solid #CFD3DB",
                                                outline: "none",
                                              }}
                                            />
                                          </span>
                                        </div>
                                        <div className="flex flex-col space-y-5">
                                          <div className="flex items-center justify-center ">
                                            <button
                                              type="submit"
                                              id="submit"
                                              className={`flex flex-row items-center justify-center w-4/5 md:w-full mx-1 py-5 text-sm text-center text-white bg-pink-600 border border-none shadow-sm outline-none rounded-xl ${locale}`}
                                            >
                                              {t("verifyAccount")}
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </form>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Dialog.Panel>
                        </Transition.Child>
                      </div>
                    </div>
                  </Dialog>
                </Transition>
              </>
            )}

            {/*   CHANGE PASSWORD MODAL */}
            {showModalChangePassword && (
              <>
                <Transition appear show={showModal} as={Fragment}>
                  <Dialog
                    as="div"
                    className="relative z-10 "
                    // onClose={setShowModal}
                    open={showModalChangePassword}
                    onClose={() => {}}
                    disableBackdropClick={true}
                  >
                    <Transition.Child
                      as={Fragment}
                      enter="ease-out duration-300"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in duration-200"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                      <div className="flex items-center justify-center min-h-full p-4 text-center">
                        <Transition.Child
                          as={Fragment}
                          enter="ease-out duration-300"
                          enterFrom="opacity-0 scale-95"
                          enterTo="opacity-100 scale-100"
                          leave="ease-in duration-200"
                          leaveFrom="opacity-100 scale-100"
                          leaveTo="opacity-0 scale-95"
                        >
                          <Dialog.Panel className="w-full max-w-xl overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl md:p-6 rounded-2xl">
                            <div className="relative flex flex-col justify-center py-8 overflow-hidden ">
                              <div className="relative w-full max-w-xl pt-10 mx-auto bg-white shadow-xl md:px-6 pb-9 rounded-2xl">
                                <span
                                  className="fixed top-0 right-0 float-right"
                                  onClose={showModalChangePassword}
                                >
                                  <XCircleIcon
                                    onClose={showModalChangePassword}
                                    onClick={() =>
                                      showModalChangePassword(false)
                                    }
                                    className="inline w-8 p-1 duration-500 rounded-full hover:text-white text-rose-600 hover:bg-rose-600"
                                  />
                                </span>
                                <div className="flex flex-col w-full max-w-3xl mx-auto space-y-16">
                                  <div className="flex flex-col items-center justify-center space-y-2 text-center">
                                    <div className="text-3xl font-semibold">
                                      <p className={locale}>
                                        {t("accountVerification")}
                                      </p>
                                    </div>
                                    <div className="flex flex-row text-sm font-medium text-gray-400">
                                      <p>
                                        {t(
                                          "weHaveSendMessageForYourPhoneNumber"
                                        )}{" "}
                                      </p>
                                    </div>
                                    <Player
                                      src={loaderAnim}
                                      autoplay
                                      loop
                                      className="w-56 player"
                                    />
                                  </div>
                                  <div>
                                    <form onSubmit={handleSubmit(verifyOTP)}>
                                      <div className="flex flex-col space-y-16">
                                        <div className="flex flex-row items-center justify-between w-full mx-auto max-w-7xl">
                                          <span className="w-full mx-auto">
                                            <OtpInput
                                              value={otp}
                                              onChange={handleChange}
                                              numInputs={6}
                                              // numInputs={6}
                                              separator={
                                                <span
                                                  style={{ width: "8px" }}
                                                ></span>
                                              }
                                              isInputNum={true}
                                              shouldAutoFocus={true}
                                              className="mx-2"
                                              inputStyle={combinedStyles}
                                              // inputStyle={{
                                              //   border: "1px solid #2f3e46",
                                              //   borderRadius: "8px",
                                              //   width: "54px",
                                              //   height: "54px",
                                              //   fontSize: "16px",
                                              //   color: "#2f3e46",
                                              //   fontWeight: "400",
                                              //   caretColor: "pink",
                                              // }}
                                              focusStyle={{
                                                border: "1px solid #CFD3DB",
                                                outline: "none",
                                              }}
                                            />
                                          </span>
                                        </div>
                                        <div className="flex flex-col space-y-5">
                                          <div>
                                            <button
                                              type="submit"
                                              id="submit"
                                              className="flex flex-row items-center justify-center w-full py-5 text-sm text-center text-white bg-pink-600 border border-none shadow-sm outline-none rounded-xl"
                                            >
                                              {t("verifyAccount")}
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </form>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Dialog.Panel>
                        </Transition.Child>
                      </div>
                    </div>
                  </Dialog>
                </Transition>
              </>
            )}
            {snackBar && <SnackBar Title={message} />}
            <div id="recaptcha-container" className="flex justify-center" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default MyApp;
