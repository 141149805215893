import React, { useState, useEffect } from "react";
import BreadCrumb from "../components/Cards/BreadCrumb";
import Header from "../components/Sidebar/Header";
import Sidebar from "../components/Sidebar/Sidebar";
import chart from "../icons/chart.svg";
import { Link } from "react-router-dom";
import dash from "./dash.css";
import {
  ArrowRightCircleIcon,
  ArrowRightIcon,
  ArrowUpCircleIcon,
  BanknotesIcon,
  BeakerIcon,
  ChartBarSquareIcon,
  CheckCircleIcon,
  ClockIcon,
  ShoppingBagIcon,
  SwatchIcon,
  TagIcon,
  UsersIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";
import Toast from "../components/Cards/Toast";
import CountUp from "react-countup";
import Cookies from "js-cookie";

// redux mapping
import { useDispatch, useSelector } from "react-redux";
import { getStats } from "../../features/Global/Stats";
import { getNews } from "../../features/Global/News";
import { getOrders } from "../../features/Global/Orders";
import { getOffersPercentage } from "../../features/Global/OffersPercentage";
import MainCard from "../components/Widgets/MainCard";
import Categories from "../components/Widgets/Categories";
import { Pie } from "react-chartjs-2";
import { getCategory } from "../../features/Global/Category";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { DoughnutChart } from "../Charts/DoughnutChart.tsx";
import { PieChart } from "../Charts/DoughnutChartMostOrderdSubcategories.tsx";
import { AreaChart } from "../Charts/AreaChart.tsx";
import { AreaChartProducts } from "../Charts/AreaChartProducts.tsx";
import { SellingPerMonth } from "../Charts/SellingPerMonth.tsx";
import { getItem } from "../../features/Global/Dashboard/Items";

import AOS from "aos";
import "aos/dist/aos.css"; // You can also use <link> for styles
import { getDashboardData } from "../../features/Global/Dashboard";
const Index = () => {
  const [toast, setToast] = useState(true);
  const dispatch = useDispatch();
  const data = useSelector((state) => state.stats.data);
  const news = useSelector((state) => state.News.data);
  const orders = useSelector((state) => state.Orders?.data);
  const discount = useSelector((state) => state.OffersPercentage?.data);
  const categories = useSelector((state) => state.category.data);
  const products = useSelector((state) => state.item.data);
  AOS.init();

  // const data = useSelector((state) => state.stats.data);
  useEffect(() => {
    dispatch(getNews());
    dispatch(getStats());
    dispatch(getOrders());
    dispatch(getOffersPercentage());
    dispatch(getCategory());
    // dispatch(getItem());
    dispatch(getDashboardData());
  }, []);
  return (
    <div>
      <main className="relative h-screen overflow-y-auto ltr">
        <div className="flex items-start justify-between h-screen">
          <Sidebar />
          <div className="flex flex-col w-full h-screen overflow-y-auto md:space-y-4">
            <header className="z-10 flex items-center justify-between w-full h-16">
              {/* <div className="block ml-6 lg:hidden">
                <button className="flex items-center p-2 text-gray-500 bg-white rounded-full shadow text-md">
                  <svg
                    width="20"
                    height="20"
                    className="text-gray-400"
                    fill="currentColor"
                    viewBox="0 0 1792 1792"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M1664 1344v128q0 26-19 45t-45 19h-1408q-26 0-45-19t-19-45v-128q0-26 19-45t45-19h1408q26 0 45 19t19 45zm0-512v128q0 26-19 45t-45 19h-1408q-26 0-45-19t-19-45v-128q0-26 19-45t45-19h1408q26 0 45 19t19 45zm0-512v128q0 26-19 45t-45 19h-1408q-26 0-45-19t-19-45v-128q0-26 19-45t45-19h1408q26 0 45 19t19 45z"></path>
                  </svg>
                </button>
              </div> */}
              {/* Header */}
              <Header />
            </header>
            <div className="h-screen px-4 pb-24 overflow-y-auto md:px-6 fade-up">
              <BreadCrumb
                page={"Dashboard"}
                icon={<ChartBarSquareIcon className="inline w-6 mx-2" />}
                className=""
              />

              {/* <!-- Start Discover --> */}
              {/* <div className="discover">
        <h3 className="section-header dark:text-gray-300">Discover</h3>
        <div className="discover-videos section-border-radius section-padding">
          <ul className="list-videos">
            <li className="video">
              <img src="https://images.unsplash.com/photo-1550751827-4bd374c3f58b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTB8fHRlY2h8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60"/>
              <div className="details">
                <span className="play"><i className="fas fa-play"></i></span>
                <span className="time">15:03</span>
              </div>
            </li>
            <li className="video">
              <img src="https://images.unsplash.com/photo-1451187580459-43490279c0fa?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NHx8dGVjaHxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60"/>
              <div className="details">
                <span className="play"><i className="fas fa-play"></i></span>
                <span className="time">05:22</span>
              </div>
            </li>
            <li className="video">
              <img src="https://images.unsplash.com/photo-1485827404703-89b55fcc595e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTd8fHRlY2h8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60"/>
              <div className="details">
                <span className="play"><i className="fas fa-play"></i></span>
                <span className="time">30:45</span>
              </div>
            </li>
          </ul>
        </div>
      </div> */}
              <div>
                {/* Start Analytics */}
                <div className="analytics section-border-radius fade-up">
                  <h3 className="section-header dark:text-gray-300">
                    <span className="px-3 py-1 rounded-md bg-violet-100 text-violet-500">
                      Analytics Overview
                    </span>
                  </h3>
                  {/* <div className="list-analytics ">
                    <div className="analytic a dark:bg-gray-800 group">
                      <span className="icon">
                        <SwatchIcon className="w-10 h-10 mx-auto mt-2" />
                      </span>
                      <h3 className="text-blue-600 num">
                        <CountUp end={data?.brands} />
                      </h3>
                      <p className="text-lg font-medium text-blue-500 desc dark:text-blue-300 ">
                        Brands
                      </p>
                    </div>
                    <div className="analytic b dark:bg-gray-800">
                      <span className="icon">
                        <ShoppingBagIcon className="w-10 h-10 mx-auto mt-2" />
                      </span>
                      <h3 className="text-indigo-600 num">
                        <CountUp end={data?.products} />
                      </h3>
                      <p className="text-lg font-medium text-indigo-500 desc dark:text-indigo-300 ">
                        Products
                      </p>
                    </div>
                    <div className="analytic c dark:bg-gray-800">
                      <span className="icon">
                        <TagIcon className="w-10 h-10 mx-auto mt-2" />{" "}
                      </span>
                      <h3 className="text-yellow-600 num">
                        <CountUp end={data?.category} />
                      </h3>
                      <p className="text-lg font-medium text-yellow-500 desc dark:text-yellow-300 ">
                        Categories
                      </p>
                    </div>
                    <div className="analytic d dark:bg-gray-800">
                      <span className="icon">
                        <UsersIcon className="w-10 h-10 mx-auto mt-2" />{" "}
                      </span>
                      <h3 className="text-green-600 num">
                        <CountUp end={data?.users} />
                      </h3>
                      <p className="text-lg font-medium text-green-500 desc dark:text-green-300 ">
                        Customers
                      </p>
                    </div>
                  </div> */}
                  <MainCard />
                  {/* <MainCard /> */}
                </div>

                <Categories />
                {/* Start Message */}
                <div className="message section-border-radius">
                  <p className="text">See latest orders</p>
                  <Link
                    to="/dashboard/orders"
                    className="text-center btn-discover"
                  >
                    Discover
                  </Link>
                  {/* <i className="fas fa-times icon-close" /> */}
                </div>
                {/* Start Events + Calendar */}
                <div className="events-calendar">
                  {/* Start Events */}
                  {/* <div className="events w-lg" style={{ width: "100%" }}>
                    <h3 className="section-header dark:text-gray-300">
                      <span className="px-3 py-1 text-blue-500 bg-blue-100 rounded-md">
                        Latest News
                      </span>
                    </h3>
                    <div className="flex-row flex-wrap w-100 list-events">
                      {news
                        ?.slice(news.length - 30, news?.length)
                        .map((item) => (
                          <div className="m-1 event w-xl dark:bg-gray-800">
                            <h4 className="inline my-2 text-lg text-gray-600 title dark:text-gray-400">
                              {item.englishTitle}
                              <span className="">
                                <img
                                  src={item.fileName}
                                  alt
                                  className="inline float-right w-40 rounded-lg"
                                />
                              </span>
                            </h4>
                            <br />
                            <div className="sub-title">
                              <i className="fas fa-calendar" />
                  
                            </div>
                            <div className="block">
                              <p className="mt-5 description">
                                {item.englishBody.slice(0, 300)}...
                              </p>
                            </div>

                            <Link
                              className="time"
                              to={`/information/${item.id}`}
                            >
                              <span className="text"> </span>
                              <span className="date">
                                read More{" "}
                                <ArrowRightCircleIcon className="inline w-6" />
                              </span>
                            </Link>
                          </div>
                        ))}
                    </div>
                  </div> */}
                  {/* {toast && (
                    <Toast
                      Text={"Data added succsfully"}
                      Type={"teal"}
                      setOpen={setToast}
                    />
                  )} */}
                  {/* Start Calendar */}
                  {/* <div className="calendar">
      <h3 className="section-header">Calendar</h3>
      <div className="calendar-details section-padding section-border-radius">
        <h3 className="date">March 2015</h3>
        <span className="navigation">
          <i className="fas fa-chevron-left" />
          <i className="fas fa-chevron-right" />
        </span>
        <div className="clear-float" />
        <table className="calendar-date">
          <thead>
            <tr>
              <th>Mon</th>
              <th>Tue</th>
              <th>Wed</th>
              <th>Thu</th>
              <th>Fri</th>
              <th>Sat</th>
              <th>Sun</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="lm">29</td>
              <td className="lm">30</td>
              <td>1</td>
              <td>2</td>
              <td>3</td>
              <td>4</td>
              <td>5</td>
            </tr>
            <tr>
              <td>6</td>
              <td>7</td>
              <td>8</td>
              <td>9</td>
              <td>10</td>
              <td>11</td>
              <td>12</td>
            </tr>
            <tr>
              <td>13</td>
              <td>14</td>
              <td>15</td>
              <td>16</td>
              <td className="act"><span>17</span></td>
              <td>18</td>
              <td>19</td>
            </tr>
            <tr>
              <td>20</td>
              <td>21</td>
              <td>22</td>
              <td>23</td>
              <td>24</td>
              <td>25</td>
              <td>26</td>
            </tr>
            <tr>
              <td>27</td>
              <td>28</td>
              <td>29</td>
              <td>30</td>
              <td>31</td>
              <td className="lm">1</td>
              <td className="lm">2</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div> */}
                </div>
                {/* Start Apps + Features */}
                <div className="apps-features">
                  {/* Start Apps */}
                  <div className="apps">
                    <h3 className="section-header dark:text-gray-300">
                      <span className="h-full px-3 py-1 text-green-500 bg-green-100 rounded-md">
                        Latest Orders
                      </span>
                    </h3>
                    <span className="min-w-full antialiased text-gray-600 section-header">
                      <div className="flex flex-col justify-center w-full">
                        <div className="w-full max-w-5xl mx-auto bg-white border border-gray-200 rounded-sm rounded-md shadow-lg dark:bg-gray-800">
                          <div className="p-3">
                            <div className="overflow-x-auto">
                              <table className="w-full table-auto">
                                <thead className="text-xs font-semibold text-gray-400 uppercase rounded-md bg-gray-50 dark:bg-gray-600">
                                  <tr>
                                    <th className="p-2 whitespace-nowrap">
                                      <div className="font-semibold text-center ">
                                        Name
                                      </div>
                                    </th>
                                    <th className="p-2 whitespace-nowrap">
                                      <div className="font-semibold text-center">
                                        Phone
                                      </div>
                                    </th>
                                    <th className="p-2 whitespace-nowrap">
                                      <div className="font-semibold text-center">
                                        status
                                      </div>
                                    </th>
                                    <th className="p-2 whitespace-nowrap">
                                      <div className="font-semibold text-center">
                                        Total Price
                                      </div>
                                    </th>
                                    <th className="p-2 whitespace-nowrap">
                                      <div className="font-semibold text-center">
                                        Address
                                      </div>
                                    </th>{" "}
                                    <th className="p-2 whitespace-nowrap">
                                      <div className="font-semibold text-center">
                                        Order Date
                                      </div>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody className="text-sm divide-y divide-gray-100">
                                  {orders
                                    ?.slice(orders.length - 8, orders.length)
                                    ?.map((item, index) => (
                                      <tr className="py-2 duration-500 hover:bg-gray-50 dark:hover:bg-gray-600 hover:scale-105">
                                        <td className="p-2 whitespace-nowrap">
                                          <div className="flex items-center">
                                            <div className="font-medium text-gray-800 dark:text-gray-300">
                                              {item?.userName}
                                            </div>
                                          </div>
                                        </td>
                                        <td className="p-2 whitespace-nowrap">
                                          <div className="text-left dark:text-gray-300">
                                            {item?.userPhoneNumber}
                                          </div>
                                        </td>
                                        <td className="p-2 whitespace-nowrap">
                                          <div
                                            className={` justify-center text-sm font-medium shadow-md duration-300 inline-flex items-center rounded-full py-2 px-3 text-white ${
                                              item.status === 1
                                                ? " bg-blue-600  hover:bg-blue-700  focus:ring-blue-500 focus:ring-offset-blue-200 "
                                                : item.status === 0
                                                ? " bg-orange-500  hover:bg-orange-600  focus:ring-orange-400 focus:ring-offset-orange-200 "
                                                : " bg-rose-500  hover:bg-rose-600  focus:ring-rose-400 focus:ring-offset-rose-200   "
                                            }  border-none rounded-md  focus:outline-none focus:ring-2 focus:ring-offset-2`}
                                            // className={`font-medium text-center ${item?.status === 0? 'text-orange-500': 'text-green-50'}`}>
                                          >
                                            {item.status === 1 ? (
                                              <>
                                                <CheckCircleIcon className="inline w-5 mx-1" />{" "}
                                                Completed
                                              </>
                                            ) : (
                                              <>
                                                {item.status === 0 ? (
                                                  <>
                                                    <ClockIcon className="inline w-5 mx-1" />{" "}
                                                    Pending
                                                  </>
                                                ) : (
                                                  <>
                                                    <XCircleIcon className="inline w-5 mx-1" />{" "}
                                                    Canceled
                                                  </>
                                                )}
                                              </>
                                            )}
                                          </div>
                                        </td>
                                        <td className="p-2 whitespace-nowrap">
                                          <div className="text-center text-md dark:text-gray-300">
                                            {Intl.NumberFormat()?.format(
                                              item.totalPrice
                                            )}{" "}
                                            IQD
                                          </div>
                                        </td>
                                        <td className="p-2 whitespace-nowrap">
                                          <div className="text-center text-md dark:text-gray-300">
                                            {item?.orderAddress}
                                          </div>
                                        </td>
                                        <td className="p-2 whitespace-nowrap">
                                          <div className="text-center text-md dark:text-gray-300">
                                            {item?.orderDate}
                                          </div>
                                        </td>
                                      </tr>
                                    ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </span>
                  </div>
                  {/* Start Features */}
                  <div className="features">
                    <h3 className="section-header dark:text-gray-300">
                      <span className="px-3 py-1 text-indigo-500 bg-indigo-100 rounded-md">
                        Most Ordered Categories
                      </span>
                    </h3>
                    <div className=" feature section-padding section-border-radius dark:bg-gray-800">
                      <DoughnutChart />
                    </div>
                  </div>
                </div>
                <div className="apps-features">
                  {/* Start Apps */}
                  <div className="apps">
                    <h3 className="section-header dark:text-gray-300">
                      <span className="px-3 py-1 my-2 rounded-md text-fuchsia-500 bg-fuchsia-100">
                        Global stats
                      </span>
                    </h3>
                    <span className="min-w-full antialiased text-gray-600 section-header">
                      <div className="flex flex-col justify-center w-full">
                        <div className="w-full max-w-5xl mx-auto bg-white border border-gray-200 rounded-md shadow-lg dark:bg-gray-800">
                          <div className="p-3">
                            <div className="overflow-x-auto">
                              <div className="inline w-1/2 feature section-padding section-border-radius dark:bg-gray-700">
                                <AreaChart />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </span>
                  </div>
                  {/* Start Features */}
                  <div className="features">
                    <h3 className="section-header dark:text-gray-300">
                      <span className="px-3 py-1 rounded-md bg-amber-100 text-amber-500">
                        Most Ordered Sub categories
                      </span>
                    </h3>
                    <div className=" feature section-padding section-border-radius dark:bg-gray-800">
                      <PieChart />
                    </div>
                  </div>
                </div>
              </div>
              <div className="apps-features">
                {/* Start Apps */}
                <div className="w-full">
                  <h3 className="section-header dark:text-gray-300">
                    <span className="px-3 py-1 mt-5 rounded-md text-secondary-500 bg-secondary-100">
                      Monthly Selling
                    </span>
                  </h3>
                  <span className="min-w-full antialiased text-gray-600 section-header">
                    <div className="w-full mx-auto bg-white border border-gray-200 rounded-sm shadow-lg dark:bg-gray-800">
                      <div className="p-3">
                        <div className="overflow-x-auto">
                          <SellingPerMonth />
                        </div>
                      </div>
                    </div>
                  </span>
                </div>
              </div>
              {/* <div className="apps-features">
                <div className="w-full apps">
                  <div className="inline w-1/2 mt-3 feature section-padding section-border-radius dark:bg-gray-700">
                    <span className="px-3 py-1 mt-5 rounded-md text-secondary-500 bg-secondary-100">
                      Monthly Selling
                    </span>
                    <SellingPerMonth />
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Index;
