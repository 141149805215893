import React, { useEffect, useState } from "react";
import { useNavigate, NavLink } from "react-router-dom";
import vary from "../../../web/assets/images/vary.jpeg";
import css from "./style.css";
// import sc  from './scri.js'
import {
  ArrowLeftOnRectangleIcon,
  BellAlertIcon,
  CalculatorIcon,
  ChartBarSquareIcon,
  ChartPieIcon,
  CogIcon,
  DevicePhoneMobileIcon,
  IdentificationIcon,
  MapIcon,
  NewspaperIcon,
  PhoneIcon,
  PhotoIcon,
  QuestionMarkCircleIcon,
  RectangleStackIcon,
  SwatchIcon,
  UserGroupIcon,
  FireIcon,
} from "@heroicons/react/24/outline";
import { UserCircleIcon } from "@heroicons/react/24/outline";
import { ChatBubbleBottomCenterTextIcon } from "@heroicons/react/24/outline";
import { ListBulletIcon } from "@heroicons/react/24/outline";
import { TagIcon } from "@heroicons/react/24/outline";
import { RectangleGroupIcon } from "@heroicons/react/24/outline";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { ReceiptPercentIcon } from "@heroicons/react/24/outline";
import { ArrowLeftCircleIcon } from "@heroicons/react/24/outline";
import { ArrowRightCircleIcon } from "@heroicons/react/24/outline";
import { getConfiguration } from "../../../features/Global/Configuration";
import { useSelector, useDispatch } from "react-redux";
import { EnvelopeIcon } from "@heroicons/react/20/solid";
import Cookies from "js-cookie";
import {
  increment,
  // setSidebar,
  // getSidebar,
} from "../../../features/Global/Dashboard/Sidebar";
import {
  HttpTransportType,
  HubConnectionBuilder,
  LogLevel,
} from "@microsoft/signalr";
import { get } from "jquery";
import axios from "axios";
import { getChatUsers } from "../../../features/Global/ChatUsers";
const Sidebar = () => {
  let navigate = useNavigate();
  const title = useSelector((state) => state.Configuration.data);
  const sidebarStore = useSelector((state) => state.Sidebar?.value);
  const dispatch = useDispatch();
  const chatUsers = useSelector((state) => state.ChatUsers.copyData);

  useEffect(() => {
    // dispatch(getSidebar());
    dispatch(getConfiguration());
  }, []);
  const divStyle = {
    direction: "ltr",
  };
  const [open, setOpen] = useState(true);
  const classNameFunc = ({ isActive }) =>
    isActive
      ? "dark:text-gray-100  duration-400 rounded-md text-white bg-secondary-700"
      : "";
  const [toggle, setToggle] = useState(false);
  const [dark, setDark] = useState(false);

  const body = document.querySelector("body"),
    sidebar = body.querySelector("nav"),
    modeText = body.querySelector(".mode-text");

  const handleToggle = () => {
    // sidebar.classNameList.toggle("close
    setToggle(dispatch(increment()));
  };

  const handleSwitch = () => {
    setDark(!dark);
    body.classList.toggle("dark");

    if (document.body.classList.contains("dark")) {
    }
    Text.innerText = "Dark mode";
    // }
  };
  const logout = () => {
    Cookies.remove("dashboardToken");

    navigate("/dashboard/login");
  };
  const checkActive = (match, location) => {
    //some additional logic to verify you are in the home URI
    if (!location) return false;
    const { pathname } = location;
    console.log("path", pathname);
    return pathname === match;
  };

  const [connection, setConnection] = useState("");

  //  Notification count
  useEffect(() => {
    dispatch(getChatUsers());
    if (!connection && Cookies.get("dashboardToken")) {
      const connection = new HubConnectionBuilder()
        // .withUrl(`https://localhost:7247/chatHub`, {
        .withUrl(`https://varyhealthcare.com/api/chatHub`, {
          accessTokenFactory: () => Cookies.get("dashboardToken"),
          UseDefaultCredentials: true,
          skipNegotiation: true,
          transport: HttpTransportType.WebSockets,
        })
        .configureLogging(LogLevel.Information)
        .withAutomaticReconnect()
        .build();
      connection.logging = true;
      connection.start();
      setConnection(connection);
    }
  }, [connection, dispatch]);
  useEffect(() => {
    if (connection) { 
      connection.on("receive", (message) => {
        dispatch(getChatUsers());
      });
    }
  }, [connection, dispatch]);

  return (
    <nav
      style={divStyle}
      className={` 
        ${
          sidebarStore
            ? "sidebar z-20 bg-gradient-to-br from-secondary-600 to-secondary-600 h-full text-white transition-all duration-300 border-none  sidebar rounded min-h-full dark:from-gray-800 dark:to-gray-800"
            : "sidebar close  z-20 dark:from-gray-800 dark:to-gray-800  bg-gradient-to-br from-secondary-600 to-secondary-600 h-full text-white transition-all duration-300 border-none sidebar rounded min-h-full"
        }`}
    >
      <header>
        <NavLink to="/dashboard" className="py-2 my-2 image-text">
          <span className="image">
            <img src={title[0]?.imageUrl} alt="" />
          </span>

          <div className="text logo-text">
            <span className="text-white dark:text-white">
              {title[0]?.englishName}
            </span>
            <span className="text-white profession dark:text-white">
              {/* Heath Care */}
            </span>
          </div>
        </NavLink>

        <ArrowRightCircleIcon
          className="z-30 bg-secondary-400 bx bx-chevron-right toggle dark:text-secondary-500 dark:bg-secondary-50"
          onClick={() => handleToggle()}
        ></ArrowRightCircleIcon>
      </header>

      <div className="menu-bar">
        <div className="menu">
          {/* <li className="search-box" onClick={() => handleSearchbox()}>
            <MagnifyingGlassIcon className="w-6 h-6 bx bx-search icon" />
            <input type="text" placeholder="Search..." />
          </li> */}

          <ul className="menu-links">
            <li className="nav-link">
              <NavLink to="/dashboard" className={classNameFunc}>
                <ChartBarSquareIcon className="w-6 h-6 bx bx-home-alt icon"></ChartBarSquareIcon>
                <span className={`text nav-text ${classNameFunc}`}>
                  Dashboard
                </span>
              </NavLink>
            </li>

            <li className="nav-link">
              <NavLink to="/dashboard/items" className={classNameFunc}>
                <RectangleGroupIcon className="w-6 h-6 bx bx-bar-chart-alt-2 icon" />
                <span className={`text nav-text ${classNameFunc}`}>Items</span>
              </NavLink>
            </li>
            <li className="nav-link">
              <NavLink to="/dashboard/brands" className={classNameFunc}>
                <SwatchIcon className="w-6 h-6 bx bx-bar-chart-alt-2 icon" />
                <span className={`text nav-text ${classNameFunc}`}>Brands</span>
              </NavLink>
            </li>

            <li className="nav-link">
              <NavLink to="/dashboard/category" className={classNameFunc}>
                <TagIcon className="w-6 h-6 bx bx-bell icon" />
                <span className={`text nav-text ${classNameFunc}`}>
                  Categories
                </span>
              </NavLink>
            </li>
            <li className="nav-link">
              <NavLink to="/dashboard/subcategory" className={classNameFunc}>
                <RectangleStackIcon className="w-6 h-6 bx bx-bell icon" />
                <span className={`text nav-text ${classNameFunc}`}>
                  Sub Category
                </span>
              </NavLink>
            </li>
            <li className="nav-link">
              <NavLink to="/dashboard/customers" className={classNameFunc}>
                <UserCircleIcon className="w-6 h-6 bx bx-pie-chart-alt icon" />
                <span className={`text nav-text ${classNameFunc}`}>
                  Customers
                </span>
              </NavLink>
            </li>
            <li className="nav-link">
              <NavLink to="/dashboard/orders" className={classNameFunc}>
                <ListBulletIcon className="w-6 h-6 bx bx-pie-chart-alt icon" />
                <span className={`text nav-text ${classNameFunc}`}>Orders</span>
              </NavLink>
            </li>

            <li className="nav-link">
              <NavLink to="/dashboard/offer" className={classNameFunc}>
                <ReceiptPercentIcon className="w-6 h-6 bx bx-heart icon" />
                <span className={`text nav-text ${classNameFunc}`}>Offers</span>
              </NavLink>
            </li>
            <li className="nav-link">
              <NavLink to="/dashboard/notification" className={classNameFunc}>
                <BellAlertIcon className="w-6 h-6 bx bx-heart icon" />
                <span className={`text nav-text ${classNameFunc}`}>
                  Notification
                </span>
              </NavLink>
            </li>
            <li className="nav-link w-100">
              <NavLink to="/dashboard/chat" className={classNameFunc}>
                <div class="relative">
                  <ChatBubbleBottomCenterTextIcon className="w-6 h-6 bx bx-heart icon" />
                  {!sidebarStore && (
                    <>
                      {chatUsers?.filter((item) => item.seenMessages === false)
                        .length > 0 && (
                        <div class="absolute -top-2 right-1 w-5 h-5 bg-rose-500 text-white text-xs flex items-center justify-center rounded-full animate-pulse">
                          {
                            chatUsers?.filter(
                              (item) => item.seenMessages === false
                            ).length
                          }
                        </div>
                      )}
                    </>
                  )}
                </div>

                <div
                  className={`text nav-text flex justify-between ${classNameFunc}`}
                >
                  <span className="flex-1 w-28">Chat</span>
                  {sidebarStore && (
                    <>
                      {chatUsers?.filter((item) => item.seenMessages === false)
                        .length > 0 && (
                        <span className="px-2.5 py-1 rounded-full bg-secondary-400 animate-pulse">
                          {
                            chatUsers?.filter(
                              (item) => item.seenMessages === false
                            ).length
                          }
                        </span>
                      )}
                    </>
                  )}
                </div>
              </NavLink>
            </li>
            <li className="nav-link">
              <NavLink to="/dashboard/askvary" className={classNameFunc}>
                <QuestionMarkCircleIcon className="w-6 h-6 bx bx-heart icon" />
                <span className={`text nav-text ${classNameFunc}`}>
                  Ask Vary
                </span>
              </NavLink>
            </li>
            <li className="nav-link">
              <NavLink to="/dashboard/profile" className={classNameFunc}>
                <IdentificationIcon className="w-6 h-6 bx bx-wallet icon" />
                <span className={`text nav-text ${classNameFunc}`}>
                  Profiles
                </span>
              </NavLink>
            </li>
            <li className="nav-link">
              <NavLink to="/dashboard/information" className={classNameFunc}>
                <NewspaperIcon className="w-6 h-6 bx bx-wallet icon" />
                <span className={`text nav-text ${classNameFunc}`}>
                  Information
                </span>
              </NavLink>
            </li>

            <li className="nav-link">
              <NavLink to="/dashboard/users" className={classNameFunc}>
                <UserGroupIcon className="w-6 h-6 bx bx-wallet icon" />
                <span className={`text nav-text ${classNameFunc}`}>Users</span>
              </NavLink>
            </li>

            <li className="nav-link">
              <NavLink to="/dashboard/reports" className={classNameFunc}>
                <CalculatorIcon className="w-6 h-6 bx bx-wallet icon" />
                <span className={`text nav-text text-sm ${classNameFunc}`}>
                  Most selling items
                </span>
              </NavLink>
            </li>
            <li className="nav-link">
              <NavLink
                to="/dashboard/reports-most-selling-categories"
                className={classNameFunc}
              >
                <ChartPieIcon className="w-6 h-6 bx bx-wallet icon" />
                <span className={`text nav-text text-sm ${classNameFunc}`}>
                  Most selling categories
                </span>
              </NavLink>
            </li>
            <li className="nav-link">
              <NavLink to="/dashboard/cities" className={classNameFunc}>
                <MapIcon className="w-6 h-6 bx bx-wallet icon" />
                <span className={`text nav-text ${classNameFunc}`}>Cities</span>
              </NavLink>
            </li>
            <li className="nav-link">
              <NavLink to="/dashboard/contactUs" className={classNameFunc}>
                <PhoneIcon className="w-6 h-6 bx bx-wallet icon" />
                <span className={`text nav-text ${classNameFunc}`}>
                  ContactUs
                </span>
              </NavLink>
            </li>
            <li className="nav-link">
              <NavLink to="/dashboard/carousel" className={classNameFunc}>
                <PhotoIcon className="w-6 h-6 bx bx-wallet icon" />
                <span className={`text nav-text ${classNameFunc}`}>
                  Carousel
                </span>
              </NavLink>
            </li>
            <li className="nav-link">
              <NavLink to="/dashboard/emails" className={classNameFunc}>
                <EnvelopeIcon className="w-6 h-6 bx bx-wallet icon" />
                <span className={`text nav-text ${classNameFunc}`}>Emails</span>
              </NavLink>
            </li>
            <li className="nav-link">
              <NavLink to="/dashboard/banner" className={classNameFunc}>
                <FireIcon className="w-6 h-6 bx bx-wallet icon" />
                <span className={`text nav-text ${classNameFunc}`}>Banner</span>
              </NavLink>
            </li>
            <li className="nav-link">
              <NavLink to="/dashboard/configuration" className={classNameFunc}>
                <CogIcon className="w-6 h-6 bx bx-wallet icon" />
                <span className={`text nav-text ${classNameFunc}`}>
                  Configuration
                </span>
              </NavLink>
            </li>
          </ul>
        </div>

        <div className="bottom-content">
          <li className="">
            <ArrowLeftOnRectangleIcon
              onClick={() => logout()}
              className="w-6 h-6 bx bx-log-out icon"
            />
            <button onClick={() => logout()} className="inline w-5 h-6">
              <span className={`text nav-text ${classNameFunc}`}>Logout</span>
            </button>
          </li>
        </div>
      </div>
    </nav>
  );
};

export default Sidebar;
