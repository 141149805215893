import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useDispatch, useSelector } from "react-redux";
import { getBrand } from "../../../features/Global/Brands";
import { Link } from "react-router-dom";
import CustomDot from "../Basics/CustomDot";
import { motion } from "framer-motion";
import { GiftIcon, ReceiptPercentIcon } from "@heroicons/react/24/outline";
import axios from "axios";
import { getBasket } from "../../../features/web/ShopingCart";
import SnackBar from "./SnackBarDynamic";
import AddedSnackBar from "./AddedToBasketSnackBar";
import CheckAuth from "../CheckAuthModal/CheckAuth";
import Cookies from "js-cookie";
import { ArrowLeftIcon } from "@heroicons/react/20/solid";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
    slidesToSlide: 3, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
    slidesToSlide: 2, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};

// const CustomDot = ({ onClick, active, index, carouselState }) => {
//   const { currentSlide } = carouselState;
//   return (
//     <li style={{ background: active ? "grey" : "initial" }}>
//       <button
//         style={{ background: active ? "grey" : "initial" }}
//         onClick={() => onClick()}
//       />
//     </li>
//   );
// };

const Brand = () => {
  const isLogedIn = Cookies.get("webAuth")?.length > 0 ? true : false;
  const locale = localStorage.getItem("i18nextLng");
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const category = useSelector((state) => state.Brands.data);
  const loading = useSelector((state) => state.Brands.loading);
  const isSuccess = useSelector((state) => state.Brands.isSuccess);
  const [checkLoginModal, setcheckLoginModal] = useState(false);
  const [snackBar, setSnackBar] = useState(false);
  const [AddedToBasket, setAddedToBasket] = useState(false);
  useEffect(() => {
    dispatch(getBrand());
  }, []);
  const config = {
    headers: {
      Authorization: `Bearer ${Cookies.get("webAuth")}`,
    },
  };

  return (
    <Carousel
      additionalTransfrom={0}
      arrows
      autoPlay
      autoPlaySpeed={3000}
      centerMode={false}
      className=""
      containerClass="container-with-dots"
      dotListClass=""
      draggable
      focusOnSelect={false}
      infinite
      itemClass=""
      keyBoardControl
      minimumTouchDrag={80}
      pauseOnHover
      renderArrowsWhenDisabled={false}
      renderButtonGroupOutside={false}
      renderDotsOutside={false}
      responsive={{
        desktop: {
          breakpoint: {
            max: 3000,
            min: 1024,
          },
          items: 4,
          partialVisibilityGutter: 40,
        },
        mobile: {
          breakpoint: {
            max: 464,
            min: 0,
          },
          items: 1,
          partialVisibilityGutter: 30,
        },
        tablet: {
          breakpoint: {
            max: 1024,
            min: 464,
          },
          items: 2,
          partialVisibilityGutter: 30,
        },
      }}
      rewind
      rewindWithAnimation={false}
      shouldResetAutoplay
      showDots={false}
      sliderClass=""
      slidesToSlide={2}
      swipeable
      rtl={locale === "en-US" ? false : true}
    >
      {/* <div className="grid gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 snap-x "> */}
      {isSuccess &&
        category
          // .slice(0, 20)
          // ?.filter((item) => item.qty > 0)
          .map((item, index) => (
            <>
              <motion.div
                key={index}
                className="m-2 duration-500 bg-white shadow-md snap-center hover:scale-105 hover:shadow-xl rounded-xl"
              >
                {/* <Link to={`shop/product/${item.id}`}>
                <img
                  src={item.imageUrl}
                  alt="Product image"
                  className="object-cover w-full h-80 rounded-t-xl"
                />
              </Link> */}
                <Link
                  to={
                    locale === "en-US"
                      ? `/shop/${
                          item?.id + "." + item.name.english + "searchBrand"
                        }`
                      : locale === "ar"
                      ? `/shop/${
                          item?.id + "." + item.name.arabic + "searchBrand"
                        }`
                      : `/shop/${
                          item?.id + "." + item.name.kurdish + "searchBrand"
                        }`
                  }
                  replace={true}
                  relative={true}
                  className="relative flex mt-3 overflow-hidden h-60"
                >
                  {item.qty < 1 && (
                    <span
                      className={`absolute top-0 z-10 ${
                        locale === "en-US" ? "left-0" : "right-0"
                      } m-2 rounded-md bg-gradient-to-br from-rose-600 to-rose-500 px-2 text-center text-sm font-medium text-white ${locale}`}
                    >
                      {t("outOfStock")}
                    </span>
                  )}
                  <img
                    src={item?.imageUrl}
                    alt="Product image"
                    className="object-contain w-full duration-500 rounded-t-xl hover:rounded-t-xl hover:scale-110"
                  />
                </Link>
                <div className="flex justify-between w-auto px-3 py-3">
                  <p
                    className={`block text-md font-bold text-black capitalize truncate overflow-hidden ${locale}`}
                  >
                    {locale === "en-US" && item.name?.english}
                    {locale === "ar" && item.name?.arabic}
                    {locale === "ku" && item.name?.kurdish}
                  </p>
                  <Link
                    to={
                      locale === "en-US"
                        ? `/shop/${
                            item?.id + "." + item.name.english + "searchBrand"
                          }`
                        : locale === "ar"
                        ? `/shop/${
                            item?.id + "." + item.name.arabic + "searchBrand"
                          }`
                        : `/shop/${
                            item?.id + "." + item.name.kurdish + "searchBrand"
                          }`
                    }
                    className={`text-xs text-secondary-600 my-auto ${locale}`}
                  >
                    {t("viewProducts")} <ArrowLeftIcon className="inline w-4" />
                  </Link>
                </div>
              </motion.div>
            </>
          ))}
      {checkLoginModal && (
        <CheckAuth open={checkLoginModal} setOpen={setcheckLoginModal} />
      )}
      {/* {snackBar && <SnackBar />} */}
      {/* {AddedToBasket && <AddedSnackBar />} */}
      {/* <div>
        <AddedSnackBar />
      </div> */}
      {/* </div> */}
    </Carousel>
  );
};

export default Brand;
