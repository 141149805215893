import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";

// Assets
import style from "../assets/css/index.css";
import image from "../assets/images/medicine.jpeg";
import image2 from "../assets/images/medicne1.jpeg";
import medicine from "../assets/images/medicine3.jpeg";
import offer from "../assets/images/offer.png";
import vary from "../assets/images/vary.jpeg";
import sale1 from "../assets/images/sale1.avif";
import sale2 from "../assets/images/sale2.jpg";
import sale3 from "../assets/images/sale4.jpg";
import background from "../assets/images/background.jpg";
import banner from "../assets/images/subscribe-banner.png";
import VaryTeam from "./Profile/VaryTeam.jsx";
import { Link } from "react-router-dom";
// componets
import Footer from "./Footer";
import CopyWrite from "./CopyWrite";
import Categories from "./shop/Categories";
import NewsSection from "./News/NewsSection";
import HeroSection from "./HeroSection";
import Features from "../componets/Marketting/Features";
import { getItem } from "../../features/Global/Items.js";
import { ArrowRightCircleIcon } from "@heroicons/react/20/solid";
import AskVary from "../componets/AskVary/AskVary";
import { ArrowLeftCircleIcon } from "@heroicons/react/24/outline";
// redux mapping
import { useDispatch, useSelector } from "react-redux";
import { getCategory } from "../../features/Global/Category";
import NewArrival from "./shop/NewArrival";
import MostSelling from "./shop/MostSelling";
import Brand from "./shop/Brands";
// Animation
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import ChatPop from "./Chat/ChatPopup";
import Marketing from "./Marketting/Marketing";
import PreLoader from "./Loader/PreLoader";
import Sale from "./Marketting/Sale";
import Chat from "./Chat/Chat";
import Chatpopup from "./Chat/ChatPopupTailwind";

//
import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import loaderAnim from "./underConstraction.json";
import { Player } from "@lottiefiles/react-lottie-player";
import Slider from "./Carousel/Carousel";
import AppModal from "../componets/AppModal.jsx";
import BMI from "./BMI/BMI";
const Content = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const category = useSelector((state) => state.category.data);
  const animation = useAnimation();
  const [appmodal, setAppMoadl] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setAppMoadl(true);
    }, 5000);
  }, []);

  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0,
  });
  useEffect(() => {
    // console.log(inView);
    if (inView) {
      animation.start({
        y: 0,
        opacity: 100,
        transition: {
          type: "spring",
          duration: 1,
        },
      });
    }
    if (!inView) {
      animation.start({
        y: 100,
        opacity: 0,
      });
    }
    dispatch(getCategory());
  }, [inView]);
  const auth = Cookies.get("webAuth");
  const locale = localStorage.getItem("i18nextLng");

  let [isOpen, setIsOpen] = useState(true);
  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }
  return (
    <div>
      {/* <HeroSection /> */}
      <Slider />

      {/* Warnning */}
      <>
        {/* <div className="fixed inset-0 flex items-center justify-center">
          <button
            type="button"
            onClick={openModal}
            className="px-4 py-2 text-sm font-medium text-white bg-black rounded-md bg-opacity-20 hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
          >
            Open dialog
          </button>
        </div> */}

        {/* <Transition appear show={isOpen} as={Fragment}>
          <Dialog as="div" className="relative z-10" onClose={closeModal}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-y-auto">
              <div className="flex items-center justify-center min-h-full p-4 text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className="w-full max-w-2xl p-6 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                    <Dialog.Title
                      as="h3"
                      className="text-xl font-medium leading-6 text-yellow-600"
                    >
                      Warning
                    </Dialog.Title>
                    <div className="mt-4">
                      <Player
                        src={loaderAnim}
                        autoplay
                        loop
                        className="player"
                      />
                    </div>

                    <div className="mt-4">
                      <button
                        type="button"
                        className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                        onClick={closeModal}
                      >
                        Got it, thanks!
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition> */}
      </>
      {/* Warnning */}
      {/* <div
        className="bg-center bg-no-repeat bg-cover py-36"
        style={{ backgroundImage: `url(${background})` }}
      >
        <div className="container">
          <h1 className="mb-4 text-6xl font-medium text-gray-800 capitalize">
            <span className="text-pink-500 font-"> Vary </span> <br /> Pharmcy
          </h1>
          <p>
            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Aperiam{" "}
            <br />
            accusantium perspiciatis, sapiente magni eos dolorum ex quos dolores
            odio
          </p>
          <div className="mt-12">
            <Link
              to="/shop"
              className="px-8 py-3 font-medium text-white border rounded-md bg-primary border-primary md:w-1/3 lg:w-1/4 hover:bg-transparent hover:text-primary"
            >
              Shop Now
            </Link>
          </div>
        </div>
      </div> 



            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="inline w-6 h-6 text-pink-500 group-hover:text-white"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M8.25 18.75a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m3 0h6m-9 0H3.375a1.125 1.125 0 01-1.125-1.125V14.25m17.25 4.5a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m3 0h1.125c.621 0 1.129-.504 1.09-1.124a17.902 17.902 0 00-3.213-9.193 2.056 2.056 0 00-1.58-.86H14.25M16.5 18.75h-2.25m0-11.177v-.958c0-.568-.422-1.048-.987-1.106a48.554 48.554 0 00-10.026 0 1.106 1.106 0 00-.987 1.106v7.635m12-6.677v6.677m0 4.5v-4.5m0 0h-12"
              />
            </svg>

            <div>
              <h4
                className={`text-lg font-medium capitalize group-hover:text-white ${locale}`}
              >
                {t("freeDelivary")}
              </h4>
              <p
                className={`text-sm text-gray-500 group-hover:text-white ${locale}`}
              >
                {t("orderOver")}
              </p>
            </div>
          </div>
          <div className="flex items-center justify-center gap-5 px-3 py-6 border rounded-sm border-primary hover:bg-pink-500 hover:text-white group">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="inline w-6 h-6 text-pink-500 group-hover:text-white"
            >
              <path
                className="inline"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z"
              />
            </svg>

            <div>
              <h4
                className={`text-lg font-medium capitalize group-hover:text-white ${locale}`}
              >
                {t("monyreturn")}
              </h4>
              <p
                className={`text-sm text-gray-500 group-hover:text-white ${locale}`}
              >
                {t("in3Day")}
              </p>
            </div>
          </div>
          <div className="flex items-center justify-center gap-5 px-3 py-6 border rounded-sm border-primary group hover:text-white hover:bg-pink-500">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="inline w-6 h-6 text-pink-500 group-hover:text-white"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>

            <div>
              <h4
                className={`text-lg font-medium capitalize group-hover:text-white ${locale}`}
              >
                {t("24hOpen")}
              </h4>
              <p
                className={`text-sm text-gray-500 group-hover:text-white ${locale}`}
              >
                {t("customerSupport")}{" "}
              </p>
            </div>
          </div>
        </div>
      </div> */}
      {/* <PreLoader /> */}
      {/* <motion.section
        ref={ref}
        animate={animation}
        data-aos="fade-up"
        className="text-gray-700 border-t border-gray-200 body-font"
      >
        <Features />
      </motion.section> */}
      {/* <!-- ./features --> */}

      {/* <!-- categories --> */}
      {/* <h2 className="container mb-12 text-2xl font-medium text-gray-800 uppercase">
        {t("shopByCategories")}
      </h2>
      <Categories /> */}
      {/* <div className="container py-16">
        <div className="grid gap-3 sm:grid-cols-2 sm:grid-cols-3 lg:grid-cols-5">
          <div className="relative overflow-hidden rounded-sm group">
            <img src={image} alt="category 1" className="w-full h-50" />
            <Link
              to="#"
              className="absolute inset-0 flex items-center justify-center text-xl font-medium text-white transition bg-black bg-opacity-40 font-roboto group-hover:bg-opacity-60"
            >
              Medicine
            </Link>
          </div>
          <div className="relative overflow-hidden rounded-sm group">
            <img src={image2} alt="category 1" className="w-full" />
            <Link
              to="#"
              className="absolute inset-0 flex items-center justify-center text-xl font-medium text-white transition bg-black bg-opacity-40 font-roboto group-hover:bg-opacity-60"
            >
              Health Care
            </Link>
          </div>
          <div className="relative overflow-hidden rounded-sm group">
            <img src={medicine} alt="category 1" className="w-full" />
            <Link
              to="#"
              className="absolute inset-0 flex items-center justify-center text-xl font-medium text-white transition bg-black bg-opacity-40 font-roboto group-hover:bg-opacity-60"
            >
              Phill
            </Link>
          </div>
          <div className="relative overflow-hidden rounded-sm group">
            <img src={image} alt="category 1" className="w-full" />
            <Link
              to="#"
              className="absolute inset-0 flex items-center justify-center text-xl font-medium text-white transition bg-black bg-opacity-40 font-roboto group-hover:bg-opacity-60"
            >
              medicine
            </Link>
          </div>
          <div className="relative overflow-hidden rounded-sm group">
            <img src={image2} alt="category 1" className="w-full" />
            <Link
              to="#"
              className="absolute inset-0 flex items-center justify-center text-xl font-medium text-white transition bg-black bg-opacity-40 font-roboto group-hover:bg-opacity-60"
            >
              Injections
            </Link>
          </div>
          <div className="relative overflow-hidden rounded-sm group">
            <img src={medicine} alt="category 1" className="w-full" />
            <Link
              to="#"
              className="absolute inset-0 flex items-center justify-center text-xl font-medium text-white transition bg-black bg-opacity-40 font-roboto group-hover:bg-opacity-60"
            >
              Makeup
            </Link>
          </div>
        </div>
      </div> */}
      {/* <!-- ./categories --> */}

      {/* <!-- new arrival --> */}
      <div className="container pb-20">
        <div className="col-span-12 lg:col-span-3" data-aos="fade-up">
          <div className="max-w-screen-sm mx-auto my-6 mb-8 lg:mb-16">
            <p
              className={`mb-4 text-4xl font-semibold tracking-tight bg-clip-text text-transparent bg-gradient-to-br from-pink-400 to-pink-600 text-center py-2 ${locale}`}
            >
              {t("newArrival")}
            </p>
            <p
              className={`font-light text-gray-500 sm:text-xl dark:text-gray-400 text-center ${locale}`}
            >
              {t("newArrivalDesc")}
            </p>
          </div>

          <NewArrival />
        </div>
      </div>
      {/* <!-- ./new arrival --&gt; */}

      {/* <!-- ads --&gt; */}
      {/* <!-- component --> */}
      <Sale data-aos="fade-up" />
      {/* <!-- ./ads --&gt; */}

      {/* <!-- product --&gt; */}
      <div className="container py-16" data-aos="fade-up">
        <div className="max-w-screen-sm mx-auto my-6 mb-8 lg:mb-16">
          <p
            className={`mb-4 text-4xl font-semibold tracking-tight bg-clip-text text-transparent bg-gradient-to-br from-pink-400 to-pink-600 text-center py-2 ${locale}`}
          >
            {t("mostSellingproduct")}
          </p>
          <p
            className={`font-light text-gray-500 sm:text-xl dark:text-gray-400 text-center ${locale}`}
          >
            {t("MostSellingDesc")}
          </p>
        </div>

        {/* <SaleCarousel /> */}

        <MostSelling data-aos="fade-up" />
      </div>
      {/* BRAND SECTION */}
      <div
        className="container py-16"
        data-aos="fade-up"
        id="brand"
        name="brand"
      >
        <div className="max-w-screen-sm mx-auto my-6 mb-8 lg:mb-16">
          <p
            className={`mb-4 text-4xl font-semibold tracking-tight bg-clip-text text-transparent bg-gradient-to-br from-pink-400 to-pink-600 text-center py-2 ${locale}`}
          >
            {t("brands")}
          </p>
        </div>
        <Brand data-aos="fade-up" />
      </div>
      {/* Features */}
      {/* <section data-aos="fade-up" className="text-gray-700 border-t body-font">
        <Features />
      </section> */}
      {/* BRAND SECTION */}
      {/* BMI SECTION */}
      {/*   <section data-aos="fade-up" className="text-gray-700 border-t body-font">
        <div className="max-w-screen-sm mx-auto my-6 mb-8 lg:mb-16">
          <p
            className={`mb-4 text-4xl font-semibold tracking-tight bg-clip-text text-transparent bg-gradient-to-br from-pink-400 to-pink-600 text-center py-2 ${locale}`}
          >
            {t("BMI")}
          </p>
        </div>
         <BMI /> 
      </section>*/}
      {/* BMI SECTION */}
      {/* <!-- ./product --&gt; */}
      {/* <!-- Ask Vary --&gt; */}
      <NewsSection />
      <div className="rounded-md ">
        <AskVary />
      </div>
      {/* <div
        className="overflow-hidden rounded-md scroll-auto"
        data-aos="fade-up"
      >
        <VaryTeam />
      </div> */}
      {appmodal && (
        <>
          <AppModal isOpen={appmodal} onClose={() => setAppMoadl(false)} />
        </>
      )}

      {/* <!-- Ask Vary --&gt; */}
      {/* <!-- ./News Section --&gt; */}
      {/* <ChatPop /> */}
      {/* <!-- ./News Section --&gt; */}
      {/* <Chat /> */}
      {/* <!-- footer --&gt; */}
      <Footer data-aos="fade-up" />
      {/* <!-- ./footer --&gt; */}

      {/* <!-- copyright --&gt; */}
      <CopyWrite data-aos="fade-up" />
      {/* <!-- copyright --&gt; */}
    </div>
  );
};

export default Content;
