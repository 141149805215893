import React, { useState, useEffect } from "react";
import BreadCrumb from "../../components/Cards/BreadCrumb";
import Header from "../../components/Sidebar/Header";
import Sidebar from "../../components/Sidebar/Sidebar";
import { useLocation } from "react-router-dom";
import orders from "../../icons/users.svg";
import addIcon from "../../icons/add.svg";
import InfoCard from "../../components/Cards/Carts";
import Add from "./Add";
import Edit from "./Edit";
import View from "./View";
import axios from "axios";
import Toast from "../../components/Cards/Toast";
import Image from "./Imgae";
// Icons
import {
  EyeIcon,
  PlusCircleIcon,
  TrashIcon,
  UserGroupIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";
import { PencilIcon } from "@heroicons/react/24/outline";

// Grid
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import Cookies from "js-cookie";

// redux mapping
import { useDispatch, useSelector } from "react-redux";
import { getUsers } from "../../../features/Global/Users";
import Spiner from "../../components/Loader/Spiner";
const Index = () => {
  const [isHeadlessOpen, setIsHeadlessOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [view, setView] = useState(false);
  const [upload, setUpload] = useState(false);
  const [editID, setEditId] = useState("");
  const [deleteID, setDeleteId] = useState("");
  const dispatch = useDispatch();
  const data = useSelector((state) => state.Users.data);
  // Toast
  const [toast, setToast] = useState(false);
  const [error, setError] = useState(false);
  const [message, setMessage] = useState("");
  // hook to fetch items
  useEffect(() => {
    dispatch(getUsers());
  }, []);
  // Data Grid Compoenets
  const [pageSize, setPageSize] = useState(10);
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }
  const columns = [
    { field: "id", headerName: "id", width: 70 },
    {
      field: "imageUrl",
      headerName: "Image",
      width: 70,
      renderCell: (params) => {
        return (
          <img
            onClick={() => {
              handleUload();
            }}
            className="w-10 h-10 rounded-full"
            src={params.value}
          />
        );
      },
    },
    { field: "userName", headerName: "User name", width: 150, flex: 1 },
    { field: "emailAddress", headerName: "Email", width: 150, flex: 1 },
    {
      field: "isActive",
      headerName: "Status",
      width: 120,
      // flex: 1,
      renderCell: (cellvalues) => (
        <button
          onClick={() => handleStatus(cellvalues.id)}
          type="button"
          className={`inline-flex items-center justify-center px-4 py-1  text-sm font-medium w-full text-center text-white transition duration-200 ease-in  ${
            cellvalues.row.isActive === true
              ? "text-emerald-700 bg-emerald-200 hover:bg-emerald-400-600 focus:ring-emerald-500 focus:ring-offset-emerald-200 f "
              : "text-rose-700 bg-rose-200 hover:bg-rose-400-600 focus:ring-rose-500 focus:ring-offset-rose-200"
          }  border-none rounded-md  focus:outline-none focus:ring-2 focus:ring-offset-2`}
        >
          {cellvalues.row.isActive === true ? "Active" : "InActive"}
          <div className="absolute z-10 px-3 py-2 text-xs text-center text-white bg-black rounded-lg opacity-0 pointer-events-none w-28 group-hover:opacity-100 bottom-full -left-1/2 ml-14">
            Tooltip center
            <svg
              className="absolute left-0 w-full h-2 text-black top-full"
              x="0px"
              y="0px"
              viewBox="0 0 255 255"
            >
              <polygon className="fill-current" points="0,0 127.5,127.5 255,0" />
            </svg>
          </div>
        </button>
      ),
    },
    // {
    //   field: "Upload",
    //   headerName: "Edit",
    //   width: 130,
    //   renderCell: (cellvalues) => (
    //     <button
    //       onClick={(e) => handleEdit(cellvalues.id)}
    //       type="button"
    //       className="flex items-center justify-center float-right w-full py-2 text-sm text-center text-white transition duration-200 ease-in bg-blue-600 rounded-lg shadow-md hover:bg-blue-700 focus:ring-blue-600 focus:ring-offset-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2"
    //     >
    //       <PencilIcon
    //         src={addIcon}
    //         alt="add icon"
    //         className="w-4 h-4 mr-2 text-white "
    //       />
    //       Edit
    //     </button>
    //   ),
    // },
    {
      field: "Edit",
      headerName: "Edit",
      width: 130,
      renderCell: (cellvalues) => (
        <button
          onClick={(e) => handleEdit(cellvalues.id)}
          type="button"
          className="flex items-center justify-center float-right w-full py-2 text-sm text-center text-white transition duration-200 ease-in bg-blue-600 rounded-lg shadow-md hover:bg-blue-700 focus:ring-blue-600 focus:ring-offset-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 "
        >
          <PencilIcon
            src={addIcon}
            alt="add icon"
            className="w-4 h-4 mr-2 text-white "
          />
          Edit
        </button>
      ),
    },
    // {
    //   field: "Delete",
    //   headerName: "Delete",
    //   width: 130,
    //   renderCell: (cellvalues) => (
    //     <button
    //       onClick={(e) => handleDelete(cellvalues.id)}
    //       type="button"
    //       className="flex items-center justify-center float-right w-full py-2 text-sm text-center text-white transition duration-200 ease-in rounded-lg shadow-md bg-rose-500 hover:bg-rose-600 focus:ring-rose-500 focus:ring-offset-rose-200 focus:outline-none focus:ring-2 focus:ring-offset-2 "
    //     >
    //       <TrashIcon
    //         src={addIcon}
    //         alt="add icon"
    //         className="w-4 h-4 mr-2 text-white "
    //       />
    //       Delete
    //     </button>
    //   ),
    // },
    // {
    //   field: "View",
    //   headerName: "View",
    //   width: 130,
    //   renderCell: () => (
    //     <button
    //       onClick={() => setView(true)}
    //       type="button"
    //       className="flex items-center justify-center float-right w-full px-3 py-2 text-center text-white transition duration-200 ease-in bg-orange-500 rounded-lg shadow-md hover:bg-orange-600 focus:ring-orange-500 focus:ring-offset-secondary-200 focus:outline-none focus:ring-2 focus:ring-offset-2 "
    //     >
    //       <EyeIcon
    //         src={addIcon}
    //         alt="add icon"
    //         className="w-4 h-4 mr-2 text-white "
    //       />
    //       View
    //     </button>
    //   ),
    // },
  ];
  // const config = {
  //   headers: {
  //     Authorization: `Bearer ${Cookies.get("dashboardToken")}`,
  //   },
  // };
  const config = {
    headers: { Authorization: `Bearer ${Cookies.get("dashboardToken")}` },
  };

  const handleStatus = (id) => {
    const data = {
      status: "new_status",
    };

    axios
      .put(`api/DashboardUsers/${id}`, data, config)
      .then((r) => {
        // console.log(r);
        if (r.status === 200 || r.status === 204) {
          setMessage("User Account Status Updated Successfully.");
          setToast(true);
          dispatch(getUsers());
        }
      })
      .catch((e) => {
        console.log(e.response.data);
        setMessage(e.response.data);
        setError(true);
      });
  };
  const handleEdit = (id) => {
    setEditId(id);
    setEdit(true);
    dispatch(getUsers());
  };
  const handleDelete = (id) => {
    setDeleteId(id);
    setView(true);
    dispatch(getUsers());
  };
  const handleUload = (id) => {
    // setEditId(id);
    setUpload(true);
    dispatch(getUsers());
    // console.log(upload);
  };
  // const handleDelete = (id) => {
  //   const config = {
  //     headers: {
  //       Authorization: `Bearer ${Cookies.get("dashboardToken")}`,
  //     },
  //   };
  //   axios
  //     .delete(`api/DashboardUsers/${id}`, config)
  //     .then((r) => {
  //       // console.log(r);
  //       if (r.status === 200) {
  //         setToast(true);
  //         dispatch(getUsers());
  //         setMessage("User deleted successfully");
  //       }
  //       if (r.status === 204) {
  //         setToast(true);
  //         dispatch(getUsers());
  //         setMessage("User deleted successfully");
  //       }
  //     })
  //     .catch((e) => {
  //       console.log(e.response.data);
  //       setMessage(e.response.data);
  //       setError(true);
  //     });
  // };
  return (
    <div className="ltr">
      <main className="relative h-screen bg-gray-100 dark:bg-gray-800">
        <div className="flex items-start justify-between h-screen overflow-y-auto">
          <Sidebar />
          <div className="flex flex-col w-full h-screen overflow-y-auto md:space-y-4">
            <header className="z-10 flex items-center justify-between w-full h-16">
              {/* <div className="block ml-6 lg:hidden">
                <button className="flex items-center p-2 text-gray-500 bg-white rounded-full shadow text-md">
                  <svg
                    width="20"
                    height="20"
                    className="text-gray-400"
                    fill="currentColor"
                    viewBox="0 0 1792 1792"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M1664 1344v128q0 26-19 45t-45 19h-1408q-26 0-45-19t-19-45v-128q0-26 19-45t45-19h1408q26 0 45 19t19 45zm0-512v128q0 26-19 45t-45 19h-1408q-26 0-45-19t-19-45v-128q0-26 19-45t45-19h1408q26 0 45 19t19 45zm0-512v128q0 26-19 45t-45 19h-1408q-26 0-45-19t-19-45v-128q0-26 19-45t45-19h1408q26 0 45 19t19 45z"></path>
                  </svg>
                </button>
              </div> */}
              {/* Header */}
              <Header />
            </header>
            <div className="px-4 pb-24 overflow-auto md:px-6 dark:bg-gray-900">
              <div className="">
                <BreadCrumb
                  page={"Users"}
                  icon={<UserGroupIcon className="inline w-6 mx-2" />}
                />
              </div>
              <InfoCard />
              <div className="p-4 h-[44rem] mb-7 rounded-md bg-gray-50 dark:bg-gray-800">
                <button
                  onClick={() => setIsHeadlessOpen(true)}
                  type="button"
                  className="flex items-center justify-center float-right px-4 py-2 text-center text-white transition duration-200 ease-in rounded-lg shadow-md bg-secondary-500 w-fit hover:bg-secondary-600 focus:ring-secondary-500 focus:ring-offset-secondary-200 focus:outline-none focus:ring-2 focus:ring-offset-2 "
                >
                  <PlusCircleIcon
                    src={addIcon}
                    alt="add icon"
                    className="w-6 h-6 mr-2 text-white "
                  />
                  Add
                </button>{" "}
                {isHeadlessOpen && (
                  <Add
                    Title={"Add User"}
                    open={isHeadlessOpen}
                    setOpen={setIsHeadlessOpen}
                  />
                )}
                {edit && (
                  <Edit
                    Title={"Edit User"}
                    open={edit}
                    id={editID}
                    setOpen={setEdit}
                  />
                )}{" "}
                {upload && (
                  <Image
                    Title={"Upload Image"}
                    open={upload}
                    // id={editID}
                    setOpen={setUpload}
                  />
                )}{" "}
                {view && (
                  <View
                    Title={"View User"}
                    open={view}
                    setOpen={setView}
                    id={deleteID}
                  />
                )}
                <br />
                <br />
                <div className="flex items-center w-auto h-full mx-2 mt-2 dark:bg-gray-800">
                  {data?.length === 0 ? (
                    <>
                      <Spiner />
                    </>
                  ) : (
                    <DataGrid
                      rows={data}
                      columns={columns}
                      pageSize={pageSize}
                      onPageSizeChange={(newPageSize) =>
                        setPageSize(newPageSize)
                      }
                      rowsPerPageOptions={[10, 20, 50, 100]}
                      className="min-h-full dark:text-gray-200 "
                      components={{
                        Toolbar: CustomToolbar,
                      }}
                      // checkboxSelection
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      {toast && (
        <Toast
          className="float-right"
          Text={message}
          Type={"secondary"}
          setOpen={setToast}
        />
      )}
      {error && <Toast Text={message} Type={"rose"} setOpen={setError} />}
    </div>
  );
};

export default Index;
