import { Fragment, useState, useEffect, useReducer } from "react";
import { Dialog, Disclosure, Menu, Transition } from "@headlessui/react";
import GmailLoader from "../Loader/GmailLoader";
import { reducer } from "../../../features/Global/reducer";
import { getSortedData } from "../../../features/Global/getSortedData";
import { getFilteredData } from "../../../features/Global/getFilteredData";
import { Player } from "@lottiefiles/react-lottie-player";
import loaderAnim from "../Loader/list.json";
import Cookies from "js-cookie";

import {
  XMarkIcon,
  ShoppingBagIcon,
  MagnifyingGlassCircleIcon,
  MagnifyingGlassIcon,
  ReceiptPercentIcon,
} from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";
import BreadCrumb from "../BreadCrumb";
// Redux
import { useDispatch, useSelector } from "react-redux";
import { getCategory } from "../../../features/Global/Category";
import {
  getSubCategory,
  selectCategory,
  removeCategoryFilter,
} from "../../../features/Global/SubCategory";
import {
  getItem,
  filteredItem,
  selectFilter,
  removeFilter,
  sort,
} from "../../../features/Global/Items";
import {
  selectStateInStock,
  selectStateOutStock,
  selectCategoryItemFilter,
} from "../../../features/Global/Offers";
import {
  ChevronDownIcon,
  FunnelIcon,
  MinusIcon,
  PlusIcon,
  Squares2X2Icon,
} from "@heroicons/react/20/solid";
import Products from "./ProductsOffer";

// const sortOptions = [
//   { name: "Most Popular", to: "#", current: true },
//   { name: "Best Rating", to: "#", current: false },
//   { name: "Newest", to: "#", current: false },
//   { name: "Price: Low to High", to: "#", current: false },
//   { name: "Price: High to Low", to: "#", current: false },
// ];

const filters = [
  // {
  //   id: 'color',
  //   name: 'Color',
  //   options: [
  //     { value: 'white', label: 'White', checked: false },
  //     { value: 'beige', label: 'Beige', checked: false },
  //     { value: 'pink', label: 'Blue', checked: true },
  //     { value: 'brown', label: 'Brown', checked: false },
  //     { value: 'green', label: 'Green', checked: false },
  //     { value: 'purple', label: 'Purple', checked: false },
  //   ],
  // },
  {
    id: "category",
    name: "subCategory",
    options: [
      { value: "new-arrivals", label: "New Arrivals", checked: false },
      { value: "sale", label: "Sale", checked: false },
      { value: "travel", label: "Travel", checked: true },
      { value: "organization", label: "Organization", checked: false },
      { value: "accessories", label: "Accessories", checked: false },
    ],
  },
  // {
  //   id: 'size',
  //   name: 'Size',
  //   options: [
  //     { value: '2l', label: '2L', checked: false },
  //     { value: '6l', label: '6L', checked: false },
  //     { value: '12l', label: '12L', checked: false },
  //     { value: '18l', label: '18L', checked: false },
  //     { value: '20l', label: '20L', checked: false },
  //     { value: '40l', label: '40L', checked: true },
  //   ],
  // },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Example(id) {
  // console.log("id", id);
  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false);
  const [checkOverWtite, setCheckOverWrite] = useState(false);
  const [search, setsearch] = useState();
  const { t } = useTranslation();
  const data = useSelector((state) => state.category?.data);
  const categoriesLoading = useSelector((state) => state.category?.loading);
  const subCategories = useSelector((state) => state.SubCategory?.copyData);
  const subCategoriesLoading = useSelector(
    (state) => state.SubCategory?.loading
  );
  const item = useSelector((state) => state.item?.data);
  const loader = useSelector((state) => state.item.loading);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCategory());
    // dispatch(getItem());
    dispatch(getSubCategory());
    if (id != null && checkOverWtite === false) {
      handleCheck(id);
    }
    setCheckOverWrite(true);
  }, []);
  const locale = localStorage.getItem("i18nextLng");

  const handleFilter = (e) => {
    setsearch(e.target.value);
    dispatch(filteredItem(e.target.value));
  };
  let ids = [];
  const [checked, setChecked] = useState([id.id]);
  const [activeLi, setActiveLi] = useState();

  const handleCheck = (event) => {
    var updatedList = [...checked];
    if (event?.target?.checked) {
      updatedList = [...checked, parseInt(event.target?.value)];
    } else {
      updatedList = updatedList.filter(
        (id) => id !== parseInt(event.target?.value)
      );
    }
    setChecked(updatedList);
    if (updatedList?.length > 0) {
      dispatch(selectFilter(updatedList));
    } else {
      dispatch(getItem());
      setActiveLi("");
    }
  };

  // // Add/Remove checked item from list
  // const handleCheck = (event) => {
  //   var updatedList = [...checked];
  //   if (event?.target?.checked) {
  //     updatedList = [...checked, parseInt(event.target?.value)];
  //   } else {
  //     updatedList.splice(checked.indexOf(event.target?.value), 1);
  //   }
  //   setChecked(updatedList);
  //   // console.log(updatedList);
  //   if (updatedList?.length > 0) dispatch(selectFilter(updatedList));
  //   else dispatch(getItem());
  //   // console.log('item', item)
  //   // const res = item.filter(person => updatedList.includes(person.id))

  //   // console.log(res);
  // };
  // Return classes based on whether item is checked
  const isChecked = (item) =>
    checked.includes(item) ? "checked-item" : "not-checked-item";
  const handleSubCategoryFilter = (id) => {
    // console.log('filter id',id);
    setChecked([]);
    // setChecked([]);
    setActiveLi(id);
    dispatch(selectCategory(id));
    dispatch(selectCategoryItemFilter(id));

    // data.filter((item) => item?.subCategory?.id);
  };

  const [{ sortBy, showEntireInventory, showFastDeliveryOnly }] = useReducer(
    reducer,
    {
      sortBy: "none",
      showEntireInventory: false,
      showFastDeliveryOnly: false,
    }
  );

  const sortedData = getSortedData([...data], sortBy);
  const filteredData = getFilteredData(
    sortedData,
    showEntireInventory,
    showFastDeliveryOnly
  );
  useEffect(() => {
    if (id !== null && id !== "undefined") {
      // console.log("iddddd", id.id);
      handleSubCategoryFilter(parseInt(id.id));
    }
  }, [id]);
  const handleRemoveFilters = () => {
    dispatch(removeFilter());
    dispatch(removeCategoryFilter());
    setsearch("");
    setActiveLi("");
  };
  const [checkState, setCheckState] = useState();
  const handleInStock = () => {
    dispatch(selectStateInStock());
    setCheckState(true);
  };
  const handleOutStock = () => {
    dispatch(selectStateOutStock());
    setCheckState(false);
  };
  const [selectedFilter, setSelectedFilter] = useState(1);
  let sortOptions = [
    // { name: "New added", to: "#", current: true },
    // { name: "Best Rating", to: "#", current: false },
    { id: 1, name: "newest", to: "C", current: true },
    { id: 2, name: "priceLowToHight", to: "L", current: false },
    { id: 3, name: "priceHighToLow", to: "H", current: false },
    { id: 4, name: "sortA-Z", to: "A", current: false },
    { id: 5, name: "sortZ-A", to: "Z", current: false },
  ];
  const handleSort = (id, payload) => {
    dispatch(sort(payload));
    setSelectedFilter(id);
    // console.log(selectFilter);
  };
  return (
    <div className="rounded-md">
      {/* {loader ? (
        <GmailLoader />
      ) : ( */}
      <>
        {/* Mobile filter dialog */}
        <Transition.Root show={mobileFiltersOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-40 lg:hidden"
            onClose={setMobileFiltersOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>

            <div className="fixed inset-0 z-40 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                {/* ${locale === 'en-US'? 'ml-auto' : 'mr-auto'} */}
                <Dialog.Panel
                  className={`relative flex flex-col w-full h-full max-w-xs py-4 pb-12 ml-auto  overflow-y-auto bg-white shadow-xl`}
                >
                  <div className="flex items-center justify-between px-4">
                    <h2
                      className={`text-lg font-medium text-gray-900 ${locale}`}
                    >
                      {t("filters")}
                    </h2>
                    <button
                      type="button"
                      className={`flex items-center justify-center w-10 h-10 p-2 ${
                        locale === "en-US" ? "-mr-2" : "-ml-2"
                      }text-gray-400 bg-white rounded-md`}
                      onClick={() => setMobileFiltersOpen(false)}
                    >
                      <span className={` sr-only ${locale}`}>Close menu</span>
                      <XMarkIcon className="w-6 h-6" aria-hidden="true" />
                    </button>
                  </div>

                  {/* Filters */}
                  {/* ::Input Search (small devices) */}
                  <div className="relative m-5 lg:hidden">
                    {/* ::::label */}
                    <label htmlFor="search" className={`sr-only ${locale}`}>
                      {t("search")}
                    </label>
                    {/* ::::input */}
                    <input
                      type="search"
                      id="search"
                      name="search"
                      value={search}
                      placeholder={t("search")}
                      onChange={(e) => handleFilter(e)}
                      className={`${locale} block w-full py-3 ${
                        locale === "en-US" ? "pl-5" : "pr-5 float-left "
                      } text-sm placeholder-gray-300 border-gray-300 rounded-md shadow-sm  form-input pl-11 bg-gray-50 focus:border-pink-600 focus:ring-1 focus:ring-pink-500`}
                    />
                    {/* ::::icon */}
                    <span
                      className={`absolute text-gray-400 transform -translate-y-1/2 top-1/2 ${
                        locale === "en-US" ? "left-3" : "right-3"
                      }`}
                    >
                      <MagnifyingGlassIcon className="w-4 h-4" />
                    </span>
                  </div>

                  <form className="mt-4 border-t border-gray-200">
                    {/* <h3 className={` sr-only ${locale}`}>Categories</h3> */}

                    <ul
                      role="list"
                      className="px-2 py-3 font-medium text-gray-900"
                    >
                      {data.map((category) =>
                        categoriesLoading ? (
                          <>
                            {" "}
                            <Player
                              src={loaderAnim}
                              autoplay
                              loop
                              className="player"
                            />
                          </>
                        ) : (
                          <>
                            <li
                              key={category.id}
                              onClick={() =>
                                handleSubCategoryFilter(category.id)
                              }
                              className={` ${
                                activeLi === category.id
                                  ? "bg-gray-100 text-pink-500 p-3 rounded-md font-medium duration-300 my-auto"
                                  : ""
                              } flex my-2`}
                            >
                              <span
                                className={`${locale} flex-1 my-auto`}
                                // onClick={() => handleSubCategoryFilter(category.id)}
                              >
                                <img
                                  className="inline w-8 h-8 mx-2 rounded-full"
                                  alt={category?.imageUrl}
                                  src={category?.imageUrl}
                                />
                                {locale === "en-US" &&
                                  category.category?.english}
                                {locale === "ar" && category.category?.arabic}
                                {locale === "ku" && category.category?.kurdish}
                              </span>
                              <span
                                className={`mx-2 px-3 py-1 rounded-lg bg-pink-50 text-pink-500`}
                              >
                                {" "}
                                {
                                  category.subCategories?.filter(
                                    (item) => item?.isActive
                                  ).length
                                }{" "}
                              </span>
                            </li>
                          </>
                        )
                      )}
                    </ul>

                    {filters.map((section) => (
                      <Disclosure
                        as="div"
                        key={section.id}
                        className="px-4 py-6 border-t border-gray-200"
                      >
                        {({ open }) => (
                          <>
                            <h3 className="flow-root -mx-2 -my-3">
                              <Disclosure.Button className="flex items-center justify-between w-full px-2 py-3 text-gray-400 bg-white hover:text-gray-500">
                                <span
                                  className={` font-medium text-gray-900 ${locale} `}
                                >
                                  {t(section.name)}
                                </span>
                                <span
                                  className={`flex items-center ${
                                    locale === "en-US" ? "ml-6" : "mr-6"
                                  }`}
                                >
                                  {open ? (
                                    <MinusIcon
                                      className="w-5 h-5"
                                      aria-hidden="true"
                                    />
                                  ) : (
                                    <PlusIcon
                                      className="w-5 h-5"
                                      aria-hidden="true"
                                    />
                                  )}
                                </span>
                              </Disclosure.Button>
                            </h3>
                            {/* Sub categories MOB */}
                            <Disclosure.Panel className="pt-6">
                              <div className="space-y-6">
                                {subCategories.length === 0 ? (
                                  <>
                                    {" "}
                                    <span className="p-3 mx-auto font-medium text-orange-500 duration-300 bg-orange-100 rounded-md">
                                      {t("subcategoriesNotFound")}
                                    </span>
                                  </>
                                ) : (
                                  <>
                                    {subCategories.map((option, optionIdx) => (
                                      <div
                                        key={option.value}
                                        className="flex items-center"
                                      >
                                        <input
                                          id={`filter-mobile-${section.id}-${optionIdx}`}
                                          name={`${section.id}[]`}
                                          defaultValue={option.value}
                                          type="checkbox"
                                          value={`${option.id}`}
                                          onChange={(event) =>
                                            handleCheck(event)
                                          }
                                          // defaultChecked={option.checked}
                                          className="w-4 h-4 text-pink-600 border-gray-300 rounded focus:ring-pink-500"
                                        />
                                        <label
                                          htmlFor={`filter-mobile-${section.id}-${optionIdx}`}
                                          className={`flex-1 min-w-0 ${
                                            locale === "en-US" ? "ml-3" : "mr-3"
                                          } text-gray-500 ${locale}`}
                                        >
                                          {locale === "en-US" &&
                                            option?.englishSubCategory}
                                          {locale === "ar" &&
                                            option?.arabicSubCategory}
                                          {locale === "ku" &&
                                            option?.kurdishSubCategory}{" "}
                                        </label>
                                      </div>
                                    ))}
                                  </>
                                )}
                              </div>
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                    ))}
                    <p className={`font-medium mb-1 text-gray-500 ${locale}`}>
                      {t("status")}
                    </p>
                    <div className="flex gap-x-2">
                      <div
                        className="relative flex items-center justify-center w-56 px-4 py-3 text-sm font-medium text-gray-700 rounded-xl bg-gray-50"
                        onClick={() => handleInStock()}
                      >
                        <input
                          className="hidden peer"
                          type="radio"
                          name="radio"
                          id="radio1"
                          checked={checkState === true}
                        />
                        <label
                          className="absolute top-0 w-full h-full border cursor-pointer peer-checked:border-secondary-400 peer-checked:bg-secondary-200 rounded-xl"
                          htmlFor="radio1"
                        >
                          {" "}
                        </label>
                        <div
                          className={`peer-checked:border-transparent peer-checked:bg-secondary-500 peer-checked:ring-2 absolute  ${
                            locale === "en-US" ? "left-4" : "right-4"
                          } h-3 w-3 rounded-full border-2 border-gray-300 bg-gray-200 ring-secondary-500 ring-offset-2`}
                        ></div>
                        <span
                          className={`pointer-events-none z-10 ${
                            locale === "en-US" ? "ml-3 pl-3" : "mr-3 pr-3"
                          } ${locale}`}
                        >
                          {t("inStock")}
                        </span>
                      </div>
                      <div
                        className="relative flex items-center justify-center w-56 px-4 py-3 text-sm font-medium text-gray-700 rounded-xl bg-gray-50"
                        onClick={() => handleOutStock()}
                      >
                        <input
                          className="hidden peer"
                          type="radio"
                          name="radio"
                          id="radio3"
                          checked={checkState === false}
                        />
                        <label
                          className="absolute top-0 w-full h-full text-sm border cursor-pointer peer-checked:border-rose-500 peer-checked:bg-rose-200 rounded-xl"
                          htmlFor="radio3"
                        >
                          {" "}
                        </label>
                        <div
                          className={`peer-checked:border-transparent peer-checked:bg-rose-500 peer-checked:ring-2 absolute  ${
                            locale === "en-US" ? "left-4" : "right-4"
                          } h-3 w-3 rounded-full border-2 border-gray-300 bg-gray-200 ring-rose-400 ring-offset-2`}
                        ></div>
                        <span
                          className={`pointer-events-none z-10 ${
                            locale === "en-US" ? "ml-3 pl-3" : "mr-3 pr-3"
                          }  ${locale}`}
                        >
                          {t("outOfStock")}
                        </span>
                      </div>
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
        {/* Large */}
        <main className="container p-4 px-4 mx-auto bg-white rounded-md max-w-7xl sm:px-6 lg:px-8">
          <BreadCrumb Name={"offers"} Iconn={ReceiptPercentIcon} />

          <div className="flex items-baseline justify-between pt-12 pb-6 border-b border-gray-200">
            <h1
              className={`text-4xl font-bold tracking-tight text-gray-900 ${locale}`}
            >
              {t("offers")}
            </h1>

            <div className="flex items-center">
              {/* ::Label */}
              {/* ::Input */}

              <Menu as="div" className="relative inline-block text-left">
                <div>
                  <div className="relative hidden mx-3 lg:inline-block">
                    {/* ::::label */}
                    <label htmlFor="search" className={` sr-only ${locale}`}>
                      {"search"}
                    </label>
                    {/* ::::input */}
                    <input
                      type="search"
                      id="search"
                      name="search"
                      value={search}
                      placeholder={t("search")}
                      onChange={(e) => handleFilter(e)}
                      className={`block w-full px-10 py-3 text-sm placeholder-gray-300 border-gray-300 rounded-md shadow-sm form-input pl-11 bg-gray-50 focus:border-pink-600 focus:ring-1 focus:ring-pink-500 ${locale}`}
                    />
                    {/* ::::icon */}
                    <span
                      className={`absolute text-gray-400 transform -translate-y-1/2 top-1/2 ${
                        locale === "en-US" ? "left-3" : "right-3"
                      }`}
                    >
                      <MagnifyingGlassIcon className="w-4 h-4" />
                    </span>
                  </div>
                  <Menu.Button
                    className={`inline-flex justify-center text-sm font-medium text-gray-700 group hover:text-gray-900 mx-2 border focus:ring-pink-500 px-4 rounded-md py-3 ${locale}`}
                  >
                    {t("sort")}
                    <ChevronDownIcon
                      className="flex-shrink-0 w-5 h-5 ml-1 -mr-1 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                  </Menu.Button>
                </div>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items
                    className={`absolute  z-10 w-40 mt-2 ${
                      locale === "en-US"
                        ? "origin-top-right right-0"
                        : "origin-top-left left-0"
                    } bg-white rounded-md shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none`}
                  >
                    <div className="py-1">
                      {sortOptions.map((option) => (
                        <Menu.Item key={option.name}>
                          {({ active }) => (
                            <button
                              onClick={() => handleSort(option?.id, option?.to)}
                              className={classNames(
                                selectedFilter === option?.id
                                  ? `font-medium text-gray-900  ${
                                      locale === "en-US"
                                        ? "text-left"
                                        : "text-right"
                                    } ${locale}`
                                  : `text-gray-500 ${locale}`,
                                active ? "bg-gray-100 font-semibold" : "",
                                `block px-4 py-2 text-sm w-full ${
                                  locale === "en-US"
                                    ? "text-left"
                                    : "text-right"
                                } ${locale}`
                              )}
                            >
                              {t(option.name)}
                            </button>
                          )}
                        </Menu.Item>
                      ))}
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>

              <button
                // onClick={() => dispatch(removeFilter())}
                onClick={() => handleRemoveFilters()}
                type="button"
                // className={`p-2 ${
                //   locale === "en-US" ? "ml-5" : "mr-5"
                // } -m-2 text-gray-400 hover:text-gray-500 sm:ml-7`}
                className="flex items-center justify-center w-10 h-10 text-white rounded-lg shrink-0 bg-gradient-to-br from-pink-600 to-pink-500 hover:bg-pink-700"
              >
                <span className={` sr-only ${locale}`}>{t("View grid")}</span>
                {/* <Squares2X2Icon className="w-5 h-5" aria-hidden="true" /> */}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="flex items-center justify-center w-8 h-8 text-white rounded-lg shrink-0 bg-gradient-to-br from-pink-600 to-pink-500 hover:bg-pink-700 "
                  viewBox="0 0 24 24"
                  strokeWidth="1.0"
                  stroke="#fff"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <line x1="3" y1="3" x2="21" y2="21" />
                  <path d="M9 5h9.5a1 1 0 0 1 .5 1.5l-4.049 4.454m-.951 3.046v5l-4 -3v-4l-5 -5.5a1 1 0 0 1 .18 -1.316" />
                </svg>
              </button>
              <button
                type="button"
                className={`p-2 ${
                  locale === "en-US" ? "ml-4" : "mr-4"
                } -m-2 text-gray-400 hover:text-gray-500 sm:ml-6 lg:hidden bg-pink-50 hover:scale-105 hover:bg-pink-50 duration-500`}
                onClick={() => setMobileFiltersOpen(true)}
              >
                <span className={` sr-only ${locale} px-2 py-1 bg-pink-50`}>
                  {t("filters")}
                </span>
                <FunnelIcon
                  className="w-5 h-5 text-pink-500 duration-500 bg-pink-50 hover:bg-pink-100 hover:text-pink-600 hover:scale-105"
                  aria-hidden="true"
                />
              </button>
            </div>
          </div>

          <section aria-labelledby="products-heading" className="pt-6 pb-24">
            <h2 id="products-heading" className={` sr-only ${locale}`}>
              {t("products")}
            </h2>

            <div className="grid grid-cols-1 gap-x-8 gap-y-10 lg:grid-cols-4">
              {/* Filters */}
              <form className="hidden lg:block">
                <h3 className={` sr-only ${locale}`}>Categories</h3>
                <ul
                  role="list"
                  className="pb-6 space-y-4 text-sm font-medium text-gray-900 border-b border-gray-200"
                >
                  {data?.map((category) =>
                    categoriesLoading ? (
                      <>
                        {" "}
                        <Player
                          src={loaderAnim}
                          autoplay
                          loop
                          className="player"
                        />
                      </>
                    ) : (
                      <>
                        {" "}
                        <li
                          key={category.id}
                          onClick={() => handleSubCategoryFilter(category.id)}
                          className={` ${
                            activeLi === category.id
                              ? "bg-gray-100 text-pink-500 p-3 rounded-md font-medium duration-300 my-auto"
                              : ""
                          } flex`}
                        >
                          <span
                            className={`${locale} flex-1 my-auto`}
                            // onClick={() => handleSubCategoryFilter(category.id)}
                          >
                            <img
                              className="inline w-8 h-8 mx-2 rounded-full"
                              alt={category?.imageUrl}
                              src={category?.imageUrl}
                            />
                            {locale === "en-US" && category.category?.english}
                            {locale === "ar" && category.category?.arabic}
                            {locale === "ku" && category.category?.kurdish}
                          </span>
                          <span
                            className={`mx-2 px-3 py-1 rounded-lg bg-pink-50 text-pink-500`}
                          >
                            {" "}
                            {
                              category.subCategories?.filter(
                                (item) => item?.isActive
                              ).length
                            }{" "}
                          </span>
                        </li>
                      </>
                    )
                  )}
                </ul>

                {filters.map((section) => (
                  <Disclosure
                    as="div"
                    key={section.id}
                    className="py-6 border-b border-gray-200"
                  >
                    {({ open }) => (
                      <>
                        <h3 className="flow-root -my-3">
                          <Disclosure.Button className="flex items-center justify-between w-full py-3 text-sm text-gray-400 bg-white hover:text-gray-500">
                            <span
                              className={` font-medium text-gray-900 ${locale} `}
                            >
                              {t(section.name)}
                            </span>
                            <span
                              className={`flex items-center ${
                                locale === "en-US" ? "ml-6" : "ml-6"
                              }`}
                            >
                              {open ? (
                                <MinusIcon
                                  className="w-5 h-5"
                                  aria-hidden="true"
                                />
                              ) : (
                                <PlusIcon
                                  className="w-5 h-5"
                                  aria-hidden="true"
                                />
                              )}
                            </span>
                          </Disclosure.Button>
                        </h3>
                        <Disclosure.Panel className="pt-6">
                          <div className="space-y-4">
                            {subCategories.length === 0 ? (
                              <>
                                <div className="w-full p-3 mx-auto my-2 font-medium text-orange-500 duration-300 bg-orange-100 rounded-md">
                                  <span className="mx-auto">
                                    {" "}
                                    {t("subcategoriesNotFound")}{" "}
                                  </span>
                                </div>
                              </>
                            ) : (
                              <>
                                {subCategories
                                  ?.filter((item) => item.status)
                                  ?.map((option, optionIdx) => (
                                    <div
                                      key={option.value}
                                      className="flex items-center px-2 duration-300"
                                    >
                                      <input
                                        id={`filter-${section.id}-${optionIdx}`}
                                        name={`${section?.id}[]`}
                                        defaultValue={option.value}
                                        type="checkbox"
                                        value={option?.id}
                                        onChange={(event) => handleCheck(event)}
                                        checked={checked.includes(option?.id)}
                                        className={` 
                                      w-4 h-4 text-pink-600 border-gray-300 rounded focus:ring-pink-500`}
                                      />
                                      <label
                                        htmlFor={`filter-${section.id}-${optionIdx}`}
                                        className={` ${
                                          locale === "en-US" ? "ml-3" : "mr-3"
                                        } text-sm text-gray-600 ${locale}`}
                                      >
                                        <img
                                          className="inline object-cover w-8 h-8 mx-1 rounded-full"
                                          src={option?.subCategoryImage}
                                          alt="image"
                                        />
                                        {locale === "en-US" &&
                                          option?.englishSubCategory}
                                        {locale === "ar" &&
                                          option?.arabicSubCategory}
                                        {locale === "ku" &&
                                          option?.kurdishSubCategory}{" "}
                                      </label>
                                    </div>
                                  ))}
                              </>
                            )}
                          </div>
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                ))}
                <p className={`font-medium mb-1 text-gray-500 ${locale}`}>
                  {t("status")}
                </p>
                <div className="flex flex-col gap-y-2">
                  <div
                    className="relative flex items-center justify-center w-56 px-4 py-3 text-sm font-medium text-gray-700 rounded-xl bg-gray-50"
                    onClick={() => handleInStock()}
                  >
                    <input
                      className="hidden peer"
                      type="radio"
                      name="radio"
                      id="radio1"
                      checked={checkState === true}
                    />
                    <label
                      className="absolute top-0 w-full h-full border cursor-pointer peer-checked:border-secondary-400 peer-checked:bg-secondary-200 rounded-xl"
                      htmlFor="radio1"
                    >
                      {" "}
                    </label>
                    <div
                      className={`peer-checked:border-transparent peer-checked:bg-secondary-500 peer-checked:ring-2 absolute  ${
                        locale === "en-US" ? "left-4" : "right-4"
                      } h-3 w-3 rounded-full border-2 border-gray-300 bg-gray-200 ring-secondary-500 ring-offset-2`}
                    ></div>
                    <span
                      className={`pointer-events-none z-10 ${
                        locale === "en-US" ? "ml-3 pl-3" : "mr-3 pr-3"
                      } ${locale}`}
                    >
                      {t("inStock")}
                    </span>
                  </div>
                  <div
                    className="relative flex items-center justify-center w-56 px-4 py-3 text-sm font-medium text-gray-700 rounded-xl bg-gray-50"
                    onClick={() => handleOutStock()}
                  >
                    <input
                      className="hidden peer"
                      type="radio"
                      name="radio"
                      id="radio3"
                      checked={checkState === false}
                    />
                    <label
                      className="absolute top-0 w-full h-full text-sm border cursor-pointer peer-checked:border-rose-500 peer-checked:bg-rose-200 rounded-xl"
                      htmlFor="radio3"
                    >
                      {" "}
                    </label>
                    <div
                      className={`peer-checked:border-transparent peer-checked:bg-rose-500 peer-checked:ring-2 absolute  ${
                        locale === "en-US" ? "left-4" : "right-4"
                      } h-3 w-3 rounded-full border-2 border-gray-300 bg-gray-200 ring-rose-400 ring-offset-2`}
                    ></div>
                    <span
                      className={`pointer-events-none z-10 ${
                        locale === "en-US" ? "ml-3 pl-3" : "mr-3 pr-3"
                      }  ${locale}`}
                    >
                      {t("outOfStock")}
                    </span>
                  </div>
                </div>
              </form>

              {/* Product grid */}
              <div className="lg:col-span-3">
                {/* Replace with your content */}
                {/*           <fieldset>
      <legend>sort by:</legend>
        <label>
          <input
            type="radio"
            name="sort"
            onChange={() =>
              dispatch({ type: "SORT", payload: "PRICE_LOW_TO_HIGH" })
            }
            checked={sortBy && sortBy === "PRICE_LOW_TO_HIGH"}
          />
          price - low to high
        </label>
        <label>
          <input
            type="radio"
            name="sort"
            onChange={() =>
              dispatch({ type: "SORT", payload: "PRICE_HIGH_TO_LOW" })
            }
            checked={sortBy && sortBy === "PRICE_HIGH_TO_LOW"}
          />
          price - high to low
        </label>
        <label>
          <input
            type="radio"
            name="sort"
            onChange={() =>
              dispatch({ type: "SORT", payload: "RATING_HIGH_TO_LOW" })
            }
            checked={sortBy && sortBy === "RATING_HIGH_TO_LOW"}
          />
          rating - high to low
        </label>
      </fieldset>
      <fieldset>
        <legend>filter:</legend>
        <label>
          <input
            type="checkbox"
            name="filter"
            onChange={() =>
              dispatch({ type: "FILTER", payload: "showEntireInventory" })
            }
            checked={showEntireInventory}
          />
          include out of stock
        </label>
        <label>
          <input
            type="checkbox"
            name="filter"
            onChange={() =>
              dispatch({ type: "FILTER", payload: "showFastDeliveryOnly" })
            }
            checked={showFastDeliveryOnly}
          />
          show items with fast delivery
        </label>
      </fieldset>
      <ul>
        {filteredData.map(({ id, name, price, rating, inStock, delivery }) => (
          <li
            style={{
              listStyle: "none",
              padding: "1em",
              border: "1px solid black",
              margin: "1em"
            }}
            key={id}
          >
            <div>{name}</div>
            <div>price: &#8377; {price}</div>
            <div>avg rating: {rating}</div>
            {inStock ? <div>in stock</div> : <div>out of stock</div>}
            <div>{delivery}</div>
          </li>
        ))}
      </ul> */}
                <Products />
                {/* <div className="border-4 border-gray-200 border-dashed rounded-lg h-96 lg:h-full" /> */}
                {/* /End replace */}
              </div>
            </div>
          </section>
        </main>
      </>
      {/* )} */}
    </div>
  );
}
