import React from "react";
import {
  HomeIcon,
  UserIcon,
  FolderIcon,
  ShoppingBagIcon,
  TagIcon,
} from "@heroicons/react/24/outline";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setFillter } from "../../features/web/Filter";
import { getAuth } from "../../features/web/WebAuthentication";
import { selectCategory } from "../../features/Global/SubCategory";
import Cookies from "js-cookie";
const Breadcrumb4 = ({ Name, Iconn, product, productIcon, category }) => {
  const pages = [
    // { name: 'Account', link: '#', icon: UserIcon, current: false },
    { name: Name, link: "#", icon: Iconn, current: true, category: category },
  ];

  const { t } = useTranslation();
  const locale = localStorage.getItem("i18nextLng");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const filtter = (params) => {
    if (category?.isApplicableForOffers && category.isOfferActive) {
      navigate(`/offers/${params}`);
      // dispatch(selectCategory(params));
    } else {
      navigate(`/shop/${params}`);
      // dispatch(selectCategory(params));
    }
    dispatch(setFillter(params));
    dispatch(getAuth());
  };
  const handleChangePage = (params) => {
    if (category?.isApplicableForOffers && category.isOfferActive) {
      navigate(`/offers`);

    } else {
      navigate(`/shop`);

    }
    dispatch(getAuth());
  };
  return (
    <nav
      className="box-border hidden w-full max-w-full my-4 md:flex whitespace-nowrap"
      aria-label="breadcrumb"
    >
      <ul className="flex items-center text-sm font-medium text-gray-400 list-none -space-x-11">
        {/* :HOME */}
        <li>
          <Link
            to="/"
            className={`relative z-10  inline-flex items-center px-4 md:px-10 text-xsm lg:text-sm lg:px-12 py-4 bg-gray-50 rounded-full shadow-xl-right hover:text-gray-800 hover:z-20 ${locale}`}
          >
            <HomeIcon
              className={`flex-shrink-0 w-5 h-5 ${
                locale === "en-US" ? "mr-1" : "ml-1"
              }`}
              aria-hidden="true"
            />
            {t("home")}
          </Link>
        </li>
        {product && (
          <>
            <li className={`${locale === "en-US" ? "" : " translate-x-10"}`}>
              <button
                onClick={() => handleChangePage()}
                className={`relative  inline-flex items-center px-8 text-xsm lg:text-sm lg:px-11 py-4 bg-gray-50 rounded-full shadow-xl-right hover:text-gray-800 hover:z-50 ${locale}`}
              >
                <ShoppingBagIcon
                  className={`flex-shrink-0 w-5 h-5  ${
                    locale === "en-US" ? "mr-2" : "ml-2"
                  }`}
                  aria-hidden="true"
                />
                {t(product)}
              </button>
            </li>
            <li
              className={`${locale === "en-US" ? "" : " translate-x-10 z-10"}`}
            >
              <span
                onClick={() => filtter(category?.categoryId)}
                // to={`/shop`}
                // onClick={(e) => navigate(`/shop/${category?.id}`)}
                className={`relative z-10 inline-flex items-center px-8 text-xsm lg:text-sm lg:px-11 py-4 bg-gray-50 rounded-full shadow-xl-right hover:text-gray-800 hover:z-50 ${locale}`}
              >
                <TagIcon
                  className={`flex-shrink-0 w-5 h-5  ${
                    locale === "en-US" ? "mr-2" : "ml-2"
                  }`}
                  aria-hidden="true"
                />
                {locale === "en-US" && category?.category?.english}
                {locale === "ar" && category?.category?.arabic}
                {locale === "ku" && category?.category?.kurdish}
                {/* {t(cate)} */}
              </span>
            </li>
          </>
        )}
        {/* :PAGES */}
        {pages.map((page, index) => {
          const translate = `${
            locale === "en-US"
              ? `-translate-x-${2 - index * 12}`
              : `translate-x-${12 + index * 12}`
          } `;
          const Icon = page.icon;
          return (
            <li
              key={page.name}
              className={` sm:${
                index === 0
                  ? "z-0"
                  : index === 1
                  ? "z-0"
                  : index === 2
                  ? "z-0"
                  : index === 3
                  ? "z-0"
                  : "z-0"
              }   transition-all duration-150 transform ${translate} hover:z-50  ${
                locale === "en-US" ? "" : "translate-x-12"
              } ${locale}  ${
                product === "products" ? "invisible md:visible" : ""
              }`}
            >
              <Link
                to={page.link}
                className={`relative py-3  ${
                  locale === "en-US"
                    ? `pl-16 pr-5 ${
                        product === "undefined" ? "translate-x-20" : ""
                      } `
                    : ` pr-16 text-sm  pl-3 md:pl-5 lg:pr-16 lg:pl-5  ${
                        product === "undefined" ? "translate-x-12" : ""
                      } `
                } inline-flex items-center rounded-full shadow-xl-right ${
                  page.current
                    ? "bg-gradient-to-br from-pink-600 to-pink-500  text-white font-semibold"
                    : "hover:text-gray-800"
                } `}
              >
                {/* {icon} */}
                {page.icon && (
                  <Icon
                    className={`flex-shrink-0 w-5 h-5 ${
                      locale === "en-US" ? "mr-1" : "ml-1"
                    }`}
                    aria-hidden="true"
                  />
                )}
                {/* Name */}
                <span
                  className={`px-1 truncate whitespace-nowrap py-1 ${locale}`}
                >
                  {t(page.name)}
                </span>
              </Link>
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

export default Breadcrumb4;
