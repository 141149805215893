import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  CheckCircleIcon,
  PlusIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { PlusCircleIcon } from "@heroicons/react/24/outline";
import { XCircleIcon } from "@heroicons/react/24/outline";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import axios from "axios";
import Toast from "../../components/Cards/Toast";
// redux mapping
import { useDispatch, useSelector } from "react-redux";
import {
  getSubCategory,
  selectCategory,
} from "../../../features/Global/Dashboard/SubCategory";
import { getBrand } from "../../../features/Global/Dashboard/Brands";
import { getItem } from "../../../features/Global/Dashboard/Items";
import { getCategory } from "../../../features/Global/Category";

import {
  HtmlEditor,
  Image,
  Inject,
  Link,
  QuickToolbar,
  RichTextEditorComponent,
  Toolbar,
} from "@syncfusion/ej2-react-richtexteditor";
import { TextareaAutosize } from "@mui/material";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Parser from "html-react-parser";
import Cookies from "js-cookie";

export default function SlideOver({ Title, open, setOpen, chilren }) {
  const [checkPassword, setCheckPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [toast, setToast] = useState(false);
  const [error, setError] = useState(false);
  const [disable, setDisable] = useState(false);
  const [message, setMessage] = useState("");
  const dispatch = useDispatch();
  const category = useSelector((state) => state.categoryDashboard.data);
  const subCategory = useSelector((state) => state.SubCategory.copyData);
  const brand = useSelector((state) => state.BrandsDashboard.ordered);
  const [englishTags, setEnglishTags] = useState();
  const [arabicTags, setArabicTags] = useState();
  const [kurdishTags, setKurdishTags] = useState();
  const [englishDescription, setEnglishDescription] = useState();
  const [arabicDescription, setArabicDescription] = useState();
  const [kurdishDescription, setKurdishDescription] = useState();
  const [englishUsage, setEnglsihUsage] = useState("");
  const [arabicUsage, setArabicUsage] = useState("");
  const [kurdishUaage, setKurdishUsage] = useState("");
  const [englishVoice, setEnglishVoice] = useState("");
  const [arabicVoice, setArabicVoice] = useState("");
  const [KurdishVoice, setKurdishVoice] = useState("");
  const [categoryId, setCategoryId] = useState(null);
  const [subCategories, setSubCategories] = useState(null);
  const [subcategoryId, setSubCategoryId] = useState(null);
  const handleSubCategoryChange = (e) => {
    setSubCategoryId(e.target.value);
  };
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm();
  const [file, setFile] = useState();
  const [preview, setPreview] = useState();
  const handleFileSelect = (e) => {
    if (e.target.files[0].type.startsWith("image/")) {
      setFile(e.target.files[0]);
      const objectUrl = URL.createObjectURL(e.target.files[0]);
      setPreview(objectUrl);
    } else {
      setMessage("Please select an image!");
      setError(true);
    }
  };
  const config = {
    headers: {
      Authorization: `Bearer ${Cookies.get("dashboardToken")}`,
    },
  };

  const onSubmit = (data) => {
    setDisable(true);

    var form = new FormData();
    form.append("EnglishName", data.englishName || "empty");
    form.append("KurdishName", data.kurdishName || "زیادنەکراوە");
    form.append("ArabicName", data.arabicName || "فارغة");
    form.append("EnglishDescription", englishDescription || "empty");
    form.append("KurdishDescription", kurdishDescription || "زیادنەکراوە");
    form.append("ArabicDescription", arabicDescription || "فارغة");
    form.append("EnglishUsage", englishUsage || "empty");
    form.append("KurdishUsage", kurdishUaage || "زیادنەکراوە");
    form.append("ArabicUsage", arabicUsage || "فارغة");
    form.append("SubCategoryId", subcategoryId);
    form.append("BrandId", data.brandId);
    form.append("Index", data.index);
    form.append("Qty", data.qty);
    form.append("Price", data.price);
    form.append("tags", `${englishTags} | ${arabicTags} | ${kurdishTags}`);
    form.append("Files", file);
    form.append("id", data.id);
    form.append("OfferId", data.OfferId);
    form.append("Barcode", data.barcode);
    form.append("EnglishVoice", englishVoice);
    form.append("KurdishVoice", KurdishVoice);
    form.append("ArabicVoice", arabicVoice);
    form.append("Wholesale", data.Wholesale);
    form.append("ImageIndex", data.ImageIndex);
    if (file !== null) {
      axios
        .post("api/Products", form, config)
        .then((r) => {
          setDisable(true);
          if (r.status === 200) {
            // // console.log(r);
            setToast(true);
            dispatch(getItem());
          }
        })
        .catch((e) => {
          setDisable(false);
          // console.log(e.response.status);
          // console.log(e.response.data);
          setMessage(e.response.data);
          setError(true);
        });
    }
  };
  if (file === null) {
    setToast(true);
    setMessage("Please select image for product.");
  }
  useEffect(() => {
    dispatch(getCategory());
    dispatch(getSubCategory());
    dispatch(getBrand());
  }, []);
  const handleCategoryChange = (e) => {
    const categoryId = e.target.value;
    setCategoryId(categoryId);
    const subCategories = subCategory.filter(
      (item) => parseInt(item?.categoryId) === parseInt(categoryId)
    );
    if (subCategories.length > 0) {
      setSubCategoryId(subCategories[0].id);
      console.log(subCategories);
    } else {
      setSubCategoryId(null);
    }
  };

  const formats = {
    align: "",
    bold: "",
    italic: "",
    underline: "",
    strike: "",
    size: "",
    font: "",
    color: "",
    background: "",
    script: "",
    header: "",
    indent: "",
    list: "",
    direction: "",
    code: "",
    blockquote: "",
    link: "",
    image: "",
  };

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ align: [] }],
      ["bold", "italic", "underline", "strike"],
      [{ color: [] }, { background: [] }],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ script: "sub" }, { script: "super" }],
      [{ indent: "-1" }, { indent: "+1" }],
      ["blockquote", "code-block"],
      ["link"],
      ["clean"],
      [{ direction: "rtl" }],
    ],
  };
  const filteredSubCategories = subCategories?.filter(
    (item) =>
      categoryId === null || parseInt(item?.categoryId) === parseInt(categoryId)
  );

  const defaultSubCategory =
    filteredSubCategories?.length > 0 ? filteredSubCategories[0] : null;

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-40" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="fixed inset-y-0 right-0 flex max-w-2xl pl-10 pointer-events-none">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="relative w-screen max-w-2xl pointer-events-auto">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-500"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-500"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-0 right-0 flex pt-4 pr-2 -ml-8 sm:-ml-10 sm:pr-4">
                      <button
                        type="button"
                        className="text-gray-300 rounded-md hover:text-white focus:outline-none focus:ring-2 "
                        onClick={() => setOpen(false)}
                      >
                        <span className="sr-only">Close panel</span>
                        <XMarkIcon
                          className="relative h-6 my-3 text-white right-5 "
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="flex flex-col h-full py-6 overflow-y-scroll bg-white shadow-xl dark:bg-gray-800">
                    <div className="inline px-4 py-6 -mt-6 rounded-sm bg-secondary-600 sm:px-6 dark:bg-secondary-600">
                      <Dialog.Title className="pt-4 -mt-4 text-xl font-light text-white border border-transparent rounded-md ">
                        <PlusCircleIcon className="inline w-6 h-6" /> {Title}
                      </Dialog.Title>
                    </div>
                    {/* <hr className="mx-1 text-gray-300 dark:text-secondary-700" /> */}
                    <div className="relative flex-1 px-4 mt-2 sm:px-6">
                      {/* Replace with your content */}

                      <div className="absolute inset-0 px-4 sm:px-6 ">
                        {/* <!-- component --> */}
                        <div className="">
                          {/* <!-- Author: FormBold Team --> */}
                          {/* <!-- Learn More: https://formbold.com --> */}
                          <div className="mx-auto w-full max-w-[550px] bg-white dark:bg-gray-800">
                            <form
                              className="px-1 py-6"
                              onSubmit={handleSubmit(onSubmit)}
                            >
                              <div className="pt-4 mb-6">
                                <label className="mb-5 block text-xl font-semibold text-[#07074D] dark:text-gray-300">
                                  Upload Image
                                </label>

                                <div className="mb-8">
                                  <input
                                    type="file"
                                    required
                                    name="file"
                                    id="file"
                                    className="sr-only"
                                    onChange={(e) => handleFileSelect(e)}
                                  />
                                  <span>{errors.File}</span>
                                  <label
                                    htmlFor="file"
                                    className="relative flex min-h-[200px] items-center justify-center rounded-md border border-dashed border-[#e0e0e0] p-12 text-center"
                                  >
                                    <div>
                                      {/*                       <span className="mb-2 block text-xl font-semibold text-[#07074D] dark:text-gray-300">
                                        Drop files here
                                      </span> */}
                                      <span className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-300">
                                        {preview && (
                                          <img
                                            src={preview}
                                            alt="image"
                                            className="mx-auto rounded-lg "
                                          />
                                        )}
                                      </span>
                                      <span className="inline-flex rounded border border-[#e0e0e0] py-2 px-7 text-base font-medium text-[#07074D] dark:text-gray-300">
                                        Browse
                                      </span>
                                    </div>
                                  </label>
                                </div>
                              </div>
                              <div className="mb-5">
                                <label className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-300">
                                  Image Index:
                                </label>
                                <input
                                  type="number"
                                  placeholder="Image Index"
                                  {...register("ImageIndex", {
                                    required: true,
                                  })}
                                  className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-secondary-500 focus:border-secondary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white border ${
                                    errors.ImageIndex  && "border-rose-500"
                                  } bg-white py-2  text-base font-medium text-gray-700 outline-none focus:border-secondary-600 focus:shadow-md dark:bg-gray-700 dark:text-gray-300`}
                                />
                              </div>
                              <div className="mb-5">
                                <label className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-300">
                                  English Name:
                                </label>
                                <input
                                  type="text"
                                  placeholder="product name english"
                                  {...register("englishName", {
                                    // required: true,
                                  })}
                                  className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-secondary-500 focus:border-secondary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white border ${
                                    errors.englishName && "border-rose-500"
                                  } bg-white py-2  text-base font-medium text-gray-700 outline-none focus:border-secondary-600 focus:shadow-md dark:bg-gray-700 dark:text-gray-300`}
                                />
                              </div>
                              <div className="mb-5">
                                <label className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-300">
                                  Arabic Name:
                                </label>
                                <input
                                  type="text"
                                  style={{ unicodeBidi: "plaintext" }}
                                  placeholder="product name arabic"
                                  {...register("arabicName", {
                                    // required: true,
                                    pattern: /[\u0600-\u06FF]{1}/,
                                  })}
                                  className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-secondary-500 focus:border-secondary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white border rtl text-right ${
                                    errors.arabicName &&
                                    "border-rose-500 dark:border-rose-400"
                                  } bg-white py-2  text-base font-medium text-gray-700 outline-none focus:border-secondary-600 focus:shadow-md dark:bg-gray-700 dark:text-gray-300`}
                                />
                              </div>
                              <div className="mb-5">
                                <label className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-300">
                                  Kurdish Name:
                                </label>
                                <input
                                  type="text"
                                  style={{ unicodeBidi: "plaintext" }}
                                  placeholder="product name kurdish"
                                  {...register("kurdishName", {
                                    // required: true,
                                    pattern: /[\u0600-\u06FF]{1}/,
                                  })}
                                  className={`bg-gray-50 rtl text-right border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-secondary-500 focus:border-secondary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white border ${
                                    errors.kurdishName && "border-rose-500"
                                  } bg-white py-2  text-base font-medium text-gray-700 outline-none focus:border-secondary-600 focus:shadow-md dark:bg-gray-700 dark:text-gray-300`}
                                />
                              </div>
                              {/* Descrition */}
                              <div className="mb-5">
                                <label className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-300">
                                  English Description:
                                </label>
                                {/* <TextareaAutosize
                                  rows={5}
                                  type="text"
                                  placeholder="product description english"
                                  {...register("englishDescription", {
                                    // required: true,
                                  })}
                                  className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-secondary-500 focus:border-secondary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white border ${
                                    errors.englishDescription &&
                                    "border-rose-500"
                                  } bg-white py-2  text-base font-medium text-gray-700 outline-none focus:border-secondary-600 focus:shadow-md dark:bg-gray-700 dark:text-gray-300`}
                                /> */}
                                {/* {/* <Editor
                                  editorState={editorState}
                                  toolbarClassName="toolbarClassName"
                                  wrapperClassName="wrapperClassName"
                                  editorClassName="editorClassName"
                                  onEditorStateChange={this.onEditorStateChange}
                                /> */}
                                {/* <Editor editorState={editorState} onChange={setEditorState} /> */}
                                <ReactQuill
                                  theme="snow"
                                  value={englishDescription}
                                  onChange={setEnglishDescription}
                                  modules={modules}
                                  formats={formats}
                                />
                              </div>
                              <div className="mb-5">
                                <label className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-300">
                                  Arabic Description:
                                </label>
                                <ReactQuill
                                  theme="snow"
                                  value={arabicDescription}
                                  onChange={setArabicDescription}
                                  modules={modules}
                                  formats={formats}
                                  style={{ unicodeBidi: "plaintext" }}
                                />
                              </div>{" "}
                              <div className="mb-5">
                                <label className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-300">
                                  Kurdish Description:
                                </label>
                                <ReactQuill
                                  theme="snow"
                                  value={kurdishDescription}
                                  onChange={setKurdishDescription}
                                  modules={modules}
                                  formats={formats}
                                  style={{ unicodeBidi: "plaintext" }}
                                />
                              </div>
                              {/* Description */}
                              {/* Usage */}
                              <div className="mb-5">
                                <label className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-300">
                                  English Usage:
                                </label>
                                <ReactQuill
                                  theme="snow"
                                  value={englishUsage}
                                  onChange={setEnglsihUsage}
                                  modules={modules}
                                  formats={formats}
                                />
                              </div>
                              <div className="mb-5">
                                <label className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-300">
                                  Arabic Usage:
                                </label>
                                <ReactQuill
                                  theme="snow"
                                  value={arabicUsage}
                                  onChange={setArabicUsage}
                                  modules={modules}
                                  formats={formats}
                                  style={{ unicodeBidi: "plaintext" }}
                                />
                              </div>{" "}
                              <div className="mb-5">
                                <label className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-300">
                                  Kurdish Usage:
                                </label>
                                <ReactQuill
                                  theme="snow"
                                  value={kurdishUaage}
                                  onChange={setKurdishUsage}
                                  modules={modules}
                                  formats={formats}
                                  style={{ unicodeBidi: "plaintext" }}
                                />
                              </div>
                              {/* Tags */}
                              <div className="mb-5">
                                <label className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-300">
                                  English Tags:
                                </label>
                                <input
                                  type="text"
                                  placeholder="product tags in english"
                                  onChange={(e) =>
                                    setEnglishTags(e.target.value)
                                  }
                                  // {...register("tags", {
                                  //   required: true,
                                  // })}
                                  className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-secondary-500 focus:border-secondary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white border ${
                                    errors.tags && "border-rose-500"
                                  } bg-white py-2  text-base font-medium text-gray-700 outline-none focus:border-secondary-600 focus:shadow-md dark:bg-gray-700 dark:text-gray-300`}
                                />
                              </div>
                              <div className="mb-5">
                                <label className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-300">
                                  Arabic Tags:
                                </label>
                                <input
                                  type="text"
                                  onChange={(e) =>
                                    setArabicTags(e.target.value)
                                  }
                                  placeholder="product tags arabic"
                                  className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-secondary-500 focus:border-secondary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white border ${
                                    errors.arabicName &&
                                    "border-rose-500 dark:border-rose-400"
                                  } bg-white py-2  text-base font-medium text-gray-700 outline-none focus:border-secondary-600 focus:shadow-md dark:bg-gray-700 dark:text-gray-300`}
                                />
                              </div>{" "}
                              <div className="mb-5">
                                <label className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-300">
                                  Kurdish Tags:
                                </label>
                                <input
                                  type="text"
                                  onChange={(e) =>
                                    setKurdishTags(e.target.value)
                                  }
                                  placeholder="product tags arabic"
                                  className={`bg-gray-50 rtl text-right border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-secondary-500 focus:border-secondary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white border ${
                                    errors.arabicName &&
                                    "border-rose-500 dark:border-rose-400"
                                  } bg-white py-2  text-base font-medium text-gray-700 outline-none focus:border-secondary-600 focus:shadow-md dark:bg-gray-700 dark:text-gray-300`}
                                />
                              </div>{" "}
                              {/* End-Tags */}
                              {/* Numerics */}
                              <div className="mb-5">
                                <label className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-300">
                                  Category
                                </label>
                                <select
                                  onChange={
                                    // (e) => setCategoryId(e.target.value)
                                    handleCategoryChange
                                  }
                                  placeholder="Select sub category"
                                  className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-secondary-500 focus:border-secondary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white border ${
                                    errors.SubCategoryId && "border-rose-500"
                                  } bg-white py-2  text-base font-medium text-gray-700 outline-none focus:border-secondary-600 focus:shadow-md dark:bg-gray-700 dark:text-gray-300`}
                                >
                                  {/* <option value="null" selected>
                                    select category
                                  </option> */}
                                  {category?.map((item) => (
                                    <option
                                      value={item.id}
                                      key={item.id}
                                      className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-secondary-500 focus:border-secondary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white border ${
                                        errors.SubCategoryId &&
                                        "border-rose-500"
                                      } bg-white py-2  text-base font-medium text-gray-700 outline-none focus:border-secondary-600 focus:shadow-md dark:bg-gray-700 dark:text-gray-300`}
                                    >
                                      {item.category.english}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              <div className="mb-5">
                                <label className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-300">
                                  Sub Category
                                </label>
                                {/* <select
                                  {...register("SubCategoryId", {
                                    required: true,
                                  })}
                                  required
                                  placeholder="Select sub category"
                                  className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-secondary-500 focus:border-secondary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white border ${
                                    errors.SubCategoryId && "border-rose-500"
                                  } bg-white py-2  text-base font-medium text-gray-700 outline-none focus:border-secondary-600 focus:shadow-md dark:bg-gray-700 dark:text-gray-300`}
                                >
                                  {subCategories
                                    ?.filter(
                                      (item) =>
                                        categoryId === null ||
                                        parseInt(item?.categoryId) ===
                                          parseInt(categoryId)
                                    )
                                    ?.map((item) => (
                                      <option
                                        value={item.id}
                                        key={item.id}
                                        className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-secondary-500 focus:border-secondary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white border ${
                                          errors.SubCategoryId &&
                                          "border-rose-500"
                                        } bg-white py-2  text-base font-medium text-gray-700 outline-none focus:border-secondary-600 focus:shadow-md dark:bg-gray-700 dark:text-gray-300`}
                                      >
                                      {item.id}  {item.englishSubCategory}
                                      </option>
                                    ))}
                                </select> */}
                                <select
                                  value={subcategoryId}
                                  required
                                  onChange={handleSubCategoryChange}
                                  placeholder="Select subcategory"
                                  className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-secondary-500 focus:border-secondary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white border bg-white py-2  text-base font-medium text-gray-700 outline-none focus:border-secondary-600 focus:shadow-md dark:bg-gray-700 dark:text-gray-300`}
                                >
                                  {subCategory
                                    ?.filter(
                                      (item) =>
                                        categoryId === null ||
                                        parseInt(item?.categoryId) ===
                                          parseInt(categoryId)
                                    )
                                    ?.map((item) => (
                                      <option
                                        key={item.id}
                                        value={item.id}
                                        className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-secondary-500 focus:border-secondary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white border bg-white py-2  text-base font-medium text-gray-700 outline-none focus:border-secondary-600 focus:shadow-md dark:bg-gray-700 dark:text-gray-300`}
                                      >
                                        {item.englishSubCategory}
                                      </option>
                                    ))}
                                </select>
                              </div>
                              <div className="mb-5">
                                <label className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-300">
                                  Brand
                                </label>
                                <select
                                  {...register("brandId", {
                                    required: true,
                                  })}
                                  placeholder="Select category"
                                  className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-secondary-500 focus:border-secondary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white border ${
                                    errors.brandId && "border-rose-500"
                                  } bg-white py-2  text-base font-medium text-gray-700 outline-none focus:border-secondary-600 focus:shadow-md dark:bg-gray-700 dark:text-gray-300`}
                                >
                                  {/* <option value="null" selected>
                                    select category
                                  </option> */}
                                  {brand?.map((item) => (
                                    <option
                                      value={item.id}
                                      key={item.id}
                                      className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-secondary-500 focus:border-secondary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white border ${
                                        errors.brandId && "border-rose-500"
                                      } bg-white py-2  text-base font-medium text-gray-700 outline-none focus:border-secondary-600 focus:shadow-md dark:bg-gray-700 dark:text-gray-300`}
                                    >
                                      {item.name.english}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              <div className="mb-5">
                                <label className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-300">
                                  Offer Type
                                </label>
                                <select
                                  {...register("OfferType", {
                                    required: true,
                                  })}
                                  placeholder="Select sub category"
                                  className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-secondary-500 focus:border-secondary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white border ${
                                    errors.OfferType && "border-rose-500"
                                  } bg-white py-2  text-base font-medium text-gray-700 outline-none focus:border-secondary-600 focus:shadow-md dark:bg-gray-700 dark:text-gray-300`}
                                >
                                  <option
                                    value={0}
                                    className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-secondary-500 focus:border-secondary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white border ${
                                      errors.OfferType && "border-rose-500"
                                    } bg-white py-2  text-base font-medium text-gray-700 outline-none focus:border-secondary-600 focus:shadow-md dark:bg-gray-700 dark:text-gray-300`}
                                  >
                                    No Offer
                                  </option>
                                  <option
                                    value={1}
                                    className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-secondary-500 focus:border-secondary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white border ${
                                      errors.OfferType && "border-rose-500"
                                    } bg-white py-2  text-base font-medium text-gray-700 outline-none focus:border-secondary-600 focus:shadow-md dark:bg-gray-700 dark:text-gray-300`}
                                  >
                                    Buy one get one
                                  </option>{" "}
                                  <option
                                    value={2}
                                    className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-secondary-500 focus:border-secondary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white border ${
                                      errors.OfferType && "border-rose-500"
                                    } bg-white py-2  text-base font-medium text-gray-700 outline-none focus:border-secondary-600 focus:shadow-md dark:bg-gray-700 dark:text-gray-300`}
                                  >
                                    Percentage
                                  </option>
                                </select>
                              </div>
                              <div className="mb-5">
                                <label className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-300">
                                  Barcode:
                                </label>
                                <input
                                  type="number"
                                  placeholder="Barcode"
                                  {...register("barcode", {
                                    required: true,
                                  })}
                                  className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-secondary-500 focus:border-secondary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white border ${
                                    errors.barcode && "border-rose-500"
                                  } bg-white py-2  text-base font-medium text-gray-700 outline-none focus:border-secondary-600 focus:shadow-md dark:bg-gray-700 dark:text-gray-300`}
                                />
                              </div>
                              <div className="mb-5">
                                <label className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-300">
                                  Price:
                                </label>
                                <input
                                  type="text"
                                  placeholder="Price"
                                  {...register("price", {
                                    required: true,
                                    type: Number,
                                    pattern: /[0-9]/,
                                  })}
                                  className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-secondary-500 focus:border-secondary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white border ${
                                    errors.price && "border-rose-500"
                                  } bg-white py-2  text-base font-medium text-gray-700 outline-none focus:border-secondary-600 focus:shadow-md dark:bg-gray-700 dark:text-gray-300`}
                                />
                              </div>{" "}
                              <div className="mb-5">
                                <label className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-300">
                                  Quantity:
                                </label>
                                <input
                                  type="number"
                                  placeholder="Quantity"
                                  {...register("qty", {
                                    required: true,
                                  })}
                                  className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-secondary-500 focus:border-secondary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white border ${
                                    errors.qty && "border-rose-500"
                                  } bg-white py-2  text-base font-medium text-gray-700 outline-none focus:border-secondary-600 focus:shadow-md dark:bg-gray-700 dark:text-gray-300`}
                                />
                              </div>
                              <div className="mb-5">
                                <label className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-300">
                                  Wholesale:
                                </label>
                                <input
                                  type="number"
                                  placeholder="Wholesale"
                                  {...register("Wholesale", {
                                    required: true,
                                  })}
                                  className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-secondary-500 focus:border-secondary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white border ${
                                    errors.Wholesale && "border-rose-500"
                                  } bg-white py-2  text-base font-medium text-gray-700 outline-none focus:border-secondary-600 focus:shadow-md dark:bg-gray-700 dark:text-gray-300`}
                                />
                              </div>
                              <div className="mb-5">
                                <label className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-300">
                                  Index:
                                </label>
                                <input
                                  type="number"
                                  placeholder="Index"
                                  {...register("index", {
                                    required: true,
                                  })}
                                  className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-secondary-500 focus:border-secondary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white border ${
                                    errors.index && "border-rose-500"
                                  } bg-white py-2  text-base font-medium text-gray-700 outline-none focus:border-secondary-600 focus:shadow-md dark:bg-gray-700 dark:text-gray-300`}
                                />
                              </div>
                              <div className="mb-5">
                                <label className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-300">
                                  English Voice:
                                </label>
                                <input
                                  type="file"
                                  placeholder="Quantity"
                                  onChange={(e) =>
                                    setEnglishVoice(e.target.files[0])
                                  }
                                  className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white border ${
                                    errors.qty && "border-rose-500"
                                  } bg-white py-2  text-base font-medium text-gray-700 outline-none focus:border-blue-500 focus:shadow-md dark:bg-gray-700 dark:text-gray-300`}
                                />
                              </div>
                              <div className="mb-5">
                                <label className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-300">
                                  Arabic Voice:
                                </label>
                                <input
                                  type="file"
                                  placeholder="Quantity"
                                  onChange={(e) =>
                                    setArabicVoice(e.target.files[0])
                                  }
                                  className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white border ${
                                    errors.qty && "border-rose-500"
                                  } bg-white py-2  text-base font-medium text-gray-700 outline-none focus:border-blue-500 focus:shadow-md dark:bg-gray-700 dark:text-gray-300`}
                                />
                              </div>
                              <div className="mb-5">
                                <label className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-300">
                                  Kurdish Voice:
                                </label>
                                <input
                                  type="file"
                                  placeholder="Quantity"
                                  onChange={(e) =>
                                    setKurdishVoice(e.target.files[0])
                                  }
                                  className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white border ${
                                    errors.qty && "border-rose-500"
                                  } bg-white py-2  text-base font-medium text-gray-700 outline-none focus:border-blue-500 focus:shadow-md dark:bg-gray-700 dark:text-gray-300`}
                                />
                              </div>
                              <div className="float-right pt-2 pb-4">
                                {/* <button className="hover:shadow-form bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-secondary-500 focus:border-secondary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white bg-[#6A64F1] py-3 px-8 text-center text-base font-semibold text-white outline-none">
                                  Send File
                                </button> */}
                                <button
                                  type="submit"
                                  disabled={disable}
                                  className={
                                    "bg-secondary-100 mx-1 px-6 py-2 rounded-md text-secondary-600 hover:bg-secondary-200 hover:text-secondary-700 border border-transparent focus:outline-none focus:ring-2 focus:ring-secondary-500 focus:border-transparent dark:text-secondary-100 dark:bg-secondary-600"
                                  }
                                >
                                  <PlusCircleIcon className="inline w-6 h-6 mx-2" />
                                  Add
                                </button>
                                <button
                                  onClick={() => setOpen(false)}
                                  type="button"
                                  className={
                                    "bg-rose-100 mx-1 px-4 py-2 rounded-md text-rose-600 hover:bg-rose-200 hover:text-rose-700 border border-transparent focus:outline-none focus:ring-2 focus:ring-rose-500 focus:border-transparent dark:text-rose-100 dark:bg-rose-600"
                                  }
                                  // {...props}
                                >
                                  <XCircleIcon className="inline w-6 h-6 mx-2" />
                                  Cancel
                                  {/* {children} */}
                                </button>
                              </div>
                              {toast && (
                                <Toast
                                  Text={"Product Created Successfully"}
                                  Type={"secondary"}
                                  setOpen={setToast}
                                  className="z-50"
                                />
                              )}
                              {error && (
                                <Toast
                                  Text={message}
                                  Type={"rose"}
                                  setOpen={setError}
                                />
                              )}
                              <br />
                            </form>
                          </div>
                        </div>

                        {/* <div className=F"h-full border-2 border-gray-200 border-dashed" aria-hidden="true" /> */}
                      </div>
                      {/* {/* /End replace */}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
