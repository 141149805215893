import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import sale1 from "../../assets/images/sale1.avif";
import sale2 from "../../assets/images/sale2.jpg";
import sale3 from "../../assets/images/sale4.jpg";
import { getItem } from "../../../features/Global/Items";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import offerSlider from "./offerSlider.css";
import { getOffersItem } from "../../../features/Global/Offers";
import loaderAnim from "../Loader/Loader.json";
import { Player } from "@lottiefiles/react-lottie-player";
import { getBasket } from "../../../features/web/ShopingCart";
import CheckAuth from "../../componets/CheckAuthModal/CheckAuth";
import Cookies from "js-cookie";

// import {
//   TiChevronLeftOutline,
//   TiChevronRightOutline,
// } from "https://cdn.skypack.dev/react-icons";
import { FaChevronRight, FaChevronLeft } from "react-icons/fa";
import { GiftIcon, ReceiptPercentIcon } from "@heroicons/react/24/outline";
import axios from "axios";
const Sale = () => {
  const { t } = useTranslation();
  const locale = localStorage.getItem("i18nextLng");
  const discount = useSelector((state) => state.item?.data)?.filter(
    (item) => item.offerType !== 0 && item.isOfferActive
  );
  const data = useSelector((state) => state.Offers.copyData);
  const loader = useSelector((state) => state.Offers.loading);
  const dispatch = useDispatch();
  const [min, setMin] = useState();
  const [max, setMax] = useState();
  useEffect(() => {
    dispatch(getItem());
    dispatch(getOffersItem());
    // setMin = discount.hasMin("percentageOf");
    // setMax = discount.hasMax("percentageOf");
  }, []);
  const CARDS = 10;
  const MAX_VISIBILITY = 3;

  const Carousel = ({ children }) => {
    const [active, setActive] = useState(2);
    const count = React.Children.count(children);

    return (
      <div className="carousel sm:min-w-1.2 mx-auto">
        {active > 0 && (
          <button
            className={`duration-500 nav left group ${
              locale === "en-US" ? "" : "float-left left-0"
            }`}
            onClick={() => setActive((i) => i - 1)}
          >
            <FaChevronLeft className="text-pink-500 duration-500 group-hover:text-pink-600" />
          </button>
        )}
        {React.Children.map(children, (child, i) => (
          <div
            key={i}
            className="card-container"
            style={{
              "--active": i === active ? 1 : 0,
              "--offset": (active - i) / 3,
              "--direction": Math.sign(active - i),
              "--abs-offset": Math.abs(active - i) / 3,
              "pointer-events": active === i ? "auto" : "none",
              opacity: Math.abs(active - i) >= MAX_VISIBILITY ? "0" : "1",
              display: Math.abs(active - i) > MAX_VISIBILITY ? "none" : "block",
            }}
          >
            {child}
          </div>
        ))}
        {active < count - 1 && (
          <button
            className="duration-500 nav right group"
            onClick={() => setActive((i) => i + 1)}
          >
            <FaChevronRight className="text-pink-500 duration-500 group-hover:text-pink-600" />
          </button>
        )}
      </div>
    );
  };

  const [checkLoginModal, setcheckLoginModal] = useState(false);
  const [AddedToBasket, setAddedToBasket] = useState(false);

  const [snackBar, setSnackBar] = useState(false);
  const isLogedIn = Cookies.get("webAuth")?.length > 0 ? true : false;
  // const dispatch = useDispatch();
  const [quantity, setQuantity] = useState(1);
  const config = {
    headers: {
      Authorization: `Bearer ${Cookies.get("webAuth")}`,
    },
  };

  const submitOrder = (id) => {
    if (isLogedIn) {
      console.log(id);
      const data = {};
      data.productId = id;
      data.qty = 1;
      axios
        .post("api/BasketItems/AddAndRemoveFromBasket", data, config)
        .then((r) => {
          // console.log(r);
          setAddedToBasket(true);
          setTimeout(() => {
            setAddedToBasket(false);
          }, 4000);
          dispatch(getBasket());
        })
        .catch((e) => {
          console.log(e.response);
          if (e.response.status === 401) {
          }
          if (e.response.data === "not enough items in stock") {
            setSnackBar(true);

            setTimeout(() => {
              setSnackBar(false);
            }, 4000);
          }
        });
    } else {
      setcheckLoginModal(true);
    }
  };
  return (
    <div className="py-16 bg-white" data-aos="fade">
      <div className="container m-auto space-y-8 text-gray-500 md:px-12 lg:px-14">
        <div className="flex-col-reverse justify-center gap-6 text-center lg:flex-row md:text-left md:flex lg:items-center lg:gap-16">
          <div
            className={`order-last mb-6 sm:mx-auto lg:mx-0 ${
              locale === "en-US" ? "ml-auto" : "mr-auto"
            } space-y-6 md:mb-0 md:w-6/12 lg:w-6/12`}
          >
            <h1
              className={`text-4xl font-bold text-gray-700 md:text-5xl sm:text-center ${
                locale === "en-US" ? "text-left" : "text-right"
              } ${locale}`}
            >
              {t("Buynowandbenefitupto")}{" "}
              <span className={`text-blue-500 ${locale}`}>
                {min}
                {/* {t("30% off")} */}
              </span>
            </h1>
            <p
              className={`text-lg ${locale} ${
                locale === "en-US" ? "text-left" : "text-right mr-2"
              }`}
            >
              {t("newItemsWichHaveOffers")}
            </p>
            <div className="flex flex-row flex-wrap justify-center gap-4 md:gap-6 md:flex-row sm:justify-center">
              <Link
                to="/offers"
                type="button"
                title="Start buying"
                className="w-full px-10 py-3 text-center transition duration-500 rounded-lg shadow-xl bg-gradient-to-br from-pink-400 to-pink-600 hover:bg-pink-600 active:bg-gray-700 focus:bg-pink-600 sm:w-max hover:scale-y-105 hover:scale-x-110"
              >
                <span className={`block font-semibold text-white ${locale}`}>
                  {t("startBuying")}
                </span>
              </Link>
              {/* <div className="flex items-center justify-center w-64 h-16 b">
                <div className="absolute items-center w-64 h-16 overflow-hidden transition duration-300 ease-out transform rounded-lg shadow-2xl cursor-pointer i bg-gradient-to-br from-pink-400 to-pink-600 hover:scale-x-110 hover:scale-y-105"></div>
                <a className={`text-center text-white font-semibold z-10 pointer-events-none ${locale}`}>
                {"startBuying"}
                </a>
              </div> */}
              {/* <Link
                to={"/offers"}
                type="button"
                title="more about"
                className="order-first w-full px-6 py-3 text-center transition bg-pink-100 rounded-xl hover:bg-pink-200 active:bg-pink-300 focus:bg-pink-200 sm:w-max"
              >
                <span className={`block font-semibold text-pink-600 ${locale}`}>
                  {"moreInfo"}
                </span>
              </Link> */}
            </div>
          </div>
          <Carousel className="">
            {data
              ?.filter((item) => item.isActive === true)
              .map((item, index) =>
                data?.length < 1 ? (
                  <>
                    <p className="text-3-xl text-rose">no data found</p>
                  </>
                ) : (
                  <>
                    {loader ? (
                      <Player
                        src={loaderAnim}
                        autoplay
                        loop
                        className="player"
                      />
                    ) : (
                      <>
                        <div
                          key={index}
                          className="duration-500 bg-white shadow-md hover:scale-105 hover:shadow-xl rounded-xl"
                        >
                          <Link
                            to={`/shop/product/${item.id}`}
                            replace={true}
                            relative={true}
                            className="relative flex mt-3 overflow-hidden h-60"
                          >
                            {item.qty < 1 && (
                              <span
                                className={`absolute top-0 ${
                                  locale === "en-US" ? "left-0" : "right-0"
                                } m-2 rounded-md bg-rose-500 px-2 text-center text-sm font-medium text-white ${locale}`}
                              >
                                {t("outOfStock")}
                              </span>
                            )}
                            <img
                              src={item?.imageUrl}
                              alt="Product image"
                              className="object-contain w-full rounded-t-xl"
                            />
                             {item.isOfferActive &&
                              item.isApplicableForOffers && (
                                <div
                                  className={` absolute z-10 top-2 ${
                                    item.offerType === 1
                                      ? "bg-teal-500 "
                                      : "bg-rose-500 "
                                  } py-2 px-4 rounded-full text-white ${
                                    locale === "en-US" ? "left-5" : "right-16"
                                  }`}
                                >
                                  <span>
                                    {item.offerType === 1 && (
                                      <GiftIcon className="inline w-6 py-2 text-white" />
                                    )}
                                    {item.offerType === 2 && (
                                      <>
                                        <strong className="flex-col text-sm ku">
                                          {" "}
                                          {item.percentageOf}%
                                          <div className="ku"> OFF</div>
                                        </strong>
                                        {/* <ReceiptPercentIcon className="inline w-5 mx-1 text-blue-500" /> */}
                                      </>
                                    )}
                                    {/* {item.offerType === 3 && (
                                      <ReceiptPercentIcon className="inline w-5 mx-1 text-blue-500" />
                                    )} */}
                                  </span>
                                </div>
                              )}
                          </Link>
                          <div className="w-auto px-4 py-3" key={index}>
                            <span
                              className={`mr-3 text-xs text-gray-400 uppercase ${
                                locale === "en-US" ? "" : "float-right right-0"
                              } ${locale}`}
                            >
                              {locale === "en-US" && item.brand?.english}
                              {locale === "ar" && item.brand?.arabic}
                              {locale === "ku" && item.brand?.kurdish}{" "}
                            </span>
                            <div
                              className={`block text-md font-bold text-black capitalize truncate max-w-lg ${locale}`}
                            >
                              {locale === "en-US" && item.name?.english}
                              {locale === "ar" && item.name?.arabic}
                              {locale === "ku" && item.name?.kurdish}{" "}
                            </div>
                            <div className="flex items-center">
                              <p
                                className={`my-3 text-lg font-semibold text-black cursor-auto max-w-fit ${locale}`}
                              >
                                {item?.isOfferActive === false ? (
                                  <>
                                    {Intl?.NumberFormat?.format(item.price)}{" "}
                                    <small className={`${locale}`}>
                                      {t("iqd")}{" "}
                                    </small>
                                  </>
                                ) : (
                                  <>
                                    {item?.offerType === 2 &&
                                    item?.isOfferActive &&
                                    item?.isApplicableForOffers ? (
                                      <>
                                        {Intl.NumberFormat()?.format(
                                          item.priceAfterDiscount
                                        )}{" "}
                                        <small className={`${locale}`}>
                                          {t("iqd")}{" "}
                                        </small>
                                        <span className="mx-2 text-sm font-light line-through">
                                          {Intl?.NumberFormat().format(
                                            item.price
                                          )}{" "}
                                          <small className={`${locale} 	`}>
                                            {t("iqd")}{" "}
                                          </small>
                                        </span>
                                      </>
                                    ) : (
                                      <>
                                        {Intl.NumberFormat().format(item.price)}{" "}
                                        <small className={`${locale}`}>
                                          {t("iqd")}{" "}
                                        </small>
                                      </>
                                    )}
                                  </>
                                )}
                              </p>

                              <div
                                className={` ${
                                  locale === "en-US" ? "ml-auto" : "mr-auto"
                                } `}
                              >
                                <button
                                  // type="submit"
                                  onClick={() => submitOrder(item?.id)}
                                  disabled={item.qty < 1}
                                  hidden={item.qty < 1}
                                  // onClick={() => handleSubmitOrder(item.id)}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    fill="currentColor"
                                    className="bi bi-bag-plus"
                                    viewBox="0 0 16 16"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M8 7.5a.5.5 0 0 1 .5.5v1.5H10a.5.5 0 0 1 0 1H8.5V12a.5.5 0 0 1-1 0v-1.5H6a.5.5 0 0 1 0-1h1.5V8a.5.5 0 0 1 .5-.5z"
                                    />
                                    <path d="M8 1a2.5 2.5 0 0 1 2.5 2.5V4h-5v-.5A2.5 2.5 0 0 1 8 1zm3.5 3v-.5a3.5 3.5 0 1 0-7 0V4H1v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V4h-3.5zM2 5h12v9a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V5z" />
                                  </svg>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </>
                )
              )}
            {checkLoginModal && (
              <CheckAuth open={checkLoginModal} setOpen={setcheckLoginModal} />
            )}
          </Carousel>

          {/* <SaleCarousel /> */}
          {/* <section className="slider">
            <div className="flex">
              <img src="http://baronwatts.com/tuts/swipe/1.png" />
              <div className="desc">
                <h2 className="h2-text">BOSE QC35</h2>
                <p className="h2-text">$289.99 - $299.99</p>
                <a href="#" className="btn">
                  Add to Cart
                </a>
              </div>
            </div>

            <div className="flex">
              <img src="http://baronwatts.com/tuts/swipe/2.png" />
              <div className="desc">
                <h2>BOSE SoundLink II</h2>
                <p>$299.99 - $329.99</p>
                <a href="#" className="btn">
                  Add to Cart
                </a>
              </div>
            </div>

            <div>
              <img src="http://baronwatts.com/tuts/swipe/3.png" />
              <div className="desc">
                <h2>BOSE QC35</h2>
                <p>$289.99 - $299.99</p>
                <a href="#" className="btn">
                  Add to Cart
                </a>
              </div>
            </div>

            <div>
              <img src="http://baronwatts.com/tuts/swipe/4.png" />
              <div className="desc">
                <h2>BOSE SoundLink II</h2>
                <p>$299.99 - $329.99</p>
                <a href="#" className="btn">
                  Add to Cart
                </a>
              </div>
            </div>

            <div>
              <img src="http://baronwatts.com/tuts/swipe/5.png" />
              <div className="desc">
                <h2>BOSE QC35</h2>
                <p>$289.99 - $299.99</p>
                <a href="#" className="btn">
                  Add to Cart
                </a>
              </div>
            </div>

    
          </section> */}
          {/* <div className="col-span-2 row-span-4">
              <img
                src={sale3}
                // src={discount[discount.length - 3]?.imageUrl}
                className="my-auto rounded-full "
                width="640"
                height="960"
                alt="shoes"
                loading="lazy"
              />
            </div>
            <div className="col-span-2 row-span-2">
              <img
                // src={discount[discount.length - 2]?.imageUrl}
                src={sale1}
                className="object-cover object-top w-full h-full rounded-xl"
                width="640"
                height="640"
                alt="shoe"
                loading="lazy"
              />
            </div>
            <div className="col-span-3 row-span-3">
              <img
                // src={discount[discount.length - 1]?.imageUrl}
                src={sale3}
                className="object-cover object-top w-full h-full rounded-xl"
                width="640"
                height="427"
                alt="shoes"
                loading="lazy"
              />
            </div> */}
        </div>
      </div>
    </div>
  );
};

export default Sale;
