import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import axios from "axios";
const config = {
  headers: {
    Authorization: `Bearer ${Cookies.get('webAuth')}`,
  },
};
const locale = localStorage.getItem("i18nextLng");
export const getWishList = createAsyncThunk(
  "getWishList",
  async (object, { getState, rejectWithValue }) => {
    // console.log(getState());
    try {
      const { data } = await axios.get("api/Wishlists", config);
      return data;
    } catch (error) {
      rejectWithValue(error.response);
    }
  }
);


const loremSlice = createSlice({
  name: "lorem",
  initialState: {
    data: [],
    count: 0,
    loading: true,
    isSuccess: false,
    message: "",
  },
  reducers: {
  },
  extraReducers: {
    [getWishList.pending]: (state, action) => {
      state.loading = true;
    },
    [getWishList.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.count = payload?.length;
      state.data = payload;
      state.isSuccess = true;
    },
    [getWishList.rejected]: (state, { payload }) => {
      state.loading = false;
      state.isSuccess = false;
      state.message = "failed";
    },
  },
});

export default loremSlice;
