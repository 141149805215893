import {
  CheckCircleIcon,
  ClockIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";
import React from "react";
import { useTranslation } from "react-i18next";

const NotificationCenter = ({ notificationHistory }) => {
  const { t } = useTranslation();
  const l = localStorage.getItem("i18nextLng");
  return (
    <div className="col-span-8 px-8 overflow-hidden shadow rounded-xl bg-gray-50">
      <div className="pt-4 pb-8 border-b">
        <h1 className={`py-2 text-2xl font-semibold ${l}`}>
          {t("notifications")}
        </h1>
        <p className={`font- text-slate-600 ${l}`}>
          {t("listOfPreviousNotifications")}{" "}
        </p>
      </div>
      {/* {notificationHistory?.map((item) => (
        <div className="grid py-6 border-b sm:grid-cols-2">
          <div className="">
            <h2 className="text-lg font-semibold leading-4 text-center text-slate-700">
              {item?.englishTitle}
            </h2>
            <p className="text-center font- text-slate-600">
              <div className="flex-row justify-between md:flex ">
                <div className={`${l} text-center py-1 lg:py-0`}>
                  {Intl.NumberFormat()?.format(item?.totalPrice)} {t("iqd")}
                </div>
                <div>{item?.insertedAt.split("T")[0]}</div>
              </div>
            </p>
          </div>
        </div>
      ))} */}
      {/* <!-- Table Section --> */}
      <div className="max-w-full py-10 mx-auto lg:py-6">
        {/* <!-- Card --> */}
        <div className="flex flex-col">
          <div className="-m-1.5 overflow-x-auto">
            <div className="inline-block max-h-screen min-w-full overflow-y-scroll align-middle ">
              <div className="overflow-hidden bg-white border border-gray-200 shadow-sm rounded-xl dark:bg-slate-900 dark:border-gray-700">
                {/* <!-- Header --> */}
                {/* <div className="grid gap-3 px-6 py-4 border-b border-gray-200 md:flex md:justify-between md:items-center dark:border-gray-700">
            <div>
              <h2 className="text-xl font-semibold text-gray-800 dark:text-gray-200">
                Users
              </h2>
              <p className="text-sm text-gray-600 dark:text-gray-400">
                Add users, edit and more.
              </p>
            </div>

            <div>
              <div className="inline-flex gap-x-2">
                <a className="inline-flex items-center justify-center gap-2 px-3 py-2 text-sm font-medium text-gray-700 align-middle transition-all bg-white border rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 dark:bg-slate-900 dark:hover:bg-slate-800 dark:border-gray-700 dark:text-gray-400 dark:hover:text-white dark:focus:ring-offset-gray-800" href="#">
                  View all
                </a>

                <a className="inline-flex items-center justify-center gap-2 px-3 py-2 text-sm font-semibold text-white transition-all bg-blue-500 border border-transparent rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 dark:focus:ring-offset-gray-800" href="#">
                  <svg className="w-3 h-3" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path d="M2.63452 7.50001L13.6345 7.5M8.13452 13V2" stroke="currentColor" stroke-width="2" stroke-linecap="round"/>
                  </svg>
                  Add user
                </a>
              </div>
            </div>
          </div> */}

                <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                  <thead className="bg-gray-50 dark:bg-slate-800">
                    <tr>
                      <th
                        scope="col"
                        className="py-3 pl-6 pr-6 text-left lg:pl-3 xl:pl-0"
                      >
                        <div className="flex items-center gap-x-2">
                          <span className={`text-sm font-semibold tracking-wide text-gray-800 uppercase dark:text-gray-200 ${l}`}>
                            {t("title")}
                          </span>
                        </div>
                      </th>

                      <th scope="col" className="px-6 py-3 text-left">
                        <div className="flex items-center gap-x-2">
                          <span className={`text-sm font-semibold tracking-wide text-gray-800 uppercase dark:text-gray-200 ${l}`}>
                            {t("notificationDetail")}
                          </span>
                        </div>
                      </th>

                      <th scope="col" className="px-6 py-3 text-left">
                        <div className="flex items-center gap-x-2">
                          <span className={`text-xs font-semibold tracking-wide text-gray-800 uppercase dark:text-gray-200 ${l}`}>
                            {t("insertedAt")}
                          </span>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
                    {notificationHistory?.map((item) => (
                      <tr>
                        <td className="w-auto h-px whitespace-nowrap">
                          <div className="px-4 py-3">
                            <span
                              className={`block  text-gray-800 text-md dark:text-gray-200 ${l}`}
                            >
                              {l === "en" && item.englishTitle}
                              {l === "ar" && item.arabicTitle}
                              {l === "ku" && item.kurdishTitle}
                            </span>
                            {/* <span className="block text-sm text-gray-500">
                              Human resources
                            </span> */}
                          </div>
                        </td>
                        <td className="w-px h-px whitespace-nowrap">
                          <div className="px-4 py-3">
                            <span
                              className={`inline-flex items-center gap-1.5 py-0.5 px-2 rounded-full text-sm font-medium bg-secondary-100 text-secondary-800 dark:bg-green-900 dark:text-green-200 ${l}`}
                            >
                              <svg
                                className="w-2.5 h-2.5"
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                viewBox="0 0 16 16"
                              >
                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                              </svg>
                              {l === "en" && item?.englishBody}
                              {l === "ar" && item?.arabicBody}
                              {l === "ku" && item?.kurdishBody}
                            </span>
                          </div>
                        </td>
                        <td className="w-px h-px whitespace-nowrap">
                          <div className="px-6 py-3">
                            <span className="text-sm text-gray-500">
                              {item.insertedAt}
                            </span>
                          </div>
                        </td>
                      </tr>
                    ))}{" "}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotificationCenter;
