// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyB07N-aBTJXZBn0GQ1Fxsq3QY-EyE6Pb-s",
  authDomain: "vary-healthcare.firebaseapp.com",
  projectId: "vary-healthcare",
  storageBucket: "vary-healthcare.appspot.com",
  messagingSenderId: "1072854892106",
  appId: "1:1072854892106:web:632320a10ec666a040f0b3",
  measurementId: "G-DY3RB5ECM4"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const authentication = getAuth(app);
