import { ShoppingBagIcon, SwatchIcon, TagIcon, UsersIcon } from "@heroicons/react/24/outline";
import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getStats } from "../../../features/Global/Stats";
import CountUp from "react-countup";

const MainCard = () => {
  const data = useSelector((state) => state.stats.data);
  const dispatch = useDispatch();
  useMemo(() => {
    dispatch(getStats());
  }, []);

  return (
    <div className="grid w-full gap-5 m-3 mx-auto sm:grid-cols-4 ">
      <div className="px-4 py-6 duration-500 border border-gray-100 rounded-lg shadow-lg dark:shadow-md shadow-blue-100 bg-gradient-to-br from-blue-50 to-blue-100 hover:scale-105 hover:bg-gradient-to-bl">
        <SwatchIcon className="p-4 text-white bg-blue-400 h-14 w-14 rounded-xl" />
        <p className="mt-4 font-medium text-blue-600">Brands</p>
        <p className="mt-2 text-xl font-medium">
          <CountUp end={data?.brands} />
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="inline w-4 h-4"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth="2"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M5 10l7-7m0 0l7 7m-7-7v18"
            />
          </svg>
        </p>
        {/* <span className="text-xs text-gray-400">+4.9%</span> */}
      </div>
      <div className="px-4 py-6 duration-500 border border-gray-100 rounded-lg shadow-lg dark:shadow-md shadow-blue-100 bg-gradient-to-br from-pink-50 to-pink-100 hover:scale-105 hover:bg-gradient-to-bl">
        <ShoppingBagIcon className="p-4 text-white bg-pink-400 h-14 w-14 rounded-xl" />

        <p className="mt-4 font-medium text-pink-600">Products</p>
        <p className="mt-2 text-xl font-medium">
          <CountUp end={data?.products} />
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="inline w-4 h-4"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth="2"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M5 10l7-7m0 0l7 7m-7-7v18"
            />
          </svg>
        </p>
        {/* <span className="text-xs text-gray-400">+4.9%</span> */}
      </div>
      <div className="px-4 py-6 duration-500 border border-gray-100 rounded-lg shadow-lg dark:shadow-md shadow-blue-100 bg-gradient-to-br from-rose-50 to-rose-100 hover:scale-105 hover:bg-gradient-to-bl">
        <TagIcon className="p-4 text-white h-14 w-14 rounded-xl bg-rose-400" />
        <p className="mt-4 font-medium text-rose-600">Categories</p>
        <p className="mt-2 text-xl font-medium">
          <CountUp end={data?.category} />
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="inline w-4 h-4"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth="2"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M5 10l7-7m0 0l7 7m-7-7v18"
            />
          </svg>
        </p>
        {/* <span className="text-xs text-gray-400">+4.9%</span> */}
      </div>
      <div className="px-4 py-6 duration-500 border border-gray-100 rounded-lg shadow-lg dark:shadow-md shadow-blue-100 bg-gradient-to-br from-indigo-50 to-indigo-100 hover:scale-105 hover:bg-gradient-to-bl">
        <UsersIcon className="p-4 text-white bg-indigo-400 h-14 w-14 rounded-xl" />
        <p className="mt-4 font-medium text-indigo-600">Users</p>
        <p c="mt-2 text-xl font-medium">
          <CountUp end={data?.users} />
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="inline w-4 h-4"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth="2"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M5 10l7-7m0 0l7 7m-7-7v18"
            />
          </svg>
        </p>
        {/* <span className="text-xs text-gray-400">+4.9%</span> */}
      </div>
    </div>
  );
};

export default MainCard;
