import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
// import HttpApi from 'i18next-http-backend'

const fallbackLng = ["ar"];
const availableLanguages = ["en-US", "ar", "ku"];

i18n
  .use(Backend) // load translations using http (default                                               public/assets/locals/en-US/translations)
  .use(LanguageDetector) // detect user language
  .use(initReactI18next) // pass the i18n instance to react-i18next.
  .init({
    // fallbackLng: 'En',
    lng: localStorage.getItem("i18nextLng") || "ar", // default language
    debug: true,
    whitelist: availableLanguages,
    interpolation: {
      escapeValue: false,
    },

    debug: false,

    whitelist: availableLanguages,

    interpolation: {
      escapeValue: false, // no need for react. it escapes by default
    },
    react: { useSuspense: false },
    backend: {
      loadPath: "/assets/locales/{{lng}}/translation.json",
    },
  });

export default i18n;
