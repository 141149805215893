import { Fragment, useState, useEffect, useCallback } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  MinusIcon,
  PlusIcon,
  ShoppingBagIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getBasket } from "../../../features/web/ShopingCart";
import EmptyBag from "../../assets/icons/Group.svg";
import Checkout from "./Checkout";
import axios from "axios";
import { Link } from "react-router-dom";
import SnackBar from "./SnackBarDynamic";
import Cookies from "js-cookie";
import { getWishList } from "../../../features/Global/wishList";
import { HeartIcon } from "@heroicons/react/20/solid";

export default function Example({ Title, open, setOpen }) {
  const config = {
    headers: {
      Authorization: `Bearer ${Cookies.get("webAuth")}`,
    },
  };
  const [CheckOut, setCheckOut] = useState(false);

  const data = useSelector((state) => state.wishList.data);
  const total = useSelector((state) => state.wishList.total);
  let sum = 0;
  const [snackBar, setSnackBar] = useState();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const l = localStorage.getItem("i18nextLng");
  const [customer, setCustomer] = useState();
  // const customer = JSON?.parse(Cookies.get("Customer"));

  useEffect(() => {
    if (Cookies.get("Customer")) {
      setCustomer(JSON.parse(Cookies.get("Customer")));
    }
  }, []);

  useEffect(() => {
    if (Cookies.get("webAuth")) dispatch(getWishList());
    console.log(data);
  }, []);
  const handleWishList = (id) => {
    console.log(id);
    const data = {
      productId: id,
    };
    axios
      .post(`api/Wishlists`, data, config)
      .then((r) => {
        dispatch(getWishList());
        console.log("sent");
        // }
      })
      .catch((e) => {
        if (e?.response?.data === "not enough items in stock") {
          setSnackBar(true);

          setTimeout(() => {
            setSnackBar(false);
          }, 4000);
        }
        console.log(e);
      });
  };
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-[400]" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="fixed inset-y-0 right-0 flex max-w-full pl-10 pointer-events-none">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="w-screen max-w-md pointer-events-auto">
                  <div className="flex flex-col h-full overflow-y-scroll bg-white shadow-xl">
                    <div className="flex-1 px-4 py-6 overflow-y-auto sm:px-6">
                      <div className="flex items-start justify-between">
                        <Dialog.Title
                          className={`text-lg font-medium text-gray-900 ${l}`}
                        >
                          <HeartIcon className="inline w-6 h-6 text-rose-500" />{" "}
                          {t("wishList")}
                        </Dialog.Title>
                        <div className="flex items-center ml-3 h-7">
                          <button
                            type="button"
                            className="p-2 -m-2 text-gray-400 hover:text-gray-500"
                            onClick={() => setOpen(false)}
                          >
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="w-6 h-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                      <div className="mt-8">
                        <div className="flow-root">
                          <ul
                            role="list"
                            className="z-50 -my-6 divide-y divide-gray-200"
                          >
                            {data?.length === 0 ? (
                              <>
                                <div className="max-w-screen-lg mx-auto ">
                                  <img
                                    src={EmptyBag}
                                    className="content-center overflow-hidden mt-36"
                                  />
                                  <div
                                    className={`${l} text-gray-500 mx-auto my-6 font-medium`}
                                  >
                                    <div
                                      className={`${l} text-gray-500 mx-auto my-10  text-center`}
                                    >
                                      {/* <span className={`${l} mx-auto min-w-1/2 `}> */}
                                      {t("noItemAvailableInWishList")} {/* </span> */}
                                    </div>
                                  </div>
                                </div>
                              </>
                            ) : (
                              <>
                                {" "}
                                {data?.map((product) => (
                                  <li key={product.id} className="flex py-6">
                                    <div className="flex-shrink-0 w-24 h-24 border border-gray-200 rounded-md">
                                      <div className="relative z-30 shrink-0">
                                        <img
                                          className="object-cover w-24 h-24 max-w-full rounded-lg"
                                          src={product.imageUrl}
                                          alt=""
                                        />
                                      </div>
                                    </div>
                                    <div
                                      className={`flex flex-col flex-1 ${
                                        l === "en-US" ? " ml-4" : "mr-4"
                                      }`}
                                    >
                                      <div>
                                        <div className="flex justify-between text-base font-medium text-gray-900">
                                          <h3>
                                            <a
                                              href={product.href}
                                              className={l}
                                            >
                                              {l === "en-US" &&
                                                product.name.english}
                                              {l === "ar" &&
                                                product.name.arabic}
                                              {l === "ku" &&
                                                product.name.kurdish}
                                            </a>
                                          </h3>
                                          <p className={`ml-4 ${l}`}>
                                            {Intl.NumberFormat().format(
                                              product.price * product.qty
                                            )}{" "}
                                            {t("iqd")}
                                          </p>
                                        </div>
                                      </div>
                                      <button
                                        onClick={() =>
                                          handleWishList(product.id)
                                        }
                                      >
                                        <HeartIcon className="inline float-left w-6 my-auto ml-8 mr-auto text-rose-500" />
                                      </button>
                                    </div>
                                  </li>
                                ))}
                              </>
                            )}
                          </ul>
                        </div>
                      </div>
                    </div>
                    {snackBar && <SnackBar />}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
