import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";

export default function MyModal({ show, setShow, FIBResponse }) {
  const { t } = useTranslation();
  const l = localStorage.getItem("i18nextLng");
  function closeModal() {
    setShow(false);
  }

  function openModal() {
    setShow(true);
  }

  return (
    <>
      <Transition appear show={show} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => {}}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md p-6 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                  <Dialog.Title
                    as="div"
                    className={`text-lg font-medium leading-6 p-2 rounded-lg text-gray-900 ${
                      l === "en-US" ? "text-left" : "text-right"
                    } ${l}`}
                  >
                    <span
                      className={`p-2 text-white text-center rounded-md bg-gradient-to-bl from-teal-500 to-emerald-400 ${l}`}
                    >
                      {t("FIBPayment")}{" "}
                    </span>
                  </Dialog.Title>
                  <div className="mt-2">
                    <img
                      className="w-1/2 p-1 m-2 mx-auto border border-2 border-teal-500 rounded-md"
                      src={FIBResponse?.fibDetails?.qrCode}
                      alt="fib qr code"
                    />
                    <div
                      className={`text-sm mt-1 text-gray-50 flex w-1/3 p-2 rounded-md my-2 mx-auto text-center bg-gradient-to-bl from-teal-500 to-emerald-400 ${l}`}
                    >
                      {FIBResponse?.fibDetails?.readableCode}
                    </div>
                    <p
                      className={`text-sm text-gray-500 m-2 my-3 mx-auto text-center ${l}`}
                    >
                      {t("scanQrCodeForFIBPayment")}
                    </p>
                  </div>

                  <div className="mt-4">
                    <button
                      type="button"
                      className={`inline-flex justify-center px-4 py-2 text-sm font-medium text-rose-900 bg-rose-100 border border-transparent rounded-md hover:bg-rose-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-rose-500 focus-visible:ring-offset-2 ${l}`}
                      onClick={closeModal}
                    >
                      {t('close')}
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
