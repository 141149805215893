import React, { useTransition, Fragment, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { Dialog, Transition } from "@headlessui/react";
import Cookies from "js-cookie";
import BreadCrumb from "../BreadCrumb";
import {
  UserPlusIcon,
  CheckCircleIcon,
  XCircleIcon,
  PhoneIcon,
  CalendarDaysIcon,
  MapPinIcon,
  EnvelopeIcon,
  KeyIcon,
  InformationCircleIcon,
} from "@heroicons/react/24/outline";
import male from "../../assets/icons/male.svg";
import female from "../../assets/icons/female.svg";
import { useForm } from "react-hook-form";
import {
  CheckIcon,
  ChevronUpDownIcon,
  EyeIcon,
  EyeSlashIcon,
  UserCircleIcon,
} from "@heroicons/react/20/solid";
import { authentication } from "../../../firebase";
// import fire base Auth
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";

import vary from "../../assets/images/varybackground.jpg";
import SnackBar from "../Snackbar/SnackBar";
import RegisterFail from "../Snackbar/RegistraionFailSnackbar";
import { Navigate, useNavigate } from "react-router-dom";
import { Player } from "@lottiefiles/react-lottie-player";
import loaderAnim from "../../assets/icons/otp.json";
import OtpInput from "react18-input-otp";
import { getConfiguration } from "../../../features/Global/Configuration";
import { useDispatch, useSelector } from "react-redux";
import { setAuth } from "../../../features/web/WebAuthentication";
const Gender = [
  {
    id: 1,
    name: "Male",
    avatar: male,
  },
  {
    id: 2,
    name: "Female",
    avatar: female,
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
const RegisterForm = () => {
  const navigate = useNavigate();
  const data = useSelector((state) => state.Configuration?.data);
  const dispatch = useDispatch();
  const [selected, setSelected] = useState("");
  const [checkPassword, setCheckPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [gender, setGender] = useState("");
  const [showCheck, setShowCheck] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [registerStatus, setRegiserStatus] = useState(false);
  const [formData, setFormData] = useState();
  const [user, setUser] = useState();
  const [succesRegister, setSuccessRegister] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [token, setToken] = useState();
  const [checkpasswordType, setCheckPasswordType] = useState();
  const [autoFoucs, setAutoFocus] = useState(1);
  const [login, setLogin] = useState({});
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const strongRegex = new RegExp(
    "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
  );
  const mediumRegex = new RegExp(
    "^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})"
  );
  useEffect(() => {
    dispatch(getConfiguration());
  }, []);

  //  OTP
  const [otp, setOtp] = useState("");
  const handleChange = (enteredOtp) => {
    setOtp(enteredOtp);
  };

  useEffect(() => {
    // console.log(password);
    if (password === confirmPassword && password && confirmPassword !== "") {
      if (strongRegex.test(password)) {
        // this.setState({ backgroundColor: "#0F9D58" });
        setCheckPasswordType(1);
      } else if (mediumRegex.test(password)) {
        // this.setState({ backgroundColor: "#F4B400" });
        setCheckPasswordType(2);
      } else {
        // this.setState({ backgroundColor: "#DB4437" });
        setCheckPasswordType(3);
      }
    }
  }, [password, confirmPassword]);

  const genarateRecaptcha = () => {
    window.recaptchaVerifier = new RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
        callback: (response) => {
          console.log("prepared phone auth process");
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          // ...
        },
        "expired-callback": () => {
          // Response expired. Ask user to solve reCAPTCHA again.
          // ...
        },
      },
      authentication
    );
  };
  const onSubmit = (data) => {
    // console.log(data.phoneNumber);
    data.gender = gender;
    setPhoneNumber(data.phoneNumber);
    setFormData(data);
    data.phoneNumber = data?.phoneNumber
      .replace(/^0/, "")
      .trim()
      .replace(/\s/g, "");
    if (checkpasswordType <= 2) {
      setLogin({ phoneNumber: data.phoneNumber, password: data.password });
      axios
        .post("api/UsersAuth/SignUp", data)
        .then((r) => {
          Cookies.set("webAuth", r.data.token, { expires: 7 });
          Cookies.set("Customer", JSON.stringify(r.data), { expires: 7 });
          // end axios
          genarateRecaptcha();
          const appVerifier = window.recaptchaVerifier;
          console.log(data.phoneNumber);
          signInWithPhoneNumber(
            authentication,
            `+964${data.phoneNumber}`,
            appVerifier
          )
            .then((confirmationResult) => {
              console.log("message sent.");
              // SMS sent. Prompt user to type the code from the message, then sign the
              // user in with confirmationResult.confirm(code).
              window.confirmationResult = confirmationResult;
              // ...
            })
            .catch((error) => {
              // Error; SMS not sent
              // ...
              console.log(error);
              // console.log(error, `+964${data.phoneNumber}`);
            });
          // setShowModal(true);
          navigate("/shop");

        })
        .catch((e) => {
          console.log(e.response.data);
          if (
            e.response.data.message === "email or phone number is already taken"
          ) {
            setRegiserStatus(true);
            const login = {};
            data.phoneNumber = data?.phoneNumber
              .replace(/^0/, "")
              .trim()
              .replace(/\s/g, "");
            login.phoneNumber = data.phoneNumber;
            login.password = data.password;
          }
        });
    }

    setRegiserStatus(false);

    // console.log("password not match");
  };
  useEffect(() => {
    // console.log(password, confirmPassword);
    if (
      (password === "" || password === null) &&
      (confirmPassword === "" || confirmPassword === null)
    ) {
      setCheckPassword(false);
    } else {
      setShowCheck(true);
      if (password === confirmPassword) {
        setCheckPassword(true);
        // console.log(checkPassword);
      } else {
        setCheckPassword(false);
        // console.log(checkPassword);
      }
    }
  }, [password, confirmPassword]);
  const [num1, setNum1] = useState("");
  const [num2, setNum2] = useState("");
  const [num3, setNum3] = useState("");
  const [num4, setNum4] = useState("");
  const [num5, setNum5] = useState("");
  const [num6, setNum6] = useState("");

  const handleNumChange = (event) => {
    const limit = 1;
    if (event.target.value !== null) {
      setNum1(event.target.value.slice(0, limit));
    }
  };
  const handleNumChange2 = (event) => {
    const limit = 1;
    if (event.target.value !== null) {
      setNum2(event.target.value.slice(0, limit));
    }
  };
  const handleNumChange3 = (event) => {
    const limit = 1;
    if (event.target.value !== null) {
      setNum3(event.target.value.slice(0, limit));
    }
  };
  const handleNumChange4 = (event) => {
    const limit = 1;
    if (event.target.value !== null) {
      setNum4(event.target.value.slice(0, limit));
    }
  };
  const handleNumChange5 = (event) => {
    const limit = 1;
    if (event.target.value !== null) {
      setNum5(event.target.value.slice(0, limit));
    }
  };
  const handleNumChange6 = (event) => {
    const limit = 1;
    if (event.target.value !== null) {
      setNum6(event.target.value.slice(0, limit));
    }
  };
  const verifyOTP = () => {
    if (otp.length === 6) {
      let confirmationResult = window.confirmationResult;

      confirmationResult.confirm(otp).then((result) => {
        // User signed in successfully.
        const user = result.user;
        user
          .getIdToken()
          .then((r) => {
            // setToken = r;
            // console.log(user);
            setUser(user);
            const set = {};
            // console.log("phone number ", phoneNumber);
            set.phoneNumber = phoneNumber;
            set.firebaseUserIdToken = r;
            // console.log("tokennnnn::::::::::", set.firebaseUserIdToken);
            const config = {
              headers: {
                Authorization: `Bearer ${Cookies.get("webAuth")}`,
              },
            };
            axios
              .post("api/UsersAuth/ConfirmPhoneNumber", set, config)
              .then((r) => {
                setSuccessRegister(true);
                localStorage.removeItem("_grecaptcha");
                // console.log(r);
                setTimeout(() => {
                  //   navigate("/shop");
                  axios
                    .post("/api/UsersAuth/LogIn", login)
                    .then((r) => {
                      // console.log(r);
                      if (r.status === 200) {
                        Cookies.set("webAuth", r.data.token, { expires: 7 });
                        Cookies.set("Customer", JSON.stringify(r.data), {
                          expires: 7,
                        });

                        dispatch(setAuth(true));
                        navigate("/shop");
                      }
                      if (
                        r.status === 401 &&
                        r.data === "email or password is wrong"
                      ) {
                        // setSnackBar(true);
                        // setMessage("emailOrPasswordIncorrect");
                      }
                      if (r.status === 400) {
                        // setSnackBar(true);
                        // setMessage("tooManyRequestsForLogin");
                      }
                    })
                    .catch((e) => {
                      if (
                        e.status === 401 &&
                        e.data === "email or password is wrong"
                      ) {
                        // setSnackBar(true);
                        // setMessage("emailOrPasswordIncorrect");
                      } else {
                        // console.log(e);
                        // setSnackBar(true);
                        // setMessage("userDoseNotExist");
                      }
                    });
                }, 1500);
              })
              .catch((e) => console.log(e));
            // ...
          })
          .catch((error) => {
            // User couldn't sign in (bad verification code?)
            // ...
          });
      });
    }
  };

  // useEffect(() => {
  //   if (num1 !== "") {
  //     console.log("check 1");
  //     setAutoFocus(2);
  //     console.log(autoFoucs);
  //     setAutoFocus1(false);
  //     setAutoFocus2(true);
  //   }
  //   if (num2 !== "") {
  //     setAutoFocus(3);
  //     console.log("check 2");
  //     console.log(autoFoucs);
  //   }
  //   if (num3 !== "") {
  //     setAutoFocus(4);
  //     console.log("check 3");
  //     console.log(autoFoucs);
  //   }
  //   if (num4 !== "") {
  //     setAutoFocus(5);
  //     console.log("check 4");
  //     console.log(autoFoucs);
  //   }
  //   if (num5 !== "") {
  //     setAutoFocus(6);
  //     console.log("check 5");
  //     console.log(autoFoucs);
  //   }
  // }, [num1, num2, num3, num4, num5]);

  // // const auth = getAuth();
  // window.recaptchaVerifier = new RecaptchaVerifier(
  //   "sign-in-button",
  //   {
  //     size: "invisible",
  //     callback: (response) => {
  //       // reCAPTCHA solved, allow signInWithPhoneNumber.
  //       // onSignInSubmit();
  //       // onSubmit(data);
  //     },
  //   },
  //   auth
  // );

  const { t } = useTranslation();
  const locale = localStorage.getItem("i18nextLng");
  const handleClose = () => {
    setShowModal(false);
  };
  const inputStyles = {
    border: "1px solid #2f3e46",
    borderRadius: "8px",
    width: "54px",
    height: "54px",
    fontSize: "16px",
    color: "#2f3e46",
    fontWeight: "400",
    caretColor: "pink",
    dir: "ltr",
    direction: "ltr",
    unicodeBidi: "bidi-override",
  };

  if (window.innerWidth <= 600) {
    inputStyles.width = "40px";
    inputStyles.height = "40px";
  }

  const responsiveInputStyles = {
    "@media only screen and (maxWidth: 600px)": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontSize: "14px",
    },
    "@media only screen and (maxWidth: 600px) input": {
      width: "35px",
      height: "35px",
      fontSize: "18px",
    },
  };
  const combinedStyles = { ...inputStyles, ...responsiveInputStyles };

  return (
    <div className="p-4 mx-auto mt-4 mb-8 rounded-lg md:w-3/4 bg-gray-50">
      <BreadCrumb Name={"register"} Iconn={UserPlusIcon} className="m-3" />
      <div className="mt-10 sm:mt-0">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="">
            <div className="w-full px-4 py-10 text-white rounded-3xl bg-gradient-to-t from-pink-700 via-pink-700 to-pink-600 sm:px-10 ">
              <p
                className={`mb-10 font-bold tracking-wider text-center text-lg ${locale}`}
              >
                <img
                  src={data[0]?.imageUrl}
                  className="inline object-cover w-16 h-16 mx-2 rounded-full"
                />{" "}
                {/* {t("varyPharmacy")} */}
                {locale === "en-US" && data[0]?.englishName}
                {locale === "ar" && data[0]?.arabicName}
                {locale === "ku" && data[0]?.kurdishName}{" "}
              </p>
              <p
                className={`mb-4 text-3xl font-bold md:text-4xl md:leading-snug ${locale}`}
              >
                {/* {t("loginPageMessage")} */}
                {/* {locale === "en-US" && data[0]?.englishName}
                {locale === "ar" && data[0]?.arabicName}
                {locale === "ku" && data[0]?.kurdishName}{" "} */}
              </p>
              <p className={`mb-28 leading-relaxed text-gray-200 ${locale}`}>
                {t("loginPageMessageDetail")}
              </p>
              <div className="px-4 py-8 bg-pink-600/80 rounded-2xl">
                <div className="flex items-center">
                  {/* <img
                    src={data[0]?.imageUrl}
                    className="object-cover w-16 h-16 rounded-full"
                  /> */}
                  <p className={`${locale === "en-US" ? "ml-4" : "mr-4"} w-56`}>
                    {locale === "en-US" ? (
                      <>
                        <span
                          className={` text-gray-200 font-medium ${locale}`}
                        >
                          {" "}
                          {/* <strong className={`block font-semibold text-md ${locale}`}> */}
                          {t("varyPharmacy")}
                          {/* </strong> */}
                          {/* {t("vary")}{" "} */}
                        </span>
                      </>
                    ) : (
                      <>
                        <span
                          className={`text-xs text-gray-200 font-medium ${locale}`}
                        >
                          {" "}
                          {/* {t("pharmacy")}{" "} */}
                        </span>
                        <strong className={`block font-medium ${locale}`}>
                          {/* {t("vary")} */}
                          {locale === "en-US" && data[0]?.englishName}
                          {locale === "ar" && data[0]?.arabicName}
                          {locale === "ku" && data[0]?.kurdishName}{" "}
                        </strong>
                      </>
                    )}
                  </p>
                </div>
                {/* <p
                  className={`mt-3 text-gray-200 text-lg font-medium ${locale} `}
                >
                  {t("loginMot")}
                </p> */}
              </div>
            </div>
          </div>
          <div className="mt-5 md:col-span-2 md:mt-0">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="overflow-hidden shadow sm:rounded-md">
                <div className="px-4 py-5 bg-white sm:p-6">
                  <div className="grid grid-cols-6 gap-6">
                    <div
                      className={`flex flex-col col-span-6 lg:col-span-3 mb-2  ${
                        errors.firstName && "border-rose-500"
                      } `}
                    >
                      <label
                        className={`block mb-2 text-sm text-gray-600 ${locale}`}
                      >
                        {t("firstName")}
                      </label>
                      <div className={`flex flex-row w-full`}>
                        <input
                          type="text"
                          className={`w-full px-3 py-3 placeholder-gray-300 border border-gray-300 rounded-xl focus:outline-none focus:ring focus:ring-pink-100 focus:border-pink-600 ${
                            errors.firstName &&
                            "focus:ring-rose-100 focus:border-rose-300"
                          } ${locale}`}
                          placeholder={t("firstName")}
                          {...register("firstName", { required: true })}
                        />
                      </div>
                    </div>
                    <div
                      className={`lex flex-col col-span-6 lg:col-span-3 mb-2  ${
                        errors.secondName && "border-rose-500"
                      } `}
                    >
                      <label
                        className={`block mb-2 text-sm text-gray-600 ${locale}`}
                      >
                        {t("secondName")}
                      </label>
                      <div className={`flex relative `}>
                        <input
                          type="text"
                          className={`w-full px-3 py-3 placeholder-gray-300 border border-gray-300 rounded-xl focus:outline-none focus:ring focus:ring-pink-100 focus:border-pink-600 ${
                            errors.secondName &&
                            "focus:ring-rose-100 focus:border-rose-300"
                          } ${locale}`}
                          placeholder={t("secondName")}
                          {...register("secondName", { required: true })}
                        />
                      </div>
                    </div>
                    <div
                      className={`lex flex-col col-span-6 lg:col-span-3 mb-2  ${
                        errors.thirdName && "border-rose-500"
                      } `}
                    >
                      <label
                        className={`block mb-2 text-sm text-gray-600 ${locale}`}
                      >
                        {t("thirdName")}
                      </label>
                      <div className={`flex relative `}>
                        <input
                          type="text"
                          className={`w-full px-3 py-3 placeholder-gray-300 border border-gray-300 rounded-xl focus:outline-none focus:ring focus:ring-pink-100 focus:border-pink-600 ${
                            errors.thirdName &&
                            "focus:ring-rose-100 focus:border-rose-300"
                          } ${locale}`}
                          placeholder={t("thirdName")}
                          {...register("thirdName", { required: true })}
                        />
                      </div>
                    </div>
                    <div
                      className={`lex flex-col col-span-6 lg:col-span-3 mb-2  ${
                        errors.phoneNumber && "border-rose-500"
                      } `}
                    >
                      <label
                        className={`block mb-2 text-sm text-gray-600 ${locale}`}
                      >
                        {t("phoneNumberPlaceholder")}
                      </label>
                      <div className={`flex relative `}>
                        <input
                          type="tel"
                          style={{ direction: "ltr" }}
                          className={`w-full px-3 py-3 placeholder-gray-300 border border-gray-300 rounded-xl focus:outline-none focus:ring focus:ring-pink-100 focus:border-pink-600 ${
                            errors.phoneNumber &&
                            "focus:ring-rose-100 focus:border-rose-300"
                          } ${locale}`}
                          placeholder={t("770(50)000 00 00")}
                          {...register("phoneNumber", {
                            required: true,
                            pattern: /[0-9]{1}/,
                          })}
                        />
                      </div>
                    </div>

                    <div
                      className={`flex flex-col col-span-6 lg:col-span-3 mb-2  ${
                        errors.gender && "border-rose-500"
                      } `}
                    >
                      <label
                        htmlFor="email"
                        className={`block mb-2 text-sm text-gray-600 ${locale}`}
                      >
                        {t("gender")}
                      </label>
                      <div className={`flex relative `}>
                        <div className="flex gap-x-4">
                          <div className="relative flex items-center justify-center px-2 py-3 font-medium text-gray-700 w-36 md:w-40 rounded-xl bg-gray-50">
                            <input
                              className="hidden peer"
                              type="radio"
                              name="radio"
                              id="radio1"
                              onClick={() => setGender(0)}
                              checked={gender === 0}
                            />
                            <label
                              className="absolute top-0 w-full h-full border cursor-pointer peer-checked:border-blue-400 peer-checked:bg-blue-200 rounded-xl"
                              htmlFor="radio1"
                            >
                              {" "}
                            </label>
                            <div
                              className={`peer-checked:border-transparent peer-checked:bg-blue-400 peer-checked:ring-2 absolute ${
                                locale === "en-US" ? "left-4" : "right-4"
                              } h-5 w-5 rounded-full border-2 border-gray-300 bg-gray-200 ring-blue-400 ring-offset-2`}
                            ></div>
                            <span
                              className={`pointer-events-none z-10 ${locale}`}
                            >
                              {t("male")}
                            </span>
                          </div>
                          <div className="relative flex items-center justify-center px-4 py-3 font-medium text-gray-700 w-36 md:w-40 rounded-xl bg-gray-50">
                            <input
                              className="hidden peer"
                              type="radio"
                              name="radio"
                              id="radio3"
                              onClick={() => setGender(1)}
                              checked={gender === 1}
                            />
                            <label
                              className="absolute top-0 w-full h-full border cursor-pointer peer-checked:border-rose-400 peer-checked:bg-rose-200 rounded-xl"
                              htmlFor="radio3"
                            >
                              {" "}
                            </label>
                            <div
                              className={`peer-checked:border-transparent peer-checked:bg-rose-400 peer-checked:ring-2 absolute ${
                                locale === "en-US" ? "left-4" : "right-4"
                              }  h-5 w-5 rounded-full border-2 border-gray-300 bg-gray-200 ring-rose-400 ring-offset-2`}
                            ></div>
                            <span
                              className={`pointer-events-none z-10 ${locale}`}
                            >
                              {t("female")}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`lex flex-col col-span-6 lg:col-span-3 mb-2  ${
                        errors.birthDate && "border-rose-500"
                      } `}
                    >
                      <label
                        className={`block mb-2 text-sm text-gray-600 ${locale}`}
                      >
                        {t("birthDate")}
                      </label>
                      <div className={`flex relative `}>
                        <input
                          type="date"
                          className={`w-full px-3 py-3 placeholder-gray-300 border border-gray-300 rounded-xl focus:outline-none focus:ring focus:ring-pink-100 focus:border-pink-600 ${
                            errors.birthDate &&
                            "focus:ring-rose-100 focus:border-rose-300"
                          } ${locale}`}
                          placeholder={t("birthDate")}
                          {...register("birthDate", { required: true })}
                        />
                      </div>
                    </div>
                    <div
                      className={`lex flex-col col-span-6 lg:col-span-3 mb-2  ${
                        errors.password && "border-rose-500"
                      } `}
                    >
                      <label
                        className={`block mb-2 text-sm text-gray-600 ${locale}`}
                      >
                        {t("password")}
                        <>
                          {" "}
                          {checkpasswordType === 1 ? (
                            <KeyIcon className="inline w-6 text-blue-600" />
                          ) : (
                            ""
                          )}{" "}
                        </>
                        <>
                          {" "}
                          {checkpasswordType === 2 ? (
                            <KeyIcon className="inline w-6 text-pink-400" />
                          ) : (
                            ""
                          )}{" "}
                        </>
                        <>
                          {" "}
                          {checkpasswordType === 3 ? (
                            <KeyIcon className="inline w-6 text-rose-500" />
                          ) : (
                            ""
                          )}{" "}
                        </>
                      </label>
                      <div className={`flex relative `}>
                        <input
                          type={showPassword1 === false ? "password" : "text"}
                          className={`w-full px-3 py-3 placeholder-gray-300 border border-gray-300 rounded-xl focus:outline-none focus:ring focus:ring-pink-100 focus:border-pink-600 ${
                            errors.primaryAddress &&
                            "focus:ring-rose-100 focus:border-rose-300"
                          } ${locale}`}
                          placeholder={t("password")}
                          {...register("password", { required: true })}
                          onChange={(e) => setPassword(e.target.value)}
                        />

                        {showPassword1 === false ? (
                          <EyeSlashIcon
                            onClick={() => setShowPassword1(!showPassword1)}
                            className={`absolute text-rose-500 top-3 ${
                              locale === "en-US" ? "right-3  " : "left-10"
                            } h-6 `}
                          />
                        ) : (
                          <EyeIcon
                            onClick={() => setShowPassword1(!showPassword1)}
                            className={`absolute text-pink-500  top-3 ${
                              locale === "en-US" ? "right-3  " : "left-10"
                            } h-6 `}
                          />
                        )}

                        {checkPassword && showCheck && (
                          <CheckCircleIcon
                            className={`absolute text-pink-500 top-3 ${
                              locale === "en-US" ? "right-10  " : "left-3"
                            } h-6 `}
                          />
                        )}
                        {!checkPassword && showCheck && (
                          <XCircleIcon
                            className={`absolute text-red-500 top-3 ${
                              locale === "en-US" ? "right-10  " : "left-3"
                            } h-6 `}
                          />
                        )}
                      </div>
                    </div>

                    <div
                      className={`lex flex-col col-span-6 lg:col-span-3 mb-2  ${
                        errors.conPassword && "border-rose-500"
                      } `}
                    >
                      <label
                        className={`block mb-2 text-sm text-gray-600 ${locale}`}
                      >
                        {t("passwordConfirmation")}
                        <>
                          {" "}
                          {checkpasswordType === 1 ? (
                            <KeyIcon className="inline w-6 text-blue-600" />
                          ) : (
                            ""
                          )}{" "}
                        </>
                        <>
                          {" "}
                          {checkpasswordType === 2 ? (
                            <KeyIcon className="inline w-6 text-pink-400" />
                          ) : (
                            ""
                          )}{" "}
                        </>
                        <>
                          {" "}
                          {checkpasswordType === 3 ? (
                            <KeyIcon className="inline w-6 text-rose-500" />
                          ) : (
                            ""
                          )}{" "}
                        </>
                      </label>
                      <div className={`flex relative `}>
                        <input
                          type={showPassword1 === false ? "password" : "text"}
                          className={`w-full px-3 py-3 placeholder-gray-300 border border-gray-300 rounded-xl focus:outline-none focus:ring focus:ring-pink-100 focus:border-pink-600 ${
                            errors.primaryAddress &&
                            "focus:ring-rose-100 focus:border-rose-300"
                          } ${locale}`}
                          placeholder={t("password")}
                          {...register("conPassword", { required: true })}
                          onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                        {showPassword1 === false ? (
                          <EyeSlashIcon
                            onClick={() => setShowPassword1(!showPassword1)}
                            className={`absolute text-rose-500 top-3 ${
                              locale === "en-US" ? "right-3  " : "left-10"
                            } h-6 `}
                          />
                        ) : (
                          <EyeIcon
                            onClick={() => setShowPassword1(!showPassword1)}
                            className={`absolute text-pink-500 top-3 ${
                              locale === "en-US" ? "right-3  " : "left-10"
                            } h-6 `}
                          />
                        )}
                        {checkPassword && showCheck && (
                          <CheckCircleIcon
                            className={`absolute text-pink-500 top-3 ${
                              locale === "en-US" ? "right-10  " : "left-3"
                            } h-6 `}
                          />
                        )}
                        {!checkPassword && showCheck && (
                          <XCircleIcon
                            className={`absolute text-red-500 top-3 ${
                              locale === "en-US" ? "right-10  " : "left-3"
                            } h-6 `}
                          />
                        )}
                      </div>
                      {/* <div className="float-right mt-3"></div> */}
                    </div>
                  </div>
                  {checkpasswordType > 2 && (
                    <div className={` text-rose-600 mt-1 text-sm ${locale}`}>
                      <>
                        {" "}
                        <InformationCircleIcon className="inline-flex w-5 mx-1 text-rose-600" />{" "}
                        {t("passwordIsWeek")}
                      </>
                    </div>
                  )}
                </div>
                <div
                  id="recaptcha-container"
                  className="flex justify-center"
                ></div>{" "}
                <div className="px-4 py-3 text-right bg-gray-50 sm:px-6">
                  <button
                    disabled={checkpasswordType > 2 ? true : false}
                    type="submit"
                    className={`w-full md:w-1/3 mx-auto  px-3 py-3 text-lg font-medium text-white duration-100 ease-in-out bg-gradient-to-br from-pink-500 to-pink-600 rounded-xl hover:bg-pink-400 focus:outline-none ${
                      checkpasswordType > 2
                        ? "disabled bg-gradient-to-br from-gray-400 to-gray-600"
                        : ""
                    } ${locale}`}
                  >
                    {t("register")}
                  </button>
                  {/* <!-- Recaptcha Widget will be rendered here --> */}
                </div>
              </div>
            </form>
          </div>
          {succesRegister && <SnackBar />}
          {registerStatus && <RegisterFail />}
          {/* <button onClick={() => setShowModal(!showModal)}>click</button> */}
          {/* -------------------------------OTP---------------------------------- */}
          {showModal && (
            <>
              <Transition
                appear
                show={showModal}
                as={Fragment}
                disableBackdropClick={true}
              >
                <Dialog
                  as="div"
                  className="relative z-10 "
                  open={showModal}
                  // onClose={handleClose}
                  onClose={() => {}}
                  disableBackdropClick={true}
                >
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="fixed inset-0 bg-black bg-opacity-25" />
                  </Transition.Child>

                  <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex items-center justify-center min-h-full p-4 text-center">
                      <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                      >
                        <Dialog.Panel className="w-full max-w-xl overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl md:p-6 rounded-2xl">
                          <div className="flex flex-col justify-center py-8 overflow-hidden ">
                            <span
                              className="fixed top-0 right-0 float-right"
                              onClose={setShowModal}
                            >
                              <XCircleIcon
                                onClose={setShowModal}
                                onClick={() => setShowModal(false)}
                                className="inline w-8 p-1 duration-500 rounded-full hover:text-white text-rose-600 hover:bg-rose-600"
                              />
                            </span>
                            <div className="relative w-full max-w-xl p-0 pt-10 mx-auto bg-white shadow-xl md:px-6 pb-9 rounded-2xl">
                              <div className="flex flex-col w-full max-w-3xl mx-auto space-y-16">
                                <div className="flex flex-col items-center justify-center space-y-2 text-center">
                                  <div className="text-3xl font-semibold">
                                    <p className={locale}>
                                      {t("accountVerification")}
                                    </p>
                                  </div>
                                  <div className="flex flex-row text-sm font-medium text-gray-400">
                                    <p className={`${locale}`}>
                                      {t("weHaveSendMessageForYourPhoneNumber")}{" "}
                                    </p>
                                  </div>
                                  <Player
                                    src={loaderAnim}
                                    autoplay
                                    loop
                                    className="w-56 player"
                                  />
                                </div>
                                <div>
                                  <form onSubmit={handleSubmit(verifyOTP)}>
                                    <div className="flex flex-col space-y-16">
                                      <div className="flex flex-row items-center justify-between w-full mx-auto max-w-7xl">
                                        <span className="w-full mx-auto">
                                          <OtpInput
                                            value={otp}
                                            onChange={handleChange}
                                            numInputs={6}
                                            separator={
                                              <span
                                                style={{ width: "8px" }}
                                              ></span>
                                            }
                                            isInputNum={true}
                                            shouldAutoFocus={true}
                                            className="items-center justify-center mx-1 md:mx-2"
                                            inputStyle={combinedStyles}
                                            focusStyle={{
                                              border: "1px solid #CFD3DB",
                                              outline: "none",
                                            }}
                                          />
                                        </span>
                                      </div>
                                      <div className="flex flex-col space-y-5">
                                        <div className="flex items-center justify-center">
                                          <button
                                            type="submit"
                                            id="submit"
                                            className={`flex flex-row items-center w-3/4  justify-center md:w-full py-5 text-sm text-center text-white bg-pink-600 border border-none shadow-sm outline-none rounded-xl ${locale}`}
                                          >
                                            {t("verifyAccount")}
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </form>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Dialog.Panel>
                      </Transition.Child>
                    </div>
                  </div>
                </Dialog>
              </Transition>
            </>
          )}
        </div>
      </div>
      <style></style>
    </div>
  );
};

export default RegisterForm;
