import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  CheckCircleIcon,
  PencilIcon,
  PlusIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { PlusCircleIcon } from "@heroicons/react/24/outline";
import { XCircleIcon } from "@heroicons/react/24/outline";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import axios from "axios";
import Toast from "../../components/Cards/Toast";
import { getCategory } from "../../../features/Global/Dashboard/Category";
import { getSubCategory } from "../../../features/Global/Dashboard/SubCategory";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";

export default function SlideOver({ Title, open, setOpen, chilren, id }) {
  const [checkPassword, setCheckPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [toast, setToast] = useState(false);
  const [error, setError] = useState(false);
  const [disable, setDisable] = useState(false);
  const [message, setMessage] = useState("");
  const dispatch = useDispatch();
  const category = useSelector((state) => state.categoryDashboard.data);
  useEffect(() => {
    dispatch(getCategory());
    dispatch(getSubCategory());
  }, []);
  const config = {
    headers: {
      Authorization: `Bearer ${Cookies.get("dashboardToken")}`,
    },
  };
  useEffect(() => {
    axios.get(`api/controlPane/SubCategory/${id}`, config).then((r) => {
      setValue("englishSubCategory", r.data.englishSubCategory, {
        shouldDirty: true,
      });
      setValue("arabicSubCategory", r.data.arabicSubCategory, {
        shouldDirty: true,
      });
      setValue("kurdishSubCategory", r.data.kurdishSubCategory, {
        shouldDirty: true,
      });
      setValue("categoryId", r.data.categoryId, { shouldDirty: true });
      setValue("index", r.data.index, { shouldDirty: true });
    });
  }, [id]);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
  } = useForm();
  const onSubmit = (data) => {
    setDisable(true);
    const formData = new FormData();
    formData.append("CategoryId", data.categoryId);
    formData.append("EnglishSubCategory", data.englishSubCategory);
    formData.append("ArabicSubCategory", data.arabicSubCategory);
    formData.append("KurdishSubCategory", data.kurdishSubCategory);
    formData.append("Index", data.index);
    formData.append("File", file);
    formData.append("Id", id);
    axios
      .put("api/SubCategories", formData, config)
      .then((r) => {
        setDisable(false);
        if (r.status === 200 || r.status === 204) {
          setToast(true);
          dispatch(getSubCategory());
          dispatch(getCategory());
        }
      })
      .catch((e) => {
        setDisable(false);
        // console.log(e.response.data);
        setMessage(e.response.data);
        setError(true);
        dispatch(getSubCategory());
        dispatch(getCategory());
      });
  };
  const [file, setFile] = useState();

  const [preview, setPreview] = useState();
  const handleFileSelect = (e) => {
    if (e.target.files[0].type.startsWith("image/")) {
      setFile(e.target.files[0]);
      const objectUrl = URL.createObjectURL(e.target.files[0]);
      setPreview(objectUrl);
    } else {
      setMessage("Please select an image!");
      setError(true);
    }
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="fixed inset-y-0 right-0 flex max-w-full pl-10 pointer-events-none">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="relative w-screen max-w-md pointer-events-auto">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-500"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-500"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-0 right-0 flex pt-4 pr-2 -ml-8 sm:-ml-10 sm:pr-4">
                      <button
                        type="button"
                        className="text-gray-300 rounded-md hover:text-white focus:outline-none focus:ring-2 "
                        onClick={() => setOpen(false)}
                      >
                        <span className="sr-only">Close panel</span>
                        <XMarkIcon
                          className="w-6 h-6 my-3 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="flex flex-col h-full py-6 overflow-y-scroll bg-white shadow-xl dark:bg-gray-800">
                    <div className="inline px-4 py-6 -mt-6 bg-blue-600 rounded-sm sm:px-6 dark:bg-blue-700">
                      <Dialog.Title className="pt-4 -mt-4 text-xl font-light text-white border border-transparent rounded-md ">
                        <PencilIcon className="inline w-6 h-6" /> {Title}
                      </Dialog.Title>
                    </div>
                    {/* <hr className="mx-1 text-gray-300 dark:text-blue-700" /> */}
                    <div className="relative flex-1 px-4 mt-2 sm:px-6">
                      {/* Replace with your content */}

                      <div className="absolute inset-0 px-4 sm:px-6 ">
                        {/* <!-- component --> */}
                        <div className="">
                          {/* <!-- Author: FormBold Team --> */}
                          {/* <!-- Learn More: https://formbold.com --> */}
                          <div className="mx-auto w-full max-w-[550px] bg-white dark:bg-gray-800">
                            <form
                              className="px-1 py-6"
                              onSubmit={handleSubmit(onSubmit)}
                            >
                              <div className="pt-4 mb-6">
                                <label className="mb-5 block text-xl font-semibold text-[#07074D] dark:text-gray-300">
                                  Upload Image
                                </label>

                                <div className="mb-8">
                                  <input
                                    type="file"
                                    name="file"
                                    id="file"
                                    className="sr-only"
                                    onChange={(e) => handleFileSelect(e)}
                                  />
                                  <label
                                    htmlFor="file"
                                    className="relative flex min-h-[200px] items-center justify-center rounded-md border border-dashed border-[#e0e0e0] p-12 text-center"
                                  >
                                    <div>
                                      {/* <span className="mb-2 block text-xl font-semibold text-[#07074D] dark:text-gray-300">
                                        Drop files here
                                      </span> */}
                                      <span className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-300">
                                        {preview && (
                                          <img
                                            src={preview}
                                            alt="image"
                                            className="mx-auto rounded-lg "
                                          />
                                        )}
                                      </span>
                                      <span className="inline-flex rounded border border-[#e0e0e0] py-2 px-7 text-base font-medium text-[#07074D] dark:text-gray-300">
                                        Browse
                                      </span>
                                    </div>
                                  </label>
                                </div>
                              </div>{" "}
                              <div className="mb-5">
                                <label className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-300">
                                  Category
                                </label>
                                <select
                                  {...register("categoryId", {
                                    required: true,
                                  })}
                                  placeholder="Select category"
                                  className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white border ${
                                    errors.categoryId && "border-rose-500"
                                  } bg-white py-2  text-base font-medium text-gray-700 outline-none focus:border-blue-500 focus:shadow-md dark:bg-gray-700 dark:text-gray-300`}
                                >
                                  {category.map((item, index) => (
                                    <option
                                      key={index}
                                      value={item.id}
                                      className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white border ${
                                        errors.categoryId && "border-rose-500"
                                      } bg-white py-2  text-base font-medium text-gray-700 outline-none focus:border-blue-500 focus:shadow-md dark:bg-gray-700 dark:text-gray-300`}
                                    >
                                      {item.category.english}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              <div className="mb-5">
                                <label className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-300">
                                  English Name:
                                </label>
                                <input
                                  type="text"
                                  placeholder="category name english"
                                  {...register("englishSubCategory", {
                                    required: true,
                                  })}
                                  className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white border ${
                                    errors.englishSubCategory &&
                                    "border-rose-500"
                                  } bg-white py-2 px-6 text-base font-medium text-gray-700 outline-none focus:border-blue-500 focus:shadow-md dark:bg-gray-700 dark:text-gray-300`}
                                />
                              </div>
                              <div className="mb-5">
                                <label className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-300">
                                  Arabic Name:
                                </label>
                                <input
                                  type="text"
                                  placeholder="category name arabic"
                                  {...register("arabicSubCategory", {
                                    required: true,
                                    pattern: /[\u0600-\u06FF]{1}/,
                                  })}                                   style={{ unicodeBidi: "plaintext" }}

                                  className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white border ${
                                    errors.arabicSubCategory &&
                                    "border-rose-500"
                                  } bg-white py-2 px-6 text-base font-medium text-gray-700 outline-none focus:border-blue-500 focus:shadow-md dark:bg-gray-700 dark:text-gray-300`}
                                />
                              </div>{" "}
                              <div className="mb-5">
                                <label className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-300">
                                  Kurdish Name:
                                </label>
                                <input
                                  type="text"
                                  placeholder="category name kurdish"
                                  {...register("kurdishSubCategory", {
                                    required: true,
                                    pattern: /[\u0600-\u06FF]{1}/,
                                  })}                                   style={{ unicodeBidi: "plaintext" }}

                                  className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white border ${
                                    errors.kurdishSubCategory &&
                                    "border-rose-500"
                                  } bg-white py-2 px-6 text-base font-medium text-gray-700 outline-none focus:border-blue-500 focus:shadow-md dark:bg-gray-700 dark:text-gray-300`}
                                />
                              </div>
                              <div className="mb-5">
                                <label className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-300">
                                  Index:
                                </label>
                                <input
                                  type="number"
                                  placeholder="category index"
                                  {...register("index", {
                                    required: true,
                                    pattern: /[0-9]{1}/,
                                  })}
                                  className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-secondary-500 focus:border-secondary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white border ${
                                    errors.index && "border-rose-500"
                                  } bg-white py-2 px-6 text-base font-medium text-gray-700 outline-none focus:border-secondary-600 focus:shadow-md dark:bg-gray-700 dark:text-gray-300`}
                                />
                              </div>
                              <div className="float-right">
                                <button
                                  type="submit"
                                  disabled={disable}
                                  className={
                                    "bg-blue-100 mx-1 px-6 py-2 rounded-md text-blue-600 hover:bg-blue-200 hover:text-blue-700 border border-transparent focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent dark:text-blue-100 dark:bg-blue-600"
                                  }
                                >
                                  <PencilIcon className="inline w-5 h-5 mx-2" />
                                  Update
                                </button>
                                <button
                                  onClick={() => setOpen(false)}
                                  type="button"
                                  className={
                                    "bg-rose-100 mx-1 px-4 py-2 rounded-md text-rose-600 hover:bg-rose-200 hover:text-rose-700 border border-transparent focus:outline-none focus:ring-2 focus:ring-rose-500 focus:border-transparent dark:text-rose-100 dark:bg-rose-600"
                                  }
                                >
                                  <XCircleIcon className="inline w-6 h-6 mx-2" />
                                  Cancel
                                </button>
                              </div>
                            </form>
                          </div>
                        </div>
                        {toast && (
                          <Toast
                            Text={"Sub Category Updated Successfully"}
                            Type={"blue"}
                            setOpen={setToast}
                          />
                        )}
                        {error && (
                          <Toast
                            Text={message}
                            Type={"rose"}
                            setOpen={setError}
                          />
                        )}

                        {/* <div className=F"h-full border-2 border-gray-200 border-dashed" aria-hidden="true" /> */}
                      </div>
                      {/* {/* /End replace */}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
