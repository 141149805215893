import React, { useEffect } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useDispatch, useSelector } from "react-redux";
import { getCarousel } from "../../../features/Global/Carousel";
import style from "./arrowStyle.css";
import $ from "jquery";
import { Link } from "react-router-dom";
const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 3, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 2, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};

const Slider = () => {
  const dispatch = useDispatch();
  const images = useSelector((state) => state.Carousel.data);
  const isSuccess = useSelector((state) => state.Carousel.isSuccess);
  const l = localStorage.getItem("i18nextLng");
  useEffect(() => {
    dispatch(getCarousel());
    $(
      ".react-multiple-carousel__arrow react-multiple-carousel__arrow--left rtl"
    ).addClass("z-10");
  }, []);

  function extractRelativePath(absoluteUrl) {
    const match = absoluteUrl.match(/\/\/[^\/]+(\/.*)/);
    if (match) {
      return match[1];
    }
  }
  return (
    <Carousel
      additionalTransfrom={0}
      autoPlay
      arrows
      autoPlaySpeed={4000}
      centerMode={false}
      className="z-10 min-w-full"
      containerClass=""
      dotListClass=""
      draggable
      focusOnSelect={false}
      infinite
      itemClass=""
      keyBoardControl
      minimumTouchDrag={80}
      pauseOnHover
      renderArrowsWhenDisabled={true}
      renderButtonGroupOutside={true}
      renderDotsOutside={false}
      responsive={{
        desktop: {
          breakpoint: {
            max: 3000,
            min: 1024,
          },
          items: 1,
        },
        mobile: {
          breakpoint: {
            max: 464,
            min: 0,
          },
          items: 1,
        },
        tablet: {
          breakpoint: {
            max: 1024,
            min: 464,
          },
          items: 1,
        },
      }}
      rewind={false}
      rewindWithAnimation={false}
      rtl={true}
      shouldResetAutoplay
      showDots
      sliderClass=""
      slidesToSlide={1}
      swipeable
    >
      {isSuccess &&
        images
          ?.filter((item) => item?.status)
          ?.map((image, index) => (
            <Link
              to={extractRelativePath(
                image?.webLinkWithFilters !== null
                  ? image?.webLinkWithFilters
                  : image?.webLink
              )}
            >
              <img
                src={
                  image?.imageUrl?.[
                    l === "ku" ? "kurdish" : l === "ar" ? "arabic" : "english"
                  ]
                }
                alt={
                  image?.imageUrl?.[
                    l === "ku" ? "kurdish" : l === "ar" ? "arabic" : "english"
                  ]
                }
                key={index}
                className="min-w-full m-0"
              />
            </Link>
          ))}
    </Carousel>
  );
};

export default Slider;
