import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: false,
};

export const SidebarSlice = createSlice({
  name: "Sidebar",
  initialState,
  reducers: {
    increment: (state) => {
      state.value = !state.value;
    },
    // getSidebar: (state) => {
    //   return state.value;
    // },
    incrementByAmount: (state, action) => {
      state.value += action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { increment, incrementByAmount } = SidebarSlice.actions;

export default SidebarSlice;
