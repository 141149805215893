import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  CheckCircleIcon,
  ChevronRightIcon,
  PlusIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { PlusCircleIcon } from "@heroicons/react/24/outline";
import { XCircleIcon } from "@heroicons/react/24/outline";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import axios from "axios";
import Toast from "../../components/Cards/Toast";
// redux mapping
import { useDispatch, useSelector } from "react-redux";
import { getSubCategory } from "../../../features/Global/SubCategory";
import { getBrand } from "../../../features/Global/Brands";
import { getItem } from "../../../features/Global/Items";
import { TextareaAutosize } from "@mui/material";
import Cookies from "js-cookie";
import Parser from "html-react-parser";
export default function SlideOver({ Title, open, setOpen, id }) {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm();
  const handleCheckOfferTypes = (value) => {
    if (value === 0) {
      return "No Offer";
    }
    if (value === 1) {
      return "Buy one get one";
    }
    if (value === 2) {
      return "Percentage discount";
    }
    //  else {
    //   return "Buy one get some percenteage of";
    // }
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-40" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="fixed inset-y-0 right-0 flex max-w-full pl-10 pointer-events-none">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="relative w-screen max-w-md pointer-events-auto">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-500"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-500"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-0 right-0 flex pt-4 pr-2 -ml-8 sm:-ml-10 sm:pr-4">
                      <button
                        type="button"
                        className="text-gray-300 rounded-md hover:text-white focus:outline-none focus:ring-2 "
                        onClick={() => setOpen(false)}
                      >
                        <span className="sr-only">Close panel</span>
                        <XMarkIcon
                          className="w-6 h-6 my-3 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="flex flex-col h-full py-6 overflow-y-scroll bg-white shadow-xl dark:bg-gray-800">
                    <div className="inline px-4 py-6 -mt-6 bg-orange-600 rounded-sm sm:px-6 dark:bg-orange-600">
                      <Dialog.Title className="pt-4 -mt-4 text-xl font-light text-white border border-transparent rounded-md ">
                        <PlusCircleIcon className="inline w-6 h-6" /> {Title}
                      </Dialog.Title>
                    </div>
                    <div className="relative flex-1">
                      {/* Replace with your content */}
                      <div className="absolute inset-0">
                        <div className="p-3 m-3">
                          <div className="flex flex-row py-1">
                            <div className="flex-1">
                              <p className="text-xl font-bold ">
                                {/* {data?.englishTitle} */}
                                Vary News
                              </p>
                              <img src={id?.fileName} alt=""  className="w-full"/>
                            </div>
                            <div className="mt-auto"></div>
                          </div>

                          <div className="py-3 border-gray-200 ">
                            <div className="flex flex-col justify-between my-2">
                              <p className="font-semibold text-gray-400 ">
                                {id?.englishTitle}{" "}
                              </p>
                              <p className="text-sm font-semibold text-gray-900">
                                {id?.englishBody && Parser(id?.englishBody)}
                              </p>
                            </div>
                          </div>
                          <div className="py-3 border-gray-200 ">
                            <div className="flex flex-col justify-between my-2">
                              <p className="font-semibold text-gray-400 ">
                                {id?.arabicTitle}{" "}
                              </p>
                              <p className="text-sm font-semibold text-gray-900">
                                {id?.arabicBody && Parser(id?.arabicBody)}
                              </p>
                            </div>
                          </div>
                          <div className="py-3 border-gray-200 ">
                            <div className="flex flex-col justify-between my-2">
                              <p className="font-semibold text-gray-400 ">
                                {id?.arabicTitle}{" "}
                              </p>
                              <p className="text-sm font-semibold text-gray-900">
                                {id?.kurdishBody && Parser(id?.kurdishBody)}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* </div> */}
                      {/* /End replace */}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
