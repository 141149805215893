import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  CheckCircleIcon,
  MapPinIcon,
  PencilSquareIcon,
  PlusIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { PlusCircleIcon } from "@heroicons/react/24/outline";
import { XCircleIcon } from "@heroicons/react/24/outline";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import axios from "axios";
// import Toast from "../../components/Cards/Toast;
import { getNews } from "../../../features/Global/News";
import { useDispatch } from "react-redux";
import { TextareaAutosize } from "@mui/material";
import { useTranslation } from "react-i18next";
import { getAddresses } from "../../../features/Global/Address";
import verify from "../../assets/icons/Verify.svg";
import NoOrder from "../../assets/icons/NoOrder.svg";
import Cookies from "js-cookie";

export default function SlideOver({
  deleteAccount,
  open,
  setOpen,
  chilren,
  user,
}) {
  const [toast, setToast] = useState(false);
  const [error, setError] = useState(false);
  const [message, setMessage] = useState("");
  const { t } = useTranslation();
  const l = localStorage.getItem("i18nextLng");
  const dispatch = useDispatch();
  const [file, setFile] = useState();
  const [preview, setPreview] = useState();
  const handleFileSelect = (e) => {
    setFile(e.target.files[0]);
    const objectUrl = URL.createObjectURL(e.target.files[0]);
    setPreview(objectUrl);
  };
  const config = {
    headers: {
      Authorization: `Bearer ${Cookies.get("webAuth")}`,
      "Content-Type": "application/json",
    },
  };
  // const {
  //   register,
  //   handleSubmit,
  //   watch,
  //   setValue,
  //   formState: { errors },
  // } = useForm();
  // useEffect(() => {
  //   setValue("addressTitle", id.addressTitle);
  //   setValue("city", id.city);
  //   setValue("streetName", id.streetName);
  //   setValue("phoneNumber", id.phoneNumber);
  //   setValue("buildingName", id.buildingName);
  //   setValue("buildingFloor", id.buildingFloor);
  //   setValue("appartmentNumber", id.appartmentNumber);
  //   setValue("detailedAdderess", id.detailedAdderess);
  // }, []);
  // const config = {
  //   headers: {
  //     Authorization: `Bearer ${Cookies.get('webAuth')}`,
  //   },
  // };
  // const onSubmit = (data) => {
  //   data.oldId = id?.id;

  //   axios
  //     .put("api/OrderAddresses", data, config)
  //     .then((r) => {
  //       // console.log(r);
  //       if (r.status === 200 || r.status === 204) {
  //         setToast(true);
  //         // console.log(r);
  //         dispatch(getAddresses());
  //       }
  //     })
  //     .catch((e) => {
  //       console.log(e.response.status);
  //       console.log(e.response.data);
  //       setMessage(e.response.data);
  //       setError(true);
  //     });
  //   setToast(false);
  // };
  const handleDelete = () => {
    axios.delete('/api/users', config)
      .then((r) => {
        setToast(true);
        Cookies.remove("webAuth");
        Cookies.remove("Customer");
        // Refresh the page by navigating to the root URL
        window.location.href = "/";
      })
      .catch((error) => {
        // Handle any errors here
        console.error("Error: ", error);
      });
  }
  
  var sum = 0;

  const handleSum = (data) => {
    sum = data?.reduce((a, v) => (a = a + v.currentPrice), 0);
    return sum;
  };
  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog as="div" className="relative z-40" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-full p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-lg overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <div className="py-10 text-center text-white bg-gradient-to-br from-rose-600 to-rose-500">
                  <p className={`${l} text-2xl font-semibold`}>
                    {/* <MapPinIcon className="inline mx-2 w-7 " />{" "} */}
                    {t("deleteAccount")}
                  </p>
                </div>
                <div className="flex flex-col items-stretch justify-center w-full space-y-4 md:flex-row md:space-y-0 md:space-x-6 xl:space-x-8">
                  <span className={`p-2 ${l}`}>
                    {t("areYouSureDeleteYourAccount?")}
                  </span>
                </div>
                <div className="flex justify-around m-4">
                  <button
                    onClick={() => setOpen(false)}
                    className={`w-full px-10 py-3 text-center transition duration-500 rounded-lg shadow-xl text-gray-50 bg-gradient-to-br from-gray-400 to-gray-600 hover:bg-gray-600 active:bg-gray-700 focus:bg-gray-600 sm:w-max hover:scale-y-105 hover:scale-x-110 ${l}`}
                  >
                    {t("cancel")}
                  </button>
                  <button
                    onClick={() => handleDelete()}
                    className={`w-full px-10 py-3 text-center transition duration-500 rounded-lg shadow-xl text-gray-50 bg-gradient-to-br from-rose-400 to-rose-600 hover:bg-rose-600 active:bg-gray-700 focus:bg-rose-600 sm:w-max hover:scale-y-105 hover:scale-x-110 ${l}`}
                  >
                    {t("delete")}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
