import { Fragment } from "react";
import { Dialog, Transition, Tab } from "@headlessui/react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { setAuth } from "../../../features/web/WebAuthentication";
import { useDispatch } from "react-redux";
import FirstForm from "./ForgetPassword/StepperForm/FirstForm";
import SecondForm from "./ForgetPassword/StepperForm/SecondForm";
import ThirdForm from "./ForgetPassword/StepperForm/ThirdForm";
import Cookies from "js-cookie";
import {
  ArrowRightOnRectangleIcon,
  KeyIcon,
  PhoneIcon,
} from "@heroicons/react/24/outline";
import {
  CheckCircleIcon,
  CloudArrowUpIcon,
  TrashIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { PlusCircleIcon } from "@heroicons/react/24/outline";
import { XCircleIcon } from "@heroicons/react/24/outline";

import { useEffect, useState } from "react";
import axios from "axios";
import login from "../../assets/icons/login.svg";
import { useTranslation } from "react-i18next";

export default function SlideOver({ Title, open, setOpen, id }) {
  const formList = ["FirstForm", "SecondForm", "ThirdForm"];

  const formLength = formList.length;

  const [page, setPage] = useState(0);

  const handlePrev = () => {
    setPage(page === 0 ? formLength - 1 : page - 1);
  };
  const handleNext = () => {
    setPage(page === formLength - 1 ? 0 : page + 1);
  };

  const initialValues = {
    name: "",
    lastname: "",
    password: "",
    confirmPassword: "",
    username: "",
    city: "1",
    address: "",
    zip: "",
    terms: "",
  };
  const [values, setValues] = useState(initialValues);

  const handleForms = () => {
    switch (page) {
      case 0: {
        return (
          <div>
            <FirstForm formValues={values} onChange={onChange}></FirstForm>
          </div>
        );
      }
      case 1: {
        return (
          <SecondForm
            formValues={values}
            onChange={onChange}
            option={states}
          ></SecondForm>
        );
      }
      case 2: {
        return <ThirdForm formValues={values} onChange={onChange}></ThirdForm>;
      }
      default:
        return null;
    }
  };

  const states = [
    { id: "0", name: "Paris" },
    { id: "1", name: "London" },
    { id: "2", name: "Berlin" },
    { id: "3", name: "Warsaw" },
  ];

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   const response = await setTimeout(() => {
  //     console.log("form", values);
  //   }, 2000);
  //   return response;
  // };

  const setForm = (e) => {
    const name = e.target.innerText;
    switch (name) {
      case "Person Info": {
        return setPage(0);
      }
      case "Other Info": {
        return setPage(1);
      }
      case "Login Info": {
        return setPage(2);
      }
      default:
        setPage(0);
    }
  };

  const onChange = (e) => {
    const { name, value, type, checked } = e.target;
    setValues({ ...values, [name]: type === "checkbox" ? checked : value });
  };

  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const onSubmit = (data) => {
    ////    console.log(data);

    data.phoneNumber = data?.phoneNumber
      .replace(/^0/, "")
      .trim()
      .replace(/\s/g, "");
    axios.post("/api/UsersAuth/LogIn", data).then((r) => {
      // // console.log(r);
      if (r.status === 200) {
        Cookies.set("webAuth", r.data.token, { expires: 7 });
        Cookies.set("Customer", JSON.stringify(r.data), { expires: 7 });
        dispatch(setAuth(true));
        setOpen(false);
      }
    });
  };
  const l = localStorage.getItem("i18nextLng");
  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10 " onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-full p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-2xl p-6 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <div className="items-center place-content-center h-96 place-items-center ">
                  <ul className="flex w-full justify-evenly">
                    <li
                      onClick={setForm}
                      className={
                        page === 0
                          ? "ring-1 ring-pink-500 ring-offset-2 ring-offset-pink-50 dark:ring-offset-slate-900 w-2/6 rounded-lg  "
                          : "bg: transparent"
                      }
                    >
                      <div className="flex items-center justify-center h-full mx-auto align-middle ">
                        <span className="stepper-head-icon">
                          {" "}
                          {/* <svg
                            className="w-8 h-8 text-gray-50"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            strokeWidth="2"
                            stroke="currentColor"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            {" "}
                            <path stroke="none" d="M0 0h24v24H0z" />{" "}
                            <line x1="12" y1="12" x2="12" y2="12.01" />{" "}
                            <path
                              d="M12 2a4 10 0 0 0 -4 10a4 10 0 0 0 4 10a4 10 0 0 0 4 -10a4 10 0 0 0 -4 -10"
                              transform="rotate(45 12 12)"
                            />{" "}
                            <path
                              d="M12 2a4 10 0 0 0 -4 10a4 10 0 0 0 4 10a4 10 0 0 0 4 -10a4 10 0 0 0 -4 -10"
                              transform="rotate(-45 12 12)"
                            />
                          </svg> */}
                        </span>
                        <span
                          className={` ${l} my-auto items-center text-center
                          ${
                            page === 0
                              ? "ml-2 text-gray-700 font-medium"
                              : "ml-2 text-gray-500 cursor-pointer border border-gray-50"
                          }
                          `}
                        >
                          {t("enterPhoneNumber")}
                        </span>
                      </div>
                    </li>
                    <li
                      onClick={setForm}
                      className={
                        page === 1
                          ? "ring-1 ring-pink-500 ring-offset-2 ring-offset-pink-50 dark:ring-offset-slate-900 w-2/6 rounded-lg  "
                          : "bg: transparent"
                      }
                    >
                      <div className="flex items-center justify-center h-full mx-auto align-middle">
                        <span className="stepper-head-icon">
                          {" "}
                          {/* <svg
                            className="w-8 h-8 text-gray-50"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            strokeWidth="2"
                            stroke="currentColor"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            {" "}
                            <path stroke="none" d="M0 0h24v24H0z" />{" "}
                            <line x1="12" y1="12" x2="12" y2="12.01" />{" "}
                            <path
                              d="M12 2a4 10 0 0 0 -4 10a4 10 0 0 0 4 10a4 10 0 0 0 4 -10a4 10 0 0 0 -4 -10"
                              transform="rotate(45 12 12)"
                            />{" "}
                            <path
                              d="M12 2a4 10 0 0 0 -4 10a4 10 0 0 0 4 10a4 10 0 0 0 4 -10a4 10 0 0 0 -4 -10"
                              transform="rotate(-45 12 12)"
                            />
                          </svg> */}
                        </span>
                        <span
                          className={
                            page === 1
                              ? " text-gray-700 font-medium"
                              : "text-gray-500 cursor-pointer"
                          }
                        >
                          Other Info{" "}
                        </span>
                      </div>
                    </li>
                    <li
                      onClick={setForm}
                      className={
                        page === 2
                          ? "ring-1 ring-pink-500 ring-offset-2 ring-offset-pink-50 dark:ring-offset-slate-900 w-2/6 rounded-lg  "
                          : "bg: transparent"
                      }
                    >
                      <div className="flex items-center justify-center h-full mx-auto align-middle">
                        <span className="stepper-head-icon">
                          {" "}
                          {/* <svg
                            className="w-8 h-8 text-gray-50"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            strokeWidth="2"
                            stroke="currentColor"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            {" "}
                            <path stroke="none" d="M0 0h24v24H0z" />{" "}
                            <line x1="12" y1="12" x2="12" y2="12.01" />{" "}
                            <path
                              d="M12 2a4 10 0 0 0 -4 10a4 10 0 0 0 4 10a4 10 0 0 0 4 -10a4 10 0 0 0 -4 -10"
                              transform="rotate(45 12 12)"
                            />{" "}
                            <path
                              d="M12 2a4 10 0 0 0 -4 10a4 10 0 0 0 4 10a4 10 0 0 0 4 -10a4 10 0 0 0 -4 -10"
                              transform="rotate(-45 12 12)"
                            />
                          </svg> */}
                        </span>
                        <span
                          className={
                            page === 2
                              ? " text-gray-700 font-medium"
                              : " text-gray-500 cursor-pointer"
                          }
                        >
                          {" "}
                          Login Info{" "}
                        </span>
                      </div>
                    </li>
                  </ul>
                  <div className="flex-1 w-full">{handleForms()}</div>
                  <div className="grid items-center gap-4 place-content-center">
                    <button
                      onClick={handlePrev}
                      className="px-4 py-2 font-bold text-gray-800 bg-blue-200 rounded-md hover:bg-gradient-to-br from-pink-600 to-pink-500 disabled:bg-gray-400 "
                      disabled={page === 0}
                    >
                      Prev
                    </button>
                    {page === 2 ? (
                      <button
                        onClick={handleSubmit}
                        className="px-4 py-2 font-bold text-gray-800 bg-blue-200 rounded-md hover:bg-gradient-to-br from-pink-600 to-pink-500 "
                      >
                        Submit
                      </button>
                    ) : (
                      <button
                        onClick={handleNext}
                        className="px-4 py-2 font-bold text-gray-800 bg-blue-200 rounded-md hover:bg-gradient-to-br from-pink-600 to-pink-500 "
                      >
                        Next
                      </button>
                    )}
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
