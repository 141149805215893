import { useSelect } from "@mui/base";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { setAuth } from "../../../features/web/WebAuthentication";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";
import {
  KeyIcon,
  PhoneIcon,
  UserCircleIcon,
} from "@heroicons/react/24/outline";
import { ArrowRightOnRectangleIcon } from "@heroicons/react/24/outline";
import BreadCrumb from "../BreadCrumb";
import { getConfiguration } from "../../../features/Global/Configuration";
import SnackBar from "../Snackbar/SnackBarDynamic";
import ForgotPasswordModal from "./ForgetPasswordModal";
import DynamicSnackBar from "../../componets/Snackbar/SnackBarChangePassword ";
function MyApp() {
  const [snackBar, setSnackBar] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState();
  const locale = localStorage.getItem("i18nextLng");
  const { t } = useTranslation();
  const data = useSelector((state) => state.Configuration?.data);
  useEffect(() => {
    dispatch(getConfiguration());
  }, []);
  // console.log(cookies.get("UID"));
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const onSubmit = (data) => {
    // console.log(data);
    data.firebaseUserIdToken = Cookies.get("UID");
    data.phoneNumber = Cookies.get("phoneNumber");
    // console.log(data);
    // data.phoneNumber=
    axios
      .post("/api/UsersAuth/ForgetPassword", data)
      .then((r) => {
        // // console.log(r);
        if (r.status === 200) {
          // console.log("success");
          setSnackBar(true);
          setMessage("passwordChangedSuccessfully");
          const login = {};
          login.phoneNumber = data?.phoneNumber;
          login.password = data?.newPassword;
          login.Password = data?.newPassword;
          axios
            .post("/api/UsersAuth/LogIn", login)
            .then((res) => {
              if (res.status === 200 || res.status === 204) {
                console.log(res);
                Cookies.set("webAuth", res.data.token);
                Cookies.set("Customer", JSON.stringify(res.data));

                dispatch(setAuth(true));
                navigate("/shop");
              }
              if (
                res.status === 401 &&
                res.data === "email or password is wrong"
              ) {
                setSnackBar(true);
                setMessage("emailOrPasswordIncorrect");
              }
              if (res.status === 400) {
                setSnackBar(true);
                setMessage("tooManyRequestsForLogin");
              }
            })
            .catch((e) => {
              if (e.status === 401 && e.data === "email or password is wrong") {
                setSnackBar(true);
                setMessage("emailOrPasswordIncorrect");
              } else {
                // console.log(e);
                setSnackBar(true);
                setMessage("userDoseNotExist");
              }
            });
        }
      })
      .catch((e) => {
        console.log(e);
        setSnackBar(true);
        setMessage("userDoseNotExist");
      });
    setSnackBar(false);

    // console.log("password not match");
  };
  return (
    <div className="m-0 text-gray-600 bg-white body-font">
      {/* // <div className="container mx-auto my-auto mb-5">
      //   <div className="flex flex-col max-w-md px-4 py-8 mx-auto bg-white rounded-lg shadow w- dark:bg-gray-800 sm:px-6 md:px-8 lg:px-10">
      //     <div className={`self-center mb-6 text-xl font-light text-gray-600 sm:text-2xl dark:text-white ${locale}`}>
      //       {t("login")}{" "}
      //     </div>
      //     <div className="mt-8">
      //       <form onSubmit={handleSubmit(onSubmit)}>
      //         <div className="flex flex-col mb-2">
      //           <div className="relative flex ">
      //             <span
      //               className={`${
      //                 locale === "en-Us" ? "rounded-l-md" : "rounded-r-md"
      //               } inline-flex  items-center px-3 border-t bg-white border-l border-b  border-gray-300 text-gray-500 shadow-sm text-sm`}
      //             >
      //               <PhoneIcon className="w-5 h-5 " />
      //             </span>
      //             <input
      //               type="number"
      //               className={` ${
      //                 locale === "en-US" ? "rounded-r-lg" : "rounded-l-lg"
      //               } flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-pink-600 focus:border-transparent ${
      //                 errors.phoneNumber && "border-rose-500"
      //               }`}
      //               placeholder={t("phoneNumber")}
      //               {...register("phoneNumber", { required: true })}
      //             />
      //           </div>
      //         </div>
      //         <div className="flex flex-col mb-6">
      //           <div className="relative flex ">
      //             <span
      //               className={`${
      //                 locale === "en-Us" ? "rounded-l-md" : "rounded-r-md"
      //               } inline-flex  items-center px-3 border-t bg-white border-l border-b  border-gray-300 text-gray-500 shadow-sm text-sm`}
      //             >
      //               <KeyIcon className="w-5 h-5 " />
      //             </span>
      //             <input
      //               type="password"
      //               className={`${
      //                 locale === "en-US" ? "rounded-r-lg" : "rounded-l-lg"
      //               } flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-pink-600 focus:border-transparent ${
      //                 errors.password && "border-rose-500"
      //               }`}
      //               placeholder={t("password")}
      //               {...register("password", { required: true })}
      //             />
      //           </div>
      //         </div>
      //         <div className="flex items-center mb-6 -mt-4">
      //           <div className="flex ml-auto">
      //             <Link
      //               href="#"
      //               className={`inline-flex text-xs font-thin text-gray-500 sm:text-sm dark:text-gray-100 hover:text-gray-700 dark:hover:text-white ${locale}`}
      //             >
      //               {t("forgotPassword")}
      //             </Link>
      //           </div>
      //         </div>
      //         <div className="flex w-full">
      //           <button
      //             type="submit"
      //             className={`w-full px-4 py-2 text-base font-semibold text-center text-white transition duration-200 ease-in bg-pink-600 rounded-lg shadow-md hover:bg-pink-700 focus:ring-pink-500 focus:ring-offset-pink-200 focus:outline-none focus:ring-2 focus:ring-offset-2  ${locale}`}
      //           >
      //             {t("login")}
      //           </button>
      //         </div>
      //       </form>
      //     </div>
      //     <div className="flex items-center justify-center mt-6">
      //       <Link
      //         to="/register"
      //         className="inline-flex items-center text-xs font-thin text-center text-gray-500 hover:text-gray-700 dark:text-gray-100 dark:hover:text-white"
      //       >
      //         <span className={`ml-2 ${locale}`}>{t("dontHaveAccount")}</span>
      //       </Link>
      //     </div>
      //   </div>
      // </div> */}

      {/* <BreadCrumb Name={"login"} Iconn={ArrowRightOnRectangleIcon} /> */}

      <div className="flex flex-wrap items-center mx-auto ">
        {/* <div className="absolute hidden w-56 h-56 text-indigo-300 sm:block a-z-10 left-50 -top-30">
            <svg
              id="patternId"
              width="100%"
              height="100%"
              xmlns="http://www.w3.org/2000/svg"
            >
              <defs>
                <pattern
                  id="a"
                  patternUnits="userSpaceOnUse"
                  width="40"
                  height="40"
                  patternTransform="scale(0.6) rotate(0)"
                >
                  <rect x="0" y="0" width="100%" height="100%" fill="none" />
                  <path
                    d="M11 6a5 5 0 01-5 5 5 5 0 01-5-5 5 5 0 015-5 5 5 0 015 5"
                    strokeWidth="1"
                    stroke="none"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width="800%"
                height="800%"
                transform="translate(0,0)"
                fill="url(#a)"
              />
            </svg>
          </div> */}
        {/* <div
          className={`lg:w-3/5 ${locale === "en-US" ? "lg:pr-8" : "lg:pl-8"} `}
        >
          <h1
            className={`text-3xl font-bold text-center  py-3 text-transparent bg-clip-text bg-gradient-to-br from-pink-600 to-pink-500 title-font md:text-5xl ${
              locale === "en-US" ? "lg:text-left" : "lg:text-right"
            } ${locale}`}
          >
            {t("loginPageMessage")}
          </h1>
          <p
            className={`mt-4 text-lg font-normal leading-relaxed text-center  text-transparent bg-clip-text bg-gradient-to-br from-gray-800 to-gray-700 md:text-2xl lg:max-w-xl ${
              locale === "en-US" ? "lg:text-left" : "lg:text-right"
            }  ${locale}`}
          >
            <div className="absolute hidden w-56 h-56 text-indigo-300 sm:block -z-10 -left-20 -bottom-30">
              <svg
                id="patternId"
                width="100%"
                height="100%"
                xmlns="http://www.w3.org/2000/svg"
              >
                <defs>
                  <pattern
                    id="a"
                    patternUnits="userSpaceOnUse"
                    width="40"
                    height="40"
                    patternTransform="scale(0.6) rotate(0)"
                  >
                    <rect x="0" y="0" width="100%" height="100%" fill="none" />
                    <path
                      d="M11 6a5 5 0 01-5 5 5 5 0 01-5-5 5 5 0 015-5 5 5 0 015 5"
                      strokeWidth="1"
                      stroke="none"
                      fill="currentColor"
                    />
                  </pattern>
                </defs>
                <rect
                  width="800%"
                  height="800%"
                  transform="translate(0,0)"
                  fill="url(#a)"
                />
              </svg>
            </div>{" "}
            {t("loginPageMessageDetail")}
          </p>

        </div> */}
        <div className="w-3/4 px-4 py-10 mx-auto text-white lg:mx-2 lg:max-w-xl rounded-3xl bg-gradient-to-t from-pink-700 via-pink-700 to-pink-600 sm:px-10 md:m-6 md:mr-8">
          <p className={`mb-20 font-bold tracking-wider ${locale}`}>{t("vary")}</p>
          <p
            className={`mb-4 text-3xl font-bold md:text-4xl md:leading-snug ${locale}`}
          >
            {t("loginPageMessage")}
          </p>
          <p className={`mb-28 leading-relaxed text-gray-200 ${locale}`}>
            {t("loginPageMessageDetail")}

            {/* Lorem ipsum dolor sit amet consectetur adipisicing elit. Facere nisi
            voluptas a officia. Omnis. */}
          </p>
          <div className="px-4 py-8 bg-pink-600/80 rounded-2xl">
            <div className="flex items-center">
              {/* <img
                    src={data[0]?.imageUrl}
                    className="object-cover w-16 h-16 rounded-full"
                  /> */}
              <p className={`${locale === "en-US" ? "ml-4" : "mr-4"} w-56`}>
                {locale === "en-US" ? (
                  <>
                    <span className={` text-gray-200 font-medium ${locale}`}>
                      {" "}
                      {/* <strong className={`block font-semibold text-md ${locale}`}> */}
                      {t("varyPharmacy")}
                      {/* </strong> */}
                      {/* {t("vary")}{" "} */}
                    </span>
                  </>
                ) : (
                  <>
                    <span className={`text-xs text-gray-200 font-medium ${locale}`}>
                      {" "}
                      {t("pharmacy")}{" "}
                    </span>
                    <strong className={`block font-medium ${locale}`}>
                      {t("vary")}
                    </strong>
                  </>
                )}
              </p>
            </div>
            {/* <p className={`mt-3 text-gray-200 text-lg font-medium ${locale} `}>
              {t("loginMot")}
            </p> */}
          </div>
        </div>

        <div className="flex-col w-3/4 mx-auto my-3 bg-white rounded-lg shadow-2xl lg:flex lg:w-2/5">
          <div className="mx-2 my-6 text-center">
            <h1 className={`text-3xl font-semibold text-gray-800 ${locale}`}>
              {t("changePassword")}
            </h1>
            <p className={`mt-2 font-medium text-gray-700 ${locale}`}>
              {t("dontHaveAccount")}{" "}
              <Link
                to="/register"
                className={`text-pink-500 cursor-pointer hover:text-pink-700 ${locale}`}
              >
                {t("createAccount")}
              </Link>
            </p>
          </div>
          <div className="pb-8 mx-6">
            <form className="mb-4" onSubmit={handleSubmit(onSubmit)}>
              {/* <div className="mb-6">
                <label
                  htmlFor="email"
                  className={`block mb-2 text-sm text-gray-600 ${locale}`}
                >
                  {t("phoneNumberPlaceholder")}
                </label>
                <input
                  type="tel"
                  name="email"
                  id="email"
                  // placeholder={t("PhoneNumberPlaceholder")}
                  placeholder={t("770(50)000 00 00")}
                  {...register("phoneNumber", { required: true })}
                  className={`w-full px-3 py-3 placeholder-gray-300 border border-gray-300 rounded-xl focus:outline-none focus:ring focus:ring-pink-100 focus:border-pink-600 ${
                    errors.phoneNumber &&
                    "focus:ring-rose-100 focus:border-rose-300"
                  } ${locale}`}
                />
              </div> */}
              <div className="mb-6">
                <div className="flex justify-between mb-2">
                  <label
                    htmlFor="password"
                    className={`text-sm text-gray-600 ${locale}`}
                  >
                    {t("newPassword")}
                  </label>
                </div>
                <input
                  type="password"
                  name="password"
                  id="password"
                  placeholder={t("passwordPlaceholder")}
                  className={`w-full px-3 py-3 placeholder-gray-300 border  border-gray-300 rounded-xl focus:outline-none focus:ring focus:ring-pink-100 focus:border-pink-600 ${
                    errors.newPassword &&
                    "focus:ring-rose-100 focus:border-rose-300"
                  } ${locale}`}
                  {...register("newPassword", { required: true })}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
              </div>
              <div className="mb-6">
                <div className="flex justify-between mb-2">
                  <label
                    htmlFor="password"
                    className={`text-sm text-gray-600 ${locale}`}
                  >
                    {t("confirmPassword")}
                  </label>
                </div>
                <input
                  type="password"
                  name="password"
                  id="password"
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  placeholder={t("passwordPlaceholder")}
                  className={`w-full px-3 py-3 placeholder-gray-300 border  border-gray-300 rounded-xl focus:outline-none focus:ring focus:ring-pink-100 focus:border-pink-600 ${
                    errors.passwconfirmNewPasswordord &&
                    "focus:ring-rose-100 focus:border-rose-300"
                  } ${locale}`}
                  {...register("confirmNewPassword", { required: true })}
                />
              </div>
              <div className="mb-6">
                <button
                  type="submit"
                  // disabled={newPassword !== confirmPassword}
                  className={`w-full px-3 py-3 text-lg font-medium text-white duration-100 ease-in-out bg-pink-600 rounded-xl hover:bg-pink-400 focus:outline-none ${locale}`}
                >
                  {t("submit")}
                </button>
              </div>
            </form>
            {/* <div className="flex flex-row justify-center mb-8">
          <span className="absolute px-4 text-gray-500 bg-white">or sign-in with</span>
          <div className="w-full h-px mt-3 bg-gray-200" />
        </div> */}
            {/* <div className="flex flex-row gap-2">
          <button className="flex flex-row items-center justify-center w-full gap-2 p-2 text-gray-700 duration-100 ease-in-out bg-white border border-gray-700 rounded-xl hover:bg-gray-100">
            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" className="w-5" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24">
              <g fill="none">
                <path fillRule="evenodd" clipRule="evenodd" d="M12 0C5.372 0 0 5.373 0 12s5.372 12 12 12c6.627 0 12-5.373 12-12S18.627 0 12 0zm.14 19.018c-3.868 0-7-3.14-7-7.018c0-3.878 3.132-7.018 7-7.018c1.89 0 3.47.697 4.682 1.829l-1.974 1.978v-.004c-.735-.702-1.667-1.062-2.708-1.062c-2.31 0-4.187 1.956-4.187 4.273c0 2.315 1.877 4.277 4.187 4.277c2.096 0 3.522-1.202 3.816-2.852H12.14v-2.737h6.585c.088.47.135.96.135 1.474c0 4.01-2.677 6.86-6.72 6.86z" fill="currentColor" />
              </g>
            </svg>
            Google
          </button>
          <button className="flex flex-row items-center justify-center w-full gap-2 p-2 text-gray-700 duration-100 ease-in-out bg-white border border-gray-700 rounded-xl hover:bg-gray-100">
            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" className="w-5" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24">
              <g fill="none">
                <path fillRule="evenodd" clipRule="evenodd" d="M12 0C5.37 0 0 5.37 0 12c0 5.31 3.435 9.795 8.205 11.385c.6.105.825-.255.825-.57c0-.285-.015-1.23-.015-2.235c-3.015.555-3.795-.735-4.035-1.41c-.135-.345-.72-1.41-1.23-1.695c-.42-.225-1.02-.78-.015-.795c.945-.015 1.62.87 1.845 1.23c1.08 1.815 2.805 1.305 3.495.99c.105-.78.42-1.305.765-1.605c-2.67-.3-5.46-1.335-5.46-5.925c0-1.305.465-2.385 1.23-3.225c-.12-.3-.54-1.53.12-3.18c0 0 1.005-.315 3.3 1.23c.96-.27 1.98-.405 3-.405s2.04.135 3 .405c2.295-1.56 3.3-1.23 3.3-1.23c.66 1.65.24 2.88.12 3.18c.765.84 1.23 1.905 1.23 3.225c0 4.605-2.805 5.625-5.475 5.925c.435.375.81 1.095.81 2.22c0 1.605-.015 2.895-.015 3.3c0 .315.225.69.825.57A12.02 12.02 0 0 0 24 12c0-6.63-5.37-12-12-12z" fill="currentColor" />
              </g>
            </svg>
            Github
          </button>
        </div> */}
            {snackBar && <DynamicSnackBar Title={message} />}
          </div>
        </div>

        {/* <div className="flex flex-row gap-2">
              <button className="flex flex-row items-center justify-center w-full gap-2 p-2 text-gray-700 duration-100 ease-in-out bg-white border border-gray-700 rounded-xl hover:bg-gray-100">
                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" className="w-5" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24">
                  <g fill="none">
                    <path fillRule="evenodd" clipRule="evenodd" d="M12 0C5.372 0 0 5.373 0 12s5.372 12 12 12c6.627 0 12-5.373 12-12S18.627 0 12 0zm.14 19.018c-3.868 0-7-3.14-7-7.018c0-3.878 3.132-7.018 7-7.018c1.89 0 3.47.697 4.682 1.829l-1.974 1.978v-.004c-.735-.702-1.667-1.062-2.708-1.062c-2.31 0-4.187 1.956-4.187 4.273c0 2.315 1.877 4.277 4.187 4.277c2.096 0 3.522-1.202 3.816-2.852H12.14v-2.737h6.585c.088.47.135.96.135 1.474c0 4.01-2.677 6.86-6.72 6.86z" fill="currentColor" />
                  </g>
                </svg>
                Google
              </button>
              <button className="flex flex-row items-center justify-center w-full gap-2 p-2 text-gray-700 duration-100 ease-in-out bg-white border border-gray-700 rounded-xl hover:bg-gray-100">
                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" className="w-5" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24">
                  <g fill="none">
                    <path fillRule="evenodd" clipRule="evenodd" d="M12 0C5.37 0 0 5.37 0 12c0 5.31 3.435 9.795 8.205 11.385c.6.105.825-.255.825-.57c0-.285-.015-1.23-.015-2.235c-3.015.555-3.795-.735-4.035-1.41c-.135-.345-.72-1.41-1.23-1.695c-.42-.225-1.02-.78-.015-.795c.945-.015 1.62.87 1.845 1.23c1.08 1.815 2.805 1.305 3.495.99c.105-.78.42-1.305.765-1.605c-2.67-.3-5.46-1.335-5.46-5.925c0-1.305.465-2.385 1.23-3.225c-.12-.3-.54-1.53.12-3.18c0 0 1.005-.315 3.3 1.23c.96-.27 1.98-.405 3-.405s2.04.135 3 .405c2.295-1.56 3.3-1.23 3.3-1.23c.66 1.65.24 2.88.12 3.18c.765.84 1.23 1.905 1.23 3.225c0 4.605-2.805 5.625-5.475 5.925c.435.375.81 1.095.81 2.22c0 1.605-.015 2.895-.015 3.3c0 .315.225.69.825.57A12.02 12.02 0 0 0 24 12c0-6.63-5.37-12-12-12z" fill="currentColor" />
                  </g>
                </svg>
                Github
              </button>
            </div> */}
      </div>
    </div>
  );
}

export default MyApp;
