import React from "react";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";

const FirstForm = ({ formValues, onChange }) => {
  const { t } = useTranslation();
  const l = localStorage.getItem("i18nextLng");
  return (
    <div className="w-full max-w-4xl">
      <form className="min-w-full px-20">
        {/* <div className="grid items-center gap-4 place-content-center">
          <h1 className="pb-8 text-2xl font-bold text-gray-700">Person Info</h1>
        </div>
        <div className="mb-4">
          <label
            className="block mb-2 text-sm font-bold text-gray-700"
            htmlFor="Name"
          >
            Name
          </label>
          <input
            className="w-full px-3 py-2 text-gray-700 border rounded shadow appearance-none "
            id="name"
            name="name"
            type="text"
            placeholder="Name"
            onChange={onChange}
            value={formValues.name}
          ></input>
        </div>
        <div className="mb-6">
          <label
            className="block mb-2 text-sm font-bold text-gray-700"
            htmlFor="LastName"
          >
            LastName
          </label>
          <input
            className="w-full px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
            id="lastname"
            name="lastname"
            onChange={onChange}
            value={formValues.lastname}
            type="text"
            placeholder="LastName"
          ></input>
        </div> */}
        {/* <div className="w-full px-20 mb-6">
          <label
            htmlFor="email"
            className={`block mb-2 text-sm text-gray-600 ${l}`}
          >
            {t("phoneNumberPlaceholder")}
          </label>
          <input
            type="tel"
            name="email"
            id="email"
            // placeholder={t("PhoneNumberPlaceholder")}
            placeholder={t("770(50)000 00 00")}
            className={`min-w-full px-3 py-3 placeholder-gray-300 border border-gray-300 rounded-xl focus:outline-none focus:ring focus:ring-pink-100 focus:border-pink-600
                   ${l}`}
          />
        </div> */}
        <div className="mb-6">
          <div className="flex justify-between mb-2">
            <label htmlFor="password" className={`text-sm text-gray-600 ${l}`}>
              {t("password")}
            </label>
          </div>
          <input
            type="password"
            name="password"
            id="password"
            placeholder={t("passwordPlaceholder")}
            className={`w-full px-3 py-3 placeholder-gray-300 border  border-gray-300 rounded-xl focus:outline-none focus:ring focus:ring-pink-100 focus:border-pink-600  ${l}`}
          />
        </div>
        {/* <div className="mb-6">
          <button
            type="submit"
            className={`w-full px-3 py-3 text-lg font-medium text-white duration-100 ease-in-out bg-pink-600 rounded-xl hover:bg-pink-400 focus:outline-none ${l}`}
          >
            {t("login")}
          </button>
        </div>
        <div className="flex items-center justify-between"></div> */}
      </form>
    </div>
  );
};

export default FirstForm;
