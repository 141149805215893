import React, { useState, useEffect, useRef } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon, TrashIcon } from "@heroicons/react/20/solid";
import axios from "axios";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import Toast from "../../components/Cards/Toast";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Example({ id, userId }) {
  const [message, setMessage] = useState("");
  const [toast, setToast] = useState(false);
  const [error, setError] = useState(false);

  const connection = useSelector((state) => state.HubConnection.value);
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const closeDropdown = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", closeDropdown);
    } else {
      document.removeEventListener("mousedown", closeDropdown);
    }

    return () => {
      document.removeEventListener("mousedown", closeDropdown);
    };
  }, [isOpen]);

  const handleDelete = (id) => {
    const config = {
      headers: {
        Authorization: `Bearer ${Cookies.get("dashboardToken")}`,
        "Content-Type": "multipart/form-data",
      },
    };

    axios
      .delete(`api/Messages/DeleteMessage?id=${id}`, config)
      .then((response) => {
        console.log(response);
        setMessage("Message Deleted Successfully.");
        setToast(true);
        connection.invoke("RemoveMessage", userId?.toString());
      })
      .catch((error) => {
        console.log("Error sending file: ", error);
        setMessage("Something went wrong");
        setError(true);
      });
  };
  return (
    <div className="relative inline-block text-left" ref={dropdownRef}>
      <span className="absolute right-0">
        <ChevronDownIcon
          onClick={toggleDropdown}
          className="inline w-5 h-5 text-gray-400 duration-300 cursor-pointer hover:text-gray-500"
        />
      </span>
      <Menu as="div" className="relative inline-block text-left">
        <div>
          {/* <Menu.Button
            className="inline-flex w-full justify-center gap-x-1.5 rounded-md  px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm"
            onClick={toggleDropdown}
          >
            <ChevronDownIcon
              onClick={toggleDropdown}
              className="inline w-4 h-4 text-gray-400 duration-300 cursor-pointer hover:text-gray-500"
            />
          </Menu.Button> */}
        </div>

        <Transition
          show={isOpen}
          as={React.Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 z-10 w-56 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg focus:outline-none dark:bg-secondary-500">
            <div className="">
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={() => handleDelete(id)}
                    className={classNames(
                      active
                        ? "bg-gray-100 text-gray-900 dark:bg-secondary-600 dark:text-gray-50"
                        : "text-gray-700 dark:text-gray-50",
                      "block px-4 py-3 text-sm w-full h-full text-left"
                    )}
                  >
                    <TrashIcon className="inline w-6" /> Delete
                  </button>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
      {toast && <Toast Text={message} Type={"secondary"} setOpen={setToast} />}
      {error && <Toast Text={message} Type={"rose"} setOpen={setError} />}
    </div>
  );
}
