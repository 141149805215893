import React from "react";
import BreadCrumb from "../componets/BreadCrumb";
import CopyWrite from "../componets/CopyWrite";
import { useParams } from "react-router-dom";
// Componetns
import Footer from "../componets/Footer";
import Header from "../componets/Header";
import Navbar from "../componets/Navbar";
import ShopSideBar from "../componets/shop/ShopSideBar";
import Products from "../componets/shop/Products";
import { ShoppingBagIcon } from "@heroicons/react/24/outline";
import Doctors from "../componets/Profile/Doctors";
const src = "https://source.unsplash.com/random/200x200?sig=1";

const Profiles = () => {
  const params = useParams();
  return (
    <div>
      {/* <Header /> */}
      <Navbar />

      {/* <!-- shop wrapper --> */}
      {/* <div className="container grid items-start gap-0 pt-4 pb-16 md:gap-6"> */}
        {/* <!-- breadcrumb --> */}
        {/* <!-- ./breadcrumb --> */}
      {/* </div> */}
      {/* <!-- ./shop wrapper --> */}
      <div className="grid items-start gap-0 pt-4 pb-16 md:gap-6">

      <Doctors />
      </div>
      {/* <!-- footer --> */}
      <Footer />
      {/* <!-- ./footer --> */}

      {/* <!-- copyright --> */}
      <CopyWrite />
      {/* <!-- ./copyright --> */}
    </div>
  );
};

export default Profiles;
