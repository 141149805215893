import React from "react";
import BreadCrumb from "../componets/BreadCrumb";
import CopyWrite from "../componets/CopyWrite";
import { Link, useParams } from "react-router-dom";
// Componetns
import Footer from "../componets/Footer";
import Header from "../componets/Header";
import Navbar from "../componets/Navbar";
import { motion } from "framer-motion";
import ShopSideBar from "../componets/shop/ShopSideBar";
import Products from "../componets/shop/Products";
import { ShoppingBagIcon } from "@heroicons/react/24/outline";
import { Menu, Transition } from "@headlessui/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  ArrowLeftIcon,
  CheckBadgeIcon,
  ChevronDownIcon,
  FunnelIcon,
  MagnifyingGlassIcon,
  SwatchIcon,
} from "@heroicons/react/20/solid";
import { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  getBrand,
  resetSearch,
  searchBrand,
} from "../../features/Global/Brands";

const Shop = () => {
  const params = useParams();
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false);
  const [search, setsearch] = useState();
  const locale = localStorage.getItem("i18nextLng");
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const brands = useSelector((state) => state.Brands.copyData);
  const loading = useSelector((state) => state.Brands.loading);
  const isSuccess = useSelector((state) => state.Brands.isSuccess);
  useEffect(() => {
    dispatch(getBrand());
  }, []);
  const handleFilter = (e) => {
    setsearch(e.target.value);
    dispatch(searchBrand(e.target.value));
  };
  const handleRemoveFilters = () => {
    dispatch(resetSearch());
    setsearch("");
  };

  // console.log('in shop',params.id);
  return (
    <div>
      {/* <Header /> */}
      <Navbar />

      {/* <!-- shop wrapper --> */}
      <div className="grid items-start gap-0 pt-4 pb-16 md:gap-6">
        {/* <!-- breadcrumb --> */}
        {/* <!-- ./breadcrumb --> */}
        <main className="p-4 px-4 mx-auto bg-white rounded-md w-7xl sm:px-6 lg:px-8">
          <BreadCrumb Name={"brands"} Iconn={SwatchIcon} />
          <div className="container flex items-baseline justify-between w-screen pt-12 pb-6 border-b border-gray-200">
            <h1
              className={`text-4xl font-bold tracking-tight text-gray-900 ${locale}`}
            >
              {t("brands")}
            </h1>

            <div className="flex items-center">
              <Menu as="div" className="relative inline-block text-left">
                <div>
                  <div className="relative hidden mx-3 lg:inline-block">
                    {/* ::::label */}
                    <label htmlFor="search" className={` sr-only ${locale}`}>
                      {"search"}
                    </label>
                    {/* ::::input */}
                    <input
                      type="search"
                      id="search"
                      name="search"
                      value={search}
                      placeholder={t("search")}
                      onChange={(e) => handleFilter(e)}
                      className={`block w-full px-10 py-3 text-sm placeholder-gray-300 border-gray-300 rounded-md shadow-sm form-input pl-11 bg-gray-50 focus:border-pink-600 focus:ring-1 focus:ring-pink-500 ${locale}`}
                    />
                    {/* ::::icon */}
                    <span
                      className={`absolute text-gray-400 transform -translate-y-1/2 top-1/2 ${
                        locale === "en-US" ? "left-3" : "right-3"
                      }`}
                    >
                      <MagnifyingGlassIcon className="w-4 h-4" />
                    </span>
                  </div>
                </div>
              </Menu>

              <button
                onClick={() => handleRemoveFilters()}
                type="button"
                className="flex items-center justify-center w-10 h-10 text-white rounded-lg shrink-0 bg-gradient-to-br from-pink-600 to-pink-500 hover:bg-pink-700"
              >
                <span className={` sr-only ${locale}`}>{t("View grid")}</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="flex items-center justify-center w-8 h-8 text-white rounded-lg shrink-0 bg-gradient-to-br from-pink-600 to-pink-500 hover:bg-pink-700 "
                  viewBox="0 0 24 24"
                  strokeWidth="1.0"
                  stroke="#fff"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <line x1="3" y1="3" x2="21" y2="21" />
                  <path d="M9 5h9.5a1 1 0 0 1 .5 1.5l-4.049 4.454m-.951 3.046v5l-4 -3v-4l-5 -5.5a1 1 0 0 1 .18 -1.316" />
                </svg>
              </button>
              <button
                type="button"
                className="flex items-center justify-center w-10 h-10 mx-2 text-white rounded-lg shrink-0 bg-gradient-to-br from-pink-600 to-pink-500 hover:bg-pink-700 lg:hidden "
                onClick={() => setMobileFiltersOpen(true)}
              >
                <span className={` sr-only ${locale}`}>{t("filters")}</span>
                <FunnelIcon className="w-5 h-5" aria-hidden="true" />
              </button>
            </div>
          </div>
          {/* <!-- ./sidebar --> */}
          {/* <!-- Brands --> */}
          <section
            aria-labelledby="products-heading"
            className="w-full pt-6 pb-24"
          >
            <h2 id="products-heading" className={` sr-only ${locale}`}>
              {t("products")}
            </h2>

            <div className="grid grid-cols-1 gap-x-2 gap-y-8 lg:grid-cols-4">
              {/* Filters */}
              <h3 className={` sr-only ${locale}`}>Categories</h3>
              {isSuccess &&
                brands.map((item, index) => (
                  <>
                    <motion.div
                      key={index}
                      className="m-2 duration-500 bg-white shadow-md snap-center hover:scale-105 hover:shadow-xl rounded-xl"
                    >
                      <Link
                        to={
                          locale === "en-US"
                            ? `/shop/${
                                item?.id +
                                "." +
                                item.name.english +
                                "searchBrand"
                              }`
                            : locale === "ar"
                            ? `/shop/${
                                item?.id +
                                "." +
                                item.name.arabic +
                                "searchBrand"
                              }`
                            : `/shop/${
                                item?.id +
                                "." +
                                item.name.kurdish +
                                "searchBrand"
                              }`
                        }
                        replace={true}
                        relative={true}
                        className="relative flex mt-3 overflow-hidden h-60"
                      >
                        {item.qty < 1 && (
                          <span
                            className={`absolute top-0 z-10 ${
                              locale === "en-US" ? "left-0" : "right-0"
                            } m-2 rounded-md bg-gradient-to-br from-rose-600 to-rose-500 px-2 text-center text-sm font-medium text-white ${locale}`}
                          >
                            {t("outOfStock")}
                          </span>
                        )}
                        <img
                          src={item?.imageUrl}
                          alt="Product image"
                          className="object-contain w-full duration-500 rounded-t-xl hover:rounded-t-xl hover:scale-110"
                        />
                      </Link>
                      <div className="flex justify-between w-auto px-3 py-3">
                        <p
                          className={`block text-md font-bold text-black capitalize truncate overflow-hidden ${locale}`}
                        >
                          {locale === "en-US" && item.name?.english}
                          {locale === "ar" && item.name?.arabic}
                          {locale === "ku" && item.name?.kurdish}
                        </p>
                        <Link
                          to={
                            locale === "en-US"
                              ? `/shop/${
                                  item?.id +
                                  "." +
                                  item.name.english +
                                  "searchBrand"
                                }`
                              : locale === "ar"
                              ? `/shop/${
                                  item?.id +
                                  "." +
                                  item.name.arabic +
                                  "searchBrand"
                                }`
                              : `/shop/${
                                  item?.id +
                                  "." +
                                  item.name.kurdish +
                                  "searchBrand"
                                }`
                          }
                          className={`text-xs text-secondary-600 my-auto ${locale}`}
                        >
                          {t("viewProducts")}{" "}
                          <ArrowLeftIcon className="inline w-4" />
                        </Link>
                      </div>
                    </motion.div>
                  </>
                ))}
            </div>
          </section>
        </main>
      </div>

      {/* <!-- .footer --> */}
      <Footer />
      {/* <!-- ./footer --> */}

      {/* <!-- copyright --> */}
      <CopyWrite />
      {/* <!-- ./copyright --> */}
    </div>
  );
};

export default Shop;
