import { Fragment } from "react";
import { Dialog, Transition, Tab } from "@headlessui/react";
import {
  CheckCircleIcon,
  ClockIcon,
  CloudArrowUpIcon,
  PencilIcon,
  TrashIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { PlusCircleIcon } from "@heroicons/react/24/outline";
import { XCircleIcon } from "@heroicons/react/24/outline";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import axios from "axios";
import Toast from "../../components/Cards/Toast";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";
import { getEmails } from "../../../features/Global/Emails";
export default function SlideOver({ Title, open, setOpen, id }) {
  const [checkPassword, setCheckPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [toast, setToast] = useState(false);
  const [disable, setDisable] = useState(false);
  const [error, setError] = useState(false);
  const [message, setMessage] = useState("");
  const [file, setFile] = useState();
  const [preview, setPreview] = useState();
  const handleSelect = (e) => {
    setFile(e.target.files[0]);
    const objectUrl = URL.createObjectURL(e.target.files[0]);
    setPreview(objectUrl);
  };
  const dispatch = useDispatch();

  const config = {
    headers: {
      Authorization: `Bearer ${Cookies.get("dashboardToken")}`,
    },
  };
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm();
  useEffect(() => {
    // console.log(id);
    setValue("state", id?.status, { shouldDirty: true });
  }, [id]);

  const onSubmit = (data) => {
    setDisable(true);
    data.id = id?.id;
    axios
      .post("api/Emails", data, config)
      .then((r) => {
        setDisable(false);
        // console.log(r.data);
        if (r.status === 200) {
          setToast(true);
          dispatch(getEmails());
          setMessage("Email Added Successfully");
          setOpen(false);
        }
      })
      .catch((e) => {
        setDisable(false);
        console.log(e);
        if (
          e.response.status === 400 &&
          e.response.data.message === "email or phone number is already taken"
        ) {
          console.log(e.response.data);
          setMessage(e.response.data);
          setError(true);
        }
      });
  };
  const handleDelete = (id) => {
    axios
      .delete(`api/Emails/${id}`)
      .then((r) => {
        if (r.status === 200 || r.status === 204) {
          setToast(true);
          setMessage("Image Deleted Successfully");
          setOpen(false);
          // console.log(r);
        }
      })
      .catch((e) => {
        console.log(e);
        console.log(e);
        if (
          e.response.status === 400 &&
          e.response.data.message === "email or phone number is already taken"
        ) {
          console.log(e.response.data);
          setMessage(e.response.data);
          setError(true);
        }
      });
  };
  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10 " onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-full p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-2xl overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                {/* <Dialog.Title
                  as="h3"
                  className="my-2 text-lg font-medium leading-6 text-gray-900 bg-secondary-500 "
                >
                  <PencilIcon className="inline w-6 mx-2" /> Update order status
                </Dialog.Title> */}
                <div className="py-10 text-center text-white bg-gradient-to-br from-secondary-600 to-secondary-500">
                  <p className={` text-2xl font-semibold`}>
                    <PencilIcon className="inline mx-2 w-7 " /> Add Email
                  </p>
                </div>
                <form
                  className="px-8 py-8 space-y-2"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <div className="my-2 antialiased text-gray-600 ">
                    <div className="flex flex-col justify-center my-2">
                      <div className="w-full max-w-2xl mx-auto my-2 bg-white rounded-sm ">
                        <div className="w-full p-3">
                          <div className="overflow-x-auto">
                            <label className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-300">
                              Email
                            </label>
                            <input
                              type="email"
                              placeholder="Email"
                              {...register("emailAddress", {
                                required: true,
                              })}
                              className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-secondary-500 focus:border-secondary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white border ${
                                errors.emailAddress && "border-rose-500"
                              } bg-white py-2 px-6 text-base font-medium text-gray-700 outline-none focus:border-secondary-600 focus:shadow-md dark:bg-gray-700 dark:text-gray-300`}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <button
                    disabled={disable}
                    type="submit"
                    className="px-6 py-2 mx-1 border border-transparent rounded-md text-secondary-600 bg-secondary-100 hover:bg-secondary-200 hover:text-secondary-700 focus:outline-none focus:ring-2 focus:ring-secondary-500 focus:border-transparent dark:text-secondary-100 dark:bg-secondary-600"
                  >
                    <PlusCircleIcon className={`inline w-6 mx-1`} /> Add
                  </button>
                </form>
                {toast && (
                  <Toast
                    Text={"Email Added Successfully"}
                    Type={"secondary"}
                    setOpen={setToast}
                  />
                )}
                {error && (
                  <Toast Text={message} Type={"rose"} setOpen={setError} />
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
