import React, { useEffect, useState, Fragment } from "react";
import Navbar from "../componets/Navbar";
import Footer from "../componets/Footer";
import CopyWrite from "../componets/CopyWrite";
import Breadcrumb4 from "../componets/BreadCrumb";
import Cookies from "js-cookie";

import {
  ArrowPathIcon,
  CheckBadgeIcon,
  CheckCircleIcon,
  ChevronDoubleRightIcon,
  ClockIcon,
  EyeIcon,
  HashtagIcon,
  MagnifyingGlassIcon,
  MapIcon,
  MapPinIcon,
  PencilIcon,
  PhoneIcon,
  QuestionMarkCircleIcon,
  UserCircleIcon,
  UserMinusIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";
import faq from "../assets/icons/Faq.svg";
// Redux
import { useDispatch, useSelector } from "react-redux";
import { getQuestion, searchFilter } from "../../features/Global/AskVary";
import usePagination from "../componets/pagination/Pagination";
import { Pagination } from "@mui/material";
import { pink } from "@mui/material/colors";
import UpdateAddress from "../componets/Profile/UpdateAddressModal";
import OrderDetailModal from "../componets/Profile/OrderDetailMoadl";
import DeleteAccountModal from "../componets/Profile/DeleteAccount";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { getAddresses } from "../../features/Global/Address";
import { getOrderHistory } from "../../features/Global/OrderHistory";
import Reciept from "../../Dashboard/pages/Orders/Reciept";
import { authentication } from "../../firebase";
// import fire base Auth
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import axios from "axios";
import { useForm } from "react-hook-form";
import { Dialog, Transition } from "@headlessui/react";
import { Player } from "@lottiefiles/react-lottie-player";
import loaderAnim from "../assets/icons/otp.json";
import OtpInput from "react18-input-otp";
import { useNavigate } from "react-router-dom";
import verify from "../assets/icons/Verify.svg";
import NoOrder from "../assets/icons/NoOrder.svg";

import ChangePhoneNumber from "../componets/Authentication/ChangeNumber";
import { ajaxSetup, ajaxTransport } from "jquery";
import NotificationCenter from "../componets/UserProfile/NotificationCenter";
import { getUserNotifications } from "../../features/Global/notificationCenter";
const AskVary = () => {
  const { t } = useTranslation();
  const l = localStorage.getItem("i18nextLng");
  const data = useSelector((state) => state.AskVary.copyData);
  const address = useSelector((state) => state.Address.data);
  const OrderHistory = useSelector((state) => state.OrderHistory.data);
  const notificationCenterData = useSelector(
    (state) => state.notificationCenter.data
  );
  const dispatch = useDispatch();
  const [order, setOrder] = useState([]);

  const [phoneNumber, setPhoneNumber] = useState("");
  const [succesRegister, setSuccessRegister] = useState(false);
  const [openChangeNumberModal, setOpenChangeNumberModal] = useState(false);
  const [openDeleteAccountModal, setOpenDeleteAccountModal] = useState(false);
  const [openViewOrderDetail, setOpenViewOrderDetail] = useState(false);
  const [openState, setOpenState] = useState();
  const [search, setSearch] = useState();
  const [user, setUser] = useState();
  // const user = JSON?.parse());
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(getQuestion());
    dispatch(getAddresses());
    dispatch(getOrderHistory());
    dispatch(getUserNotifications());
    if (Cookies.get("Customer")) {
      setUser(JSON.parse(Cookies.get("Customer")));
    }
  }, []);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const config = {
    headers: {
      Authorization: `Bearer ${Cookies.get("webAuth")}`,
      "Content-Type": "application/json",
    },
  };
  const config2 = {
    headers: {
      Authorization: `Bearer ${Cookies.get("webAuth")}`,
      ContentType: "text/plain",
    },
  };
  const PER_PAGE = 20;
  let [page, setPage] = useState(1);
  const count = Math.ceil(
    data.filter((item) => item?.answer !== "")?.length / PER_PAGE
  );
  // const _DATA = usePagination(data, PER_PAGE);
  // const handleChange = (e, p) => {
  //   setPage(p);
  //   _DATA.jump(p);
  // };
  const handleOpenDrop = (id) => {
    // console.log(id);
    setOpenState(id);
  };
  const handleFilter = (e) => {
    // setSearch(e?.target?.value);
    dispatch(searchFilter(e.target.value));
  };
  const [openUpdate, setUpdateModal] = useState(false);
  const [updateId, setUpdateId] = useState();
  const handleUpdateModal = (id) => {
    setUpdateId(id);
    setUpdateModal(true);
  };
  const [orderDetail, setOrderDetail] = useState();

  const handleViewOrderdetail = (id) => {};
  const handleOrderDetail = (id) => {
    setOpenViewOrderDetail(true);
    axios.get(`api/Orders/GetOrderItems/${id}`, config).then((r) => {
      setOrderDetail(r.data);
    });
  };
  var sum = 0;

  const handleSum = (data) => {
    sum = data?.reduce((a, v) => (a = a + v.currentPrice), 0);
    return sum;
  };

  //  Confirm User
  const [showModal, setShowModal] = useState(false);
  const genarateRecaptcha = () => {
    window.recaptchaVerifier = new RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
        callback: (response) => {
          console.log("prepared phone auth process");

          // reCAPTCHA solved, allow signInWithPhoneNumber.
          // ...
        },
        "expired-callback": () => {
          // Response expired. Ask user to solve reCAPTCHA again.
          // ...
        },
      },
      authentication
    );
  };
  const onSubmit = (data) => {
    data.phoneNumber = data?.phoneNumber
      .replace(/^0/, "")
      .trim()
      .replace(/\s/g, "");
    setPhoneNumber(data.phoneNumber);
    genarateRecaptcha();
    const appVerifier = window.recaptchaVerifier;

    signInWithPhoneNumber(
      authentication,
      `+964${data.phoneNumber}`,
      appVerifier
    )
      .then((confirmationResult) => {
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        window.confirmationResult = confirmationResult;
        // ...
      })
      .catch((error) => {
        // Error; SMS not sent
        // ...
        console.log(error);
        // console.log(error, `+964${data.phoneNumber}`);
      });
    setShowModal(true);
    // setRegiserStatus(false);

    // console.log("password not match");
  };
  //  OTP
  const [otp, setOtp] = useState("");
  const handleChange = (enteredOtp) => {
    setOtp(enteredOtp);
  };

  const verifyOTP = () => {
    // let OTP = num1 + "" + num2 + "" + num3 + "" + num4 + "" + num5 + "" + num6;
    console.log(otp);
    if (otp.length === 6) {
      let confirmationResult = window.confirmationResult;

      confirmationResult.confirm(otp).then((result) => {
        // User signed in successfully.
        const user = result.user;
        user
          .getIdToken()
          .then((r) => {
            // setToken = r;
            // console.log(user);
            setUser(user);
            const set = {};
            // console.log("phone number ", phoneNumber);
            set.phoneNumber = phoneNumber;
            set.firebaseUserIdToken = r;
            // console.log("tokennnnn::::::::::", set.firebaseUserIdToken);
            const config = {
              headers: {
                Authorization: `Bearer ${Cookies.get("webAuth")}`,
              },
            };
            axios
              .post("api/UsersAuth/ConfirmPhoneNumber", set, config)
              .then((r) => {
                setSuccessRegister(true);
                // // console.log(r);

                Cookies.remove("webAuth");
                Cookies.remove("Customer");
                localStorage.removeItem("_grecaptcha");
                // setTimeout(() => {
                navigate("/login");
                // }, 2000);
              })
              .catch((e) => console.log(e));
            // ...
          })
          .catch((error) => {
            // User couldn't sign in (bad verification code?)
            // ...
          });
      });
    }
  };
  const handleChangePhoneNumber = (data) => {
    axios
      .post(
        "api/UsersAuth/ChangePhoneNumber",
        JSON.stringify(data.phoneNumber),
        config
      )
      .then((r) => {
        // console.log(r);
        setOpenChangeNumberModal(false);
      })
      .catch((error) => console.log(error));
  };

  const [activeTab, setActiveTab] = useState(1);
  const sidebarItems = [
    { id: 1, name: "profile" },
    { id: 2, name: "addresses" },
    { id: 3, name: "orders" },
    { id: 4, name: "notifications" },
  ];

  const handleChangeTab = (id) => {
    setActiveTab(id);
  };
  const inputStyles = {
    border: "1px solid #2f3e46",
    borderRadius: "8px",
    width: "54px",
    height: "54px",
    fontSize: "16px",
    color: "#2f3e46",
    fontWeight: "400",
    caretColor: "pink",
    dir: "ltr",
    direction: "ltr",
    unicodeBidi: "bidi-override",
  };

  if (window.innerWidth <= 600) {
    inputStyles.width = "40px";
    inputStyles.height = "40px";
  }

  const responsiveInputStyles = {
    "@media only screen and (maxWidth: 600px)": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontSize: "14px",
    },
    "@media only screen and (maxWidth: 600px) input": {
      width: "35px",
      height: "35px",
      fontSize: "18px",
    },
  };
  const combinedStyles = { ...inputStyles, ...responsiveInputStyles };
  // public static readonly short Pending = 0;
  // public static readonly short Accepted = 1;
  // public static readonly short Canceled = 2;
  // public static readonly short WaitingForFibPayment = 3;
  // public static readonly short FibPaymentDeclined = 4
  const handleOrderTypes = (type) => {
    if (type === 0) {
      return t("pending");
    } else if (type === 1) {
      return t("accepted");
    } else if (type === 2) {
      return t("canceled");
    } else if (type === 3) {
      return t("waitingForFibPayment");
    } else if (type === 4) {
      return t("fibPaymentDeclined");
    }
  };

  return (
    <div>
      <Navbar />
      <div className="grid items-start gap-0 pt-4 pb-16 my-3 md:gap-6 ">
        <div className="container w-full p-5 mx-auto my-6 rounded-lg shadow bg-gray-50">
          <Breadcrumb4 Name={"myProfile"} Iconn={UserCircleIcon} />
          <div id="recaptcha-container" className="flex justify-center">
            {/* <!-- Recaptcha Widget will be rendered here --> */}
          </div>{" "}
          <div className="max-w-screen-xl min-h-screen mx-4 sm:mx-8 xl:mx-auto">
            <h1 className={`py-6 text-4xl font-semibold border-b ${l}`}>
              {t("settings")}
            </h1>
            <div className="grid grid-cols-8 pt-3 sm:grid-cols-10">
              <div className="relative justify-center my-4 w-96 sm:hidden">
                <input
                  className="hidden peer"
                  type="checkbox"
                  name="select-1"
                  id="select-1"
                />
                <label
                  htmlFor="select-1"
                  className={`flex min-w-full justify-center p-2 px-3 text-sm text-gray-700 border rounded-lg cursor-pointer select-none ring-pink-700 peer-checked:ring ${l}`}
                >
                  {t("settings")}{" "}
                </label>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="absolute right-0 h-4 ml-auto mr-5 transition pointer-events-none top-3 text-slate-700 peer-checked:rotate-180"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
                <ul className="flex-col overflow-hidden list-none transition-all duration-300 rounded-b-lg shadow-md select-none max-h-0 peer-checked:max-h-56 peer-checked:py-3">
                  {sidebarItems.map((item) => (
                    <li
                      key={item.id}
                      onClick={() => handleChangeTab(item.id)}
                      className={`px-3 py-2 text-sm cursor-pointer text-slate-600 hover:bg-pink-700 hover:text-white ${l}`}
                    >
                      {t(item.name)}
                    </li>
                  ))}
                </ul>
              </div>

              <div className="hidden col-span-2 sm:block">
                <ul className="list-none">
                  {sidebarItems.map((item) => (
                    <li
                      key={item.id}
                      onClick={() => handleChangeTab(item.id)}
                      className={`mt-5 cursor-pointer ${
                        l === "en-US" ? "border-l-2" : "border-r-2"
                      }  border-transparent px-2 py-2 font-semibold transition ${
                        l === "en-US"
                          ? "hover:border-l-pink-500"
                          : "hover:border-r-pink-500"
                      }  hover:text-pink-500 ${
                        activeTab === item?.id
                          ? ` ${
                              l === "en-US"
                                ? " border-l-pink-400"
                                : " border-r-pink-400 "
                            } text-pink-700`
                          : ""
                      } ${l}`}
                    >
                      {t(item.name)}
                    </li>
                  ))}
                </ul>
              </div>
              {/* Sections Control */}
              {activeTab === 1 && (
                <>
                  <div className="col-span-8 overflow-hidden shadow rounded-xl bg-gray-50 lg:px-8">
                    <section className=" bg-pinkGray-50">
                      <div className="w-full mx-auto lg:px-4">
                        <div className="relative flex flex-col w-full min-w-0 mt-16 mb-6 break-words bg-white rounded-lg shadow-xl">
                          <div className="px-6">
                            <div className="flex flex-wrap justify-center">
                              <div className="flex justify-center w-full px-4">
                                <div className="relative">
                                  <UserCircleIcon
                                    alt="..."
                                    // src="https://demos.creative-tim.com/notus-js/assets/img/team-2-800x800.jpg"
                                    className="absolute h-auto -m-16 -ml-20 text-pink-500 align-middle border-none rounded-full shadow-xl lg:-ml-16 max-w-150-px"
                                  />
                                </div>
                              </div>
                              <div className="w-full px-4 mt-20 text-center">
                                <div className="items-center justify-center mx-auto">
                                  <span className="block text-xl font-bold tracking-wide uppercase text-pinkGray-600">
                                    {user?.isPhoneConfirmed === true ? (
                                      <>
                                        {user?.firstName +
                                          " " +
                                          user?.secondName}{" "}
                                        <span className="inline mx-2">
                                          <CheckBadgeIcon
                                            className={`inline w-6 text-pink-500 stroke-pink-600`}
                                          />
                                        </span>
                                      </>
                                    ) : (
                                      <>
                                        <form
                                          onSubmit={handleSubmit(onSubmit)}
                                          className="flex flex-col flex-1 min-w-full"
                                        >
                                          <div
                                            className={`lex flex-col col-span-6 lg:col-span-3 mb-2 space-y-4 ${
                                              errors.phoneNumber &&
                                              "border-rose-500"
                                            } `}
                                          >
                                            <label
                                              className={`block mb-2 text-sm text-gray-600 ${l}`}
                                            >
                                              {/* {t("phoneNumber")} */}
                                            </label>
                                            <div className={`flex relative `}>
                                              <input
                                                type="tel"
                                                className={`w-full px-3 py-3 placeholder-gray-300 border border-gray-300 rounded-xl focus:outline-none focus:ring focus:ring-pink-100 focus:border-pink-600 ${
                                                  errors.phoneNumber &&
                                                  "focus:ring-rose-100 focus:border-rose-300"
                                                } ${l}`}
                                                placeholder={t(
                                                  "770(50)000 00 00"
                                                )}
                                                style={{ direction: "ltr" }}
                                                {...register("phoneNumber", {
                                                  required: true,
                                                  pattern: /[0-9]{1}/,
                                                })}
                                              />
                                            </div>
                                          </div>
                                          <button
                                            type="submit"
                                            className={`w-full mx-auto  px-1 py-3 text-md font-medium text-white  ease-in-out bg-gradient-to-br from-secondary-600 to-secondary-500 rounded-md hover:scale-x-110 focus:outline-none duration-500 scale-y-105 ${l}`}
                                          >
                                            <CheckBadgeIcon className="inline w-6 mx-1 text-white" />{" "}
                                            {t("confirm")}
                                          </button>
                                          <button
                                            type="button"
                                            onClick={(e) =>
                                              setOpenChangeNumberModal(true)
                                            }
                                            className={`w-full mx-auto my-2  px-1 py-3 text-md font-medium text-white  ease-in-out bg-gradient-to-br from-rose-600 to-rose-500 rounded-md hover:scale-x-110 focus:outline-none duration-500 scale-y-105 ${l}`}
                                          >
                                            <ArrowPathIcon className="inline w-6 mx-1 text-white" />{" "}
                                            {t("changePhoneNumber")}
                                          </button>
                                        </form>
                                      </>
                                    )}{" "}
                                  </span>{" "}
                                </div>
                                <div className="flex justify-center py-4 pt-8 lg:pt-4">
                                  <div className="p-3 mr-4 text-center">
                                    <span className="block text-lg font-bold tracking-wide uppercase text-pinkGray-600">
                                      {user?.isPhoneConfirmed === true ? (
                                        <>
                                          <CheckBadgeIcon className="inline w-6 text-pink-600" />{" "}
                                        </>
                                      ) : (
                                        <>
                                          <XCircleIcon className="inline w-6 text-rose-600" />{" "}
                                        </>
                                      )}
                                    </span>
                                    <span
                                      className={`text-sm text-pinkGray-400 ${l}`}
                                    >
                                      {t("status")}
                                    </span>
                                  </div>
                                  <div className="p-3 mr-4 text-center">
                                    <span className="block text-xl font-bold tracking-wide uppercase text-pinkGray-600">
                                      {address?.length}
                                    </span>
                                    <span
                                      className={`text-sm text-pinkGray-400 ${l}`}
                                    >
                                      {t("addresses")}
                                    </span>
                                  </div>
                                  <div className="p-3 text-center lg:mr-4">
                                    <span className="block text-xl font-bold tracking-wide uppercase text-pinkGray-600">
                                      {OrderHistory?.length}
                                    </span>
                                    <span
                                      className={`text-sm text-pinkGray-400 ${l}`}
                                    >
                                      {t("orders")}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="my-8 text-center">
                              <h3
                                className={`mb-2 text-lg leading-normal text-pinkGray-700 ${l}`}
                              >
                                <PhoneIcon className="inline w-6 mx-2 text-pink-500" />{" "}
                                {t("phoneNumberPlaceholder")}:{" "}
                                {user?.phoneNumber}
                              </h3>
                              <hr className="m-2 text-gray-300 rounded-lg" />
                              <h3
                                className={`mb-2 text-lg leading-normal text-tealGray-700 ${l}`}
                              >
                                <UserMinusIcon className="inline w-6 mx-2 text-rose-500" />{" "}
                                {t("deleteAccount")}:
                                <button
                                  onClick={() =>
                                    setOpenDeleteAccountModal(true)
                                  }
                                  className="p-2 m-1 rounded-lg bg-gradient-to-br from-rose-500 to-rose-600 text-gray-50"
                                >
                                  {t("delete")}
                                </button>
                              </h3>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </>
              )}
              {activeTab === 2 && (
                <>
                  <div className="col-span-8 px-8 overflow-hidden shadow rounded-xl bg-gray-50">
                    <div className="pt-4 pb-8 border-b">
                      <h1 className={`py-2 text-2xl font-semibold ${l}`}>
                        <MapPinIcon className="inline w-6 mx-2" />{" "}
                        {t("addresses")}
                      </h1>
                      <p className={`font- text-slate-600 ${l}`}>
                        {t("listOfAdderesses")}
                      </p>
                    </div>
                    {address.map((item, index) => (
                      <div className="grid py-6 border-b sm:grid-cols-2">
                        <div className="">
                          <h2 className="text-lg font-semibold leading-4 text-slate-700">
                            {item.addressTitle}
                          </h2>
                          <p className="my-1 font- text-slate-600 text-md">
                            {item?.city?.cityName?.english}
                            {item?.streetName?.length > 0 && (
                              <ChevronDoubleRightIcon className="inline w-3 mx-1" />
                            )}

                            {item?.streetName}
                            {item.buildingName?.length > 0 && (
                              <ChevronDoubleRightIcon className="inline w-3 mx-1" />
                            )}

                            {item.buildingName}
                          </p>
                        </div>
                        <div className="flex items-center sm:justify-end">
                          <div className="flex flex-col gap-3">
                            <label
                              className="relative inline-flex items-center cursor-pointer"
                              onClick={() => handleUpdateModal(item)}
                            >
                              <span
                                className={`mt-4 text-sm rounded-md bg-gradient-to-br from-pink-600 to-pink-500 px-6 py-2 font-medium text-white ${l}`}
                              >
                                <PencilIcon className="inline w-4 mx-2" />{" "}
                                {t("update")}
                              </span>
                            </label>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              )}
              {activeTab === 3 && (
                <div className="col-span-8 px-8 overflow-hidden shadow rounded-xl bg-gray-50">
                  <div className="pt-4 pb-8 border-b">
                    <h1 className={`py-2 text-2xl font-semibold ${l}`}>
                      {t("previosOrders")}
                    </h1>
                    <p className={`font- text-slate-600 ${l}`}>
                      {t("listOfPreviousOrders")}{" "}
                    </p>
                  </div>
                  {OrderHistory?.map((item) => (
                    <div className="grid py-6 border-b sm:grid-cols-2">
                      <div className="">
                        <h2 className="text-lg font-semibold leading-4 text-center text-slate-700">
                          {item?.orderAddress}
                        </h2>
                        <p className="text-center font- text-slate-600">
                          <div className="flex-row justify-between md:flex ">
                            <div className={`${l} text-center py-1 lg:py-0`}>
                              {Intl.NumberFormat()?.format(item?.totalPrice)}{" "}
                              {t("iqd")}
                            </div>
                            <div>{item?.orderDate.split("T")[0]}</div>
                            <div>
                              <span
                                // onClick={() => handleStatus(cellvalues.id)}
                                className={`inline-flex items-center justify-center px-2 lg:px-4 py-1  text-sm font-medium shadow-md ${
                                  item?.status === 1
                                    ? "text-emerald-700 bg-emerald-200 hover:bg-emerald-400 focus:ring-emerald-500 focus:ring-offset-emerald-200 "
                                    : item?.status === 0
                                    ? "text-orange-700 bg-orange-200 hover:bg-orange-400 focus:ring-orange-500 focus:ring-offset-orange-200 "
                                    : "text-rose-700 bg-rose-200 hover:bg-rose-400 focus:ring-rose-500 focus:ring-offset-rose-200  "
                                }  border-none rounded-md  focus:outline-none focus:ring-2 focus:ring-offset-2 ${l}`}
                              >
                                {handleOrderTypes(item?.status)}
                              </span>
                            </div>
                          </div>
                        </p>
                      </div>
                      <div className="flex items-center justify-center lg:justify-end">
                        <div className="flex flex-col gap-3">
                          <label
                            className={`mt-4 text-sm rounded-md bg-gradient-to-br from-pink-600 to-pink-500 w-full px-6 py-2 font-medium text-white ${l}`}
                            onClick={() => handleOrderDetail(item?.id)}
                          >
                            <span
                              className={` text-sm font-medium  py-2  text-white ${l}`}
                              onClick={() => handleOrderDetail(item?.id)}
                            >
                              <EyeIcon className="inline w-6 mx-2" />{" "}
                              {t("viewDetail")}
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
              {activeTab === 4 && (
                <NotificationCenter
                  notificationHistory={notificationCenterData}
                />
              )}
            </div>
          </div>
        </div>
        {/* Update Adderss */}
        {openUpdate && (
          <UpdateAddress
            Title={"Edit News"}
            open={openUpdate}
            id={updateId}
            setOpen={setUpdateModal}
          />
        )}{" "}
        {openViewOrderDetail && (
          <OrderDetailModal
            Title={"Order Detail"}
            open={openViewOrderDetail}
            setOpen={setOpenViewOrderDetail}
            orderDetail={orderDetail}
            user={user}
          />
        )}
        {openDeleteAccountModal && (
          <DeleteAccountModal
            Title={"Delete Account"}
            open={openDeleteAccountModal}
            setOpen={setOpenDeleteAccountModal}
            orderDetail={orderDetail}
            user={user}
          />
        )}
      </div>
      {showModal && (
        <>
          <Transition appear show={showModal} as={Fragment}>
            <Dialog
              as="div"
              className="relative z-10 "
              onClose={() => {}}
              disableBackdropClick={true}
            >
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-black bg-opacity-25" />
              </Transition.Child>

              <div className="fixed inset-0 overflow-y-auto">
                <div className="flex items-center justify-center min-h-full p-4 text-center">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                  >
                    <Dialog.Panel className="w-full max-w-xl p-6 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                      <div className="float-right" onClose={setShowModal}>
                        <XCircleIcon
                          onClose={setShowModal}
                          onClick={() => setShowModal(false)}
                          className="inline w-8 p-1 duration-500 rounded-full hover:text-white text-rose-600 hover:bg-rose-600"
                        />
                      </div>
                      <div className="relative flex flex-col justify-center py-8 overflow-hidden ">
                        <div className="relative w-full max-w-xl px-6 pt-10 mx-auto bg-white shadow-xl pb-9 rounded-2xl">
                          <div className="flex flex-col w-full max-w-3xl mx-auto space-y-16">
                            <div className="flex flex-col items-center justify-center space-y-2 text-center">
                              <div className="text-3xl font-semibold">
                                <p className={l}>{t("accountVerification")}</p>
                              </div>
                              <div className="flex flex-row text-sm font-medium text-gray-400">
                                <p className={`${l}`}>
                                  {t("weHaveSendMessageForYourPhoneNumber")}{" "}
                                </p>
                              </div>
                              <Player
                                src={loaderAnim}
                                autoplay
                                loop
                                className="w-56 player"
                              />
                            </div>
                            <div>
                              <form onSubmit={handleSubmit(verifyOTP)}>
                                <div className="flex flex-col space-y-16">
                                  <div className="flex flex-row items-center justify-between w-full mx-auto max-w-7xl">
                                    <span className="w-full mx-auto">
                                      <OtpInput
                                        value={otp}
                                        classNam
                                        onChange={handleChange}
                                        numInputs={6}
                                        // numInputs={6}
                                        separator={
                                          <span style={{ width: "8px" }}></span>
                                        }
                                        isInputNum={true}
                                        shouldAutoFocus={true}
                                        className="items-center justify-center mx-1 md:mx-2"
                                        inputStyle={combinedStyles}
                                        focusStyle={{
                                          border: "1px solid #CFD3DB",
                                          outline: "none",
                                        }}
                                      />
                                    </span>
                                  </div>
                                  <div className="flex flex-col space-y-5">
                                    <div>
                                      <button
                                        type="submit"
                                        id="submit"
                                        className={`flex flex-row items-center justify-center w-full py-5 text-sm text-center text-white bg-pink-600 border border-none shadow-sm outline-none rounded-xl ${l}`}
                                      >
                                        {t("verifyAccount")}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition>
        </>
      )}
      {openViewOrderDetail && (
        <>
          <Transition appear show={showModal} as={Fragment}>
            <Dialog as="div" className="relative z-10 " onClose={setShowModal}>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-black bg-opacity-25" />
              </Transition.Child>

              <div className="fixed inset-0 overflow-y-auto">
                <div className="flex items-center justify-center min-h-full p-4 text-center">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                  >
                    <Dialog.Panel className="w-full max-w-xl p-6 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                      <div className="relative flex flex-col justify-center py-8 overflow-hidden ">
                        <div className="relative w-full max-w-xl px-6 pt-10 mx-auto bg-white shadow-xl pb-9 rounded-2xl">
                          <div className="flex flex-col w-full max-w-3xl mx-auto space-y-16">
                            <div className="flex flex-col items-center justify-center space-y-2 text-center">
                              <div className="text-3xl font-semibold">
                                <p className={l}>{t("accountVerification")}</p>
                              </div>
                              <div className="flex flex-row text-sm font-medium text-gray-400">
                                <p>
                                  {t("weHaveSendMessageForYourPhoneNumber")}{" "}
                                </p>
                              </div>
                              <Player
                                src={loaderAnim}
                                autoplay
                                loop
                                className="w-56 player"
                              />
                            </div>
                            <div>
                              <form onSubmit={handleSubmit(verifyOTP)}>
                                <div className="flex flex-col space-y-16">
                                  <div className="flex flex-row items-center justify-between w-full mx-auto max-w-7xl">
                                    <span className="w-full mx-auto">
                                      <OtpInput
                                        value={otp}
                                        onChange={handleChange}
                                        numInputs={6}
                                        // numInputs={6}
                                        separator={
                                          <span style={{ width: "8px" }}></span>
                                        }
                                        isInputNum={true}
                                        shouldAutoFocus={true}
                                        className="mx-2"
                                        inputStyle={{
                                          border: "1px solid #2f3e46",
                                          borderRadius: "8px",
                                          width: "54px",
                                          height: "54px",
                                          fontSize: "16px",
                                          color: "#2f3e46",
                                          fontWeight: "400",
                                          caretColor: "pink",
                                        }}
                                        focusStyle={{
                                          border: "1px solid #CFD3DB",
                                          outline: "none",
                                        }}
                                      />
                                    </span>
                                  </div>
                                  <div className="flex flex-col space-y-5">
                                    <div>
                                      <button
                                        type="submit"
                                        id="submit"
                                        className="flex flex-row items-center justify-center w-full py-5 text-sm text-center text-white bg-pink-600 border border-none shadow-sm outline-none rounded-xl"
                                      >
                                        {t("verifyAccount")}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition>
        </>
      )}
      {/* Change Phone Number Modal */}
      {openChangeNumberModal && (
        <>
          <Transition appear show={openChangeNumberModal} as={Fragment}>
            <Dialog
              as="div"
              className="relative z-10"
              onClose={() => {}}
              disableBackdropClick={true}
            >
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-black bg-opacity-25" />
              </Transition.Child>

              <div className="fixed inset-0 overflow-y-auto">
                <div className="flex items-center justify-center min-h-full p-4 text-center">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                  >
                    <Dialog.Panel className="w-full max-w-lg overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                      <div className="float-right" onClose={setShowModal}>
                        <XCircleIcon
                          onClose={setOpenChangeNumberModal}
                          onClick={() => setOpenChangeNumberModal(false)}
                          className="inline w-8 p-1 duration-500 rounded-full hover:text-white text-rose-600 hover:bg-rose-600"
                        />
                      </div>
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-medium leading-6 text-gray-900"
                      >
                        <div className="py-10 text-center text-white bg-gradient-to-br from-pink-600 to-pink-500">
                          <p className={`${l} text-2xl font-semibold`}>
                            <ArrowPathIcon className="inline mx-2 w-7 " />{" "}
                            {t("updatePhoneNumber")}
                          </p>
                        </div>
                      </Dialog.Title>
                      <div className="p-6 mt-2">
                        <form
                          className="py-2 mb-6"
                          onSubmit={handleSubmit(handleChangePhoneNumber)}
                        >
                          <div className="mb-6">
                            <label
                              htmlFor="email"
                              className={`block mb-2 text-sm text-gray-600 ${
                                l === "en-US" ? "" : "float-right right-2"
                              } ${l}`}
                            >
                              {t("phoneNumberPlaceholder")}
                            </label>
                            <input
                              type="tel"
                              name="email"
                              id="email"
                              // placeholder={t("PhoneNumberPlaceholder")}
                              placeholder={t("770(50)000 00 00")}
                              {...register("phoneNumber", {
                                required: true,
                                pattern: /[0-9]{1}/,
                              })}
                              className={`w-full px-3 py-3 placeholder-gray-300 border border-gray-300 rounded-xl focus:outline-none focus:ring focus:ring-pink-100 focus:border-pink-600 ${
                                errors.phoneNumber &&
                                "focus:ring-rose-100 focus:border-rose-300"
                              } ${l}`}
                            />
                          </div>
                          <div className="mt-4">
                            <button
                              type="submit"
                              className={`inline-flex justify-center px-4 py-2 text-sm font-medium text-pink-900 bg-pink-100 border border-transparent rounded-md hover:bg-pink-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-pink-500 focus-visible:ring-offset-2 mb-2 ${
                                l === "en-US" ? "" : "float-right right-3"
                              } ${l} `}
                            >
                              {t("submit")}
                            </button>
                          </div>
                        </form>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition>
        </>
      )}

      <Footer />
      <CopyWrite />
    </div>
  );
};

export default AskVary;
