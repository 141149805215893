import React, { useEffect, useState } from "react";
import Navbar from "../componets/Navbar";
import Footer from "../componets/Footer";
import CopyWrite from "../componets/CopyWrite";
import Breadcrumb4 from "../componets/BreadCrumb";
import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/20/solid";

import {
  CheckCircleIcon,
  MagnifyingGlassIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";
import faq from "../assets/icons/Faq.svg";
// Redux
import { useDispatch, useSelector } from "react-redux";
import { getQuestion, searchFilter } from "../../features/Global/AskVary";
import usePagination from "../componets/pagination/Pagination";
import { Pagination } from "@mui/material";
import { pink } from "@mui/material/colors";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import Parser from "html-react-parser";
import Cookies from "js-cookie";

const AskVary = () => {
  const { t } = useTranslation();
  const l = localStorage.getItem("i18nextLng");
  const data = useSelector((state) => state.AskVary.copyData);
  const dispatch = useDispatch();
  const [openState, setOpenState] = useState();
  const [search, setSearch] = useState();

  useEffect(() => {
    dispatch(getQuestion());
  }, []);

  const PER_PAGE = 20;
  let [page, setPage] = useState(1);
  const count = Math.ceil(
    data.filter((item) => item?.answer !== "")?.length / PER_PAGE
  );
  const _DATA = usePagination(data, PER_PAGE);
  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };
  const handleOpenDrop = (id) => {
    console.log(id);
    setOpenState(id);
  };
  const handleFilter = (e) => {
    // setSearch(e?.target?.value);
    dispatch(searchFilter(e.target.value));
  };
  return (
    <div>
      <Navbar />
      <div className="grid items-start gap-0 pb-16 my-3 md:gap-6 ">
        <div className="container w-full p-5 mx-auto rounded-lg">
          <Breadcrumb4 Name={"askVary"} Iconn={QuestionMarkCircleIcon} />

          <div className="container flex flex-row flex-wrap mx-auto">
            <section className="w-full text-gray-700">
              <div className="container px-5 mx-auto">
                <div
                  className={`flex flex-col items-end justify-between mb-12 lg:flex-row header `}
                >
                  <div
                    className={`title w-full ${
                      l === "en-US"
                        ? "float-left text-left"
                        : " float-right text-right"
                    }`}
                  >
                    <p className={`mb-4 text-4xl font-bold text-pink-600 ${l}`}>
                      {t("askVary")}
                    </p>
                    <p className={`text-2xl font-light text-gray-400 ${l}`}>
                      {t("askVary")}
                    </p>
                  </div>

                  <div className="relative hidden w-full mx-3 mt-4 lg:inline-block lg:w-1/3">
                    {/* ::::label */}
                    <label htmlFor="search" className={` sr-only ${l}`}>
                      {"search"}
                    </label>
                    {/* ::::input */}
                    <input
                      type="search"
                      id="search"
                      name="search"
                      placeholder={t("search")}
                      value={search}
                      onChange={(e) => handleFilter(e)}
                      className={`block w-full px-10 py-3 text-sm placeholder-gray-300 border-gray-300 rounded-md shadow-sm form-input pl-11 bg-gray-50 focus:border-pink-600 focus:ring-1 focus:ring-pink-500 ${l}`}
                    />
                    {/* ::::icon */}
                    <span
                      className={`absolute text-gray-400 transform -translate-y-1/2 top-1/2 ${
                        l === "en-US" ? "left-3" : "right-3"
                      }`}
                    >
                      <MagnifyingGlassIcon className="w-4 h-4" />
                    </span>
                  </div>
                </div>
                <div className="w-full px-4 ">
                  <div className="w-full p-2 mx-auto bg-white rounded-2xl">
                    {_DATA.currentData().map(
                      (item, index) =>
                        item.answer !== "" &&
                        item.status &&
                        item.answer != null && (
                          <Disclosure className="my-2" key={item.id}>
                            {({ open }) => (
                              <>
                                <Disclosure.Button className="flex justify-between w-full px-4 py-4 my-2 font-medium text-left text-white bg-pink-500 rounded-lg text-md hover:bg-pink-600 focus:outline-none focus-visible:ring focus-visible:ring-pink-500 focus-visible:ring-opacity-75">
                                  <span className={`${l}`}>
                                    {item?.qestion}
                                  </span>
                                  <ChevronUpIcon
                                    className={`${
                                      open ? "rotate-180 transform" : ""
                                    } h-5 w-5 text-white`}
                                  />
                                </Disclosure.Button>
                                <Disclosure.Panel
                                  className={`px-4 pt-4 pb-2 text-md text-gray-500 ${l}`}
                                >
                                  {item?.answer.length > 0 && (
                                    <>{Parser(item?.answer)}</>
                                  )}{" "}
                                </Disclosure.Panel>
                              </>
                            )}
                          </Disclosure>
                        )
                    )}
                  </div>
                </div>
              </div>
              <div className="flex justify-end w-full px-3 my-5">
                <Pagination
                  count={count}
                  size="large"
                  page={page}
                  variant="outlined"
                  shape="rounded"
                  onChange={handleChange}
                  color="success"
                  className="text-pink-600 border-pink-400 "
                  showFirstButton
                  showLastButton
                  dir={`${l === "en-US" ? "ltr" : "rtl"}`}
                />
              </div>
            </section>
          </div>
        </div>
      </div>
      <Footer />
      <CopyWrite />
    </div>
  );
};

export default AskVary;
