import { Menu, Transition } from "@headlessui/react";
import { Fragment, useEffect, useRef, useState } from "react";
import CheckAuth from "../CheckAuthModal/CheckAuth";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import usalogo from "../../../web/assets/icons/usa.svg";
import krlogo from "../../assets/images/flag/kurdistan.png";
import irlogo from "../../assets/images/flag/iraq.png";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import {
  ArrowLeftOnRectangleIcon,
  ArrowRightCircleIcon,
  ArrowRightOnRectangleIcon,
  LanguageIcon,
  UserCircleIcon,
} from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";

export default function Account() {
  const { t } = useTranslation();
  function setPageDirection(selected) {
    // const dir = rtlLanguages.includes(selected) ? "rtl" : "ltr";
  }
  const Account = localStorage.getItem("i18nextLng");

  const isLogedIn = Cookies.get("webAuth")?.length > 0 ? true : false;
  const [checkLoginModal, setcheckLoginModal] = useState(false);
  const handleLogout = () => {
    Cookies.remove("webAuth");
    Cookies.remove("Customer");

    window.history.go(0);
  };
  const [User, setUser] = useState();
  const user = "";
  useEffect(() => {
    if (Cookies.get("Customer")) {
      setUser(JSON?.parse(Cookies.get("Customer")));
    }
  }, []);

  return (
    <div
      className={`flex items-center px-3 ${
        Account === "en-US" ? "ml-auto" : "mr-auto"
      }`}
    >
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button
            className={`inline-flex justify-center w-full h-full  py-2 text-sm font-medium text-gray-400 bg-white rounded-md bg-opacity-20 hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 hover:text-pink-600 duration-500 group ${Account}`}
          >
            {User === undefined ||
            User === null ||
            User === "" ||
            User === "null" ? (
              <>
                <UserCircleIcon className="w-6 h-6" />
              </>
            ) : (
              <>
                {/* <img src={user?.imageUrl} className="w-10 h-10 rounded-full" /> */}
                <UserCircleIcon className="w-6 h-6" />

                <p className="mx-1 my-auto font-medium text-gray-600 duration-500 group-hover:text-pink-600">
                  {User?.firstName + " " + User?.secondName}
                </p>
              </>
            )}

            {User === undefined ||
              User === null ||
              ("" && User?.firstName + " " + User?.secondName)}

            <ChevronDownIcon
              className="w-5 h-5 my-auto ml-2 -mr-1 text-gray-400 duration-500 group-hover:text-pink-600"
              aria-hidden="true"
            />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items
            className={`${
              Account === "en-US" ? "right-0" : "left-0"
            } w-56 mt-2 text-black origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg lg:absolute ring-1 ring-black ring-opacity-5 focus:outline-none`}
          >
            <div className="px-1 py-1 ">
              {/* {languages.map(({ code, name, country_code, image, index }) => ( */}
              {!Cookies.get("webAuth") && (
                <Menu.Item>
                  {({ active }) => (
                    <Link
                      to={"/login"}
                      className={`${
                        active ? "bg-pink-500 text-black" : "text-gray-900"
                      } group flex w-full items-center rounded-md px-2 py-2 text-sm ${Account}`}
                    >
                      {Account === "en-US" ? (
                        <ArrowRightOnRectangleIcon className="inline w-6 h-6 mx-1" />
                      ) : (
                        <ArrowLeftOnRectangleIcon className="inline w-6 h-6 mx-1" />
                      )}{" "}
                      {t("login")}
                    </Link>
                  )}
                </Menu.Item>
              )}
              {Cookies.get("webAuth") && (
                <>
                  <Menu.Item>
                    {({ active }) => (
                      <Link
                        to={"/userProfile"}
                        className={`${
                          active ? "bg-pink-500 text-black" : "text-gray-900"
                        } group flex w-full items-center rounded-md px-2 py-2 text-sm ${Account}`}
                      >
                        {Account === "en-US" ? (
                          <UserCircleIcon className="inline w-6 h-6 mx-1" />
                        ) : (
                          <UserCircleIcon className="inline w-6 h-6 mx-1" />
                        )}{" "}
                        {t("profile")}
                      </Link>
                    )}
                  </Menu.Item>
                </>
              )}
              {Cookies.get("webAuth") && (
                <Menu.Item>
                  {({ active }) => (
                    <button
                      onClick={() => handleLogout()}
                      className={`${
                        active ? "bg-pink-500 text-black" : "text-gray-900"
                      } group flex w-full items-center rounded-md px-2 py-2 text-sm ${Account}`}
                    >
                      {Account === "en-US" ? (
                        <ArrowLeftOnRectangleIcon className="inline w-6 h-6 mx-1" />
                      ) : (
                        <ArrowRightOnRectangleIcon className="inline w-6 h-6 mx-1" />
                      )}
                      {t("logout")}
                    </button>
                  )}
                </Menu.Item>
              )}
              {/* ))} */}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
}
