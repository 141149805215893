import React, { useEffect } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Chart, registerables } from 'chart.js/auto'
import { Bar } from 'react-chartjs-2';
import { getMainReport } from '../../features/Global/MostSellingByMonth'
import { useDispatch, useSelector } from 'react-redux';
Chart.register(...registerables)
ChartJS.register(ArcElement, Tooltip, Legend);
export function SellingPerMonth() {
  const dispatch = useDispatch();
  const databse = useSelector((state) => state.MostSellingByMonth.data);
  useEffect(() => {
    dispatch(getMainReport());
  }, [])
  const data = {
    labels: databse?.map((item) => new Date(item?.orderDate).toLocaleString()),
    datasets: [
      //   {
      //     label: '# of Orders',
      //     data: databse?.map((item) => item?.selledItem),
      //     backgroundColor: [
      //       'rgba(123, 44, 191, 0.8)',
      //       'rgba(247, 37, 133, 0.8)',
      //       'rgba(255, 206, 86, 0.8)',
      //       'rgba(75, 192, 192, 0.8)',
      //       'rgba(153, 102, 255, 0.8)',
      //       'rgba(255, 159, 64, 0.8)',
      //       'rgba(187, 208, 255, 0.8)',
      //       'rgba(200, 182, 255, 0.8)',
      //       'rgba(0, 78, 152, 0.8)',
      //       'rgba(58, 110, 165, 0.8)',
      //     ],
      //     borderColor: [
      //       'rgba(123, 44, 191, 1)',
      //       'rgba(247, 37, 133, 1)',
      //       'rgba(255, 206, 86, 1)',
      //       'rgba(75, 192, 192, 1)',
      //       'rgba(153, 102, 255, 1)',
      //       'rgba(255, 159, 64, 1)',
      //       'rgba(187, 208, 255, 1)',
      //       'rgba(200, 182, 255, 1)',
      //       'rgba(0, 78, 152, 1)',
      //       'rgba(58, 110, 165, 1)',
      //     ],
      //     borderWidth: 1,
      //   },
      // ],

      {
        label: 'Products',
        data: databse?.map((item) => item?.selledItem),
        backgroundColor: 'rgba(58, 134, 255, 0.8)',
      },
      {
        label: 'Total Price',
        data: databse?.map((item) => item?.totalOrdered),
        backgroundColor: 'rgba(251, 86, 7, 0.8)',
      }
    ]
  };
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: true,
        text: 'Monthly selling of this year',
      },
      customCanvasBackgroundColor: {
        color: 'none',
      },
    },

  };

  return <Bar className='dark:bg-gray-700' data={data} options={options} />
}
