import React from "react";
import { Link } from "react-router-dom";
import BreadCrumb from "../componets/BreadCrumb";
import CopyWrite from "../componets/CopyWrite";
import Footer from "../componets/Footer";
import Navbar from "../componets/Navbar";
// Assets
import image from '../assets/images/medicine.jpeg'
import image2 from '../assets/images/medicine3.jpeg'

const Cart = () => {
  return (
    <div>
        <Navbar />
        <BreadCrumb />
      {/* <!-- wrapper --> */}
      <div className="container grid grid-cols-12 items-start gap-6 pt-4 pb-16">
        {/* <!-- sidebar --> */}
        <div className="col-span-3">
          <div className="px-4 py-3 shadow flex items-center gap-4">
            <div className="flex-shrink-0">
              <img
                src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2370&q=80"
                alt="profile"
                className="rounded-full w-14 h-14 border border-gray-200 p-1 object-cover"
              />
            </div>
            <div className="flex-grow">
              <p className="text-gray-600">Hello,</p>
              <h4 className="text-gray-800 font-medium">John Doe</h4>
            </div>
          </div>

          <div className="mt-6 bg-white shadow rounded p-4 divide-y divide-gray-200 space-y-4 text-gray-600">
            <div className="space-y-1 pl-8">
              <Link to="#" className="block font-medium capitalize transition">
                <span className="absolute -left-8 top-0 text-base">
                  <i className="fa-regular fa-address-card"></i>
                </span>
                Manage account
              </Link>
              <Link
                to="#"
                className="relative hover:text-primary block capitalize transition"
              >
                Profile information
              </Link>
              <Link
                to="#"
                className="relative hover:text-primary block capitalize transition"
              >
                Manage addresses
              </Link>
              <Link
                to="#"
                className="relative hover:text-primary block capitalize transition"
              >
                Change password
              </Link>
            </div>

            <div className="space-y-1 pl-8 pt-4">
              <Link
                to="#"
                className="relative hover:text-primary block font-medium capitalize transition"
              >
                <span className="absolute -left-8 top-0 text-base">
                  <i className="fa-solid fa-box-archive"></i>
                </span>
                My order history
              </Link>
              <Link
                to="#"
                className="relative hover:text-primary block capitalize transition"
              >
                My returns
              </Link>
              <Link
                to="#"
                className="relative hover:text-primary block capitalize transition"
              >
                My Cancellations
              </Link>
              <Link
                to="#"
                className="relative hover:text-primary block capitalize transition"
              >
                My reviews
              </Link>
            </div>

            <div className="space-y-1 pl-8 pt-4">
              <Link
                to="#"
                className="relative hover:text-primary block font-medium capitalize transition"
              >
                <span className="absolute -left-8 top-0 text-base">
                  <i className="fa-regular fa-credit-card"></i>
                </span>
                Payment methods
              </Link>
              <Link
                to="#"
                className="relative hover:text-primary block capitalize transition"
              >
                voucher
              </Link>
            </div>

            <div className="space-y-1 pl-8 pt-4">
              <Link
                to="#"
                className="relative text-primary block font-medium capitalize transition"
              >
                <span className="absolute -left-8 top-0 text-base">
                  <i className="fa-regular fa-heart"></i>
                </span>
                My wishlist
              </Link>
            </div>

            <div className="space-y-1 pl-8 pt-4">
              <Link
                to="#"
                className="relative hover:text-primary block font-medium capitalize transition"
              >
                <span className="absolute -left-8 top-0 text-base">
                  <i className="fa-solid fa-right-from-bracket"></i>
                </span>
                Logout
              </Link>
            </div>
          </div>
        </div>
        {/* <!-- ./sidebar --> */}

        {/* <!-- wishlist --> */}
        <div className="col-span-9 space-y-4">
          <div className="flex items-center justify-between border gap-6 p-4 border-gray-200 rounded">
            <div className="w-28">
              <img
                src={image}
                alt="product 6"
                className="w-full"
              />
            </div>
            <div className="w-1/3">
              <h2 className="text-gray-800 text-xl font-medium uppercase">
                Tooth pase
              </h2>
              <p className="text-gray-500 text-sm">
                Availability: <span className="text-green-600">In Stock</span>
              </p>
            </div>
            <div className="text-primary text-lg font-semibold">$320.00</div>
            <Link
              to="#"
              className="px-6 py-2 text-center text-sm text-white bg-primary border border-primary rounded hover:bg-transparent hover:text-primary transition uppercase font-roboto font-medium"
            >
              add to cart
            </Link>

            <div className="text-gray-600 cursor-pointer hover:text-primary">
              <i className="fa-solid fa-trash"></i>
            </div>
          </div>

          <div className="flex items-center justify-between border gap-6 p-4 border-gray-200 rounded">
            <div className="w-28">
              <img
                src={image2}
                alt="product 6"
                className="w-full"
              />
            </div>
            <div className="w-1/3">
              <h2 className="text-gray-800 text-xl font-medium uppercase">
                Skin Care
              </h2>
              <p className="text-gray-500 text-sm">
                Availability: <span className="text-green-600">In Stock</span>
              </p>
            </div>
            <div className="text-primary text-lg font-semibold">$320.00</div>
            <Link
              to="#"
              className="px-6 py-2 text-center text-sm text-white bg-primary border border-primary rounded hover:bg-transparent hover:text-primary transition uppercase font-roboto font-medium"
            >
              add to cart
            </Link>

            <div className="text-gray-600 cursor-pointer hover:text-primary">
              <i className="fa-solid fa-trash"></i>
            </div>
          </div>

          <div className="flex items-center justify-between border gap-6 p-4 border-gray-200 rounded">
            <div className="w-28">
              <img
                src={image}
                alt="product 6"
                className="w-full"
              />
            </div>
            <div className="w-1/3">
              <h2 className="text-gray-800 text-xl font-medium uppercase">Antibiotic</h2>
              <p className="text-gray-500 text-sm">
                Availability: <span className="text-red-600">Out of Stock</span>
              </p>
            </div>
            <div className="text-primary text-lg font-semibold">$320.00</div>
            <Link
              to="#"
              className="cursor-not-allowed px-6 py-2 text-center text-sm text-white bg-red-400 border border-red-400 rounded transition uppercase font-roboto font-medium"
            >
              add to cart
            </Link>

            <div className="text-gray-600 cursor-pointer hover:text-primary">
              <i className="fa-solid fa-trash"></i>
            </div>
          </div>
        </div>
        {/* <!-- ./wishlist --> */}
      </div>
      <Footer />
      <CopyWrite />
    </div>
  );
};

export default Cart;
