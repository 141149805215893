import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAuth from "./useAuthDashboard";
import { useSelector } from "react-redux";
import Cookies from "js-cookie";
const RequireAuth = ({ allowedRoles }) => {
    const { auth } = useAuth();
    const location = useLocation();
    const isLogedIn = useSelector((state)=> state.Auth.value);
// console.log('isloged in', isLogedIn);
// console.log(Cookies.get('dashboardToken'))
    return (
       (Cookies.get('dashboardToken')?.length > 0 || (isLogedIn !== true && isLogedIn !== '')) 
            ? <Outlet />
            : auth?.user
                ? <Navigate to="/unauthorized" state={{ from: location }} replace />
                : <Navigate to="/dashboard/login" state={{ from: location }} replace />
    );
}

export default RequireAuth;