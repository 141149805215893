import React, { useTransition } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import stack from "../assets/images/stack.png";
const CopyWrite = () => {
  const { t } = useTransition();
  const date = new Date().getFullYear();
  return (
    <div
      className="w-full py-2 text-center bg-gray-200 " style={{direction: "ltr"}}
    >
      <a href="https://www.facebook.com/stack.solvers.official/">
        {" "}
        <big>
          <img src={stack} className="inline w-10 mx-2 my-auto" />©
        </big>{" "}
        {date}
      </a>
      <a
        className="font-semibold text-gray-500" target="_blank"
        href="https://www.facebook.com/stack.solvers.official/"
      >
        {" "}
        <span className="text-[#7FBC01]">Stack Solvers</span>
      </a>
    </div>
  );
};

export default CopyWrite;
