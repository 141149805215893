import React, { useEffect, useState } from "react";
import male from "../../assets/images/pharmisist-malle.webp";
import female from "../../assets/images/pharmisist-female.jpeg";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
// import Pagination from "../pagination/Pagination";
import BreadCrumb from "../BreadCrumb";
import { useDispatch, useSelector } from "react-redux";
import { getProfiles } from "../../../features/Global/Profiles";
import {
  IdentificationIcon,
  MagnifyingGlassIcon,
  MapPinIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";
import usePagination from "../pagination/Pagination";
import { Pagination } from "@mui/material";
import Cookies from "js-cookie";
import { searchFilter } from "../../../features/Global/Profiles";

const Profile = () => {
  const locale = useSelector((state) => state.locale.locale);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.Profiles.data);
  const l = localStorage.getItem("i18nextLng");
  const [search, setSearch] = useState();

  const PER_PAGE = 15;
  let [page, setPage] = useState(1);
  const count = Math.ceil(data?.length / PER_PAGE);
  var _DATA = usePagination(data, PER_PAGE);
  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  useEffect(() => {
    dispatch(getProfiles());
  }, []);
  const handleSearch = (e) => {
    dispatch(searchFilter(e.target.value));
  };

  return (
    <div>
      <div className="p-4 px-4 mx-auto bg-white rounded-md max-w-7xl sm:px-6 lg:px-8">
        <BreadCrumb
          Name={"doctors"}
          Iconn={UsersIcon}
          className="container px-5 m-2 my-3"
        />

        <div className="container flex flex-row flex-wrap py-10 mx-auto">
          <div
            className={`flex flex-col items-end justify-between mb-12 lg:flex-row header w-full`}
          >
            <div
              className={`title w-full ${
                l === "en-US"
                  ? "float-left text-left"
                  : " float-right text-right"
              }`}
            >
              <p className={`mb-4 text-4xl font-bold text-pink-600 ${l}`}>
                {t("doctors")}
              </p>
              <p className={`text-2xl font-light text-gray-400 ${l}`}>
                {t("doctorsDescription")}
              </p>
            </div>

            <div className="relative hidden w-full mx-3 mt-4 lg:inline-block lg:w-1/3">
              {/* ::::label */}
              <label htmlFor="search" className={` sr-only ${l}`}>
                {"search"}
              </label>
              {/* ::::input */}
              <input
                type="search"
                id="search"
                name="search"
                placeholder={t("search")}
                value={search}
                onChange={(e) => handleSearch(e)}
                className={`block w-full px-10 py-3 text-sm placeholder-gray-300 border-gray-300 rounded-md shadow-sm form-input pl-11 bg-gray-50 focus:border-pink-600 focus:ring-1 focus:ring-pink-500 ${l}`}
              />
              {/* ::::icon */}
              <span
                className={`absolute text-gray-400 transform -translate-y-1/2 top-1/2 ${
                  l === "en-US" ? "left-3" : "right-3"
                }`}
              >
                <MagnifyingGlassIcon className="w-4 h-4" />
              </span>
            </div>
          </div>
        </div>
        <section className="bg-white dark:bg-gray-900">
          <div className="max-w-screen-xl px-4 py-8 mx-auto text-center lg:py-16 lg:px-6">
            <div className="grid gap-8 lg:gap-16 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
              {_DATA
                ?.currentData()
                ?.filter((item) => item?.type === 1)
                ?.map((item, index) => (
                  <div
                    key={index}
                    className="text-center text-gray-500 duration-500 ease-in-out dark:text-gray-400 hover:scale-105"
                  >
                    <img
                      className="mx-auto mb-4 rounded-full w-44 h-36"
                      src={item.fileName}
                      alt="Bonnie Avatar"
                    />
                    <h3
                      className={`mb-1 text-2xl font-bold tracking-tight text-gray-900 dark:text-white ${l}`}
                    >
                      <span className={`${l}`}>
                        {l === "en-US" && item?.englishName}
                        {l === "ar" && item?.arabicName}
                        {l === "ku" && item?.kurdishName}
                      </span>
                    </h3>
                    <p className={`text-sm ${l}`}>
                      {/* <IdentificationIcon className="inline w-6 h-6 mx-1" /> */}
                      {l === "en-US" && item?.englishDescription}
                      {l === "ar" && item?.arabicDescription}
                      {l === "ku" && item?.kurdishDescription}
                    </p>
                    <p className={`${l} mt-2`}>
                      <svg
                        className={` inline w-6 ${
                          l === "en-US" ? "mr-1" : "ml-1"
                        }`}
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M21.5 12C21.5 17.5228 17.5228 21.5 12 21.5C6.47715 21.5 2.5 17.5228 2.5 12C2.5 6.47715 6.47715 2.5 12 2.5C17.5228 2.5 21.5 6.47715 21.5 12Z"
                          stroke="#12B8A6"
                        />
                        <path
                          d="M10.2202 7.77165C10.125 8.00136 10.125 8.29257 10.125 8.875V10.065C10.125 10.0933 10.125 10.1074 10.1162 10.1162C10.1074 10.125 10.0933 10.125 10.065 10.125H8.875C8.29257 10.125 8.00136 10.125 7.77165 10.2202C7.46536 10.347 7.22202 10.5904 7.09515 10.8966C7 11.1264 7 11.4176 7 12C7 12.5824 7 12.8736 7.09515 13.1034C7.22202 13.4096 7.46536 13.653 7.77165 13.7798C8.00136 13.875 8.29257 13.875 8.875 13.875H10.065C10.0933 13.875 10.1074 13.875 10.1162 13.8838C10.125 13.8926 10.125 13.9067 10.125 13.935V15.125C10.125 15.7074 10.125 15.9986 10.2202 16.2284C10.347 16.5346 10.5904 16.778 10.8966 16.9049C11.1264 17 11.4176 17 12 17C12.5824 17 12.8736 17 13.1034 16.9049C13.4096 16.778 13.653 16.5346 13.7798 16.2284C13.875 15.9986 13.875 15.7074 13.875 15.125V13.935C13.875 13.9067 13.875 13.8926 13.8838 13.8838C13.8926 13.875 13.9067 13.875 13.935 13.875H15.125C15.7074 13.875 15.9986 13.875 16.2284 13.7798C16.5346 13.653 16.778 13.4096 16.9049 13.1034C17 12.8736 17 12.5824 17 12C17 11.4176 17 11.1264 16.9049 10.8966C16.778 10.5904 16.5346 10.347 16.2284 10.2202C15.9986 10.125 15.7074 10.125 15.125 10.125H13.935C13.9067 10.125 13.8926 10.125 13.8838 10.1162C13.875 10.1074 13.875 10.0933 13.875 10.065V8.875C13.875 8.29257 13.875 8.00136 13.7798 7.77165C13.653 7.46536 13.4096 7.22202 13.1034 7.09515C12.8736 7 12.5824 7 12 7C11.4176 7 11.1264 7 10.8966 7.09515C10.5904 7.22202 10.347 7.46536 10.2202 7.77165Z"
                          stroke="#12B8A6"
                        />
                      </svg>

                      {l === "en-US" && item?.englishClinicName}
                      {l === "ar" && item?.arabicClinicName}
                      {l === "ku" && item?.kurdishClinicName}
                    </p>
                    <p className={`mt-2 ${l}`}>
                      <MapPinIcon
                        className={` inline w-6 text-rose-600 ${
                          l === "en-US" ? "mr-1" : "ml-1"
                        }`}
                      />
                      {l === "en-US" && item?.englishAddress}
                      {l === "ar" && item?.arabicAddress}
                      {l === "ku" && item?.kurdishAddress}
                    </p>
                    <ul className="flex justify-center mt-4 space-x-4 list-none">
                      <li>
                        <span className="text-[#39569c] hover:text-gray-900 dark:hover:text-white">
                          {item?.opensAt}
                        </span>
                      </li>
                      <li>-</li>
                      <li>
                        <Link className="text-[#00acee] hover:text-gray-900 dark:hover:text-white">
                          {item?.closesAt}
                        </Link>
                      </li>
                    </ul>
                  </div>
                ))}
            </div>
          </div>
          <div className="flex justify-end w-full px-3 my-5">
            <Pagination
              count={count}
              size="large"
              page={page}
              variant="outlined"
              shape="rounded"
              onChange={handleChange}
              color="success"
              className="text-pink-600 border-pink-400 "
              showFirstButton
              showLastButton
              dir={`${l === "en-US" ? "ltr" : "rtl"}`}
            />
          </div>
        </section>
        {/* Paginaton */}
        {/* <Pagination /> */}
        {/* Paginaton */}

        {/* <div className="flex justify-center mx-auto mt-5">
          <Link
            to={"/profiles"}
            type="button"
            title="more about"
            className="order-first w-full text-center transition bg-pink-100 lg:w-44 lg:py-3 sm:w-maxpx-6 rounded-xl hover:bg-pink-200 active:bg-pink-300 focus:bg-pink-200 "
          >
            <span className="block font-semibold text-pink-600">
              {t('viewMore')}
            </span>
          </Link>
        </div> */}
      </div>
    </div>
  );
};

export default Profile;
