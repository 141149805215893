import { configureStore } from "@reduxjs/toolkit";
import counterReducer from "../features/counter/counterSlice";
// Website
import filter from "../features/web/Filter";
import locale from "../features/web/Locale";
import WebAuthentication from "../features/web/WebAuthentication";
import ShopingCart from "../features/web/ShopingCart";
import ChatStatus from "../features/web/ChatStatus";
import wishList from "../features/Global/wishList";
import notificationCenter from "../features/Global/notificationCenter";

// import WebAuthentication from '../features/web/'
// Dashboard
import Auth from "../features/Dashboard/Authentication";

// Global
import category from "../features/Global/Category";
import NewArrival from "../features/Global/NewArrival";
import categoryDashboard from "../features/Global/Dashboard/Category";
import item from "../features/Global/Items";
import itemDashboard from "../features/Global/Dashboard/Items";
import AskVary from "../features/Global/AskVary";
import AskVaryDashboard from "../features/Global/Dashboard/AskVary";
import Configuration from "../features/Global/Configuration";
import News from "../features/Global/News";
import NewsDashboard from "../features/Global/Dashboard/News";
import OffersPercentage from "../features/Global/OffersPercentage";
import OffersBuyOneGet from "../features/Global/OffersBuyOneGet";
import Offers from "../features/Global/Offers";
import Orders from "../features/Global/Orders";
import Profiles from "../features/Global/Profiles";
import ProfilesDashboard from "../features/Global/Dashboard/Profiles";
import Users from "../features/Global/Users";
import Brands from "../features/Global/Brands";
import BrandsDashboard from "../features/Global/Dashboard/Brands";
import SubCategory from "../features/Global/SubCategory";
import SubCategoryDashboard from "../features/Global/Dashboard/SubCategory";
import Customers from "../features/Global/Customers";
import Chat from "../features/Global/Chat";
import stats from "../features/Global/Stats";
import Daashboard from "../features/Global/Dashboard";
import Discount from "../features/Global/Discount";
import Dis from "../features/Global/Dis";
import MostSelling from "../features/Global/MostSelling";
import MostSellingByCategories from "../features/Global/MostSellingByCategorise";
import MostSellingBySubCategories from "../features/Global/MostSellingBySubCategorise";
import MostSellingByProducts from "../features/Global/MostSellingByProducts";
import MostSellingByProductList from "../features/Global/MostSellingByProductsList";
import MostSellingByMonth from "../features/Global/MostSellingByMonth";
import Client from "../features/Global/Client";
import Messages from "../features/Global/Messages";
import MessagesDashborad from "../features/Global/MessagesDashborad";
import ChatUsers from "../features/Global/ChatUsers";
import DashboardNotification from "../features/Global/DashboardNotification";
import Address from "../features/Global/Address";
import OrderHistory from "../features/Global/OrderHistory";
import Contact from "../features/Global/ContactUs";
import Cities from "../features/Global/Cities";
import DashbaordCities from "../features/Global/Dashboard/Cities";
import MainReport from "../features/Global/MainReport";
import Carousel from "../features/Global/Carousel";
import Emails from "../features/Global/Emails";
import Sidebar from "../features/Global/Dashboard/Sidebar";
import HubConnection from "../features/Global/Dashboard/HubConnection";
// import dashboardHubReducer, { nonSerializableActions } from "./dashboardHubSlice";

import CurrentChatUser from "../features/Global/Dashboard/CurrentChatUser";
import DaashboardChat from "../features/Global/Dashboard/DashbaordChat";
import Banner from "../features/Global/Banner";
import DaashboardCarousel from "../features/Global/Dashboard/Carousel";


export const store = configureStore({
  reducer: {
    counter: counterReducer,
    filter,
    locale,
    Auth,
    WebAuthentication: WebAuthentication.reducer,
    ShopingCart: ShopingCart.reducer,
    category: category.reducer,
    item: item.reducer,
    AskVary: AskVary.reducer,
    Configuration: Configuration.reducer,
    News: News.reducer,
    OffersPercentage: OffersPercentage.reducer,
    OffersBuyOneGet: OffersBuyOneGet.reducer,
    Orders: Orders.reducer,
    Offers: Offers.reducer,
    Profiles: Profiles.reducer,
    Users: Users.reducer,
    Brands: Brands.reducer,
    SubCategory: SubCategory.reducer,
    stats: stats.reducer,
    Customers: Customers.reducer,
    Daashboard: Daashboard.reducer,
    Discount: Discount.reducer,
    Dis: Dis.reducer,
    MostSelling: MostSelling.reducer,
    Client: Client.reducer,
    Messages: Messages.reducer,
    MessagesDashborad: MessagesDashborad.reducer,
    ChatUsers: ChatUsers.reducer,
    DashboardNotification: DashboardNotification.reducer,
    Address: Address.reducer,
    OrderHistory: OrderHistory.reducer,
    Contact: Contact.reducer,
    MainReport: MainReport.reducer,
    Carousel: Carousel.reducer,
    MostSellingByCategories: MostSellingByCategories.reducer,
    MostSellingBySubCategories: MostSellingBySubCategories.reducer,
    MostSellingByProductList: MostSellingByProductList.reducer,
    MostSellingByProducts: MostSellingByProducts.reducer,
    MostSellingByMonth: MostSellingByMonth.reducer,
    Emails: Emails.reducer,
    Cities: Cities.reducer,
    Chat: Chat.reducer,
    NewArrival: NewArrival.reducer,
    ChatStatus: ChatStatus.reducer,
    wishList: wishList.reducer,
    notificationCenter: notificationCenter.reducer,

    // Dashboard store
    BrandsDashboard: BrandsDashboard.reducer,
    SubCategoryDashboard: SubCategoryDashboard.reducer,
    categoryDashboard: categoryDashboard.reducer,
    itemDashboard: itemDashboard.reducer,
    AskVaryDashboard: AskVaryDashboard.reducer,
    NewsDashboard: NewsDashboard.reducer,
    ProfilesDashboard: ProfilesDashboard.reducer,
    DashbaordCities: DashbaordCities.reducer,
    Sidebar: Sidebar.reducer,
    HubConnection: HubConnection.reducer,
    // Hub: dashboardHubReducer,

    DaashboardChat: DaashboardChat.reducer,
    Banner: Banner.reducer,
    CurrentChatUser: CurrentChatUser.reducer,
    DaashboardCarousel: DaashboardCarousel.reducer,

  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
