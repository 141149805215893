import React, { useEffect } from "react";
import { motion } from "framer-motion";

import { CheckBadgeIcon, XCircleIcon } from "@heroicons/react/24/outline";

const Toast = ({ Text, Type, setOpen }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      setOpen(false);
    }, 4000);
    return () => clearTimeout(timer);
  }, []);
  return (
    <motion.div
      className="fixed z-50 justify-start px-2 bottom-1 right-3"
      initial={{ opacity: 0, x: "100%" }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: "100%" }}
      transition={{ duration: 0.5, type: "tween" }}
    >
      <div
        className={`flex  items-center rounded-lg shadow-lg mb-5   bg-${Type}-500 p-4 text-white`}
      >
        <div className="inline w-64">
          {/* <h4 className="mb-2 font-bold">Title</h4> */}

          <p className="text-sm text-inline">
            {" "}
            {Type === "blue" ? (
              <CheckBadgeIcon className="inline w-6 h-6" />
            ) : (
              <XCircleIcon className="inline w-6 h-6" />
            )}{" "}
            {Text}
          </p>
        </div>
        <div className={`w-10 bg-${Type}-500`}>
          <div className={`text-md p-2 bg-${Type}-600 rounded-full`}>
            <XCircleIcon className="" onClick={() => setOpen(false)} />
            {/* <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-6 h-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                strokeLinejoin="round"
                  strokeWidth="2"
                  d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                />
              </svg> */}
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default Toast;
