import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: null,
};

export const DashboardHubSlice = createSlice({
  name: "Hub",
  initialState,
  reducers: {
    increment: (state) => {
      state.value = !state.value;
    },
    // getHub: (state) => {
    //   return state.value;
    // },
    setHubConnection: (state, action) => {
      state.value = action.payload;
      // console.log('hub connection', state.value);
    },
  },
});

// Action creators are generated for each case reducer function
export const { increment, setHubConnection } = DashboardHubSlice.actions;

export default DashboardHubSlice;
