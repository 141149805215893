import React from "react";
import style from "./loade.css";
import { Player } from "@lottiefiles/react-lottie-player";
import loaderAnim from "./loader.json";

const Spiner = () => {
  return (
    <div className="w-full h-full mt-10">
      <Player src={loaderAnim} autoplay loop className="w-56 player" />
      {/* <div className="spinner-box">
        <div className="pulse-container">
          <div className="pulse-bubble pulse-bubble-1"></div>
          <div className="pulse-bubble pulse-bubble-2"></div>
          <div className="pulse-bubble pulse-bubble-3"></div>
        </div>
      </div> */}
    </div>
  );
};

export default Spiner;
