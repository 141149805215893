import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import Cookies from "js-cookie";

export default function MyModal({ data, open, onClose }) {
  let [isOpen, setIsOpen] = useState(true);
  var sum = 0;
  const handleSum = (data) => {
    sum = data?.orderItems.reduce((a, v) => (a = a + v.currentPrice), 0);
    return sum;
  };
  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={onClose}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md p-6 overflow-hidden text-left align-middle transition-all transform bg-white rounded-2xl">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  ></Dialog.Title>
                  <div className="mt-2">
                    <div className="w-full max-w-lg mx-auto">
                      <div className="p-4 mx-4 mt-4 text-xs bg-white rounded-lg sm:mx-0">
                        <div>
                          <div className="flex flex-col">
                            <h1 className="mb-2 text-xl font-medium text-gray-800">
                              Vary Healthcare
                            </h1>
                            <p className="text-xs text-gray-600">
                              Order Number: {data?.orderItems[0]?.orderId}
                            </p>
                            <p className="text-xs text-gray-600">
                              Customer:{" "}
                              {data?.user?.firstName +
                                " " +
                                data?.user?.secondName +
                                " " +
                                data?.user?.thirdName}
                            </p>
                            <p className="text-xs text-gray-600">
                              Address: {data?.orderItems[0]?.address}
                            </p>
                            <p className="text-xs text-gray-600">
                              PhoneNumber: 0{data?.user?.phoneNumber}
                            </p>
                            <p className="text-xs text-gray-600">
                              Date:{" "}
                              {new Date()
                                .toString()
                                ?.split(" GMT+0300 (Arabian Standard Time)")}
                            </p>
                          </div>
                          <hr className="my-4" />
                          <div>
                            {data?.orderItems?.map((item) => (
                              <>
                                {" "}
                                <div className="flex items-center justify-between">
                                  <span className="text-base font-medium">
                                    {item?.productName.english}{" "}
                                  </span>
                                  <span className="text-base font-medium">
                                    {Intl.NumberFormat().format(
                                      item?.currentPrice
                                    )}{" "}
                                    IQD
                                  </span>
                                </div>
                                <div className="flex items-center justify-between mb-4">
                                  <span>*Quantity:</span>
                                  <span className="">X{item?.qty}</span>
                                </div>
                              </>
                            ))}
                            <div className="flex items-center justify-between mb-2">
                              {/* <span>Taxes & Fees</span> */}
                              {/* <span className="">$8.36</span> */}
                            </div>
                            <div className="flex items-center justify-between">
                              <span className="text-lg font-medium">Total</span>
                              <span className="text-lg font-medium">
                                {Intl?.NumberFormat().format(handleSum(data))}{" "}
                                IQD
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
