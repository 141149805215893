import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { EyeDropperIcon, EyeIcon, InformationCircleIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { PlusCircleIcon } from "@heroicons/react/24/outline";
import { XCircleIcon } from "@heroicons/react/24/outline";
import Cookies from "js-cookie";

export default function SlideOver({ Title, open, setOpen, chilren }) {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="fixed inset-y-0 right-0 flex max-w-full pl-10 pointer-events-none">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="relative w-screen max-w-md pointer-events-auto">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-500"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-500"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-0 left-0 flex pt-4 pr-2 -ml-8 sm:-ml-10 sm:pr-4">
                      <button
                        type="button"
                        className="text-gray-300 rounded-md hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                        onClick={() => setOpen(false)}
                      >
                        <span className="sr-only">Close panel</span>
                        <XCircleIcon className="w-6 h-6 text-red-500" aria-hidden="true" />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="flex flex-col h-full py-6 overflow-y-scroll bg-white shadow-xl dark:bg-gray-800">
                    <div className="px-4 sm:px-6">
                      <Dialog.Title className="px-6 py-2 text-xl font-semibold text-indigo-500 border border-transparent rounded-md font-bg-indigo-50 dark:text-orange-400">
                        <InformationCircleIcon className="inline w-6 h-6" /> {Title}
                      </Dialog.Title>
                    </div>
                    <hr className="mx-1 text-gray-300 dark:text-gray-700" />
                    <div className="relative flex-1 px-4 mt-6 sm:px-6">
                      {/* Replace with your content */}

                      <div className="absolute inset-0 px-4 sm:px-6 ">
                        {/* <!-- component --> */}
                        <div className="">
                          {/* <!-- Author: FormBold Team --> */}
                          {/* <!-- Learn More: https://formbold.com --> */}
                          <div className="mx-auto w-full max-w-[550px] bg-white dark:bg-gray-800">
                            <form
                              className="px-1 py-6"
                              action="https://formbold.com/s/FORM_ID"
                              method="POST"
                            >
                              <div className="mb-5">
                                <label
                                  htmlFor="email"
                                  className="mb-2 block text-base font-medium text-[#07074D] dark:text-gray-300"
                                >
                                  Item:
                                </label>
                                <input
                                  type="text"
                                  name="email"
                                  id="email"
                                  placeholder="Item name"
                                  className="w-full rounded-md border border-[#e0e0e0] bg-white py-2 px-6 text-base font-medium text-gray-700 outline-none focus:border-orange-500 focus:shadow-md dark:bg-gray-700 dark:text-gray-300"
                                />
                              </div>
                              <div className="mb-5">
                                <label
                                  htmlFor="email"
                                  className="mb-2 block text-base font-medium text-[#07074D] dark:text-gray-300"
                                >
                                  Item:
                                </label>
                                <input
                                  type="text"
                                  name="email"
                                  id="email"
                                  placeholder="Item name"
                                  className="w-full rounded-md border border-[#e0e0e0] bg-white py-2 px-6 text-base font-medium text-gray-700 outline-none focus:border-orange-500 focus:shadow-md dark:bg-gray-700 dark:text-gray-300"
                                />
                              </div>
                              <div className="mb-5">
                                <label
                                  htmlFor="email"
                                  className="mb-2 block text-base font-medium text-[#07074D] dark:text-gray-300"
                                >
                                  Item:
                                </label>
                                <input
                                  type="text"
                                  name="email"
                                  id="email"
                                  placeholder="Item name"
                                  className="w-full rounded-md border border-[#e0e0e0] bg-white py-2 px-6 text-base font-medium text-gray-700 outline-none focus:border-orange-500 focus:shadow-md dark:bg-gray-700 dark:text-gray-300"
                                />
                              </div><div className="mb-5">
                                <label
                                  htmlFor="email"
                                  className="mb-2 block text-base font-medium text-[#07074D] dark:text-gray-300"
                                >
                                  Item:
                                </label>
                                <input
                                  type="text"
                                  name="email"
                                  id="email"
                                  placeholder="Item name"
                                  className="w-full rounded-md border border-[#e0e0e0] bg-white py-2 px-6 text-base font-medium text-gray-700 outline-none focus:border-orange-500 focus:shadow-md dark:bg-gray-700 dark:text-gray-300"
                                />
                              </div>
                              <div className="pt-4 mb-6">
                                <label className="mb-5 block text-xl font-semibold text-[#07074D] dark:text-gray-300">
                                  Upload Image
                                </label>

                                <div className="mb-8">
                                  <input
                                    type="file"
                                    name="file"
                                    id="file"
                                    className="sr-only"
                                  />
                                  <label
                                    htmlFor="file"
                                    className="relative flex min-h-[200px] items-center justify-center rounded-md border border-dashed border-[#e0e0e0] p-12 text-center"
                                  >
                                    <div>
                {/*                       <span className="mb-2 block text-xl font-semibold text-[#07074D] dark:text-gray-300">
                                        Drop files here
                                      </span> */}
                                      <span className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-300">
                                        Or
                                      </span>
                                      <span className="inline-flex rounded border border-[#e0e0e0] py-2 px-7 text-base font-medium text-[#07074D] dark:text-gray-300">
                                        Browse
                                      </span>
                                    </div>
                                  </label>
                                </div>
                              </div>
                              <div>

                       
                                <button
                                onClick={()=> setOpen(false)}
                                  type="button"
                                  className={
                                    "bg-red-50 mx-1 px-4 py-2  float-right rounded-md text-red-500 hover:bg-red-200 border border-transparent focus:outline-none focus:ring-2 focus:ring-red-500 focus:border-transparent dark:text-red-100 dark:bg-red-600"
                                  }
                                  // {...props}
                                >
                                  <XCircleIcon className="inline w-6 h-6 mx-2" />
                                  Cancel
                                  {/* {children} */}
                                </button>
                              </div>
                            </form>
                          </div>
                        </div>

                        {/* <div className="h-full border-2 border-gray-200 border-dashed" aria-hidden="true" /> */}
                      </div>
                      {/* {/* /End replace */}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
