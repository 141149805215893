import {
  BeakerIcon,
  ListBulletIcon,
  TagIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";
import React, { useEffect, useMemo } from "react";
// redux mapping
import { useDispatch, useSelector } from "react-redux";
import { getStats } from "../../../features/Global/Stats";
import CountUp from "react-countup";
const Carts = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.stats?.data);
  // hook to fetch items
  useMemo(() => {
    dispatch(getStats());
  }, [dispatch]);
  return (
    <div className="flex flex-col my-2">
      <div className="grid grid-cols-1 gap-4 mt-4 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
        <div className="flex items-start p-4 duration-500 shadow-lg bg-gradient-to-br from-blue-50 to-blue-100 hover:scale-105 hover:bg-gradient-to-r rounded-xl dark:bg-gradient-to-br dark:from-blue-900 dark:to-blue-800 ">
          <div className="flex items-center justify-center w-12 h-12 border border-blue-100 rounded-full bg-blue-50">
            {/* <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-6 h-6 text-blue-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"
              />
            </svg> */}
            <BeakerIcon className="w-6 h-6 text-blue-400" />
          </div>

          <div className="ml-4">
            <h2 className="font-semibold text-blue-600 dark:text-gray-200">Products</h2>
            <p className="mt-2 text-lg text-blue-500 dark:text-blue-50">
              <CountUp end={data?.products} />
            </p>
          </div>
        </div>

        <div className="flex items-start p-4 duration-500 shadow-lg bg-gradient-to-br from-orange-50 to-orange-100 hover:scale-105 hover:bg-gradient-to-r rounded-xl dark:bg-gradient-to-br dark:from-orange-900 dark:to-orange-800">
          <div className="flex items-center justify-center w-12 h-12 border border-orange-100 rounded-full bg-orange-50">
            {/* <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-6 h-6 text-orange-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
              />
            </svg> */}
            <ListBulletIcon className="w-6 h-6 text-orange-400" />
          </div>

          <div className="ml-4">
            <h2 className="font-semibold text-orange-600 dark:text-gray-200">Orders</h2>
            <p className="mt-2 text-lg text-orange-500 dark:text-orange-50">
              <CountUp end={data?.orders} />
            </p>
          </div>
        </div>
        <div className="flex items-start p-4 duration-500 shadow-lg bg-gradient-to-br from-rose-50 to-rose-100 hover:scale-105 hover:bg-gradient-to-r rounded-xl dark:bg-gradient-to-br dark:from-rose-900 dark:to-rose-800">
          <div className="flex items-center justify-center w-12 h-12 border border-red-100 rounded-full bg-red-50">
            {/* <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-6 h-6 text-red-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253"
              />
            </svg> */}
            <UsersIcon className="w-6 h-6 text-red-400" />
          </div>

          <div className="ml-4">
            <h2 className="font-semibold text-rose-600 dark:text-gray-200">Customers</h2>
            <p className="mt-2 text-lg text-red-500 dark:text-rose-50">
              <CountUp end={data?.users} />
            </p>
          </div>
        </div>
        <div className="flex items-start p-4 duration-500 shadow-lg bg-gradient-to-br from-indigo-50 to-indigo-100 hover:scale-105 hover:bg-gradient-to-r rounded-xl dark:bg-gradient-to-br dark:from-indigo-900 dark:to-indigo-800">
          <div className="flex items-center justify-center w-12 h-12 border border-indigo-100 rounded-full bg-indigo-50">
            {/* <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-6 h-6 text-indigo-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"
              />
            </svg> */}
            <TagIcon className="w-6 h-6 text-indigo-400" />
          </div>

          <div className="ml-4">
            <h2 className="font-semibold text-indigo-600 dark:text-gray-200">Categories</h2>
            <p className="mt-2 text-lg text-indigo-500 dark:text-indigo-50">
              <CountUp end={data?.category} />
            </p>
          </div>
        </div>
      </div>
      {/*   
  // <div className="flex items-center">
  //   <div className="w-full my-4">
  //     <div className="flex flex-col w-full mb-2 space-y-2 lg:flex-row lg:space-x-2 lg:space-y-0 lg:mb-4">
  //       <div className="w-full">
  //         <div className="w-full p-4 transition duration-500 transform bg-white border border-gray-100 rounded-lg widget dark:bg-gray-800 dark:border-gray-700 hover:scale-105 hover:shadow-lg hover:shadow-orange-300">
  //           <div className="flex flex-row items-center justify-between">
  //             <div className="flex flex-col">
  //               <div className="text-xs font-light text-orange-500 uppercase">
  //                 Customers
  //               </div>
  //               <div className="text-xl font-bold text-orange-500">23</div>
  //             </div>
  //             <svg
  //               className="text-orange-500 stroke-current"
  //               fill="none"
  //               height="24"
  //               stroke="currentColor"
  //               strokeLinecap="round"
  //               strokeLinejoin="round"
  //               strokeWidth="2"
  //               viewBox="0 0 24 24"
  //               width="24"
  //               xmlns="http://www.w3.org/2000/svg"
  //             >
  //               <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
  //               <circle cx="9" cy="7" r="4"></circle>
  //               <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
  //               <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
  //             </svg>
  //           </div>
  //         </div>
  //       </div>
  //       <div className="w-full ">
  //         <div className="w-full p-4 transition duration-500 transform bg-white border border-gray-100 rounded-lg widget dark:bg-gray-800 dark:border-gray-700 hover:scale-105 hover:shadow-lg hover:shadow-rose-300">
  //           <div className="flex flex-row items-center justify-between">
  //             <div className="flex flex-col">
  //               <div className="text-xs font-light uppercase text-rose-500">
  //                 Orders
  //               </div>
  //               <div className="text-xl font-bold text-rose-500">23</div>
  //             </div>
  //             <svg
  //               className="stroke-current text-rose-500"
  //               fill="none"
  //               height="24"
  //               stroke="currentColor"
  //               strokeLinecap="round"
  //               strokeLinejoin="round"
  //               strokeWidth="2"
  //               viewBox="0 0 24 24"
  //               width="24"
  //               xmlns="http://www.w3.org/2000/svg"
  //             >
  //               <path
  //                 strokeLinecap="round"
  //                 strokeLinejoin="round"
  //                 d="M8.25 6.75h12M8.25 12h12m-12 5.25h12M3.75 6.75h.007v.008H3.75V6.75zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zM3.75 12h.007v.008H3.75V12zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm-.375 5.25h.007v.008H3.75v-.008zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z"
  //               />
  //             </svg>
  //           </div>
  //         </div>
  //       </div>
  //       <div className="w-full ">
  //         <div className="w-full p-4 transition duration-500 transform bg-white border border-gray-100 rounded-lg widget dark:bg-gray-800 dark:border-gray-700 hover:scale-105 hover:shadow-lg hover:shadow-indigo-300">
  //           <div className="flex flex-row items-center justify-between">
  //             <div className="flex flex-col">
  //               <div className="text-xs font-light text-indigo-500 uppercase">
  //                 Items
  //               </div>
  //               <div className="text-xl font-bold text-indigo-500">23</div>
  //             </div>

  //             <svg
  //               className="text-indigo-500 stroke-current"
  //               fill="none"
  //               height="24"
  //               // stroke="currentColor"
  //               // strokeLinecap="round"
  //               // strokeLinejoin="round"
  //               strokeWidth="1.3"
  //               viewBox="0 0 24 24"
  //               width="24"
  //               xmlns="http://www.w3.org/2000/svg"
  //             >
  //               <path
  //                 fillRule="evenodd"
  //                 d="M11.02 5.364a3 3 0 0 0-4.242-4.243L1.121 6.778a3 3 0 1 0 4.243 4.243l5.657-5.657Zm-6.413-.657 2.878-2.879a2 2 0 1 1 2.829 2.829L7.435 7.536 4.607 4.707ZM12 8a4 4 0 1 1 0 8 4 4 0 0 1 0-8Zm-.5 1.041a3 3 0 0 0 0 5.918V9.04Zm1 5.918a3 3 0 0 0 0-5.918v5.918Z"
  //               />
  //             </svg>
  //           </div>
  //         </div>
  //       </div>
  //       <div className="w-full">
  //         <div className="w-full p-4 transition duration-500 transform bg-white border border-gray-100 rounded-lg widget dark:bg-gray-800 dark:border-gray-700 hover:scale-105 hover:shadow-lg hover:shadow-green-300">
  //           <div className="flex flex-row items-center justify-between">
  //             <div className="flex flex-col">
  //               <div className="text-xs font-light text-green-500 uppercase">
  //                 Categories
  //               </div>
  //               <div className="text-xl font-bold text-green-500">23</div>
  //             </div>
  //             <svg
  //               className="text-green-500 stroke-current"
  //               fill="none"
  //               height="24"
  //               stroke="currentColor"
  //               strokeLinecap="round"
  //               strokeLinejoin="round"
  //               strokeWidth="2"
  //               viewBox="0 0 24 24"
  //               width="24"
  //               xmlns="http://www.w3.org/2000/svg"
  //             >
  //               <path
  //                 strokeLinecap="round"
  //                 strokeLinejoin="round"
  //                 d="M9.568 3H5.25A2.25 2.25 0 003 5.25v4.318c0 .597.237 1.17.659 1.591l9.581 9.581c.699.699 1.78.872 2.607.33a18.095 18.095 0 005.223-5.223c.542-.827.369-1.908-.33-2.607L11.16 3.66A2.25 2.25 0 009.568 3z"
  //               />
  //               <path
  //                 strokeLinecap="round"
  //                 strokeLinejoin="round"
  //                 d="M6 6h.008v.008H6V6z"
  //               />
  //             </svg>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  // </div> */}
    </div>
  );
};

export default Carts;
